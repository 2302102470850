import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery  } from "@apollo/client";
import { Equipment } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_LAZY_EQUIPMENT_BY_ID = gql`
  query GetLazyEquipmentById($equipmentId: String!) {
    equipmentById(equipmentId: $equipmentId) {
      id
      uniqueName
      manufacturer
      modelNumber
      department
      hours
      hoursOffset
      countHours
      categoryId
      locationId
      reportingTag
      restricted
      sms
      notes
      supplier
      inService
      expireDate
      serialNum
      arrangementNum
      artNumber
      dateEntered
      rating
      parentId
      commentsCount
      documentsCount
      photosCount
      workIssuesCount
      inventoryCount
      logEntryCount
    }
  }
`
interface EquipmentByIdData {
  equipmentById: Equipment;
}

interface EquipmentByIdResult {
  getLazyEquipmentById: LazyQueryExecFunction<EquipmentByIdData, OperationVariables>;
  equipmentByIdloading: boolean;
}

export const useGetLazyEquipmentById = (): EquipmentByIdResult => {

  const [ getEquipmentById, { loading, error }] = useLazyQuery(GET_LAZY_EQUIPMENT_BY_ID, {
    fetchPolicy:"network-only",
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetLazyEquipmentById').error('Error fetching equipment by ID -->', error.message);
  }

  return {
    getLazyEquipmentById: getEquipmentById,
    equipmentByIdloading: loading
  };
};