import { FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { Control } from 'react-hook-form';
import RichTextEditor from '../../../UI/Forms/RichTextEditor';
import DatePicker from '../../../UI/Forms/DatePicker';
import FormRevisions from './FormRevisions';
import { handleCharLimitWarning, toJSON } from '../../../../utils';
import AutocompleteInput from 'src/components/UI/Forms/AutocompleteInput';
import { CHAR_LIMIT } from 'src/consts';
import { isValidDateFormat } from 'src/utils/format-dates';
import { Form } from 'src/generated/dotnet.graphql';
import { CrewType } from 'src/hooks/crewNames/useGetCrewNames';

interface InjectedProps {
  form: Form;
  crewData: CrewType[];
  onChange: (field: string, value: any) => void;
  control: Control;
}

const FormsSummaryForm: FC<InjectedProps> = ({
  form,
  crewData,
  onChange,
  control,
}) => {
  
  const { TBLANYREPORT } = CHAR_LIMIT;

  return (
    <div className="mt-3">
      <Accordion className="mb-3" disableGutters defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="Report summary"
          id="report-summary"
        >
          General Information
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full flex flex-row mb-5">
            <div className="mr-2 w-1/2">
              <AutocompleteInput
                rules={{ required: 'Created by required!' }}
                displayExpr="name"
                valueExpr="crewID"
                options={crewData}
                label="Created By"
                placeholder='Created By'
                control={control}
                name="createdBy"
              />
            </div>

            <div className="ml-2 w-1/2">
              <DatePicker
                views={['year', 'month', 'day']}
                size="small"
                name="dateCreated"
                control={control}
                label="Created Date"
                rules={{
                  validate: (value) => isValidDateFormat(value) || 'Please enter a valid date format dd-MMM-yyyy',
                }}
              />
            </div>
          </div>

          <div className="w-full flex flex-row mb-5">
            <div className="mr-2 w-1/2">
              <AutocompleteInput
                displayExpr="name"
                valueExpr="crewID"
                options={crewData}
                label="Completed By"
                placeholder='Completed By'
                control={control}
                name="completedBy"
              />
            </div>

            <div className="ml-2 w-1/2">
              <DatePicker
                views={['year', 'month', 'day']}
                size="small"
                name="completedDate"
                control={control}
                label="Completed Date"
                rules={{
                  validate: (value) => isValidDateFormat(value) || 'Please enter a valid date format dd-MMM-yyyy',
                }}
              />
            </div>
          </div>

          <div className="w-full flex flex-row mb-5">
            <div className="mr-2 w-1/2">
              <AutocompleteInput
                displayExpr="name"
                valueExpr="crewID"
                options={crewData}
                label="Closed By"
                placeholder='Verified By'
                control={control}
                name="verifiedBy"
              />
            </div>

            <div className="ml-2 w-1/2">
              <DatePicker
                views={['year', 'month', 'day']}
                size="small"
                name="verifiedDate"
                control={control}
                label="Closed Date"
                rules={{
                  validate: (value) => isValidDateFormat(value) || 'Please enter a valid date format dd-MMM-yyyy',
                }}
              />
            </div>
          </div>
          <div className="my-4">
            <RichTextEditor
              label="Remarks"
              control={control}
              name="remark"
              rules={{ maxLength: TBLANYREPORT.fldRemark }}
              warning={(value) => handleCharLimitWarning(value, TBLANYREPORT.fldRemark)}
              onChange={onChange}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion disableGutters defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="Revision History"
          id="revision-history"
        >
          Revision History
        </AccordionSummary>
        <AccordionDetails>
          <FormRevisions form={form} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FormsSummaryForm;
