import { ApolloQueryResult, gql, useQuery } from '@apollo/client';
import { Form, FormsFilterInput, FormSortInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { PaginatedResult } from '../interfaces';

export const GET_FORMS = gql`
  query GetForms($skip: Int, $take: Int, $input: FormsFilterInput!, $order: [FormSortInput!]) {
    forms(skip: $skip, take: $take, input: $input, order: $order) {
      items {
        __typename
        id
        createdBy
        completedBy
        dateCreated
        reportFile
        reportNumber
        reportType
        remark
        createdByName
        completedDate
        verifiedDate
        verifiedBy
        documentsCount
        photosCount
        commentsCount
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export const useGetForms = (skip: number, take: number, input: FormsFilterInput, order: FormSortInput[]): PaginatedResult<Form, FormsFilterInput, FormSortInput> => {
  const { data, loading, error, refetch } = useQuery(GET_FORMS, {
    variables: { skip, take, input, order },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });

  const loadData = ( skip: number, take: number, input: FormsFilterInput, order: FormSortInput[]) => {
    logger('GetForms').debug('Query variables', { skip, take, input, order });
    refetch({ skip, take, input, order })
      .then((result: ApolloQueryResult<any>) => {
        if (result.data && result.data.forms) {
          const { items, pageInfo } = result.data.forms;
          logger('GetForms').info('Forms fetched successfully', { recordsCount: items?.length, pageInfo: pageInfo });
        } else {
          logger('GetForms').warning('No data received in query result');
        }
      })
      .catch((apolloError) => {
        logger('GetForms').error('Error re-fetching forms -->', apolloError.message);
      });
  };

  if (error) {
    logger('GetForms').error('Error fetching forms -->', error.message);
  }

  return {
    data: data?.forms?.items || [],
    totalCount: data?.forms?.totalCount || 0,
    currentCount: data?.forms?.items?.length || 0,
    pageInfo: data?.forms?.pageInfo,
    loading,
    loadData,
  };
};
