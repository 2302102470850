import { ReactNode } from "react";
import { Equipment } from "src/generated/dotnet.graphql";
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';

export const buildEquipmentNodeName = (equipment: Equipment): string => {
  return `${equipment.uniqueName} | ${equipment.manufacturer ? equipment.manufacturer : 'N/A'} | ${equipment.modelNumber ? equipment.modelNumber : 'N/A'}`;
}

export const buildEquipmentNodeAlertIcons = (equipment: Equipment): ReactNode => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      {equipment.workIssuesCount > 0 && (
        <Tooltip title="Has Tasks">
          <InventoryOutlinedIcon fontSize='small' color='info'/>
        </Tooltip>
      )}
      {equipment.sms && (
        <Tooltip title="Critical">
          <AddIcon fontSize='small' color='error'/>
        </Tooltip>
      )}
    </div>
  );
}