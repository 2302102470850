import { FC } from 'react';
import { Control } from 'react-hook-form';
import TreeSelect from 'src/components/UI/Forms/TreeSelect';
import { InventoryType } from 'src/generated/dotnet.graphql';
import { useGetInventoryCountsByLocation } from 'src/hooks/inventory/useGetInventoryCountsByLocation';
import { MasterSearchInputValue } from 'src/components/MasterSearchInput';

type InjectedProps = {
  control: Control;
  name: string;
  onChange: (field: string, value: any) => void;
  disabled?: boolean;
  inventoryType?: InventoryType;
  masterSearchInput?: MasterSearchInputValue | null;
  dropdownHeight?: number;
};

const CardViewLocationDropdown: FC<InjectedProps> = ({
  control,
  name,
  onChange,
  disabled,
  inventoryType,
  dropdownHeight,
}) => {
  const filterValue = { inventoryType };
  const { recordCountsByLocation, loading } = useGetInventoryCountsByLocation(filterValue);

  const locations = recordCountsByLocation
    .filter((elem: any) => elem.item) // Exclude elements where `item` is null or undefined
    .map((elem: any) => ({
      id: elem.item.id,
      parentId: elem.item.parentId,
      location: elem.item.location,
      expanded: false,
    }
  ));

  return (
    <div data-testid="data-grid">
      <TreeSelect
        disabled={disabled || loading}
        keyExpr="id"
        parentIdExpr="parentId"
        displayExpr="location"
        label="Location"
        onChange={onChange}
        options={locations}
        control={control}
        name={name}
        valueExpr="id"
        dropdownHeight={dropdownHeight}
        inventoryType={inventoryType}
      />
    </div>
  );
};

export default CardViewLocationDropdown;
