import { TypeFilterValue, TypeSingleSortInfo, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Box } from "@mui/system";
import { isEmpty, isNil } from "lodash";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import LicensedReactDataGrid from "src/components/UI/LicensedReactDataGrid";
import { GRID_LIMIT } from "src/consts";
import { Equipment, EquipmentFilterInput, EquipmentSortInput, SortEnumType } from "src/generated/dotnet.graphql";
import { useGetLazyEquipmentForInheritance } from 'src/hooks/equipment/useGetLazyEquipmentForInheritance';

const filter = [
  {
    name: 'uniqueName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const getSelectorByFilterName = async (
  name: string,
  value: any,
): Promise<Record<string, any>> => {
  switch (name) {
    case 'uniqueName': {
      return {
        [name]: value,
      };
    }
    default:
      return {};
  }
};

type InjectedProps = {
  visible: boolean;
  initialValue: Equipment;
  onSelect: (data: Equipment) => void;
  onClose: () => void;
}

const InheritEquipmentHoursDialog: FC<InjectedProps> = ({ 
  visible, 
  onSelect, 
  onClose, 
  initialValue, 
}) => {
  const defaultFilterValue = { hasHourMeter: true, excludeIds: [initialValue.id] };
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(GRID_LIMIT);
  const [filterValue, setFilterValue] = useState<EquipmentFilterInput>(defaultFilterValue);
  const [sortValue, setSortValue] = useState<EquipmentSortInput[]>([{ uniqueName: SortEnumType.Asc }]);
  const { data, totalCount, loading, loadData } = useGetLazyEquipmentForInheritance();

  useEffect(() => {
    if (visible) {
      loadData(skip, limit, filterValue, sortValue);
    }
  }, [visible, skip, limit, filterValue, sortValue]);  
  

  const dataSource = async () => {
    return {
      data,
      count: totalCount,
    };
  };

  const onSortInfoChange = (value: TypeSortInfo) => {
    const sortInfo = value as TypeSingleSortInfo
    if (isNil(sortInfo)) return;

    let sortPayload: EquipmentSortInput[];
    const sortDirection = sortInfo.dir === 1 ? SortEnumType.Asc : SortEnumType.Desc;
    const [field, subField] = sortInfo.name.split('.');

    if (subField) {
        // Handle nested objects
        sortPayload = [{
            [field]: {
                [subField]: sortDirection,
            },
        }];
    } else {
        // Handle non-nested objects
        sortPayload = [{
            [sortInfo.name]: sortDirection,
        }];
    }
    setSortValue(sortPayload)
  }

  const onFilterValueChange = async (filterValue: TypeFilterValue) => {
    if (isNil(filterValue)) return;

    const selectors = await Promise.all(
      filterValue.map(async (v) => {
        if (isEmpty(v.value)) return null;
        const selector = await getSelectorByFilterName(v.name, v.value);
        return selector;
      })
    );
    
    // Filter out null values
    const validSelectors = selectors.filter((selector) => selector !== null);

    // Combine the valid selectors into a single `filterInput` object
    const filterPayload: any = validSelectors.reduce((acc, obj) => {
      return { ...acc, ...obj };
    }, {});

    const newFilterValue = {
      ...defaultFilterValue,
      ...filterPayload,
    }

    setFilterValue((prevFilterValue: any) => {
      if (JSON.stringify(prevFilterValue) === JSON.stringify(newFilterValue)) {
        return prevFilterValue;
      }
      return newFilterValue;
    });
  }; 

  const onRowClick = useCallback(({ data }) => {
    onSelect(data);
  }, []);

  const columns = [
    {
      name: 'uniqueName',
      header: 'Equipment Name',
      flex: 1,
      editable: false,
    }
  ];

  return (
      <Dialog fullWidth maxWidth="md" onClose={onClose} open={visible}>
        <DialogTitle>Select Equipment</DialogTitle>
        <DialogContent>
          <Box display="flex" sx={{ height: 450 }}>
            <LicensedReactDataGrid
              idProperty="id"
              skip={skip}
              onSkipChange={setSkip}
              limit={limit}
              onLimitChange={setLimit}
              pagination='remote'
              rowHeight={40}
              loading={loading}
              defaultFilterValue={filter}
              onFilterValueChange={onFilterValueChange}
              onSortInfoChange={onSortInfoChange}
              allowUnsort={false}
              onRowClick={onRowClick}
              columns={columns}
              dataSource={dataSource}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box sx={{ width: '100%' }} display="flex" justifyContent="flex-end">
            <Button onClick={() => onClose()}>Close</Button>
          </Box>
        </DialogActions>
      </Dialog>
  )
}

export default InheritEquipmentHoursDialog;
