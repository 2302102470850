import { ApolloError, gql, useMutation } from '@apollo/client';
import { QueryEquipmentArgs } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { DeleteMutationResponse } from '../interfaces';
import { GET_EQUIPMENT_WITH_COUNTS } from './useGetEquipmentWithCounts';

export const DELETE_EQUIPMENT = gql`
  mutation DeleteEquipment($equipmentId: String!) {
    deleteEquipment(equipmentId: $equipmentId) {
      deleteResult {
        deletedIds
        message
        success
      }
      errors {
        code
        message
      }
    }
  }
`;

interface DeleteEquipmentResult {
  removeEquipment: (equipmentId: string, queryVariables?: QueryEquipmentArgs) => Promise<DeleteMutationResponse>;
  removeEquipmentLoading: boolean;
}

export const useDeleteEquipment = (): DeleteEquipmentResult => {
  const [deleteEquipment, { loading }] = useMutation(DELETE_EQUIPMENT, {
    onError: (error: ApolloError) => {
      logger('DeleteEquipment').error('Error deleting equipment -->', error.message);
    }
  });

  const removeEquipment = async (equipmentId: string, queryVariables?: QueryEquipmentArgs): Promise<DeleteMutationResponse> => {
    const response = await deleteEquipment({ 
      variables: { equipmentId },
      refetchQueries: [{
        query: GET_EQUIPMENT_WITH_COUNTS,
        variables: queryVariables
      }],
    });

    const responseData = response.data?.deleteEquipment?.deleteResult;
    if (responseData?.success) {
      logger('DeleteEquipment').info('Equipment deleted successfully', response.data);
      return {
        responseData,
        responseMessage: `Equipment removed successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to remove equipment!`,
      };
    }
  };

  return { 
    removeEquipment, 
    removeEquipmentLoading: loading, 
  };
};
