import { useApolloClient, DocumentNode } from "@apollo/client";

type UpdateCacheParams<T> = {
  typename: string;
  id: string;
  fragment: DocumentNode;
  updateCount: (existingData: T | null) => Partial<T> | null;
};

export function useUpdateCache() {
  const client = useApolloClient();

  return function updateCacheCount<T>({ typename, id, fragment, updateCount }: UpdateCacheParams<T>) {
    const cacheId = client.cache.identify({ __typename: typename, id });
    if (!cacheId) {
      console.warn(`Cache update skipped: Unable to identify ${typename} with ID ${id}`);
      return;
    }

    const existingData = client.cache.readFragment<T>({ id: cacheId, fragment });
    const updatedData = updateCount(existingData);

    if (!updatedData) {
      console.warn(`Cache update skipped: No updated data provided for ${typename} with ID ${id}`);
      return;
    }

    client.cache.writeFragment({ id: cacheId, fragment, data: updatedData });
  };
}

