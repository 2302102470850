import { Card, Typography, CardContent, CardHeader, Divider, CircularProgress } from '@mui/material';
import BarChartUI from '../BarChartUI';
import { useGetInventoryLowStockCounts } from 'src/hooks/dashboard/useGetInventoryLowStockCounts';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { InventoryType } from 'src/generated/dotnet.graphql';

const InventoryChartTile = () => {
  const { data, loading } = useGetInventoryLowStockCounts();

  const formattedData = data.map(({ item, count }) => {
    let formattedItem;

    switch (item) {
      case InventoryType.GeneralInventory:
        formattedItem = 'General';
        break;
      case InventoryType.DryAndColdStore:
        formattedItem = 'Stores';
        break;
      case InventoryType.Beverages:
        formattedItem = 'Beverages';
        break;
      case InventoryType.Uniform:
        formattedItem = 'Uniform';
        break;
      case InventoryType.Medical:
        formattedItem = 'Medical';
        break;
      default:
        break;
    }

    return { item: formattedItem, count };
  });

  const inventoryChart = () => (
    <div className="flex flex-col flex-grow h-full mt-2">
      {data?.length > 0 && (
        <BarChartUI
          width="100%"
          height={300}
          data={formattedData}
          countKey={'count'}
          itemKey={'item'}
        />
      )}
      {data?.length === 0 && (
        <div className="flex w-full h-80 justify-center items-center">
          All inventory are above the minimum holding level.
        </div>
      )}
    </div>
  );

  const cardTitle = (
    <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
      Inventory Below Minimum
    </Typography>
  );

  const loadingSpinner = (
    <div className="flex items-center justify-center w-full h-72">
      <CircularProgress />
    </div>
  );

  const footerDescription = (
    <div className="flex items-center justify-center pt-3">
      <SquareRoundedIcon fontSize="small" color="error" />
      <Typography sx={{ fontSize: 14, fontWeight: 700, paddingLeft: '5px' }}>Number of Inventory Below Minimum</Typography>
    </div>
  );

  // const cardStyle = {
  //   borderTop: 4,
  //   borderColor: '#0088FE',
  //   height: 425,
  //   '@media (min-width: 1200px) and (max-width: 1400px)': { height: 450 },
  // };

  const cardStyle = {
    display: "flex",
    flexDirection: "column",
    borderTop: 4,
    borderColor: "#0088FE",
    height: 425, 
    '@media (min-width: 1200px) and (max-width: 1400px)': { height: 450 },
  };


  return (
    <Card
      elevation={3}
      sx={cardStyle}
    >
      <CardContent sx={{ padding: 2, flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <CardHeader sx={{ padding: 1 }} title={cardTitle} />
        <Divider />
        {loading && loadingSpinner}
        {!loading && inventoryChart()}
        <Divider className="mt-2" />
        {footerDescription}
      </CardContent>
    </Card>
  );
};

export default InventoryChartTile;
