import '../styles.css';
import { FC, useCallback, useEffect, useState } from 'react';
import { Button, DialogTitle, DialogActions, DialogContent, Box, Snackbar, Alert, AlertColor } from '@mui/material';
import { CustomDialog } from 'src/helpers/modals';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import { GRID_LIMIT, SEVERITY } from 'src/consts';
import { Equipment, EquipmentFilterInput, EquipmentSortInput, SortEnumType } from 'src/generated/dotnet.graphql';
import { TypeFilterValue, TypeSingleSortInfo, TypeSortInfo } from "@inovua/reactdatagrid-enterprise/types";
import { isEmpty, isNil } from 'lodash';
import { useGetLazyEquipmentForInheritance } from 'src/hooks/equipment/useGetLazyEquipmentForInheritance';
import EquipmentReadingMeterDialog from './EquipmentReadingMeterDialog';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const filter = [
  {
    name: 'uniqueName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'manufacturer',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'modelNumber',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'department',
    operator: 'eq',
    type: 'select',
    value: '',
  },
];

const getSelectorByFilterName = async (
  name: string,
  value: any,
) => {

  switch (name) { 
    case 'uniqueName':
    case 'manufacturer':
    case 'modelNumber':
    case 'department': {
      return { [name]: value };
    }
    default:
      return {};
  }
};

interface InjectedProps {
  visible: boolean;
  onCancel: () => void;
}

const EquipmentHoursDialog: FC<InjectedProps> = ({ visible, onCancel }) => {
  const defaultFilterValue = { hasHourMeter: true };
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(GRID_LIMIT);
  const [filterValue, setFilterValue] = useState<EquipmentFilterInput>(defaultFilterValue);
  const [sortValue, setSortValue] = useState<EquipmentSortInput[]>([{ uniqueName: SortEnumType.Asc }]);
  const { data, totalCount, loading, loadData } = useGetLazyEquipmentForInheritance();
  
  const [selectedEquipment, setSelectedEquipment] = useState<Equipment>();
  const [showMeterReadingDialog, setShowMeterReadingDialog] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();

  useEffect(() => {
    if (visible) {
      loadData(skip, limit, filterValue, sortValue);
    }
  }, [visible, skip, limit, filterValue, sortValue]);

  const dataSource = async () => {
    return {
      data,
      count: totalCount,
    };
  };
  
  const onSortInfoChange = (value: TypeSortInfo) => {
    const sortInfo = value as TypeSingleSortInfo
    if (isNil(sortInfo)) return;

    let sortPayload: EquipmentSortInput[];
    const sortDirection = sortInfo.dir === 1 ? SortEnumType.Asc : SortEnumType.Desc;
    const [field, subField] = sortInfo.name.split('.');

    if (subField) {
        // Handle nested objects
        sortPayload = [{
            [field]: {
                [subField]: sortDirection,
            },
        }];
    } else {
        // Handle non-nested objects
        sortPayload = [{
            [sortInfo.name]: sortDirection,
        }];
    }
    setSortValue(sortPayload)
  }
    
  const onFilterValueChange = async (filterValue: TypeFilterValue) => {
    if (isNil(filterValue)) return;

    const selectors = await Promise.all(
      filterValue.map(async (v) => {
        if (isEmpty(v.value)) return null;
        const selector = await getSelectorByFilterName(v.name, v.value);
        return selector;
      })
    );
    
    // Filter out null values
    const validSelectors = selectors.filter((selector) => selector !== null);

    // Combine the valid selectors into a single `filterInput` object
    const filterPayload: any = validSelectors.reduce((acc, obj) => {
      return { ...acc, ...obj };
    }, {});

    const newFilterValue = {
      ...defaultFilterValue,
      ...filterPayload,
    }

    setFilterValue((prevFilterValue: any) => {
      if (JSON.stringify(prevFilterValue) === JSON.stringify(newFilterValue)) {
        return prevFilterValue;
      }
      return newFilterValue;
    });
  };

  const onRowClick = useCallback(({ data }) => {
    setSelectedEquipment(data);
    setShowMeterReadingDialog(true);
  }, []);

  const handleSave = (responseData: Equipment, responseMessage: string) => {
    setSelectedEquipment(undefined);
    setShowMeterReadingDialog(false);
    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const handleCancel = () => {
    setSelectedEquipment(undefined);
    setShowMeterReadingDialog(false);
    setFilterValue(defaultFilterValue);
  };

  const handleClose = () => {
    onCancel();
    // reset grid state to default on close action
    setFilterValue(defaultFilterValue);
    setSortValue([{ uniqueName: SortEnumType.Asc }]);
    setSkip(0);
    setLimit(GRID_LIMIT);
  }

  const columns = [
    {
      name: 'uniqueName',
      header: 'Equipment Name',
      flex: 1,
      editable: false,
    },

    {
      name: 'hoursOffset',
      header: 'Hours Offset',
      flex: 0.5,
      editable: false,
    },

    {
      name: 'hours',
      header: 'Current Hours',
      flex: 0.5,
      editable: false,
    },
  ];

  return (
    <>
      <CustomDialog
        scroll="paper"
        fullWidth
        maxWidth="md"
        open={visible}
        onClose={handleClose}
      >
        <DialogTitle>
          <span className="font-bold text-2xl">Hours</span>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 4 }}>
          <div className="bg-white h-full flex-grow">
            <div style={{ display: "flex", alignItems: "center", fontSize: "14px" }}>
              <InfoOutlinedIcon style={{ marginRight: "8px", fontSize: "18px" }} />
              <span>Select equipment from the list below to update its hours based on the current meter reading.</span>
            </div>
            <div className="flex flex-col flex-grow equipment-hour-grid pt-5">
              <LicensedReactDataGrid
                onRowClick={onRowClick}
                idProperty={"id"}
                skip={skip}
                onSkipChange={setSkip}
                limit={limit}
                onLimitChange={setLimit}
                pagination="remote"
                rowHeight={40}
                loading={loading}
                defaultFilterValue={filter}
                onFilterValueChange={onFilterValueChange}
                onSortInfoChange={onSortInfoChange}
                columns={columns}
                dataSource={dataSource}
                showColumnMenuTool={false}
                enableColumnFilterContextMenu={false}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          <Box
            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
          >
            <Button className="mr-2" onClick={handleClose}>
              Close
            </Button>
          </Box>
        </DialogActions>
      </CustomDialog>

      {!!selectedEquipment && (
        <EquipmentReadingMeterDialog
          visible={showMeterReadingDialog}
          initialValue={selectedEquipment}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </>
  );
};

export default EquipmentHoursDialog;
