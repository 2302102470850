import { ApolloError, DocumentNode, gql, useMutation } from '@apollo/client';
import { AddCommentInput, Comment, RecordType } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { 
  UpdateBunkeringCommentsCountCache, 
  UpdateDocumentationCommentsCountCache, 
  UpdateEquipmentCommentsCountCache, 
  UpdateFormCommentsCountCache, 
  UpdateInventoryCommentsCountCache, 
  UpdateLogEntryCommentsCountCache 
} from '../fragments.graphql';

export const ADD_COMMENT = gql`
  mutation AddComment($input: AddCommentInput!) {
    addComment(input: $input) {
      comment {
        id
        authorId
        author {
          first
          last
        }
        createdAt
        content
        parentId
        recordId
        recordType
        replies {
          id
          authorId
          author {
            first
            last
          }
          createdAt
          content
          parentId
          recordId
          recordType
        }
      }
    }
  }
`;

export interface CommentMutationResponse {
  responseData?: Comment;
  responseMessage: string;
}

interface AddCommentResult {
  addComment: (input: AddCommentInput) => Promise<CommentMutationResponse>;
  addCommentLoading: boolean;
  addCommentError?: ApolloError;
}

export const useAddComment = (recordId: string, recordType: RecordType, recordTypeName: string, isReply: boolean): AddCommentResult => {
  const [createComment, { loading, error }] = useMutation(ADD_COMMENT, {
    onError: (error: ApolloError) => {
      logger('AddComment').error(`Error adding comment for ${recordType}: ${recordId} -->`, error.message);
    }, 
    update: (cache, { data }) => {
      if (!isReply) {
        if (data?.addComment?.comment) {
          const recordCacheId = cache.identify({
            __typename: recordTypeName,
            id: recordId,
          });

          let fragmentSelection: DocumentNode | null = null;

          switch (recordType) {
            case RecordType.LogEntry:
              fragmentSelection = UpdateLogEntryCommentsCountCache;
              break;
            case RecordType.DocumentationVesselReference:
            case RecordType.DocumentationDrawings:
            case RecordType.DocumentationManuals:
            case RecordType.DocumentationMsds:
            case RecordType.DocumentationPhotos:
            case RecordType.DocumentationSms:
            case RecordType.DocumentationIsps:
              fragmentSelection = UpdateDocumentationCommentsCountCache;
              break;
            case RecordType.InventoryGeneral:
            case RecordType.InventoryBeverages:
            case RecordType.InventoryUniform:
            case RecordType.InventoryDryAndColdStore:
            case RecordType.InventoryMedical:
              fragmentSelection = UpdateInventoryCommentsCountCache;
              break;
            case RecordType.Bunkering:
              fragmentSelection = UpdateBunkeringCommentsCountCache;
              break;
            case RecordType.Forms:
              fragmentSelection = UpdateFormCommentsCountCache;
              break;
            case RecordType.Equipment:
              fragmentSelection = UpdateEquipmentCommentsCountCache;
              break;
            case RecordType.WorkIssue:
            case RecordType.EquipmentSchedule:
            case RecordType.EventSchedule:
            case RecordType.DrillSchedule:
            case RecordType.InspectionSchedule:
            case RecordType.PurchaseOrder:
            case RecordType.EngineersDayLog:
            case RecordType.RunningLog:
            case RecordType.Crew:
            case RecordType.Guest:
            case RecordType.VesselCertificates:
            case RecordType.CrewCertificates:
            case RecordType.HoursOfRest:
            case RecordType.Undefined:
              break;
            default:
              break;
          }

          if (fragmentSelection) {
            const existingRecord = cache.readFragment<any>({
              id: recordCacheId,
              fragment: fragmentSelection,
            });
    
            if (existingRecord) {
              cache.writeFragment({
                id: recordCacheId,
                fragment: fragmentSelection,
                data: {
                  commentsCount: existingRecord.commentsCount + 1,
                },
              });
    
            } else {
              logger('Cache-AddComment').warning(`${recordType}: ${recordId} not found in cache`);
            }
          } else {
            logger('Cache-AddComment').warning(`Query fragment not valid --> ${fragmentSelection}`);
          } 
        } else {
          logger('Cache-AddComment').warning(`${recordType}: ${recordId} cache update paused --> No response from add mutation`);
        }
      }
    },
  });

  const addComment = async (input: AddCommentInput): Promise<CommentMutationResponse> => {
    const response = await createComment({ variables: { input } });
    const responseData = response.data?.addComment?.comment;
    if (responseData) {
      logger('AddComment').info('Comment added successfully', response.data);
      return {
        responseData,
        responseMessage: `${isReply ? 'Reply' : 'Comment'} added successfully!`,
      };
    } else {
      return {
        responseMessage: `Failed to add ${isReply ? 'reply' : 'comment'}!`,
      };
    }
  };

  return { 
    addComment, 
    addCommentLoading: loading, 
    addCommentError: error,
  };
};