import { ApolloError, gql, useMutation } from '@apollo/client';
import { FormInput, QueryFormsArgs } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { FormMutationResponse } from '../interfaces';
import { GET_FORMS } from './useGetForms';
import FormsDetailForm from 'src/components/PageDrawer/Forms/FormsDetailForm';
import { GET_RECENT_FORMS } from '../dashboard/useGetRecentForms';
import FormsTile from 'src/pages/HomePage/component/FormsTile/FormsTile';
import { COMPONENTS } from 'src/consts';

export const UPSERT_FORM = gql`
  mutation UpsertForm($input: FormInput!) {
    upsertForm(input: $input) {
      form {
        categoryId
        commentsCount
        completedBy
        completedDate
        createdBy
        createdByName
        dateCreated
        documentsCount
        id
        photosCount
        remark
        reportFile
        reportNumber
        reportType
        verifiedBy
        verifiedDate
      }
      errors {
        code
        message
      }
    }
  }
`;

interface UpsertFormResult {
  upsertForm: (input: FormInput, isCreate: boolean, refetchVariables?: QueryFormsArgs, callerComponent?: string) => Promise<FormMutationResponse>;
  upsertFormLoading: boolean;
  upsertFormError?: ApolloError;
}

export const useUpsertForm = (): UpsertFormResult => {
  const [addOrModifyForm, { loading, error }] = useMutation(UPSERT_FORM, {
    onError: (error: ApolloError) => {
      logger('UpsertForm').error("Error updating form -->", error.message);
    }
  });

  const upsertForm = async (input: FormInput, isCreate: boolean, refetchVariables?: QueryFormsArgs, callerComponent?: string): Promise<FormMutationResponse> => {
    let refetchQueries: any[] = [];

    if (callerComponent === COMPONENTS.FormsTile) {
      refetchQueries = [
        {
          query: GET_RECENT_FORMS,
        },
      ];
    } else if (callerComponent === COMPONENTS.FormsDetailForm) {
      refetchQueries = [
        {
          query: GET_FORMS,
          variables: refetchVariables,
        },
      ];
    }
 
    const response = await addOrModifyForm({ 
      variables: { input },
      refetchQueries,
    });

    const responseData = response.data?.upsertForm?.form;

    if (responseData) {
      logger('UpsertForm').info(`Form ${isCreate ? 'added' : 'updated'} successfully`, response.data);
      return {
        responseData,
        responseMessage: `Form ${isCreate ? 'added' : 'updated'} successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to ${isCreate ? 'added' : 'updated'} form!`,
      };
    }
  };

  return { 
    upsertForm, 
    upsertFormLoading: loading, 
    upsertFormError: error,
  };
};
