import { useState } from 'react';
import { isNil } from 'lodash';
import moment from 'moment';
import { Card, Typography, CardContent, CardHeader, Divider, CircularProgress, Snackbar, Alert, AlertColor } from '@mui/material';
import { useGetRecentForms } from 'src/hooks/dashboard/useGetRecentForms';
import { utcToTimeZone } from 'src/utils/format-dates';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import { useGetFormsDateRange } from 'src/hooks/YMSSettings/useGetFormsDateRange';
import { AppSettingsDateRange, COMPONENTS, SEVERITY } from 'src/consts';
import FormsFormDialog from 'src/components/PageDrawer/Forms/FormsFormDialog';
import { DeleteResult, Form, FormRevisionInput } from 'src/generated/dotnet.graphql';
import { DocxFileType } from 'src/components/FileViewer/FileViewerDocx';
import { ExcelFileType } from 'src/components/FileViewer/FileViewerExcel';
import { useAddFormRevision } from 'src/hooks/forms/useAddFormRevision';
import FormPDFDialog from 'src/components/PageDrawer/Forms/components/FormPDFDialog';
import { handleFileUpload } from 'src/pages/FormsPage/util';

const FormsTile = () => {
  const [selectedForm, setSelectedForm] = useState<Form>();
  const [PDFForm, setPDFForm] = useState<Form | null>(null);
  const [PDFBuffer, setPDFBuffer] = useState<ArrayBuffer | null>(null);
  const [isFormCreate, setIsFormCreate] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();
  const { data, loading } = useGetRecentForms();
  const { recentFormsDateRange } = useGetFormsDateRange();
  const { addFormRevision, addFormRevisionLoading, addFormRevisionError } = useAddFormRevision();
  
  const handleSave = (responseData: Form, responseMessage: string) => {
    setSelectedForm(undefined);
    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
    onClose();
  };

  const handleDelete = async (responseData: DeleteResult, responseMessage: string) => {
    if (responseData.success) {
      setSelectedForm(undefined);
    }
    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const onClose = () => {
    setPDFBuffer(null);
    setPDFForm(null);
    setIsFormCreate(false);
    setSelectedForm(undefined); 
  };

  const handleFileSave = async (fileData: ArrayBuffer | DocxFileType | ExcelFileType) => {
    let filename, isFormRevisionCreated;
    if (fileData instanceof ArrayBuffer) {
      // PDF file handling
      const pdfFile = fileData as ArrayBuffer;
      setPDFBuffer(pdfFile);
      const pdfUploadResult = await handleFileUpload(selectedForm, pdfFile);
      filename = pdfUploadResult.filename;
      isFormRevisionCreated = pdfUploadResult.isFormRevisionCreated;
    } else {
      // Docx file handling
      const docxFile = fileData as DocxFileType;
      filename = docxFile.filename;
      isFormRevisionCreated = docxFile.isFormRevisionCreated;
    }

    // If file was updated and new version been uploaded -> create form revision
    if (isFormRevisionCreated) {
      const formRevision: FormRevisionInput = {
        formId: selectedForm?.id,
        reportFile: filename,
      }
      const { responseData, responseMessage, responseDataError } = await addFormRevision(formRevision);

      if(responseData) {
        // Update selectedForm's fileName after created a revision as the fileName gets set in db but needs syncronization of the state as well
        setSelectedForm((prevForm) =>
          prevForm ? { ...prevForm, reportFile: responseData.reportFile } : prevForm
        );
        setIsFormCreate(false);
      }

      setSnackbar({
        message: responseMessage,
        severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
      });
    }
  };

  const handleViewForm = (form: Form) => {
    setSelectedForm(form); // Update selected form with latest data
    setPDFForm(form);
  };

  const groupTitleMapping: { [key: string]: string } = {
    OPEN: 'Open Forms',
    CLOSED: 'Closed Forms',
    COMPLETED: 'Completed Forms',
  };

  const renderGroupTitle = (value: any, { data }: any) => {
    return `${groupTitleMapping[value]} (${data.array.length})` || `No Status Assigned`;
  };

  const onRowClick = (row: any) => {
    if (row.data.__typename === 'Form') {
      setSelectedForm(row.data);
    };
  };

  const columns = [
    {
      name: 'dateCreated',
      header: 'Created Date',
      defaultFlex: 1.5,
      headerAlign: 'start' as any,
      textAlign: 'end' as any,
      dateFormat: 'DD-MMM-YYYY',
      render: ({ value, cellProps: { dateFormat } }: any) => {
        if (!isNil(value)) {
          const UTCLogDate = utcToTimeZone(new Date(value));
          const formatedDate = moment(UTCLogDate).format(dateFormat);
          return formatedDate;
        }
      },
    },
    {
      name: 'reportType',
      header: 'Title',
      defaultFlex: 3,
      sortable: false,
    },
    {
      name: 'status',
      header: 'Status',
      defaultFlex: 3,
      sortable: false,
    },
  ];
  
  const recentFormsGrid = () => (
    <div data-testid="data-grid" className="forms-data-grid flex flex-col flex-grow h-full mt-2">
      <LicensedReactDataGrid
        loading={loading}
        rowHeight={30}
        idProperty="id"
        columns={columns}
        dataSource={data}
        disableGroupByToolbar
        onRowClick={onRowClick}
        defaultGroupBy={["status"]}
        defaultSortInfo={[{ name: 'status', dir: -1 }]}
        renderGroupTitle={renderGroupTitle}
        showColumnMenuTool={false}
        showHeader={false}
        enableSelection
      />
    </div>
  );

  const cardTitle = (
    <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
      Recent SMS/ISPS Forms
    </Typography>
  );

  const loadingSpinner = (
    <div className="flex items-center justify-center w-full h-72">
      <CircularProgress />
    </div>
  );

  const dateRangeLabel = AppSettingsDateRange.find((option) => option.id === recentFormsDateRange);
  const footerDescription = (
    <div className="flex items-center justify-center pt-3">
      <Typography sx={{ fontSize: 14, fontWeight: 700, paddingLeft: '5px' }}>{`Forms Created this ${dateRangeLabel ? dateRangeLabel.name : ''}: ${data.length}`}</Typography>
    </div>
  );

  const cardStyle = {
    display: "flex",
    flexDirection: "column",
    borderTop: 4,
    borderColor: "#0088FE",
    height: 425, 
    '@media (min-width: 1200px) and (max-width: 1400px)': { height: 450 },
  };

  return (
    <>
      <Card
        elevation={3}
        sx={cardStyle}
      >
        <CardContent sx={{ padding: 2, flexGrow: 1, display: "flex", flexDirection: "column" }}>
          <CardHeader sx={{ padding: 1 }} title={cardTitle} />
          <Divider />
          {loading && loadingSpinner}
          {!loading && recentFormsGrid()}
          <Divider className="mt-2" />
          {footerDescription}
        </CardContent>
      </Card>

      {selectedForm && (
        <FormsFormDialog
          form={selectedForm}
          onViewForm={handleViewForm}
          isFormCreate={isFormCreate}
          onSave={handleSave}
          onDelete={handleDelete}
          onClose={onClose}
          moduleReadOnly={false}
          callerComponent={COMPONENTS.FormsTile}
        />
      )}

      <FormPDFDialog
        document={PDFBuffer}
        form={PDFForm}
        visible={!isNil(PDFForm)}
        onFileSave={handleFileSave}
        onRequestClose={() => setPDFForm(null)}
      />

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </>
  );
};

export default FormsTile;
