import { FC } from 'react';
import { Autocomplete as MaterialAutocomplete, FormHelperText, FormControl, TextField } from '@mui/material';
import { Control, RegisterOptions, useController } from 'react-hook-form';

type InjectedProps = {
  control: Control;
  name: string;
  rules?: RegisterOptions;
  placeholder?: string;
  label?: string;
  freeSolo?: boolean;
  options: string[];
  loading?: boolean;
  disabled?: boolean;
  variant?: any;
  size?: any;
};

const SelectInput: FC<InjectedProps> = ({
  name,
  control,
  rules,
  placeholder,
  freeSolo = false,
  label,
  loading,
  options,
  disabled,
  variant,
  size = 'small',
}) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const handleChange = (event: any, newValue: string | null) => {
    onChange(newValue ?? ''); // Handle both clearing and selecting
  };

  const handleInputChange = (event: any, newInputValue: string) => {
    if (freeSolo) {
      onChange(newInputValue); // Update value for free text input
    }
  };

  return (
    <FormControl error={!!error} fullWidth>
      <MaterialAutocomplete
        disabled={disabled}
        value={value || ''}
        inputValue={value || ''}
        clearOnBlur
        handleHomeEndKeys
        freeSolo={freeSolo}
        loading={loading}
        options={options}
        onChange={handleChange}
        onInputChange={handleInputChange}
        size={size}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            autoComplete="off"
            variant={variant}
            placeholder={placeholder}
            error={!!error}
            helperText={error?.message}
          />
        )}
      />
    </FormControl>
  );
};

export default SelectInput;
