import { FC, useRef, useState } from "react";
import { Button, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { DeleteTwoTone } from "@mui/icons-material";
import { CustomDialog } from "src/helpers/modals";
import { FormType } from "src/consts";
import { DeleteResult, Form, QueryFormsArgs } from "src/generated/dotnet.graphql";
import FormsDetailForm from "./FormsDetailForm";
import DialogActionBar from "src/components/UI/DialogActionBar/indesx";

interface InjectedProps {
  form: Form;
  onSave: (responseData: Form, responseMessage: string, isCreated: boolean) => void;
  onDelete: (responseData: DeleteResult, responseMessage: string) => void;
  onClose: () => void;
  onViewForm: (form: Form) => void;
  refetchQueryVariables?: QueryFormsArgs;
  moduleReadOnly: boolean;
  isFormCreate?: boolean;
  callerComponent?: string;
}

const FormsFormDialog: FC<InjectedProps> = ({
  form,
  isFormCreate,
  onSave,
  onDelete,
  onClose,
  onViewForm,
  moduleReadOnly,
  refetchQueryVariables,
  callerComponent,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const detailFormRef = useRef<any>(null);

  const onSaveClick = () => {
    if (detailFormRef.current) {
      detailFormRef.current.onSaveClick();
    }
  };

  const onViewFormClick = () => {
    if (detailFormRef.current) {
      detailFormRef.current.onViewFormClick();
    }
  };

  const onDeleteClick = () => {
    if (detailFormRef.current) {
      detailFormRef.current.onDeleteClick();
    }
  };

  const handleSaveResponse = (responseData: any, responseMessage: string, isCreated: boolean) => {
    onSave(responseData, responseMessage, isCreated);
  };

  return (
    <CustomDialog scroll="paper" fullWidth maxWidth="md" open={!!form}>
      <DialogTitle>
        <span className="font-bold text-2xl">{`${isFormCreate ? "Add" : "Edit"} Entry`}</span>
      </DialogTitle>

      <DialogContent dividers sx={{ p: 0 }}>
        {!!form && (
          <FormsDetailForm
            form={form}
            onViewForm={onViewForm}
            isFormCreate={isFormCreate}
            onCancel={onClose}
            onSave={handleSaveResponse}
            onDelete={onDelete}
            moduleReadOnly={moduleReadOnly}
            refetchQueryVariables={refetchQueryVariables}
            ref={detailFormRef}
            callerComponent={callerComponent}
            setFormIsDirty={setIsEditing}
            type={FormType.DIALOG}
          />
        )}
      </DialogContent>
      <DialogActionBar
        cancelText="Cancel"
        okType={isEditing ? 'submit' : 'button'}
        okText={isEditing ? (isFormCreate ? 'Create' : 'Save') : 'Close'}
        onOk={isEditing ? onSaveClick : onClose}
        disabled={(moduleReadOnly) && isEditing}
        onCancel={isEditing ? () => onClose() : undefined}
      >
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center">
            <Button
              disabled={moduleReadOnly}
              variant="contained"
              onClick={onViewFormClick}
            >
              View Form
            </Button>
          </div>

          {!isFormCreate && (
            <IconButton
              onClick={onDeleteClick}
              color="error"
              aria-label="Delete Report"
              disabled={moduleReadOnly}
            >
              <DeleteTwoTone />
            </IconButton>
          )}
        </div>
      </DialogActionBar>
    </CustomDialog>
  );
};

export default FormsFormDialog;
