import { ApolloError, gql, useMutation } from '@apollo/client';
import { QueryFormsArgs } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { DeleteMutationResponse } from '../interfaces';
import { GET_FORMS } from './useGetForms';
import { GET_RECENT_FORMS } from '../dashboard/useGetRecentForms';
import { COMPONENTS } from 'src/consts';

export const DELETE_FORM = gql`
  mutation DeleteForm($formId: String!) {
    deleteForm(formId: $formId) {
      deleteResult {
        deletedIds
        message
        success
      }
      errors {
        code
        message
      }
    }
  }
`;

interface DeleteFormResult {
  removeForm: (FormId: string, queryVariables?: QueryFormsArgs, callerComponent?: string) => Promise<DeleteMutationResponse>;
  removeFormLoading: boolean;
}

export const useDeleteForm = (): DeleteFormResult => {
  const [deleteForm, { loading }] = useMutation(DELETE_FORM, {
    onError: (error: ApolloError) => {
      logger('DeleteForm').error('Error deleting form -->', error.message);
    }
  });

  const removeForm = async (formId: string, refetchVariables?: QueryFormsArgs, callerComponent?: string): Promise<DeleteMutationResponse> => {
    let refetchQueries: any[] = [];

    if (callerComponent === COMPONENTS.FormsTile) {
      refetchQueries = [
        {
          query: GET_RECENT_FORMS,
        },
      ];
    } else if (callerComponent === COMPONENTS.FormsDetailForm) {
      refetchQueries = [
        {
          query: GET_FORMS,
          variables: refetchVariables,
        },
      ];
    }
    
    const response = await deleteForm({ 
      variables: { formId },
      refetchQueries,
    });

    const responseData = response.data?.deleteForm?.deleteResult;
    if (responseData?.success) {
      logger('DeleteForm').info('Form deleted successfully', response.data);
      return {
        responseData,
        responseMessage: `Form removed successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to remove form!`,
      };
    }
  };

  return { 
    removeForm, 
    removeFormLoading: loading, 
  };
};
