import { isNil } from "lodash";
import { UPLOAD_URL } from "src/consts";
import { Form } from "src/generated/dotnet.graphql";

export const sanitizeFilename = (filename: string | undefined) => {
  if (!filename) {
    return filename;
  }
  const restrictedChars = /[<>:"/\\|?* ]/g;
  return filename.replace(restrictedChars, '_');
}

export const handleFileUpload = async (selectedForm: Form | undefined, buffer: ArrayBuffer | null ): Promise<{ filename: string; isFormRevisionCreated: boolean }> => {
  if (isNil(buffer)) {
    return {
      filename: selectedForm?.reportType || '',
      isFormRevisionCreated: false,
    };
  }
  const data = new FormData();
  data.append('Content-Type', 'multipart/form-data');
  const filename = `RPT_${sanitizeFilename(selectedForm?.reportType)}`;
  data.append(
    'attachment',
    new Blob([buffer], { type: 'application/pdf' }),
    filename,
  );
  const response = await fetch(UPLOAD_URL, {
    method: 'POST',
    body: data,
  });
  const result: any = await response.json();
  return { filename: result.key, isFormRevisionCreated: true };
};