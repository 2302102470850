import { gql, useQuery } from '@apollo/client';
import { logger } from 'src/helpers/logger';

export const GET_EQUIPMENT_SUPPLIERS = gql`
  query GetEquipmentSuppliers {
    equipmentSuppliers {
      name
    }
  }
`;

interface GetEquipmentSuppliersResult {
  data: String[];
  loading: boolean;
}

export const useGetEquipmentSuppliers = (): GetEquipmentSuppliersResult => {
  const { data, loading, error } = useQuery(GET_EQUIPMENT_SUPPLIERS, {
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetEquipmentSuppliers').error('Error fetching equipment suppliers -->', error.message);
  }

  return {
    data: data?.equipmentSuppliers || [],
    loading,
  };
};
