//React
import React, { FC, useRef } from 'react';
// Style
import { AppBar, Dialog, Toolbar, Slide } from '@mui/material';
import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
// TDI Components
import FileViewer from 'src/components/FileViewer';
// Utils
import { DOWNLOAD_URL } from '../../../../consts';
import { Form, FormRevision } from 'src/generated/dotnet.graphql';

interface InjectedProps {
  form: Form;
  formRevision: FormRevision;
  visible: boolean;
  onClose: () => void;
}

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

const FormRevisionPDFDialog: FC<InjectedProps> = ({
  form,
  formRevision,
  visible,
  onClose
}) => {
  const viewerRef = useRef<any>(null);

  return (
    <Dialog
      fullScreen
      open={visible}
      onClose={onClose}
      TransitionComponent={Transition as any}
    >
      <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" component="div" className="w-full">
              {form?.reportType ?? ''}
            </Typography>
          </Toolbar>
        </AppBar>

        {formRevision?.reportFile && (
          <FileViewer
            url={`${DOWNLOAD_URL}/${formRevision.reportFile}`}
            ref={viewerRef}
            form={form}
            formRevision={formRevision}
          />
        )}
      </Dialog>
  );
};

export default FormRevisionPDFDialog;
