import { gql, useQuery } from '@apollo/client';
import { LogEntryFilterInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { CategoriesResult } from '../interfaces';

export const GET_LOG_ENTRY_COUNT_BY_CATEGORY = gql`
  query GetLogEntryCountsByCategory($filterInput: LogEntryFilterInput) {
    logEntryCountsByCategory(filterInput: $filterInput) {
      count
      item {
        id
        category
        depts
        noView
        tags
        parentId
      }
    }
  }
`;

export const useGetLogEntryCountsByCategory = (filterInput: LogEntryFilterInput): CategoriesResult => {
  const { data, loading, error, refetch } = useQuery(GET_LOG_ENTRY_COUNT_BY_CATEGORY, {
    variables: { filterInput },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetLogEntryCountsByCategory').error('Error fetching categories -->', error.message);
  }

  return {
    recordCountsByCategory: data?.logEntryCountsByCategory || [],
    refetchRecordCountsByCategory: refetch,
    loading,
  };
};
