import React, {useEffect, useRef, useState} from 'react';
import {Tab} from "@mui/material";
import {getDatabase} from "../../../../rxdb";
import { isNil, size } from 'lodash';
import {Subscription} from "rxjs";
import {MangoQuerySelector} from "rxdb/dist/types/types";

interface InjectedProps {
  selector: MangoQuerySelector<any>;
  disabled?: boolean;
}

const ScheduleTabButton = ({ selector, disabled, ...rest }: InjectedProps) => {
  const [count, setCount] = useState<number>(0);
  const scheduleSubscription = useRef<Subscription>();

  const getDocumentCount = async () => {
    const db = await getDatabase();

    if (isNil(selector.EqKey)) return;
    
    // Find schedules based on selector that passed outside
    scheduleSubscription.current = db
      .tblschedmainteq
      .find({ selector: selector || {} })
      .$
      .subscribe(async schedules => { // Interested in real-time updates while tab open
        const scheduleKeys = schedules.map(s => s.SchedKey );

        const schedMaint = await db
          .tblschedmaint
          .find({ selector: { PKey: { $in: scheduleKeys }, deletedAt:{$eq:null}}})
          .exec();

        setCount(size(schedMaint))
      });
  };

  useEffect(() => {
    getDocumentCount();
    return () => {
      scheduleSubscription.current?.unsubscribe();
    }
  }, []);

  return (
    <Tab key="ScheduleTabButton" disabled={disabled} label={`Schedules (${count})`} {...rest} />
  )
};

export default ScheduleTabButton;
