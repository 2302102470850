import { isEmpty, isNil } from 'lodash';

export const validateForm = (data: any, setSnackbar: any) => {
  const { productName, categoryId, department } = data;

  if (isNil(productName) || isEmpty(productName) || isNil(categoryId) || isNil(department)) {
    setSnackbar({
      open: true,
      message: 'Please fill required field(s). Check form field(s) marked with red color',
      type: 'error',
    });
    return false;
  }
  return true;
};