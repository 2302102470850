import '../style.css'
import { FC } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import { Inventory, InventoryType } from 'src/generated/dotnet.graphql';
import { CustomDialog } from 'src/helpers/modals';
import { INVENTORY } from 'src/consts';

interface Props {
  visible: boolean;
  barcodeMatches: Inventory[];
  onCancel: () => void;
  onSelect: (item: Inventory) => void;
}

const BarcodeMultipleMatchesInventoryDialog: FC<Props> = ({ 
  barcodeMatches, 
  visible,
  onCancel,
  onSelect,
}) => {

  const onRowClick = (row: any) => {
    onSelect(row.data);
  };

  const columns = [
    {
      name: 'productName',
      header: 'Product Name',
      flex: 1,
    },
    {
      name: 'inventoryType',
      header: 'Inventory Type',
      flex: 1,
      render: ({data}: any)=>{
        if (data.inventoryType === InventoryType.GeneralInventory) return INVENTORY.GENERAL.TITLE
        if (data.inventoryType === InventoryType.Beverages) return INVENTORY.BEVERAGES.TITLE
        if (data.inventoryType === InventoryType.Uniform) return INVENTORY.UNIFORM.TITLE
        if (data.inventoryType === InventoryType.DryAndColdStore) return INVENTORY.DRY_STORES.TITLE
        if (data.inventoryType === InventoryType.Medical) return INVENTORY.MEDICAL.TITLE
      }
    },
  ];
    
  return (
    <Dialog
      scroll="paper"
      fullWidth
      maxWidth="md"
      open={visible}
    >
      <DialogTitle>
        <span className="font-bold text-2xl">Barcode Search Results</span>
      </DialogTitle>

      <DialogContent dividers sx={{ p: 0 }}>
        <div data-testid="data-grid" className="pt-6 px-6 bg-white h-full flex flex-col flex-grow justify-end mb-6 barcode-multiple-matches-grid">
          <small className='pb-6'>
            A unique match could not be found. The barcode may be associated with multiple inventory items or only partial matches were found.
          </small>
          <LicensedReactDataGrid
            rowHeight={40}
            showColumnMenuTool={false}
            idProperty="id"
            columns={columns} 
            dataSource={barcodeMatches}
            onRowClick={onRowClick}
          />
        </div>
      </DialogContent>

      <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
        <Box sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}>
          <Button 
            className="mr-2" 
            onClick={onCancel}
          >
            Close
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default BarcodeMultipleMatchesInventoryDialog;
