import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery  } from "@apollo/client";
import { logger } from "src/helpers/logger";
import { Form } from "src/generated/dotnet.graphql";

export const GET_FORM_BY_ID = gql`
  query GetLazyFormById($formId: String!) {
    formById(formId: $formId) {
      __typename
      id
      reportFile
    }
  }
`
export interface FormDataResponse {
  formById: Form;
}

interface FormResult {
  getLazyFormById: LazyQueryExecFunction<FormDataResponse, OperationVariables>;
  getLazyFormByIdLoading: boolean;
}

export const useGetLazyFormById = (): FormResult => {

  const [ getLazyFormById, { error, loading }] = useLazyQuery(GET_FORM_BY_ID, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetLazyFormById').error('Error fetching form -->', error.message);
  }

  return {
    getLazyFormById,
    getLazyFormByIdLoading: loading
  };
};
  