import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery  } from "@apollo/client";
import { EquipmentCollectionSegment, EquipmentFilterInput, EquipmentSortInput } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_LAZY_EQUIPMENT_BY_CATEGORY = gql`
  query GetLazyEquipmentByCategory($filterInput: EquipmentFilterInput) {
    lazyEquipmentByCategory: equipment(filterInput: $filterInput) {
      items {
        id
        uniqueName
        manufacturer
        modelNumber
        sms
        workIssuesCount
      }
    }
  }
`

interface EquipmentData {
  lazyEquipmentByCategory: EquipmentCollectionSegment;
}

interface LazyEquipmentByCategoryResult {
  getLazyEquipmentByCategory: LazyQueryExecFunction<EquipmentData, OperationVariables>;
}

export const useGetLazyEquipmentByCategory = (): LazyEquipmentByCategoryResult => {

  const [ getEquipmentByCategory, { error }] = useLazyQuery(GET_LAZY_EQUIPMENT_BY_CATEGORY, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetLazyEquipmentByCategory').error('Error fetching equipment by category -->', error.message);
  }

  return {
    getLazyEquipmentByCategory: getEquipmentByCategory
  };
};