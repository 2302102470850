import { ApolloError, gql, useMutation } from '@apollo/client';
import { BulkModifyEquipmentInput, Equipment, QueryEquipmentArgs } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_EQUIPMENT_WITH_COUNTS } from './useGetEquipmentWithCounts';

export const BULK_UPDATE_EQUIPMENT = gql`
  mutation BulkUpdateEquipment($input: BulkModifyEquipmentInput!) {
    bulkModifyEquipment(input: $input) {
      equipment {
        id
      }
    }
  }
`;

export interface BulkEquipmentMutationResponse {
  responseData?: Equipment[];
  responseMessage: string;
}

interface BulkEquipmentInventoryResult {
  bulkUpdateEquipment: (input: BulkModifyEquipmentInput, columnName: string, queryVariables: QueryEquipmentArgs) => Promise<BulkEquipmentMutationResponse>;
  bulkUpdateEquipmentLoading: boolean;
  bulkUpdateEquipmentError?: ApolloError;
}

export const useBulkUpdateEquipment = (): BulkEquipmentInventoryResult => {
  const [updateMultipleEquipment, { loading, error }] = useMutation(BULK_UPDATE_EQUIPMENT, {
    onError: (error: ApolloError) => {
      logger('BulkUpdateEquipment').error("Error updating multiple equipment -->", error.message);
    }
  });

  const bulkUpdateEquipment = async (input: BulkModifyEquipmentInput, columnName: string, refetchVariables: QueryEquipmentArgs): Promise<BulkEquipmentMutationResponse> => {
    const response = await updateMultipleEquipment({ 
      variables: { input },
      refetchQueries: [{
        query: GET_EQUIPMENT_WITH_COUNTS,
        variables: refetchVariables,
      }],
    });

    const responseData = response.data?.bulkModifyEquipment?.equipment;

    if (responseData) {
      logger('BulkUpdateEquipment').info(`${columnName} successfully updated on all selected record(s)`, response.data);
      return {
        responseData,
        responseMessage: `${columnName} successfully updated on all selected record(s)!`
      };
    } else {
      return {
        responseMessage: `Failed to update ${columnName}!`,
      };
    }
  };

  return { 
    bulkUpdateEquipment, 
    bulkUpdateEquipmentLoading: loading, 
    bulkUpdateEquipmentError: error,
  };
};
