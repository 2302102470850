import { FC, useEffect, useState } from 'react';
import { TreeView } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, Chip, CircularProgress } from '@mui/material';
import StyledTreeItem from 'src/components/HierarchyViewStyledTreeItem';
import DescriptionIcon from '@mui/icons-material/Description';
import { useGetLazyDocumentationById } from 'src/hooks/documentationLibraries/useGetLazyDocumentationById';
import { useGetDocumentationCountsByCategory } from 'src/hooks/documentationLibraries/useGetDocumentationCountsByCategory';
import { generateHierarchicalTree, getPlainTextFromHtml, TreeDataItem } from 'src/utils';
import useMergedFilters from 'src/hooks/common/useMergedFilters';
import { logger } from 'src/helpers/logger';
import { Documentation, DocumentationFilterInput, GroupCountOfCategory } from 'src/generated/dotnet.graphql';
import { MasterSearchInputValue } from 'src/components/MasterSearchInput';
import { useGetLazyDocumentationByCategory } from 'src/hooks/documentationLibraries/useGetLazyDocumentationsByCategory';
import { DocumentationDefaultFilterValue, GridType } from 'src/consts';

interface InjectedProps {
  onChange: (documentation: Documentation) => void;
  refetchTree?: boolean;
  masterSearchInput?: MasterSearchInputValue | null;
  defaultFilterValue: DocumentationDefaultFilterValue;
  hideToogleButton?: boolean;
}

const CategoryViewGrid: FC<InjectedProps> = ({
  onChange,
  refetchTree,
  masterSearchInput = null,
  defaultFilterValue,
  hideToogleButton=false
}) => {
  const [data, setData] = useState<TreeDataItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selected, setSelected] = useState<string | null>(null);
  const [documentationsMap, setDocumentationsMap] = useState<Map<string, TreeDataItem[]>>(new Map());
  const [expandedNodes, setExpandedNodes] = useState<Set<string>>(new Set());
  const [filterValue] = useMergedFilters<DocumentationFilterInput>(masterSearchInput, defaultFilterValue, GridType.CATEGORY);
  const { recordCountsByCategory, refetchRecordCountsByCategory } = useGetDocumentationCountsByCategory(filterValue);
  const { getLazyDocumentationById } = useGetLazyDocumentationById();
  const { getLazyDocumentationsByCategory } = useGetLazyDocumentationByCategory();

  useEffect(() => {
    if (recordCountsByCategory) {
      setLoading(true);
      const transformedData = transformData(recordCountsByCategory);
      const treeData = generateHierarchicalTree(null, 'id', 'parentId', transformedData);
      // Check if the treeData is different before setting state
      setData((prevData) => {
        if (JSON.stringify(prevData) !== JSON.stringify(treeData)) {
          return treeData || [];
        }
        return prevData;
      });
      setLoading(false)
    }
  }, [recordCountsByCategory]);

  useEffect(() => {
    refetchRecordCountsByCategory();
    expanded.forEach(async (nodeId) => {
      await loadDocumentations(nodeId);
    });
  }, [refetchTree, filterValue]);

  const transformData = (data: GroupCountOfCategory[]) => {
    return data.map((record: GroupCountOfCategory) => ({
      id: record.item?.id || "",
      name: record.item?.category !== undefined ? record.item?.category : "No Category Related",
      parentId: record.item?.parentId || null,
      count: record.count,
    }));
  };

  const handleToggle = async (event: any, nodeIds: any) => {
    const [nodeId] = nodeIds;
    if (!expandedNodes.has(nodeId)) {
      setExpandedNodes(prev => new Set(prev).add(nodeId));
      await loadDocumentations(nodeId);
    }
    setExpanded(nodeIds);
  };

  const handleExpandClick = () => {
    const dataIds: string[] = [];
    const collectIds = (node: any) => {
      dataIds.push(node.id);
      if (node.children) {
        node.children.forEach((child: any) => collectIds(child));
      }
    };
    data.forEach(item => collectIds(item));
    setExpanded(oldExpanded => (oldExpanded.length === 0 ? dataIds : []));
  };

  const handleChange = async (item: any) => {
    setSelected(item.id);
    const queryResult = await getLazyDocumentationById({ variables: { documentationId: item.id } });
    const documentation = queryResult.data?.documentationById;
    if (documentation) {
      onChange(documentation); 
    } else {
      logger('GetDocumentationById').error(`Documentation not found with id: ${item.id}`);
    }
  };  

  const loadDocumentations = async (categoryId: string) => {
    try {
      const filterInput = { ...filterValue, categoryId };
      const queryResult = await getLazyDocumentationsByCategory({ variables: { filterInput } });
      const documentations = queryResult.data?.documentation?.items || [];
      
      const documentationNodes: TreeDataItem[] = documentations.map((entry: any) => ({
        id: entry.id,
        name: entry.docTitle || 'Untitled',
        value: entry.id,
        count: 0,
        children: [],
      }));
  
      // Update the documentationsMap with the processed data
      setDocumentationsMap((prev) => new Map(prev).set(categoryId, documentationNodes));
    } catch (error) {
      logger('GetDocumentationsByCategory').error(`Error loading log entries for category ${categoryId}:`, error);
      // Set an empty array in case of error
      setDocumentationsMap((prev) => new Map(prev).set(categoryId, []));
    }
  };

  const renderTree = (node: TreeDataItem) => (
    <StyledTreeItem
      key={node.id}
      nodeId={node.id}
      label={
        <div className="flex flex-row items-center">
          <span className="mr-3 block font-bold">{node.name}</span>
          <Chip label={node.count} />
        </div>
      }
    >
      {node.children && node.children.map(renderTree)} {/* Recursive rendering */}
      {(documentationsMap.get(node.id) || []).map((documentation) => (
        <Button
          key={documentation.id}
          onClick={() => handleChange(documentation)}
          sx={{ justifyContent: 'flex-start' }}
          style={selected === documentation.id ? { background: '#0a214291', color: '#fff' } : {}}
          fullWidth
          size="large"
          startIcon={<DescriptionIcon/>}
          variant="text"
        >
          {documentation.name}
        </Button>
      ))}
    </StyledTreeItem>
  );
  

  if (loading) {
    return (
      <div className="flex flex-grow items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      {!hideToogleButton && (
        <Box sx={{ mb: 4 }}>
          <Button
            variant="contained"
            sx={{ height: 38, width: 125 }}
            startIcon={expanded.length === 0 ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
            onClick={handleExpandClick}
          >
            {expanded.length === 0 ? 'Expand' : 'Collapse'}
          </Button>
        </Box>
      )}
      <TreeView
        sx={{ width: '100%' }}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        onNodeToggle={handleToggle}
      >
        {data.map(renderTree)}
      </TreeView>
    </div>
  );
};

export default CategoryViewGrid;
