import { ReactNode } from 'react';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import ProductionQuantityLimitsOutlinedIcon from '@mui/icons-material/ProductionQuantityLimitsOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import CheckroomOutlinedIcon from '@mui/icons-material/CheckroomOutlined';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import { Inventory, InventoryLocation, InventoryType } from "src/generated/dotnet.graphql";

export const buildInventoryByCategoryNodeName = (inventory: Inventory, inventoryType: InventoryType): string => {
  switch (inventoryType) {
    case InventoryType.GeneralInventory:
      return `${inventory.productName} | ${inventory.manufacturer ? inventory.manufacturer : 'N/A'} | ${inventory.partNumber ? inventory.partNumber : 'N/A'} | (${inventory.totalAmount ? inventory.totalAmount : '0'})`;
    case InventoryType.Beverages:
    case InventoryType.Uniform:
      return `${inventory.productName} | ${inventory.modelNumber ? inventory.modelNumber : 'N/A'} | ${inventory.size ? inventory.size : 'N/A'} | (${inventory.totalAmount ? inventory.totalAmount : '0'})`;
    case InventoryType.DryAndColdStore:
      return `${inventory.productName} | ${inventory.manufacturer ? inventory.manufacturer : 'N/A'} | (${inventory.totalAmount ? inventory.totalAmount : '0'})`;
    case InventoryType.Medical:
      return `${inventory.productName} | ${inventory.manufacturer ? inventory.manufacturer : 'N/A'} | ${inventory.modelNumber ? inventory.modelNumber : 'N/A'} | (${inventory.totalAmount ? inventory.totalAmount : '0'})`;
    default:
      return 'Untitled';
  }
}

export const buildInventoryByLocationNodeName = (inventoryLocation: InventoryLocation, inventoryType: InventoryType): string => {
  switch (inventoryType) {
    case InventoryType.GeneralInventory:
      return `${inventoryLocation.inventory.productName} | ${inventoryLocation.inventory.manufacturer ? inventoryLocation.inventory.manufacturer : 'N/A'} | ${inventoryLocation.inventory.partNumber ? inventoryLocation.inventory.partNumber : 'N/A'} | (${inventoryLocation.amount ? inventoryLocation.amount : '0'} of ${inventoryLocation.inventory.totalAmount ? inventoryLocation.inventory.totalAmount : '0'})`;
    case InventoryType.Beverages:
    case InventoryType.Uniform:
      return `${inventoryLocation.inventory.productName} | ${inventoryLocation.inventory.modelNumber ? inventoryLocation.inventory.modelNumber : 'N/A'} | ${inventoryLocation.inventory.size ? inventoryLocation.inventory.size : 'N/A'} | (${inventoryLocation.amount ? inventoryLocation.amount : '0'} of ${inventoryLocation.inventory.totalAmount ? inventoryLocation.inventory.totalAmount : '0'})`;
    case InventoryType.DryAndColdStore:
      return `${inventoryLocation.inventory.productName} | ${inventoryLocation.inventory.manufacturer ? inventoryLocation.inventory.manufacturer : 'N/A'} | (${inventoryLocation.amount ? inventoryLocation.amount : '0'} of ${inventoryLocation.inventory.totalAmount ? inventoryLocation.inventory.totalAmount : '0'})`;
    case InventoryType.Medical:
      return `${inventoryLocation.inventory.productName} | ${inventoryLocation.inventory.manufacturer ? inventoryLocation.inventory.manufacturer : 'N/A'} | ${inventoryLocation.inventory.modelNumber ? inventoryLocation.inventory.modelNumber : 'N/A'} | (${inventoryLocation.amount ? inventoryLocation.amount : '0'} of ${inventoryLocation.inventory.totalAmount ? inventoryLocation.inventory.totalAmount : '0'})`;
    default:
      return 'Untitled';
  }
}

export const buildInventoryNodeAlertIcons = (inventory: Inventory, inventoryType: InventoryType): ReactNode => {

  const getBaseIcon = () => {
    switch (inventoryType) {
      case InventoryType.Beverages:
        return <LocalBarIcon fontSize="small" />;
      case InventoryType.DryAndColdStore:
        return <FastfoodIcon fontSize="small" />;
      case InventoryType.Uniform:
        return <CheckroomOutlinedIcon fontSize="small" />;
      case InventoryType.Medical:
        return <MedicalServicesOutlinedIcon fontSize="small" />;
      default:
        return <Inventory2OutlinedIcon fontSize="small" />;
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      {getBaseIcon()}
      {inventory.expiredAtLocation && (
        <EventBusyOutlinedIcon fontSize="small" color="error" />
      )}
      {inventory.lowStock && (
        <ProductionQuantityLimitsOutlinedIcon fontSize="small" color="error" />
      )}
    </div>
  );
}

export const getInventoryLabels = (inventoryType: InventoryType) => {
  return {
    manufacturerLabel: inventoryType === InventoryType.Beverages ? 'Maker/Bottler' : 'Manufacturer',
    partNumberLabel: inventoryType === InventoryType.Beverages ? 'Varietal' : 'Part Number',
    modelNumberLabel:
      inventoryType === InventoryType.Beverages
        ? 'Vintage'
        : inventoryType === InventoryType.DryAndColdStore
        ? 'Product Number'
        : inventoryType === InventoryType.Uniform
        ? 'Style'
        : inventoryType === InventoryType.Medical
        ? 'Item Number'
        : 'Model Number',
  };
};
