import { FC, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { withSnackbar } from 'src/components/UI/SnackbarHOC';
import UpdateRecordPopup from './component/UpdateRecordPopup';
import { UpdateRecordInputType, UpdateRecordsOptionsTypeOld } from 'src/consts';
import AppRegistrationTwoToneIcon from '@mui/icons-material/AppRegistrationTwoTone';
import { RecordType } from 'src/generated/dotnet.graphql';

interface UpdateRecordButtonProps {
  onSubmit: (payload: UpdateRecordInputType) => void;
  visible: boolean;
  recordType: RecordType;
  recordsNumber: number;
  options: UpdateRecordsOptionsTypeOld;
  saveLoading: boolean;
}

const UpdateRecordButton: FC<UpdateRecordButtonProps> = ({
  onSubmit,
  visible,
  recordType,
  recordsNumber,
  options,
  saveLoading
}) => {
  const [isUpdatePopupOpen, setIsUpdatePopupOpen] = useState(false);

  const handleUpdatePopup = async () => {
    setIsUpdatePopupOpen(true);
  };

  const handleUpdatePopupCancel = () => {
    setIsUpdatePopupOpen(false);
  };

  return (
    <>
      <Tooltip title="Update Records">
        <span>
          <IconButton
            onClick={handleUpdatePopup}
            aria-label="Update Records"
            color="info"
            disabled={visible}
          >
            <AppRegistrationTwoToneIcon />
          </IconButton>
        </span>
      </Tooltip>

      <UpdateRecordPopup
        visible={isUpdatePopupOpen}
        recordType={recordType}
        onCancel={handleUpdatePopupCancel}
        onSubmit={onSubmit}
        options={options}
        saveLoading={saveLoading}
        recordsNumber={recordsNumber}
      />
    </>
  );
};

export default withSnackbar(UpdateRecordButton);
