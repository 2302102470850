import { FC, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Button, Box } from "@mui/material";
import FmdBadTwoToneIcon from '@mui/icons-material/FmdBadTwoTone';
import { LoadingButton } from "@mui/lab";
import LocationDropdown from "src/components/Dropdowns/LocationDropdown";
import { Control } from "react-hook-form";
import { last } from "lodash";
import { isNotNil } from "src/utils";
import { RecordType } from "src/generated/dotnet.graphql";
import { useAppState } from "src/contexts/app-state";

interface InjectedProps extends Partial<DialogProps> {
  visible: boolean;
  control: Control;
  onMove: (newLocationId: string) => void;
  onCancel: () => void;
  disabled?: boolean;
  loading?: boolean;
}

const MoveInventoryToLocationDialog: FC<InjectedProps> = ({
  visible,
  control,
  onCancel,
  onMove,
  disabled = false,
  loading = false,
}) => {
  const [selectedLocationId, setSelectedLocationId] = useState<string | null>(null);
  const { settingsPersonal } = useAppState();
  
  const handleSelectLocation = async (value: string[]) => {
    const selectedLocation = last(value);
    if (isNotNil(selectedLocation)) {
      setSelectedLocationId(selectedLocation!); // keep track of the selected location
    }
  };

  const handleMove = () => {
    if (selectedLocationId) {
      onMove(selectedLocationId);
    }
  }

  return (
    <Dialog
      open={visible}
      onClose={onCancel}
      aria-labelledby="move-to-location-dialog-title"
      aria-describedby="move-to-location-dialog-description"
      data-testid="move-to-location-dialog"
    >
      <DialogTitle id="alert-dialog-title">
        {(<FmdBadTwoToneIcon fontSize="medium" color="info" className="mr-3"/>)}Select a Destination Location
      </DialogTitle>
      
      <DialogContent>
        <div className="pt-2">
          <LocationDropdown
            name="locationId"
            control={control}
            onChange={(_event, value: any) => {handleSelectLocation(value)}}
            label='Choose Location'
            recordType={RecordType.InventoryGeneral}
            allDepts={settingsPersonal?.fldAllDepts > 0}
            dropdownHeight={700}
          />
        </div>
      </DialogContent>
      
      <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
        <Box sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}>
          {!disabled && 
            <LoadingButton loading={loading} data-testid="warning-dialog-confirm" className="mx-2" onClick={handleMove}>
              Move
            </LoadingButton>
          }
        </Box>
        <Button data-testid="warning-dialog-cancel" onClick={onCancel} variant="contained">Cancel</Button>
      </DialogActions>

    </Dialog>
  );
};

export default MoveInventoryToLocationDialog;
