import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery  } from "@apollo/client";
import {BunkeringCollectionSegment } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_LAZY_BUNKERING_BY_FLUID_TYPE = gql`
  query GetLazyBunkeringByFluidType($filterInput: BunkeringFilterInput, $order: [BunkeringSortInput!]) {
    bunkering(filterInput: $filterInput, order: $order) {
      items {
        id,
        date,
        fluid,
        from,
        to,
        station,
        agent,
        start,
        stop,
        amount,
        qty,
        cost,
        curr,
        oilRecord,
        responsible,
        method,
      }
    }
  }
`

interface BunkeringData {
  bunkering: BunkeringCollectionSegment;
}

interface BunkeringResult {
  getLazyBunkeringByFluidType: LazyQueryExecFunction<BunkeringData, OperationVariables>;
}

export const useGetLazyBunkeringByFluidType = (): BunkeringResult => {

  const [ getBunkering, { error }] = useLazyQuery(GET_LAZY_BUNKERING_BY_FLUID_TYPE, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetLazyBunkeringByFluidType').error('Error fetching bunkering by fluid type -->', error.message);
  }

  return {
    getLazyBunkeringByFluidType: getBunkering
  };
};