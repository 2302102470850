import { FC, useState } from 'react';
import { useForm  } from 'react-hook-form';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Autocomplete as MaterialAutocomplete, Alert, Portal, Snackbar, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { CHAR_LIMIT, libTypeOptions, UpdateRecordInputType, UpdateRecordsOptionsType } from 'src/consts';
import Autocomplete from 'src/components/UI/Forms/Autocomplete';
import { last } from 'lodash';
import Select from 'src/components/UI/Forms/Select';
import Input from 'src/components/UI/Forms/Input';
import CategoryDropdown from 'src/components/Dropdowns/CategoryDropdown';
import { DocumentationLibType, RecordType } from 'src/generated/dotnet.graphql';
import { useAppState } from 'src/contexts/app-state';
import CompaniesDropdown from 'src/components/Dropdowns/CompaniesDropdown';
import ListDefaultAutocomplete from 'src/components/UI/Forms/ListDefaultAutocomplete';
import { handleCharLimitWarning } from 'src/utils';
import LocationDropdown from 'src/components/Dropdowns/LocationDropdown';
import EquipmentManufacturersDropdown from 'src/components/Dropdowns/EquipmentManufacturersDropdown';
import EquipmentSuppliersDropdown from 'src/components/Dropdowns/EquipmentSuppliersDropdown';
import ReportingTagMultiSelect from 'src/components/Dropdowns/ReportingTagMultiSelect';

interface InjectedProps {
  visible: boolean;
  recordType: RecordType;
  recordsNumber: number;
  options: UpdateRecordsOptionsType,
  onCancel?: () => void;
  onSubmit: (payload: UpdateRecordInputType) => void;
  saveLoading: boolean;
}

const booleanOptions = ['True', 'False']

const UpdateRecordPopup: FC<InjectedProps> = ({
  visible,
  recordType,
  recordsNumber,
  options,
  onCancel,
  onSubmit,
  saveLoading,
}) => {
  const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm<any>({ mode: 'onBlur', shouldFocusError: true });
  const { settingsPersonal } = useAppState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [libTypeSelected, setLibTypeSelected] = useState<any>(null);
  const { INVENTORY } = CHAR_LIMIT;

  const manufacturerLabel = recordType === RecordType.InventoryBeverages ? 'Maker/Bottler' : 'Manufacturer';
  const partNumberLabel = recordType === RecordType.InventoryBeverages ? 'Varietal' : 'Part Number';
  const modelNumberLabel = 
    recordType === RecordType.InventoryBeverages ? 'Vintage' : 
    recordType === RecordType.InventoryDryAndColdStore ? 'Product Number' : 
    recordType === RecordType.InventoryUniform ? 'Style' : 'Model Number'

  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const onChange = async (name: string, value: any) => {
    let shouldDirty = true;
    if (name === 'option') {
      setSelectedOption(value)
    }
    setValue(name, value, { shouldDirty: shouldDirty });
  };

  const handleLibraryTypeChange = (newData: any) => {
    setLibTypeSelected(newData);
    setValue('libType', newData);
  };
  
  const onSaveClicked = async (data: any) => {
    let value;

    switch (data.option.fieldName) {
      case 'categoryId':
        value = last(data[data.option.fieldName]);
        break;
      case 'manufacturer':
      case 'supplier':
        value = data[data.option.fieldName] ? data[data.option.fieldName].name : null;
        break;
      case 'department':
      case 'country':
      case 'region':
      case 'size':
      case 'color':
        value = data[data.option.fieldName].member;
        break;
      case 'libType':
        value = data[data.option.fieldName].type as DocumentationLibType;
        break;
      case 'showInCentral':
      case 'isReport':
      case 'restricted':
      case 'countHours':
      case 'requiresVerification':
      case 'consumable':
      case 'bonded':
      case 'sms':
        value = data[data.option.fieldName] === 'True' ? true : false;
        break;
      default:
        value = data[data.option.fieldName];
        break;
    }

    const payload: UpdateRecordInputType = {
      fieldName: data.option.fieldName,
      columnName: data.option.columnName,
      value: value,
    }

    onSubmit(payload);
    setSelectedOption(null);
    onCancelClick();
  };

  const onCancelClick = () => {
    reset();
    setSelectedOption(null);
    onCancel && onCancel();
  };

  return (
    <div>
      <form
        id="updateRecords-popup"
        className="relative bg-white flex-grow"
        onSubmit={handleSubmit(onSaveClicked)}
      > 
        <Dialog
          open={visible}
          onClose={onCancelClick}
          fullWidth
          maxWidth="md"
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle>
            <span className="font-bold text-2xl">
              Update Records Utility
            </span>
            {onCancel ? (
              <IconButton
                aria-label="close"
                onClick={onCancelClick}
                sx={{
                  position: 'absolute',
                  right: 10,
                  top: 14,
                  color: (theme) => theme.palette.grey[400],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent dividers>
              <div className="bg-white h-full flex-grow">
                <div className="h-full">
                  <div className="mt-6 mb-4" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <span className="font-semibold text-gray-600 text-sm">
                      {`Which field would you like to update for the selected Records?`}
                    </span>
                  </div>
                  <div className="mb-6">
                    <div className="mb-4">
                      <Autocomplete
                        label='Column Name'
                        placeholder='Select Column Name'
                        control={control} 
                        name='option' 
                        options={Object.values(options)} 
                        onChange={onChange} 
                        displayExpr='columnName' 
                        freeSolo={true}
                        keyExpr=''                        
                      />
                    </div>
                  </div>
                  {selectedOption && (
                    <div className="mt-6 mb-4" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                      <span className="font-semibold text-gray-600 text-sm">
                          {`Select the new "${(selectedOption as { columnName: string }).columnName}"`}
                      </span>
                    </div>
                  )}
                  <div className="mb-4">
                    {selectedOption === options.CATEGORY && (
                      <div className="mb-4">
                        <CategoryDropdown
                          name="categoryId"
                          label="Category"
                          control={control}
                          onChange={onChange}
                          recordType={recordType}
                          rules={{ required: true }}
                          allDepts={settingsPersonal?.fldAllDepts > 0}
                          actionable
                        />
                      </div>
                    )}
                    {selectedOption === options.LOCATION && (
                      <div className="mb-4">
                        <LocationDropdown
                          control={control}
                          label="Location"
                          name="locationId"
                          onChange={onChange}
                          recordType={recordType}
                          allDepts={settingsPersonal?.fldAllDepts > 0}
                          actionable
                        />
                      </div>
                    )}
                    {selectedOption === options.MANUFACTURER && recordType !== RecordType.Equipment && (
                      <div className="mb-4">
                        <CompaniesDropdown
                          control={control}
                          label={manufacturerLabel}
                          name="manufacturer"
                          onChange={onChange}
                        />
                      </div>
                    )}
                    {selectedOption === options.MANUFACTURER && recordType === RecordType.Equipment && (
                      <EquipmentManufacturersDropdown
                        control={control}
                        label='Manufacturer'
                        name="manufacturer"
                        onChange={onChange}
                      />
                    )}
                    {selectedOption === options.SUPPLIER && recordType === RecordType.Equipment && (
                      <EquipmentSuppliersDropdown
                        control={control}
                        label='Supplier'
                        name="supplier"
                        onChange={onChange}
                      />
                    )}
                    {selectedOption === options.DEPARTMENT && (
                      <div className="mb-4">
                        <ListDefaultAutocomplete
                          onChange={onChange}
                          control={control}
                          name="department"
                          label="Department"
                          listName="Department"
                          
                        />
                      </div>
                    )}
                    {selectedOption === options.LIBRARY_TYPE && (
                      <div className="mb-4">
                        <MaterialAutocomplete
                          disablePortal
                          id="File-type"
                          value={libTypeSelected}
                          options={libTypeOptions}
                          onChange={(event, newValue: any) => { handleLibraryTypeChange(newValue) }}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            return option.label;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Library Type"
                              autoComplete="off"
                              size="small"
                            />
                          )}
                        />
                      </div>
                    )}
                    {selectedOption === options.RE_ORDER_LEVEL && (
                      <div className="mb-4">
                        <Input
                          name="reOrderLevel"
                          inputProps={{
                            label: 'Min. Holding Level',
                            type: 'number',
                            inputProps: { min: 0, style: { textAlign: 'end' } },
                          }}
                          control={control}
                          rules={{ min: 0 }}
                        />
                      </div>
                    )}
                    {selectedOption === options.PART_NUMBER && (
                      <div className="mb-4">
                        <Input
                          inputProps={{
                            size: 'small',
                            label: partNumberLabel,
                          }}
                          rules={{ maxLength: INVENTORY.partNumber }}
                          warning={(value) => handleCharLimitWarning(value, INVENTORY.partNumber)}
                          control={control}
                          name="partNumber"
                        />
                      </div>
                    )}
                    {selectedOption === options.COUNTRY && (
                      <div className="mb-4">
                        <ListDefaultAutocomplete
                          onChange={onChange}
                          control={control}
                          name="country"
                          label="Country"
                          listName="Countries"
                          freeSolo={true}
                        />
                      </div>
                    )}
                    {selectedOption === options.REGION && (
                      <div className="mb-4">
                        <ListDefaultAutocomplete
                          onChange={onChange}
                          control={control}
                          name="region"
                          label="Region"
                          listName="Regions"
                          freeSolo={true}
                        />
                      </div>
                    )}
                    {selectedOption === options.MODEL_NUMBER && (
                      <div className="mb-4">
                        <Input
                          name="modelNumber"
                          rules={{ maxLength: INVENTORY.modelNumber }}
                          warning={(value) => handleCharLimitWarning(value, INVENTORY.modelNumber)}
                          control={control}
                          inputProps={{ label: modelNumberLabel }}
                        />
                      </div>
                    )}
                    {selectedOption === options.REPORTING_TAG && (
                      <div className="mb-4">
                        {/* TODO - Switch to multiselect after db migration to suport a longer string
                        <ReportingTagMultiSelect
                          onChange={onChange}
                          control={control}
                        /> */}
                        <ListDefaultAutocomplete
                          onChange={onChange}
                          control={control}
                          name="reportingTag"
                          label="Reporting Tag"
                          listName="Reporting Tag"
                          freeSolo={true}
                        />
                      </div>
                    )}
                    {selectedOption === options.SIZE && (
                      <div className="mb-4">
                        <ListDefaultAutocomplete
                          onChange={onChange}
                          control={control}
                          name="size"
                          label="Size"
                          listName="Sizes"
                          freeSolo={true}
                        />
                      </div>
                    )}
                    {selectedOption === options.COLOR && (
                      <div className="mb-4">
                        <ListDefaultAutocomplete
                          onChange={onChange}
                          control={control}
                          name="color"
                          label="Color"
                          listName="Colors"
                          freeSolo={true}
                        />
                      </div>
                    )}
                    {selectedOption === options.SHOW_IN_CENTRAL && (
                      <div className="mb-4" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Select
                          control={control}
                          name="showInCentral"
                          label="Included in Central Document Library"
                          options={booleanOptions}
                        />
                      </div>
                    )}
                    {selectedOption === options.IS_REPORT && (
                      <div className="mb-4" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Select
                          control={control}
                          name="isReport"
                          label="Is a Report"
                          options={booleanOptions}
                        />
                      </div>
                    )}
                    {selectedOption === options.REQUIRES_VERIFICATION && (
                      <div className="mb-4">
                        <Select
                          control={control}
                          name="requiresVerification"
                          label="Requires Verification"
                          options={booleanOptions}
                        />
                      </div>
                    )}
                    {selectedOption === options.CONSUMABLE && (
                      <div className="mb-4">
                        <Select
                          control={control}
                          name="consumable"
                          label="Consumable"
                          options={booleanOptions}
                        />
                      </div>
                    )}
                    {selectedOption === options.BONDED && (
                      <div className="mb-4">
                        <Select
                          control={control}
                          name="bonded"
                          label="Bonded"
                          options={booleanOptions}
                        />
                      </div>
                    )}
                    {selectedOption === options.SMS && (
                      <div className="mb-4">
                        <Select
                          control={control}
                          name="sms"
                          label="Critical"
                          options={booleanOptions}
                        />
                      </div>
                    )}
                    {selectedOption === options.RESTRICTED_TO_HOD && (
                      <div className="mb-4">
                        <Select
                          control={control}
                          name="restricted"
                          label="Restricted to HOD"
                          options={booleanOptions}
                        />
                      </div>
                    )}
                    {selectedOption === options.MONITOR_HOURS && (
                      <div className="mb-4">
                        <Select
                          control={control}
                          name="countHours"
                          label="Monitor Hours"
                          options={booleanOptions}
                        />
                      </div>
                    )}
                  </div>
                  {selectedOption && (
                    <div className="mt-10" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                      <span className="text-gray-600 text-sm">
                        <strong>{recordsNumber}</strong> {recordsNumber > 1 ? 'records' : 'record'} will be updated.
                      </span>
                    </div>
                  )}
                </div>
              </div>
          </DialogContent>
          <DialogActions sx={{ m: 0, p: 3 }}>
            <LoadingButton
              type='submit'
              loading={saveLoading}
              form='updateRecords-popup'
              className="ml-4 mr-3 w-32"
              variant="contained"
            >
              Save
            </LoadingButton>
            <Button onClick={onCancelClick} className="w-32">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Portal>
          <Snackbar
            open={snackBar.open}
            autoHideDuration={2000}
            onClose={onSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
              {snackBar.message}
            </Alert>
          </Snackbar>
        </Portal>
      </form>
    </div>
  );
};

export default UpdateRecordPopup;
