import { ApolloQueryResult, gql, useQuery } from '@apollo/client';
import { Equipment, EquipmentFilterInput, EquipmentSortInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { PaginatedResult } from '../interfaces';

export const GET_EQUIPMENT_WITH_COUNTS = gql`
  query GetEquipmentWithCounts($skip: Int, $take: Int, $filterInput: EquipmentFilterInput, $order: [EquipmentSortInput!]) {
    equipmentWithCounts: equipment(skip: $skip, take: $take, filterInput: $filterInput, order: $order) {
      items {
        __typename
        id
        uniqueName
        manufacturer
        modelNumber
        department
        hours
        hoursOffset
        countHours
        categoryId
        locationId
        reportingTag
        restricted
        sms
        notes
        supplier
        inService
        expireDate
        serialNum
        arrangementNum
        artNumber
        dateEntered
        rating
        parentId
        parentUniqueName
        commentsCount
        documentsCount
        photosCount
        hasOverDueTasks
        workIssuesCount
        inventoryCount
        logEntryCount
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export const useGetEquipmentWithCounts = (skip: number, take: number,  filterInput: EquipmentFilterInput, order: EquipmentSortInput[]): PaginatedResult<Equipment, EquipmentFilterInput, EquipmentSortInput> => {
  const { data, loading, error, refetch } = useQuery(GET_EQUIPMENT_WITH_COUNTS, {
    variables: { skip, take, filterInput, order },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const loadData = (skip: number, take: number, filterInput: EquipmentFilterInput, order: EquipmentSortInput[]) => {
    logger('GetEquipmentWithCounts').debug('Query variables', { skip: skip, take: take, filterInput: filterInput, order: order });
    refetch({ skip: skip, take: take, filterInput: filterInput, order: order })
      .then((result: ApolloQueryResult<any>) => {
        if (result.data && result.data.equipmentWithCounts) {
          const { items, pageInfo } = result.data.equipmentWithCounts;
          logger('GetEquipmentWithCounts').info('Equipment fetched successfully', { recordsCount: items?.length, pageInfo: pageInfo });
        } else {
          logger('GetEquipmentWithCounts').warning('No data received in query result');
        }
      })
      .catch((apolloError) => {
        logger('GetEquipmentWithCounts').error('Error re-fetching equipment -->', apolloError.message);
      });
  };

  if (error) {
    logger('GetEquipmentWithCounts').error('Error fetching equipment -->', error.message);
  }

  return {
    data: loading ? [] : data?.equipmentWithCounts?.items || [],
    totalCount: data?.equipmentWithCounts?.totalCount || 0,
    currentCount: data?.equipmentWithCounts?.items?.length || 0,
    pageInfo: data?.equipmentWithCounts?.pageInfo,
    loading,
    loadData,
  };
};
