import { FC, useEffect, useState } from 'react';
import { TreeView } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, Chip, CircularProgress } from '@mui/material';
import StyledTreeItem from 'src/components/HierarchyViewStyledTreeItem';
import { generateHierarchicalTree, TreeDataItem } from 'src/utils';
import useMergedFilters from 'src/hooks/common/useMergedFilters';
import { logger } from 'src/helpers/logger';
import { InventoryFilterInput, Bunkering, GroupCountOfString, SortEnumType } from 'src/generated/dotnet.graphql';
import { MasterSearchInputValue } from 'src/components/MasterSearchInput';
import { GridType } from 'src/consts';
import { useGetBunkeringCountsByFluidType } from 'src/hooks/bunkering/useGetBunkeringCountsByFluidType';
import { useGetLazyBunkeringByFluidType } from 'src/hooks/bunkering/useGetLazyBunkeringByFluidType';
import { buildBunkeringByFluidTypeName } from '../utils';

interface InjectedProps {
  onChange: (bunkering: Bunkering) => void;
  refetchTree?: boolean;
  masterSearchInput?: MasterSearchInputValue | null;
}

const BunkeringFluidTypeViewGrid: FC<InjectedProps> = ({
  onChange,
  refetchTree,
  masterSearchInput = null,
}) => {
  const [data, setData] = useState<TreeDataItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selected, setSelected] = useState<string | null>(null);
  const [bunkeringMap, setBunkeringMap] = useState<Map<string, TreeDataItem[]>>(new Map());
  const [expandedNodes, setExpandedNodes] = useState<Set<string>>(new Set());
  const defaultFilterValue = { searchText: undefined, sample: undefined };
  const [filterValue] = useMergedFilters<InventoryFilterInput>(masterSearchInput, defaultFilterValue, GridType.CATEGORY);
  const { bunkeringCountsByFluidType, refetchBunkeringCountsByFluidType } = useGetBunkeringCountsByFluidType(filterValue);
  const { getLazyBunkeringByFluidType } = useGetLazyBunkeringByFluidType();

  useEffect(() => {
    if (bunkeringCountsByFluidType) {
      setLoading(true);
      const transformedData = transformData(bunkeringCountsByFluidType);
      const treeData = generateHierarchicalTree(null, 'id', 'parentId', transformedData);
      // Check if the treeData is different before setting state
      setData((prevData) => {
        if (JSON.stringify(prevData) !== JSON.stringify(treeData)) {
          return treeData || [];
        }
        return prevData;
      });
      setLoading(false)
    }
  }, [bunkeringCountsByFluidType]);

  useEffect(() => {
    refetchBunkeringCountsByFluidType();
    expanded.forEach(async (nodeId) => {
      await loadBunkering(nodeId);
    });
  }, [refetchTree, filterValue]);

  const transformData = (data: GroupCountOfString[]) => {
    return data.map((record: GroupCountOfString) => ({
      id: record.item || "",
      name: record.item !== undefined ? record.item : "No Fluid Type assigned",
      parentId: null,
      count: record.count,
    }));
  };

  const handleToggle = async (event: any, nodeIds: any) => {
    const [nodeId] = nodeIds;
    if (!expandedNodes.has(nodeId)) {
      setExpandedNodes(prev => new Set(prev).add(nodeId));
      await loadBunkering(nodeId);
    }
    setExpanded(nodeIds);
  };

  const handleOnClick = async (item: TreeDataItem) => {
    setSelected(item.id);
    onChange(item.value); 
  };  

  const loadBunkering = async (fluid: string) => {
    try {
      const filterInput = { ...filterValue, fluid };
      const order = { date: SortEnumType.Desc }
      const queryResult = await getLazyBunkeringByFluidType({ variables: { filterInput, order } });
      const bunkering = queryResult.data?.bunkering?.items || [];
      
      const bunkeringNodes: TreeDataItem[] = bunkering.map((entry: Bunkering) => ({
        id: entry.id,
        name: buildBunkeringByFluidTypeName(entry),
        value: entry,
        count: 0,
        children: [],
      }));
  
      // Update the inventoryMap with the processed data
      setBunkeringMap((prev) => new Map(prev).set(fluid, bunkeringNodes));
    } catch (error) {
      logger('GetLazyBunkeringByFluidType').error(`Error loading inventory for category ${fluid}:`, error);
      // Set an empty array in case of error
      setBunkeringMap((prev) => new Map(prev).set(fluid, []));
    }
  };

  const renderTree = (node: TreeDataItem) => (
    <StyledTreeItem
      key={node.id}
      nodeId={node.id}
      label={
        <div className="flex flex-row items-center">
          <span className="mr-3 block font-bold">{node.name}</span>
          <Chip label={node.count} />
        </div>
      }
    >
      {node.children && node.children.map(renderTree)} {/* Recursive rendering */}
      {(bunkeringMap.get(node.id) || []).map((bunkering) => (
        <Button
          key={bunkering.id}
          onClick={() => handleOnClick(bunkering)}
          sx={{ justifyContent: 'flex-start' }}
          style={selected === bunkering.id ? { background: '#0a214291', color: '#fff' } : {}}
          fullWidth
          size="large"
          variant="text"
        >
          {bunkering.name}
        </Button>
      ))}
    </StyledTreeItem>
  );
  

  if (loading) {
    return (
      <div className="flex flex-grow items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      <TreeView
        sx={{ width: '100%' }}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        onNodeToggle={handleToggle}
      >
        {data.map(renderTree)}
      </TreeView>
    </div>
  );
};

export default BunkeringFluidTypeViewGrid;
