import { ApolloError, gql, useMutation } from '@apollo/client';
import { Equipment, EquipmentNotFoundError, UpdateEquipmentHoursOffsetInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { MutationResponse } from '../interfaces';

export const UPDATE_EQUIPMENT_HOURS_OFFSET = gql`
  mutation UpdateEquipmentHoursOffset($input: UpdateEquipmentHoursOffsetInput!) {
    updateEquipmentHoursOffset(input: $input) {
      equipment {
        id
        uniqueName
        manufacturer
        modelNumber
        department
        hours
        hoursOffset
        countHours
        categoryId
        locationId
        reportingTag
        restricted
        sms
        notes
        supplier
        inService
        expireDate
        serialNum
        arrangementNum
        artNumber
        dateEntered
        rating
        parentId
      }
      errors {
        ... on EquipmentNotFoundError {
          code
          message
        }
      }
    }
  }
`;

interface UpdateEquipmentHoursOffsetResult {
  updateEquipmentHoursOffset: (input: UpdateEquipmentHoursOffsetInput) => Promise<MutationResponse<Equipment, EquipmentNotFoundError>>;
  updateEquipmentHoursOffsetLoading: boolean;
  updateEquipmentHoursOffsetError?: ApolloError;
}

export const useUpdateEquipmentHoursOffset = (): UpdateEquipmentHoursOffsetResult => {
  const [modifyEquipmentHoursOffset, { loading, error }] = useMutation(UPDATE_EQUIPMENT_HOURS_OFFSET, {
    onError: (error: ApolloError) => {
      logger('UpdateEquipmentHoursOffset').error(`Error updating equipment hours offset -->`, error.message);
    }
  });

  const addHoursOffsetMessage = `Equipment hours offset added successfully!`
  const addHoursOffsetFailMessage = `Failed add equipment hours offset!`
  const removeHoursOffsetMessage = `Equipment hours offset removed successfully!`
  const removeHoursOffsetFailMessage = `Failed to remove equipment hours offset!`

  const updateEquipmentHoursOffset = async (input: UpdateEquipmentHoursOffsetInput): Promise<MutationResponse<Equipment, EquipmentNotFoundError>> => {
    const response = await modifyEquipmentHoursOffset({ 
      variables: { input },
    });

    const responseData = response.data?.updateEquipmentHoursOffset?.equipment;
    let responseMessage = '';

    if (input.hoursOffset === null) {
      if (responseData) {
        responseMessage = removeHoursOffsetMessage;
      } else {
        responseMessage = removeHoursOffsetFailMessage;
      }
    } else {
      if (responseData) {
        responseMessage = addHoursOffsetMessage;
      } else {
        responseMessage = addHoursOffsetFailMessage;
      }
    }

    if (responseData) {
      logger('UpdateEquipmentHoursOffset').info(`Equipment hours offset updated successfully`, response.data);
      return {
        responseData,
        responseMessage,
      };
    } else {
      return {
        responseMessage,
      };
    }
  };

  return { 
    updateEquipmentHoursOffset, 
    updateEquipmentHoursOffsetLoading: loading, 
    updateEquipmentHoursOffsetError: error,
  };
};