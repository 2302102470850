// styles
import '../styles.css';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
// third party components
import moment from 'moment';
import { useCallback, useState, MutableRefObject, forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import { TypeComputedProps, TypeFilterValue, TypeSingleSortInfo, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import { isBoolean, isEmpty, isNil, pick } from 'lodash';
// data
import { useGetBunkeringsWithCounts } from 'src/hooks/bunkering/useGetBunkeringsWithCounts';
import { Bunkering, BunkeringSortInput, BunkeringFilterInput, SortEnumType } from 'src/generated/dotnet.graphql';
// YMS components
import LicensedReactDataGrid from '../../../components/UI/LicensedReactDataGrid';
import GridRowIcon from 'src/components/UI/LicensedReactDataGrid/components/GridRowIcon';
// utils
import { exportCSV, exportExcel } from '../../../utils';
import { toStaticDate, utcToTimeZone } from 'src/utils/format-dates';
import { normalizeTime } from 'src/helpers';
import { useGetLazyBunkering } from 'src/hooks/bunkering/useGetLazyBunkering';
import { defaultFilterValue, defaultSortValue } from '..';
  
const filter = [
  {
    name: 'date',
    operator: 'inrange',
    type: 'date',
    value: '',
  },
  {
    name: 'fluid',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'from',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'to',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'station',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'agent',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  // {
  //   name: 'fldStart',
  //   operator: 'contains',
  //   type: 'string',
  //   value: '',
  // },
  // {
  //   name: 'fldStop',
  //   operator: 'contains',
  //   type: 'string',
  //   value: '',
  // },
  {
    name: 'amount',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'qty',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'cost',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'curr',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'oilRecord',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'responsible',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'method',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const getSelectorByFilterName = async (
  name: string,
  value: any,
): Promise<Record<string, any>> => {
  switch (name) {
    case 'date': {
      const start = toStaticDate(new Date(value.start));
      const end = toStaticDate(new Date(value.end));
      if (!isEmpty(value.start) && (isNil(value.end) || isEmpty(value.end))) {
        return {
          dateStart: start,
        };
      }
      if (!isEmpty(value.end) && (isNil(value.start) || isEmpty(value.start))) {
        return {
          dateEnd: end,
        };
      }
      if (!isEmpty(value.start) && !isEmpty(value.end)) {
        return {
          dateStart: start,
          dateEnd: end,
        };
      }
      return {};
    }
    case 'fluid':
    case 'from':
    case 'to':
    case 'station':
    case 'amount':
    case 'qty':
    case 'cost':
    case 'curr':
    case 'oilRecord':
    case 'responsible':
    case 'method':
    case 'agent': {
      return {
        [name]: value,
      };
    }
    default:
      return {};
  }
};

const transformData = (data: any[]): Record<string, Bunkering> => {
  return data.reduce((acc, { id, ...rest }) => {
    acc[id] = rest;
    return acc;
  }, {});
}

type BunkeringGridProps = {
  darken: boolean;
  onSelect: (bunkering: Bunkering) => void;
  skip: number;
  setSkip: (skip: number) => void;
  limit: number;
  setLimit: (limit: number) => void;
  filterValue: any;
  setFilterValue: (filterValue: any) => void;
  sortValue: any;
  setSortValue: (sortValue: any) => void;
  rowSelected: Record<string, Bunkering>;
  setRowSelected: (rowSelected: Record<string, Bunkering>) => void;
};

interface BunkeringGridRef {
  handleExport: (type: string) => void;
}

interface ExportDialog {
  visible: boolean;
  type: string;
  title: string;
}
  
const BunkeringGrid = forwardRef<BunkeringGridRef, BunkeringGridProps>(({ 
  onSelect, 
  skip,
  setSkip,
  limit,
  setLimit,
  filterValue,
  setFilterValue,
  sortValue,
  setSortValue,
  rowSelected,
  setRowSelected
}: BunkeringGridProps, ref) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [gridRef, setGridRef] = useState<any>(null);
  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [groupCollapsedStatus, setGroupCollapsedStatus] = useState(false);
  const { getLazyBunkering } = useGetLazyBunkering(filterValue, sortValue);
  const { data, totalCount, loading, loadData } = useGetBunkeringsWithCounts({ skip: skip, take: limit, filterInput: filterValue, order: sortValue });
  const [exportDialog, setExportDialog] = useState<ExportDialog | null>(null);

  const prevFilterValue = useRef(filterValue);
  const prevSkip = useRef(skip);
  const prevLimit = useRef(limit);
  const prevSortValue = useRef(sortValue);

  useImperativeHandle(ref, () => ({
    handleExport: (type) => {
      handleDataExport(type);
    },
  }));

  useEffect(() => {
    const refreshButton = document.querySelector('.inovua-react-pagination-toolbar__icon--named--REFRESH');
    if (refreshButton) {
      const handleClick = () => {
        // Set default values for reset when clicking refresh button
        setFilterValue(defaultFilterValue);
        setSkip(0);
        setSortValue(defaultSortValue);
        // Reload data
        loadData(skip, limit, filterValue, sortValue);
        if (gridRef.current) {
          gridRef?.current.clearAllFilters();  // Ensure all filters are cleared in the grid
        }
      };
      // Ensure the refresh button exists before adding event listener
      if (refreshButton) {
        refreshButton.addEventListener('click', handleClick);
      }
      // Cleanup the event listener on unmount or when the component changes
      return () => {
        if (refreshButton) {
          refreshButton.removeEventListener('click', handleClick);
        }
      };
    }
  }, []);

  useEffect(() => {
    const isFilterChanged = JSON.stringify(filterValue) !== JSON.stringify(prevFilterValue.current);
    const isPaginationChanged = skip !== prevSkip.current || limit !== prevLimit.current;
    const isSortChanged = JSON.stringify(sortValue) !== JSON.stringify(prevSortValue.current);
  
    if (isFilterChanged || isPaginationChanged || isSortChanged) {
      loadData(skip, limit, filterValue, sortValue);
  
      // Update refs only after the data is loaded
      prevFilterValue.current = filterValue;
      prevSkip.current = skip;
      prevLimit.current = limit;
      prevSortValue.current = sortValue;
    }
  }, [skip, limit, filterValue, sortValue]);
    
  const dataSource = async () => {
    return {
      data,
      count: totalCount,
    };
  };

  const onSortInfoChange = (value: TypeSortInfo) => {
    const sortInfo = value as TypeSingleSortInfo
    if (isNil(sortInfo)) return;

    let sortPayload: BunkeringSortInput[];
    const sortDirection = sortInfo.dir === 1 ? SortEnumType.Asc : SortEnumType.Desc;
    const [field, subField] = sortInfo.name.split('.');

    if (subField) {
        // Handle nested objects
        sortPayload = [{
            [field]: {
                [subField]: sortDirection,
            },
        }];
    } else {
        // Handle non-nested objects
        sortPayload = [{
            [sortInfo.name]: sortDirection,
        }];
    }
    setSortValue(sortPayload)
  }
    
  const onFilterValueChange = async (filterValue: TypeFilterValue) => {
    if (isNil(filterValue)) return;
  
    const filterPayload: Record<string, any> = {};
  
    await Promise.all(
      filterValue.map(async (v: { value: any; name: any; operator: any }) => {
        if (isEmpty(v.value)) return;
  
        const selector = await getSelectorByFilterName(v.name, v.value);
        if (selector) {
          Object.keys(selector).forEach((key) => {
            filterPayload[key] = selector[key];
          });
        }
      })
    );
    setFilterValue(filterPayload as BunkeringFilterInput);
  };  

  const onGroupByChange = (groupBy: string[]) => {
    if (isEmpty(groupBy)) return;

    let sortPayload: BunkeringSortInput[] = [];

    groupBy.forEach(sortField => {
      const [field, subField] = sortField.split('.');

      if (subField) {
          // Handle nested objects
          sortPayload.push({
              [field]: {
                  [subField]: SortEnumType.Asc,
              },
          });
      } else {
          // Handle non-nested objects
          sortPayload.push({
              [field]: SortEnumType.Asc,
          });
      }
    });
    setSortValue(sortPayload);
    gridRef.current.collapseAllGroups();
  }

  const onSelectionChange = useCallback(({ selected }) => {
    if (isBoolean(selected) && selected) {
      const transformedData = transformData(data);
      setRowSelected(transformedData)
    } else {
      setRowSelected(selected);
    }
  }, [data, rowSelected]);

  const onRowClick = useCallback(
    ({ data }) => {
      if (!data?.__group) {
        if (Object.keys(rowSelected || {}).length < 2) {
          onSelect(data);
        }
      }
    },
    [rowSelected]
  );

  const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    setGridRef(ref);

    // Check if ref.current exists and clearAllFilters is defined
    if (ref?.current?.clearAllFilters) {
      ref.current.clearAllFilters();
    }
  };

  const renderGroupTitle = (value: any, { data }: any) => {
     const columns = data.fieldPath.map((col: string) =>
      col === data.name ? col.toUpperCase() : col
    );
    let path = columns && columns.length && columns.join('>');
      let dataValue = data.value;
    if (path.includes('date')) {
      dataValue = moment(data.value).format('DD-MMM-YYYY');
    }
    return data.value === 'null' ? `No ${path} Assigned` : dataValue;
  };

  const CollapseButton = () => {
    if (groupCollapsedStatus) {
      setGroupCollapsedStatus(false);
      return gridRef.current.collapseAllGroups();
    }
    if (!groupCollapsedStatus) {
      setGroupCollapsedStatus(true);
      return gridRef.current.expandAllGroups();
    }
  };
  
  const areFiltersActive = !!gridRef?.current?.computedFilterValue?.some((f: { value: any, type: string, operator: string }) =>
    (Array.isArray(f.value) ? f.value.some((v) => v !== "") : f.value !== "") && 
    !(f.type === "date" && f.operator === "inrange" && f.value?.start === "" && f.value?.end === "")
  );

  const exportData = (type: string, withFilters: boolean) => {
    setExportDialog(null);
    switch (type) {
      case 'CSV':
        return onExportToCSV(withFilters);
      case 'xlsx':
        return onExportToExcel(withFilters);
      default:
    }
  };

  const getRows = async (issue: any) => {
    const rows =  issue.map((data: any) =>{
      return {
        ...pick(data, [
          'fluid',
          'from',
          'to',
          'station',
          'agent',
          'start',
          'stop',
          'amount',
          'qty',
          'cost',
          'curr',
          'oilRecord',
          'responsible',
          'method',
        ]),
        date: moment(data.date).format('DD-MMM-YYYY'),
      };
    }   
    );
    return rows
  };

  const onExportToExcel = async (withFilters: boolean) => {
    const queryResult = await getLazyBunkering({ variables: { where: withFilters ? filterValue : null, order: sortValue } });
    const data = queryResult?.data?.bunkering?.items || [];
    const columnsData = gridRef.current.visibleColumns.map((c: any) => ({
      header: c.header,
      key: c.id,
    }));
    const columns = columnsData.filter((item: { header: any; }) => {
      return item.header && typeof item.header === 'string';
    });
    const rows = await getRows(data)
    return exportExcel(columns, rows);
  };

  const onExportToCSV = async (withFilters: boolean) => {
    const columns = gridRef.current.visibleColumns;
    const queryResult = await getLazyBunkering({ variables: { where: withFilters ? filterValue : null, order: sortValue } });
    const data = queryResult?.data?.bunkering?.items || [];
    const rows = await getRows(data)
    return exportCSV(columns, rows);
  };

  const handleDataExport = (type: string) => {
    if (areFiltersActive) {
      setExportDialog({
        visible: true,
        type,
        title: type === 'CSV' ? 'CSV' : 'Excel',
      });

      return;
    }
    exportData(type, false);
  };

  const columns = [
    {
      id: 'icons',
      header: 'Icons',
      defaultWidth: 80,
      render: ({ data }: any) => (
        <GridRowIcon 
          hasAttachments={data.documentsCount > 0} 
          hasPhotos={data.photosCount > 0} 
          hasSpares={data.sparesUsedCount > 0} 
          hasComments={data.commentsCount > 0} 
          isCritical={data.sms} 
        />
      ),
      onRender: (cellProps: any) => {
        cellProps.style.borderLeft = '#e4e3e2 3px solid';
      },
      sortable: false
    },
    {
      name: 'date',
      header: 'Date',
      defaultFlex: isDesktop ? 1 : undefined,
      headerAlign: 'start' as any,
      textAlign: 'end' as any,
      dateFormat: 'DD-MMM-YYYY',
      filterEditor: DateFilter,
      filterEditorProps: (_props: any, { index }: any) => {
        return { placeholder: index == 1 ? 'End...' : 'Start...' };
      },
      render: ({ value, cellProps: { dateFormat } }: any) => {
        if (!isNil(value)) {
          const UTCLogDate = utcToTimeZone(new Date(value));
          const formatedDate = moment(UTCLogDate).format(dateFormat);
          return formatedDate;
        }
      },
    },
    {
      name: 'fluid',
      header: 'Fluid Type',
      defaultFlex: isDesktop ? 1 : undefined,
    },
    {
      name: 'from',
      header: 'Transfer From',
      defaultFlex: isDesktop ? 1 : undefined,
    },
    {
      name: 'to',
      header: 'Transfer To',
      defaultFlex: isDesktop ? 1 : undefined,
    },
    {
      name: 'station',
      header: 'Port / Location',
      defaultFlex: isDesktop ? 1 : undefined,
    },
    {
      name: 'agent',
      header: 'Agent / Supplier',
      defaultFlex: isDesktop ? 1 : undefined,
    },
    {
      name: 'start',
      header: 'Start Time',
      defaultFlex: isDesktop ? 1 : undefined,
      defaultVisible: false,
      render: ({value}: any ) => normalizeTime(value),
    },
    {
      name: 'stop',
      header: 'Stop Time',
      defaultFlex: isDesktop ? 1 : undefined,
      defaultVisible: false,
      render: ({value}: any ) => normalizeTime(value),
    },
    {
      name: 'amount',
      header: 'Amount',
      defaultFlex: isDesktop ? 1 : undefined,
      defaultVisible: false,
    },
    {
      name: 'qty',
      header: 'Unit Of Measure',
      defaultFlex: isDesktop ? 1 : undefined,
      defaultVisible: false,
    },
    {
      name: 'cost',
      header: 'Cost',
      defaultFlex: isDesktop ? 1 : undefined,
      defaultVisible: false,
    },
    {
      name: 'curr',
      header: 'Currency',
      defaultFlex: isDesktop ? 1 : undefined,
      defaultVisible: false,
    },
    {
      name: 'oilRecord',
      header: 'Oil Record Code',
      defaultFlex: isDesktop ? 1 : undefined,
      defaultVisible: false,
    },
    {
      name: 'responsible',
      header: 'Person In Charge',
      defaultFlex: isDesktop ? 1 : undefined,
      defaultVisible: false,
    },
    {
      name: 'method',
      header: 'Pump Used',
      defaultFlex: isDesktop ? 1 : undefined,
    },
  ];

  return (
    <div data-testid="data-grid" className="flex flex-col flex-grow">
      <div className="flex flex-row items-center justify-end">
        {!isEmpty(gridRef?.current.computedGroupBy) &&
          (groupCollapsedStatus ? (
            <div>
              <Tooltip title="Collapse All">
                <IconButton onClick={CollapseButton}>
                  <UnfoldLessIcon />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <div>
              <Tooltip title="Expand All">
                <IconButton onClick={CollapseButton}>
                  <UnfoldMoreIcon />
                </IconButton>
              </Tooltip>
            </div>
          ))}
  
        {/* {groupByFlag ? */}
        {/* <Tooltip title="Remove Group by Year/month">
          <IconButton onClick={() => setGroupBy([])}>
            <GroupRemoveIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Group by Year/month of log Date">
          <IconButton onClick={() => onGroupByChange(["logDate"])}>
            <GroupAddIcon />
          </IconButton>
        </Tooltip> */}
      </div>

      <LicensedReactDataGrid
        onReady={onReady}
        loading={loading}
        columns={columns}
        pagination='remote'
        idProperty='id'
        dataSource={dataSource}
        enableSelection
        checkboxColumn
        skip={skip}
        onSkipChange={setSkip}
        limit={limit}
        onLimitChange={setLimit}
        onRowClick={onRowClick}
        selected={rowSelected}
        onSelectionChange={onSelectionChange}
        onSortInfoChange={onSortInfoChange}
        defaultFilterValue={filter}
        onFilterValueChange={onFilterValueChange}
        defaultGroupBy={[]}
        onGroupByChange={onGroupByChange}
        collapsedGroups={collapsedGroups}
        onGroupCollapseChange={setCollapsedGroups}
        renderGroupTitle={renderGroupTitle}
        sourceRoot={true}
      />   

      <Dialog maxWidth="xs" open={exportDialog?.visible || false}>
        <DialogTitle>
          {`Export data to ${exportDialog?.title}`}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            You have filters applied. Would you like to export with current
            filters?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            autoFocus
            onClick={() => exportDialog && exportData(exportDialog?.type, false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => exportDialog && exportData(exportDialog?.type, true)}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default BunkeringGrid;