import './styles.css';
import { useEffect, useState, useRef, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { Alert, Snackbar, AlertColor } from '@mui/material';
import PageDrawer from '../../components/PageDrawer';
import PageHeader, { Action } from '../../components/UI/PageHeader';
import { LIBRARY, GRID_LIMIT, SEVERITY, UpdateRecordInputType, UpdateRecordsOptionsType, GridType, DocumentationDefaultFilterValue } from 'src/consts';
import { AccessType, getAccessType } from 'src/utils/permissions';
import { saveViewMode, getViewMode, isEmptyObject } from '../../utils'
import { useAppState } from 'src/contexts/app-state';
import DocumentationGrid from './components/DocumentationGrid';
import { BulkModifyDocumentationInput, DeleteResult, Documentation, DocumentationFilterInput, DocumentationLibType, DocumentationSortInput, RecordType, SortEnumType } from 'src/generated/dotnet.graphql';
import MasterSearchInput, { MasterSearchInputValue } from 'src/components/MasterSearchInput';
import useMergedFilters from 'src/hooks/common/useMergedFilters';
import DocumentationDetailForm from 'src/components/PageDrawer/Documentation/DocumentationDetailForm';
import ViewSelector from 'src/components/ViewSelector';
import DeleteRecordBtn from 'src/components/DeleteRecordsButton';
import { useDeleteDocumentations } from 'src/hooks/documentationLibraries/useDeleteDocumentations';
import UpdateRecordsButton from 'src/components/UpdateRecordsButton';
import { useBulkUpdateDocumentations } from 'src/hooks/documentationLibraries/useBulkUpdateDocumentations';
import CategoryViewGrid from './components/CategoryViewGrid';

export const RECORDS_UPDATE_OPTIONS: UpdateRecordsOptionsType = {
  CATEGORY: { fieldName: 'categoryId', columnName: 'Category' },
  LIBRARY_TYPE: { fieldName: 'libType', columnName: 'Library Type' },
  SHOW_IN_CENTRAL: { fieldName: 'showInCentral', columnName: 'Included in Central Document Library' },
  IS_REPORT: { fieldName: 'isReport', columnName: 'Is a Form' },
  REQUIRES_VERIFICATION: { fieldName: 'requiresVerification', columnName: 'Requires Verification' },
}

export const defaultSortValue: DocumentationSortInput[] = [{ docTitle: SortEnumType.Asc }];

const LibrariesPage = () => {
    // Global state
  const { user } = useAuth();
  const { settingsPersonal, syncRxdb } = useAppState();
  const history = useHistory();
  // State to track filter values by library type
  const { type } = useParams<{ type: string }>();
  const libraryInfo = Object.values(LIBRARY).find((v) => v.PATH === type);
  const [filterValuesByLibrary, setFilterValuesByLibrary] = useState<Record<string, DocumentationFilterInput>>({});
  const defaultFilterValue: DocumentationDefaultFilterValue = { 
    libType: libraryInfo?.TYPE as DocumentationLibType,
    showInCentral: true,
    searchText: undefined,
    requiresVerification: undefined,
    isReport: undefined,
  };
  // Grid pagination state - keep it on parent component so it can be used for mutations refetching
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(GRID_LIMIT);
  const [masterSearchInput, setMasterSearchInput] = useState<MasterSearchInputValue | null>(null);
  const [filterValue, setFilterValue] = useMergedFilters<DocumentationFilterInput>(masterSearchInput, defaultFilterValue, GridType.DEFAULT);
  const [sortValue, setSortValue] = useState<DocumentationSortInput[]>(defaultSortValue);
  const [rowSelected, setRowSelected] = useState<Record<string, Documentation>>({});
  // Apollo client mutations
  const { bulkRemoveDocumentations } = useDeleteDocumentations();
  const { bulkUpdateDocumentations, bulkUpdateDocumentationsLoading } = useBulkUpdateDocumentations();
  // Component related state
  const gridRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [viewType, setViewType] = useState<GridType>(GridType.DEFAULT);  
  const [savedViewList, setSavedViewList] = useState<any>([]);
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const oldSelectedItem = useRef<Documentation | null>(null);
  const [selectedDocument, setSelectedDocument] = useState<Documentation>();
  const [refetchTree, setRefetchTree] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();
  const [recordType, setRecordType] = useState<RecordType>(RecordType.Undefined);
  const [pageTitle, setPageTitle] = useState<string>(LIBRARY.UNDEFINED.TITLE);
  const refetchQueryVariables = { skip: skip, take: limit, filterInput: filterValue, order: sortValue };
  
  const initializeViewMode = async () => {
    const mode = await getViewMode();
    if (mode) setViewType(mode);
  };
  
  useEffect(() => {
    initializeViewMode();
  }, []);

  const initializeDocumentationType = () => {
    switch (type) {
      case 'vesselReference':
        setRecordType(RecordType.DocumentationVesselReference);
        setPageTitle(LIBRARY.VESSEL_REFERENCE.TITLE);
        break;
      case 'drawings':
        setRecordType(RecordType.DocumentationDrawings);
        setPageTitle(LIBRARY.DRAWINGS.TITLE);
        break;
      case 'manuals':
        setRecordType(RecordType.DocumentationManuals);
        setPageTitle(LIBRARY.MANUALS.TITLE);
        break;
      case 'msds':
        setRecordType(RecordType.DocumentationMsds);
        setPageTitle(LIBRARY.MSDS.TITLE);
        break;
      case 'photos':
        setRecordType(RecordType.DocumentationPhotos);
        setPageTitle(LIBRARY.PHOTOS.TITLE);
        break;
      case 'sms':
        setRecordType(RecordType.DocumentationSms);
        setPageTitle(LIBRARY.SMS.TITLE);
        break;
      case 'isps':
        setRecordType(RecordType.DocumentationIsps);
        setPageTitle(LIBRARY.ISPS.TITLE);
        break;
      default:
        setRecordType(RecordType.Undefined);
        setPageTitle(LIBRARY.UNDEFINED.TITLE);
        break;
    }
  };

  useEffect(() => {
    initializeDocumentationType();
    setSelectedDocument(undefined);
    setRowSelected({}) // Cear grid selection on library type change
  }, [type]);
  
  const moduleReadOnly = useMemo(() => {
    switch (type) {
      case 'msds':
      case 'drawings':
      case 'photos':
      case 'manuals':
      case 'vesselReference':
        return getAccessType(settingsPersonal?.fldCDM) !== AccessType.FULL_ACCESS;
      case 'sms':
        return !settingsPersonal?.fldSMSAdmin;
      case 'isps':
        return !settingsPersonal?.fldISPSAdmin;
      default:
        return false;
    }
  }, [type])

  // Store and retrieve filter values by library type
  const storeFilterValue = (libType: string, filterValue: DocumentationFilterInput) => {
    setFilterValuesByLibrary((prev) => ({
      ...prev,
      [libType]: filterValue,
    }));
  };

  const restoreFilterValue = (libType: string): DocumentationFilterInput | undefined => {
    return filterValuesByLibrary[libType];
  };

  useEffect(() => {
    const previousFilterValue = restoreFilterValue(libraryInfo?.TYPE as string);
    // If there's a previously stored filter value, use that, otherwise use the default
    setFilterValue(previousFilterValue ?? defaultFilterValue);
  }, [libraryInfo?.TYPE]);

  // When the filter value changes, store it by library type
  useEffect(() => {
    if (filterValue) {
      storeFilterValue(libraryInfo?.TYPE as string, filterValue);
    }
  }, [filterValue, libraryInfo?.TYPE]);

  const handleCreateClick = async () => {
    setIsCreate(true);
    const document: any = {
      showInCentral: true,
      categoryId: null, // test if this is causing rendering of the category dropdown
    };
    setSelectedDocument(document);
  };
  
  const handleSave = async (responseData: Documentation, responseMessage: string, isCreated: boolean) => {
    setSelectedDocument(responseData);
    oldSelectedItem.current = responseData;

    if (isCreated) {
      setIsCreate(false);
    }
    if (viewType !== GridType.DEFAULT) {
      setRefetchTree(!refetchTree);
      renderContent();
    }
    
    // TODO: Remove rxdb sync after refactoring Forms module
    // sync the rxdb tables after creating a form documentation
    syncRxdb();

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const handleDelete = async (responseData: DeleteResult, responseMessage: string) => {
    if (responseData.success) {
      setSelectedDocument(undefined);
    }

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });

    if (viewType !== GridType.DEFAULT) {
      setRefetchTree(!refetchTree);
      renderContent();
    }
  };

  const handleDeleteMultipleRecords = async (validRecords: string[], invalidMessage: string) => {
    if (invalidMessage) {
      setSnackbar({
        message: invalidMessage,
        severity: 'error',
      });
    }

    if (validRecords.length > 0) {
      const { responseData, responseMessage } = await bulkRemoveDocumentations(validRecords, refetchQueryVariables);
      setRowSelected({});

      setSnackbar({
        message: responseMessage,
        severity: responseData?.success ? SEVERITY.SUCCESS : SEVERITY.ERROR,
      });

      if (viewType !== GridType.DEFAULT) {
        setRefetchTree(!refetchTree);
        renderContent();
      }
    }
  };

  const handleUpdateMultipleRecords = async (payload: UpdateRecordInputType) => {

    const selectedRecordsIds: string[] = Object.keys(rowSelected || []);

    if (selectedRecordsIds.length > 0) {

      const input: BulkModifyDocumentationInput = {
        ids: selectedRecordsIds,
        [payload.fieldName]: payload.value
      }
      const { responseData, responseMessage } = await bulkUpdateDocumentations(input, payload.columnName, refetchQueryVariables);
      setRowSelected({});

      setSnackbar({
        message: responseMessage,
        severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
      });

      if (viewType !== GridType.DEFAULT) {
        setRefetchTree(!refetchTree);
        renderContent();
      }
    }
  };

  const handleCancel = () => {
    setIsCreate(false);
    setSelectedDocument(undefined);
    oldSelectedItem.current = null;
  };

  const handleShowInCentral = async (responseData: Documentation, responseMessage: string) => {
    if (responseData) {
      setSelectedDocument(undefined);
    }

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });

    if (viewType !== GridType.DEFAULT) {
      setRefetchTree(!refetchTree);
      renderContent();
    }
  };

  const onSelect = (document: Documentation) => {
    setSelectedDocument(document);
    oldSelectedItem.current = document;
  };

  const onSelectChange = async (selectedDocument: Documentation) => {
    setSelectedDocument(selectedDocument);
  };

  const isTableOriginal = useMemo(() => {
    if (viewType === GridType.CATEGORY || viewType === GridType.DEFAULT) {
      return true;
    } else {
      return false;
    }
  }, [viewType])

  const renderContent = () => {
    let gridType = viewType;
    if (!isTableOriginal) {
      const savedViewItem = savedViewList.find((item: any) => item.id === gridType);
      if (savedViewItem) {
        gridType = JSON.parse(savedViewItem.layout).mode;
      }
    }
    switch (viewType) {
      case GridType.CATEGORY:
        return (
          <div
            style={{ maxHeight: containerRef.current?.clientHeight || 0 }}
            className="flex-grow h-full w-full flex overflow-y-auto"
          >
            <CategoryViewGrid
              masterSearchInput={masterSearchInput} 
              onChange={onSelectChange} 
              refetchTree={refetchTree} 
              defaultFilterValue={defaultFilterValue}
            />
          </div>
        );
      default:
        return (
          <div className="flex flex-grow h-full">
            <DocumentationGrid
              darken 
              onSelect={onSelect}
              ref={gridRef} 
              skip={skip}
              setSkip={setSkip}
              limit={limit}
              setLimit={setLimit}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              sortValue={sortValue}
              setSortValue={setSortValue} 
              rowSelected={rowSelected}
              setRowSelected={setRowSelected}
              libraryType={libraryInfo?.TYPE}
            />
          </div>
        );
    }
  };

  const handleDataExport = (type: string) => {
    gridRef?.current.handleExport(type)
  }

  const renderView = () => (
    <ViewSelector
      defaultOptions={[GridType.DEFAULT, GridType.CATEGORY]}
      contentType={viewType}
      setContentType={setViewType}
      saveViewMode={saveViewMode}
      onSavedViewList={setSavedViewList}
      onDataExport={handleDataExport}
    />
  );

  const onDocumentationSearch = (isReset: boolean, searchValue: MasterSearchInputValue) => {
    if(isReset) {
      setMasterSearchInput(null);
      setFilterValue(defaultFilterValue);
      setSkip(0);
    }
    else {
      setMasterSearchInput(searchValue);
      setSkip(0);
    }
  };

  const renderMasterSearch = () => (
    <MasterSearchInput
      key={recordType}
      recordType={recordType}
      label={`${pageTitle} search`}
      onChange={onDocumentationSearch}
    />
  )

  if (!libraryInfo) {
    history.push('/');
    return null;
  }

  const getFilteredUpdateOptions = (libraryType: string | null): UpdateRecordsOptionsType => {
    // Create a shallow copy of the options
    const filteredOptions = { ...RECORDS_UPDATE_OPTIONS };
  
    // Conditionally remove options based on libraryType
    if (libraryType === LIBRARY.SMS.TYPE || libraryType === LIBRARY.ISPS.TYPE) {
      delete filteredOptions['LIBRARY_TYPE'];
      delete filteredOptions['SHOW_IN_CENTRAL'];
    }
  
    if (libraryType === LIBRARY.PHOTOS.TYPE) {
      delete filteredOptions['LIBRARY_TYPE'];
      delete filteredOptions['IS_REPORT'];
      delete filteredOptions['REQUIRES_VERIFICATION'];
    }
  
    if (
      libraryType === LIBRARY.VESSEL_REFERENCE.TYPE ||
      libraryType === LIBRARY.DRAWINGS.TYPE ||
      libraryType === LIBRARY.MANUALS.TYPE ||
      libraryType === LIBRARY.MSDS.TYPE
    ) {
      delete filteredOptions['IS_REPORT'];
      delete filteredOptions['REQUIRES_VERIFICATION'];
    }
  
    return filteredOptions;
  };
  
  const actions: Action[] = [
    {
      icon: 'add',
      title: 'New entry',
      onClick: handleCreateClick,
      disabled: moduleReadOnly
    },
  ]

  if(viewType === GridType.DEFAULT) {
    actions.unshift(
      {
        customComponent: (
          <UpdateRecordsButton
            onSubmit={handleUpdateMultipleRecords}
            visible={moduleReadOnly || isEmptyObject(rowSelected)}
            recordType={recordType}
            recordsNumber={rowSelected && Object.keys(rowSelected).length}
            options={getFilteredUpdateOptions(libraryInfo.TYPE)}
            saveLoading={bulkUpdateDocumentationsLoading}
          />
        ),
      },
      {
        customComponent: (
          <DeleteRecordBtn
            visible={moduleReadOnly || isEmptyObject(rowSelected)}
            records={rowSelected}
            onValidate={handleDeleteMultipleRecords}
          />
        ),
      }
    )
  }

  return (
    <div className="flex flex-grow flex-col overflow-auto">
      <PageHeader
        title={pageTitle}
        actions={actions}
        renderView={renderView}
        renderMasterSearch={renderMasterSearch}
      >
        <div className="flex md:hidden">{renderView()}</div>
      </PageHeader>

      <div ref={containerRef} className="flex flex-grow flex-col w-full pt-4">
        {renderContent()}
      </div>

      <PageDrawer 
        onClose={handleCancel} 
        visible={!!selectedDocument} 
      >
        {!!selectedDocument && (
          <DocumentationDetailForm
            initialValue={selectedDocument}
            recordType={recordType}
            isCreate={isCreate}
            onSave={handleSave}
            onShowInCentral={handleShowInCentral}
            onDelete={handleDelete}
            onCancel={handleCancel}
            refetchQueryVariables={refetchQueryVariables}
            libraryType={libraryInfo?.TYPE  as DocumentationLibType}
            moduleReadOnly={moduleReadOnly}
          />
        )}
      </PageDrawer>

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </div>
  );
};

export default LibrariesPage;
