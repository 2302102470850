import { gql, useQuery } from '@apollo/client';
import { logger } from 'src/helpers/logger';

export const GET_EQUIPMENT_MANUFACTURERS = gql`
  query GetEquipmentManufacturers {
    equipmentManufacturers {
      name
    }
  }
`;

interface EquipmentManufacturersResult {
  data: String[];
  loading: boolean;
}

export const useGetEquipmentManufacturers = (): EquipmentManufacturersResult => {
  const { data, loading, error } = useQuery(GET_EQUIPMENT_MANUFACTURERS, {
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetEquipmentManufacturers').error('Error fetching equipment manufacturers -->', error.message);
  }

  return {
    data: data?.equipmentManufacturers || [],
    loading,
  };
};
