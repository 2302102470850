// React Components
import { forwardRef, useImperativeHandle, useEffect } from 'react';
import { DocumentEditorContainerComponent, Toolbar, Editor, SfdtExport, WordExport } from '@syncfusion/ej2-react-documenteditor';
import { registerLicense } from '@syncfusion/ej2-base';
// Data
import { Form, FormRevision } from 'src/generated/dotnet.graphql';
// Utils
import { DOWNLOAD_URL, SYNCFUSION_URL, SYNCFUSION_LICENSE } from 'src/consts';
import { sanitizeFilename } from 'src/pages/FormsPage/util';

registerLicense(SYNCFUSION_LICENSE);

interface FileViewerDocxProps {
    url: string;
    form: Form;
    formRevision?: FormRevision;
}

export type DocxFileType = {
    filename: string;
    isFormRevisionCreated: boolean;
};

const FileViewerDocx = forwardRef(({ url, form, formRevision }: FileViewerDocxProps, ref) => {
    let editor: DocumentEditorContainerComponent | null;
    DocumentEditorContainerComponent.Inject(Toolbar)
    DocumentEditorContainerComponent.Inject(Editor)
    DocumentEditorContainerComponent.Inject(WordExport, SfdtExport)

    useImperativeHandle(ref, () => ({
        save: async () => {
            const timestamp = new Date().toISOString().replace(/[-:.TZ]/g, '');
            const filename = `RPT_${sanitizeFilename(form?.reportType)}-${timestamp}.docx`;

            // Save the file on server attachments folder using syncfusion Save method
            onServerSave(filename);

            // return the filename of the new uploaded file for tblAnyReport and tblAnyReportHistory reference
            const result: DocxFileType = { 
                filename: filename, 
                isFormRevisionCreated: true, 
            };
        
            return result
          },
    }));
    
    const getRemoteDocument = async (): Promise<File> => {
        // if reportHistory is not null that means we need to open a AnyReportHistory file else it's an AnyReport file.
        const filePath = formRevision ? formRevision!.reportFile : form!.reportFile;
        const docResponse = await fetch(`${DOWNLOAD_URL}/${filePath}`);
        
        if (!docResponse.ok) {
          throw new Error(`Failed to fetch document: ${docResponse.statusText}`);
        }
    
        const arrayBuffer = await docResponse.arrayBuffer();
        const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        return new File([blob], filePath, { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    };

    const onLoadDefault = async () => {
        const file = await getRemoteDocument();
        loadFile(file)
    };

    function loadFile(file: any) {
        let ajax: XMLHttpRequest = new XMLHttpRequest();
        ajax.open('POST', `${SYNCFUSION_URL}/api/documenteditor/Import`, true);
        ajax.onreadystatechange = () => {
            if (ajax.readyState === 4) {
                if (ajax.status === 200 || ajax.status === 304) {
                    // open SFDT text in document editor
                    editor?.documentEditor.open(ajax.responseText);
                    if (editor && editor.documentEditorSettings && editor.documentEditorSettings.formFieldSettings) {
                        editor.documentEditorSettings.formFieldSettings.formFillingMode = 'Inline';
                    }
                }
            }

        };
        let formData: FormData = new FormData();
        formData.append('files', file);
        ajax.send(formData);
    }

    const onServerSave = (fileName: string) => {
        let http = new XMLHttpRequest();
        http.open('POST', `${SYNCFUSION_URL}/api/documenteditor/Save`);
        http.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        http.responseType = 'json';
        let sfdt = {
            Content: editor?.documentEditor.serialize(),
            fileName: fileName,
        };
        http.send(JSON.stringify(sfdt));
      };

    const onBrowserSave = () => {
        editor?.documentEditor.saveAsBlob('Docx').then((exportedDocument) => {
            let formData = new FormData();
            formData.append('filename', "sample.rtf");
            formData.append('data', exportedDocument);
            saveAsSFDT(formData);
        });
    }

    const saveAsSFDT = (formData: FormData) => {
        //Send the blob object to server to process further.
        let httpRequest = new XMLHttpRequest();
        httpRequest.open('POST', `${SYNCFUSION_URL}/api/documenteditor/Export`, true);
        httpRequest.onreadystatechange = () => {
            if (httpRequest.readyState === 4) {
                if (httpRequest.status === 200 || httpRequest.status === 304) {
                    let a = httpRequest.response
                    const _navigator = window.navigator as any;
                    if (!(!_navigator.msSaveBlob)) {
                        _navigator.msSaveBlob(httpRequest.response, 'sample.rtf');
                    } else {
                        let downloadLink = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
                        download('sample.rtf', 'rtf', httpRequest.response, downloadLink, 'download' in downloadLink);
                    }
                } else {
                    console.error(httpRequest.response);
                }
            }
        }
        httpRequest.send(formData);
    }

    const download = (filename: string, type: string, data: any, downloadLink: any, isDownloadLinkSupported: any) => {
        if (isDownloadLinkSupported) {
            downloadLink.href = URL.createObjectURL(new Blob([data], { type: type }));
            downloadLink.download = filename;
            downloadLink.click();
            URL.revokeObjectURL(downloadLink.href);
        } else {
            // For browsers that do not support the download attribute
            const blob = new Blob([data], { type: type });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }
    };

    useEffect(() => {
        onLoadDefault();
    }, [url]);

    return (
        <div className="w-full h-full">
            <DocumentEditorContainerComponent
                id="container"
                ref={scope => {editor = scope}}
                height={'860px'}
            />
        </div>
    );
})

export default FileViewerDocx;

