import { FC } from 'react';
import { LocalizationProvider, DesktopTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { SxProps, TextField, Theme } from '@mui/material';
import { Control, RegisterOptions, useController } from 'react-hook-form';

interface InjectedProps {
  control: Control;
  name: string;
  defaultValue?: any;
  rules?: RegisterOptions;
  label?: string;
  inputFormat?: string;
  style?: SxProps<Theme>;
  className?: string;
  size?: any;
}

const TimePicker: FC<InjectedProps> = ({
  name,
  control,
  defaultValue,
  rules,
  label,
  inputFormat = "HH:mm",
  style,
  className,
  size
}) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: defaultValue ? new Date(`1970-01-01T${defaultValue}:00`) : null, // Ensure valid time format
    rules,
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopTimePicker
        {...inputProps}
        label={label}
        inputFormat={inputFormat}
        renderInput={(params) => 
          <TextField 
            {...params} 
            autoComplete='off' 
            size={size}
            error={!!error}
            helperText={error ? error.message : null}
            className={className}
            sx={{ ...style, '& .MuiInputBase-root': { height: '100%' } }} 
          />
        }
      />
    </LocalizationProvider>
  );
};

export default TimePicker;