import { FC, useCallback, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';
import WarningDialog from 'src/components/UI/WarningDialog';
import { useAppState } from 'src/contexts/app-state';
import { withSnackbar } from "src/components/UI/SnackbarHOC";
import { useAuth } from 'src/contexts/auth';

interface DeleteRecordBtnProps {
  visible: boolean;
  records: any;
  onValidate: (validRecords: string[], invalidMessage: string) => void;
  warningMessage?: string;
  loading?: boolean;}

const DeleteRecordBtn: FC<DeleteRecordBtnProps> = ({
  visible,
  records,
  onValidate,
  warningMessage = 'Are you sure you wish to delete record(s)?',
  loading,
}) => {
  const  { user } = useAuth();
  const [isDeleting, setIsDeleting] = useState(false);
  const { settingsPersonal } = useAppState();
  
  const handleDelete = async () => {
    setIsDeleting(true);
  }
   
  const handleDeleteOk = useCallback(() => {
    var allRecordNotDeleted = false;
    const keysForDeletion: string[] = [];
    let invalidMessage = '';

    const recordEntries: [string, any][] = Object.entries(records || {});
    for (const [key, record] of recordEntries) {
      if (record?.fldRestricted && !settingsPersonal.fldDeptHead) {
        allRecordNotDeleted = true;
      } else if (settingsPersonal.fldAllDepts === 1 && record?.department !== user?.Department) {
        allRecordNotDeleted = true;
      } else {
        keysForDeletion.push(key);
      }
    }
  
    if (allRecordNotDeleted) {
      invalidMessage = 'You do not have permission to delete some records. Some records were not deleted.';
    }
  
    onValidate(keysForDeletion, invalidMessage);
    setIsDeleting(false);
  }, [records, settingsPersonal, user, onValidate]);

  const handleDeleteCancel = () => {
    setIsDeleting(false);
  }

  const deleteButtonStyle = {
    height: 36, // Ensure this matches other buttons
    margin: '0 4px', // Adjust margins to keep spacing consistent
  };

  return (
    <>
      <Tooltip title="Delete Records">
        <span>
          <IconButton
            onClick={handleDelete}
            aria-label="Delete Records"
            style={deleteButtonStyle}
            disabled={visible}
            color="error"
          >
            <DeleteTwoTone />
          </IconButton>
        </span>
      </Tooltip>
      
      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content={warningMessage}
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
        loading={loading}
      />
    </>
  )
}

export default withSnackbar(DeleteRecordBtn)