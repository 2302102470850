import { FC, useEffect, useRef, useState } from "react";
import { Box, Button, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { DeleteTwoTone } from "@mui/icons-material";
import { CustomDialog } from "src/helpers/modals";
import { DeleteResult, Inventory, InventoryType, RecordType } from "src/generated/dotnet.graphql";
import { FormType, INVENTORY } from "src/consts";
import InventoryDetailForm from "../../InventoryDetailForm";

interface BarcodeExactMatchInventoryDialogProps {
  visible: boolean;
  inventory: Inventory;
  onSave: (responseData: Inventory, responseMessage: string, isCreated: boolean) => void;
  onDelete?: (responseData: DeleteResult, responseMessage: string) => void;
  onCancel: () => void;
  moduleReadOnly: boolean;
  type?: FormType;
  selectedItemOrderID?: string;
}

const BarcodeExactMatchInventoryDialog: FC<BarcodeExactMatchInventoryDialogProps> = ({
  visible,
  inventory,
  onSave,
  onDelete,
  onCancel,
  moduleReadOnly = false,
  type,
}) => {
  const inventoryDetailFormRef = useRef<any>(null);
  const [recordType, setRecordType] = useState<RecordType>(RecordType.Undefined);
  const [title, setTitle] = useState<string>('');
  
  const initializeInventoryType = () => {
    if (!inventory.inventoryType) return;
  
    switch (inventory.inventoryType) {
      case InventoryType.GeneralInventory:
        setRecordType(RecordType.InventoryGeneral);
        setTitle(INVENTORY.GENERAL.TITLE);
        break;
      case InventoryType.Beverages:
        setRecordType(RecordType.InventoryBeverages);
        setTitle(INVENTORY.BEVERAGES.TITLE);
        break;
      case InventoryType.Uniform:
        setRecordType(RecordType.InventoryUniform);
        setTitle(INVENTORY.UNIFORM.TITLE);
        break;
      case InventoryType.DryAndColdStore:
        setRecordType(RecordType.InventoryDryAndColdStore);
        setTitle(INVENTORY.DRY_STORES.TITLE);
        break;
      case InventoryType.Medical:
        setRecordType(RecordType.InventoryMedical);
        setTitle(INVENTORY.MEDICAL.TITLE);
        break;
      default:
        setRecordType(RecordType.Undefined);
        setTitle('');
        break;
    }
  };
  
  useEffect(() => {
    initializeInventoryType();
  }, [inventory.inventoryType]);

  const onSaveClick = () => {
    if (inventoryDetailFormRef.current) {
      inventoryDetailFormRef.current.onSaveClick();
    }
  };

  const onDeleteClick = () => {
    if (inventoryDetailFormRef.current) {
      inventoryDetailFormRef.current.onDeleteClick();
    }
  };

  const handleSaveResponse = (responseData: Inventory, responseMessage: string, isCreated: boolean) => {
    onSave(responseData, responseMessage, isCreated);
  }

  return (
    <CustomDialog
      scroll="paper"
      fullWidth
      maxWidth="md"
      open={visible}
    >
      <DialogTitle>
        <span className="font-bold text-2xl">{`${title}`} </span>
      </DialogTitle>

      <DialogContent dividers sx={{ p: 0 }}>
        {!!inventory && (
          <InventoryDetailForm
            initialValue={inventory}
            isCreate={false}
            onCancel={onCancel}
            onSave={handleSaveResponse}
            onDelete={onDelete}
            moduleReadOnly={moduleReadOnly}
            ref={inventoryDetailFormRef}
            recordType={recordType} 
            inventoryType={inventory.inventoryType ?? InventoryType.GeneralInventory}          
            type={type} 
            />
        )}
      </DialogContent>

      <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
        {onDelete &&
          <Box sx={{ justifyContent: 'flex-start', flexGrow: 1, display: 'flex' }}>
            <IconButton
              onClick={onDeleteClick}
              color="error"
              aria-label="Delete item"
              disabled={moduleReadOnly}
            >
              <DeleteTwoTone />
            </IconButton>
          </Box>
        }
        <Box sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}>
          <Button 
            className="mr-2" 
            onClick={onCancel}
          >
            Close
          </Button>
          
          <Button
            onClick={onSaveClick}
            variant="contained"
            disabled={moduleReadOnly}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </CustomDialog>
  );
};

export default BarcodeExactMatchInventoryDialog;
