import { getNodeList } from 'src/components/GenericTreeViewDropdown/utils';
import { getDatabase, TDIDb } from 'src/rxdb';
import { uuid } from 'uuidv4';

type ListData = {
  key: string;
  label: string;
  parentId: string;
  parent: any;
  children: any[];
};

export const saveWorkIssueCommentsToLogEntry = async (key:any,wokIssueJobNumber:any)=>{
  const db = await getDatabase();
  const findComment = await db.comments.find({
    selector:{
      referenceIssueId: wokIssueJobNumber,
      referenceIssueType: "WorkIssues",
      deletedAt: null
    }
  }).exec()
  findComment.map(async (e)=>{
     const res= await db.comments.upsert({
        referenceIssueId: key,
        referenceIssueType: "LogEntry",
        id: uuid(),
        content: e.content,
        updatedAt: new Date().toISOString(),
        createdAt: e.createdAt,
        authorId: e.authorId,
      });
  })
};

export const saveWorkIssueSparesToLogEntry= async (key:string,wokIssueJobNumber:any)=>{
  const db = await getDatabase();
  const findSpare = await db.tblsparesused.find({
    selector:{
      WorkKey: wokIssueJobNumber,
    }
  }).exec()
  findSpare.map(async (e)=>{
    e.atomicPatch({
      LogKey:key,
    })
 })
 return
};

export const getGenInvCondition = (item: any) => {
  if (!item.fldTags || item.fldTags.includes('WORKISSUES')) {
    return true;
  }
  return false;
};

export const handleFilter = (item: any) => {
  return getGenInvCondition(item);
};

export const getDisplayValue = (value: any, listMapData: any) => {
  if (Array.isArray(value)) {
    return value
      .map((item: any) => listMapData[item]?.label || item)
      .join(' >> ');
  } else {
    return listMapData[value]?.label || value;
  }
};

export const getFullPath = async (data:any) => {
  const db = await getDatabase()
  if (!data.fldSRHKey) return '';
  const withGivenDefault = await db.tblsystemshierarchy
    .find({ selector: { PKey: { $eq: data.fldSRHKey } } })
    .exec();
  const basedOnTagNull = await db.tblsystemshierarchy
    .find({ selector: { fldTags: { $eq: null } } })
    .exec();
  const basedOnTagLike = await db.tblsystemshierarchy
    .find({ selector: { fldTags: { $regex: new RegExp('work', 'i') } } })
    .exec();
  const basedOnDepartmentNull = await db.tblsystemshierarchy
    .find({ selector: { fldDepts: { $eq: null } } })
    .exec();

  const systems = [
    ...withGivenDefault,
    ...basedOnTagNull,
    ...basedOnTagLike,
    ...basedOnDepartmentNull,
  ];

  const pathData = systems.filter(handleFilter).map((elem: any) => ({
    PKey: elem.PKey,
    ParentID: elem.ParentID,
    fldCategory: elem.fldCategory,
    expanded: false,
  }));

  const sortedData = pathData?.sort((a: any, b: any) =>
    a.fldCategory.localeCompare(b.fldCategory)
  );

  const keyExpr = 'PKey';
  const displayExpr = 'fldCategory';
  const parentIdExpr = 'ParentID';
  const listData: ListData[] = sortedData.map((item: any) => ({
    key: item[keyExpr],
    label: item[displayExpr],
    parentId: item[parentIdExpr],
    parent: null,
    children: [],
  }));

  let map: any = {},
    node,
    roots: any = [],
    index,
    mapData: any = {};
  for (index = 0; index < listData.length; index += 1) {
    map[listData[index].key] = index;
    mapData[listData[index].key] = listData[index];
  }

  for (index = 0; index < listData.length; index += 1) {
    node = listData[index];
    if (node.parentId) {
      if (map[node.parentId]) {
        listData[map[node.parentId]].children.push(node);
        node.parent = listData[map[node.parentId]];
      }
    } else {
      roots.push(node);
    }
  }

  const nodeKeyList = getNodeList([mapData[data.fldSRHKey]]);
  const path = getDisplayValue(nodeKeyList, mapData);
  return path
};

export const getEquipment = async (data: any) => {
  const db = await getDatabase();
  if (!data.EqKey) return null;
  const result = await db.equipment
    .findOne({ selector: { EqKey: { $eq: data.EqKey } } })
    .exec();
  return result;
}

export const getFullLocationPath = async (data:any) => {
  const db = await getDatabase()
  if (!data.fldLocHierarchy) return '';
  const withGivenDefault = await db.tbllocationshierarchy
    .find({ selector: { PKey: { $eq: data.fldLocHierarchy } } })
    .exec();
  const basedOnTagNull = await db.tbllocationshierarchy
    .find({ selector: { fldTags: { $eq: null } } })
    .exec();
  const basedOnTagLike = await db.tbllocationshierarchy
    .find({ selector: { fldTags: { $regex: new RegExp('work', 'i') } } })
    .exec();
  const basedOnDepartmentNull = await db.tbllocationshierarchy
    .find({ selector: { fldDepts: { $eq: null } } })
    .exec();

  const systems = [
    ...withGivenDefault,
    ...basedOnTagNull,
    ...basedOnTagLike,
    ...basedOnDepartmentNull,
  ];

  const pathData = systems.filter(handleFilter).map((elem: any) => ({
    PKey: elem.PKey,
    ParentID: elem.ParentID,
    fldLocation: elem.fldLocation,
    expanded: false,
  }));

  const sortedData = pathData?.sort((a: any, b: any) =>
    a.fldLocation.localeCompare(b.fldLocation)
  );

  const keyExpr = 'PKey';
  const displayExpr = 'fldLocation';
  const parentIdExpr = 'ParentID';
  const listData: ListData[] = sortedData.map((item: any) => ({
    key: item[keyExpr],
    label: item[displayExpr],
    parentId: item[parentIdExpr],
    parent: null,
    children: [],
  }));

  let map: any = {},
    node,
    roots: any = [],
    index,
    mapData: any = {};
  for (index = 0; index < listData.length; index += 1) {
    map[listData[index].key] = index;
    mapData[listData[index].key] = listData[index];
  }

  for (index = 0; index < listData.length; index += 1) {
    node = listData[index];
    if (node.parentId) {
      if (map[node.parentId]) {
        listData[map[node.parentId]].children.push(node);
        node.parent = listData[map[node.parentId]];
      }
    } else {
      roots.push(node);
    }
  }

  const nodeKeyList = getNodeList([mapData[data.fldLocHierarchy]]);
  const path = getDisplayValue(nodeKeyList, mapData);
  return path
};