import { ApolloError, gql, useMutation } from '@apollo/client';
import { FormRevisionInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { FormRevisionMutationResponse } from '../interfaces';
import { GET_FORM_REVISIONS } from './useGetFormRevisions';

export const ADD_FORM_REVISION = gql`
  mutation AddFormRevision($input: FormRevisionInput!) {
    addFormRevision(input: $input) {
      formRevision {
        id
        formId
        dateRevision
        reportFile
      }
      errors {
        code
        message
      }
    }
  }
`;

interface AddFormRevisionResult {
  addFormRevision: (input: FormRevisionInput) => Promise<FormRevisionMutationResponse>;
  addFormRevisionLoading: boolean;
  addFormRevisionError?: ApolloError;
}

export const useAddFormRevision = (): AddFormRevisionResult => {
  const [createFormRevision, { loading, error }] = useMutation(ADD_FORM_REVISION, {
    onError: (error: ApolloError) => {
      logger('AddFormRevision').error("Error adding form revsion -->", error.message);
    }
  });

  const addFormRevision = async (input: FormRevisionInput): Promise<FormRevisionMutationResponse> => {
    const response = await createFormRevision({ 
      variables: { input },
      refetchQueries: [
        {
          query: GET_FORM_REVISIONS,
          variables: { formId: input.formId },
        }
      ],
    });

    const responseData = response.data?.addFormRevision?.formRevision;

    if (responseData) {
      logger('AddFormRevision').info(`Form revision added successfully`, response.data);
      return {
        responseData,
        responseMessage: `Form revision added successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to add form revision!`,
      };
    }
  };

  return { 
    addFormRevision, 
    addFormRevisionLoading: loading, 
    addFormRevisionError: error,
  };
};
