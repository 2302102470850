import "./style.css";
import moment from "moment";
import { useState, FC, useCallback, useEffect } from "react";
import { isEmpty, isNil } from "lodash";
import { Alert, Snackbar, useMediaQuery, AlertColor } from "@mui/material";
import { TypeFilterValue, TypeSingleSortInfo, TypeSortInfo } from "@inovua/reactdatagrid-enterprise/types";
import LicensedReactDataGrid from "src/components/UI/LicensedReactDataGrid";
import { COMPONENTS, GRID_LIMIT, SEVERITY } from "src/consts";
import { AccessType, getAccessType } from 'src/utils/permissions';
import { LogEntry, LogEntryFilterInput, LogEntrySortInput, SortEnumType } from "src/generated/dotnet.graphql";
import DateFilter from "@inovua/reactdatagrid-enterprise/DateFilter";
import { getPlainTextFromHtml } from "src/utils";
import { useAppState } from "src/contexts/app-state";
import LogEntryFormDialog from "../PageDrawer/LogEntry/LogEntryFormDialog";
import { useGetLogEntriesWithNoCounts } from "src/hooks/logEntries/useGetLogEntriesWithNoCounts";
import { utcToTimeZone } from "src/utils/format-dates";

const filter = [
  {
    name: 'logDate',
    operator: 'inrange',
    type: 'date',
    value: '',
  },
  {
    name: 'html',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const getSelectorByFilterName = async (
  name: string,
  value: any,
): Promise<Record<string, any>> => {
  switch (name) {
    case 'logDate': {
      if (!isEmpty(value.start) && (isNil(value.end) || isEmpty(value.end))) {
        const dateStart = moment(value?.start)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          logDateStart: dateStart,
        };
      }
      if (!isEmpty(value.end) && (isNil(value.start) || isEmpty(value.start))) {
        const dateEnd = moment(value?.end)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          logDateEnd: dateEnd,
        };
      }
      if (!isEmpty(value.start) && !isEmpty(value.end)) {
        const startDate = moment(value?.start)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        const endDate = moment(value?.end)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          logDateStart: startDate,
          logDateEnd: endDate,
        };
      }
      return {};
    }
    case 'html': {
      return {
        [name]: value,
      };
    }
    default:
      return {};
  }
};

interface Props {
  keyName: string;
  keyValue: string | null;
  countHours: boolean
  updateLogEntryCacheCount?: (isDelete: boolean) => void;
  recordReadOnly?: boolean;
  refetchLogEntries?: boolean;
}

const HistoryTab: FC<Props> = ({ 
  keyName, 
  keyValue, 
  updateLogEntryCacheCount,
  countHours,
  recordReadOnly = false,
  refetchLogEntries,
}) => {
  const { settingsPersonal } = useAppState();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(GRID_LIMIT);
  const defaultFilterValue = { [keyName]: keyValue };
  const [filterValue, setFilterValue] = useState<LogEntryFilterInput>(defaultFilterValue);
  const [sortValue, setSortValue] = useState<LogEntrySortInput[]>([{ logDate: SortEnumType.Desc }]);
  const { data, totalCount, loading, loadData } = useGetLogEntriesWithNoCounts(skip, limit, filterValue, sortValue);
  const [selectedLogEntry, setSelectedLogEntry] = useState<LogEntry>();
  const refetchQueryVariables = { skip: skip, take: limit, filterInput: filterValue, order: sortValue };
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();
  const logEntryModuleReadOnly = getAccessType(settingsPersonal.fldLogsMaint) !== AccessType.FULL_ACCESS;
  const isTablet = useMediaQuery('(min-width: 700px) and (max-width: 1200px)');
  const isMobile = useMediaQuery('(max-width: 420px)');

  useEffect(() => {
    loadData(skip, limit, filterValue, sortValue);
  }, [skip, limit, filterValue, sortValue, refetchLogEntries])
    
  const dataSource = async () => {
    return {
      data,
      count: totalCount,
    };
  };

  const onSortInfoChange = (value: TypeSortInfo) => {
    const sortInfo = value as TypeSingleSortInfo
    if (isNil(sortInfo)) return;

    let sortPayload: LogEntrySortInput[];
    const sortDirection = sortInfo.dir === 1 ? SortEnumType.Asc : SortEnumType.Desc;
    const [field, subField] = sortInfo.name.split('.');

    if (subField) {
        // Handle nested objects
        sortPayload = [{
            [field]: {
                [subField]: sortDirection,
            },
        }];
    } else {
        // Handle non-nested objects
        sortPayload = [{
            [sortInfo.name]: sortDirection,
        }];
    }
    setSortValue(sortPayload)
  }
    
  const onFilterValueChange = async (filterValue: TypeFilterValue) => {
    if (isNil(filterValue)) return;

    const selectors = await Promise.all(
      filterValue.map(async (v) => {
        if (isEmpty(v.value)) return null;
        const selector = await getSelectorByFilterName(v.name, v.value);
        return selector;
      })
    );
    
    // Filter out null values
    const validSelectors = selectors.filter((selector) => selector !== null);

    // Combine the valid selectors into a single `filterInput` object
    const filterPayload: any = validSelectors.reduce((acc, obj) => {
      return { ...acc, ...obj };
    }, {});

    const newFilterValue = {
      ...defaultFilterValue,
      ...filterPayload,
    }

    setFilterValue((prevFilterValue: any) => {
      if (JSON.stringify(prevFilterValue) === JSON.stringify(newFilterValue)) {
        return prevFilterValue;
      }
      return newFilterValue;
    });
  };

  const handleDelete = async (responseData: LogEntry, responseMessage: string) => {
    if (responseData) {
      setSelectedLogEntry(undefined);
      updateLogEntryCacheCount && updateLogEntryCacheCount(true);
    }
    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const handleCancel = () => {
    setSelectedLogEntry(undefined);
  };

  const handleSave = (responseData: LogEntry, responseMessage: string) => {
    setSelectedLogEntry(undefined);
    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const onRowClick = recordReadOnly ? undefined : useCallback(({ data }) => {
    setSelectedLogEntry(data);
  }, []);

  const columns = [
    {
      name: 'logDate',
      header: 'Date',
      defaultFlex: 1,
      headerAlign: 'start' as any,
      textAlign: 'end' as any,
      dateFormat: 'DD-MMM-YYYY',
      filterEditor: DateFilter,
      filterEditorProps: (_props: any, { index }: any) => {
        return { placeholder: index == 1 ? 'End...' : 'Start...' };
      },
      render: ({ value, cellProps: { dateFormat } }:any) => {
        if (!isNil(value)) {
          const UTCLogDate = utcToTimeZone(new Date(value));
          const formatedDate = moment(UTCLogDate).format(dateFormat);
          return formatedDate;
        }
      },
    },
    ...(countHours
      ? [
          {
            name: 'hours',
            header: 'Hours',
            headerAlign: 'start' as any,
            textAlign: 'end' as any,
            defaultFlex: 0.5,
            visible: !(isTablet || isMobile),
          },
        ]
      : []),
    {
      name: "html",
      header: "Log Entry",
      defaultFlex: 2,
      render: ({ data }: any) => {
        const plainTextDescription = getPlainTextFromHtml(data.html);
        return (<span>{plainTextDescription}</span>);
      },
    },
  ];

  return (
    <>
      <div data-testid="data-grid" className={`flex flex-col flex-grow ${keyName !== 'equipmentId' ? 'h-full' : ''} history`} >
        <LicensedReactDataGrid
          loading={loading}
          columns={columns}
          pagination='remote'
          idProperty='id'
          dataSource={dataSource}
          skip={skip}
          onSkipChange={setSkip}
          limit={limit}
          onLimitChange={setLimit}
          onSortInfoChange={onSortInfoChange}
          defaultFilterValue={filter}
          onFilterValueChange={onFilterValueChange}
          onRowClick={onRowClick}
        />
      </div>
      
      <LogEntryFormDialog
        selectedItem={selectedLogEntry}
        onSave={handleSave}
        onDelete={handleDelete}
        onCancel={handleCancel}
        moduleReadOnly={logEntryModuleReadOnly}
        refetchQueryVariables={refetchQueryVariables}
        callerComponent={COMPONENTS.HistoryTab}
      />

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </>
  );
};

export default HistoryTab;