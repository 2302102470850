import { gql, useQuery } from '@apollo/client';
import { Form } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const GET_RECENT_FORMS = gql`
  query GetRecentForms {
    recentForms {
      __typename
      id
      status
      createdBy
      completedBy
      dateCreated
      reportFile
      reportNumber
      reportType
      remark
      createdByName
      completedDate
      verifiedDate
      verifiedBy
      documentsCount
      photosCount
      commentsCount
    }
  }
`;

interface RecentFormsResult {
  data: Form[];
  loading: boolean;
}

export const useGetRecentForms = (): RecentFormsResult => {
  const { data, loading, error } = useQuery(GET_RECENT_FORMS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });

  if (error) {
    logger('GetRecentForms').error('Error fetching recent forms -->', error.message);
  }

  return {
    data: data?.recentForms || [],
    loading,
  };
};
