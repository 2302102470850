import { gql, useQuery } from '@apollo/client';
import { logger } from 'src/helpers/logger';
import { InputsListsData } from '../interfaces';

export const GET_INPUTS_LISTS = gql`
  query GetInputsLists {
    bunkeringStations
    bunkeringMethods
    bunkeringFromTo
    bunkeringFluids
    bunkeringAgents
  }
`;

interface InputsListsResult {
  inputsLists: Partial<InputsListsData>;
  loading: boolean;
}

export const useGetInputsLists = (): InputsListsResult => {
  const { data, loading, error } = useQuery<InputsListsData>(GET_INPUTS_LISTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache"
  });

  if (error) {
    logger('GetInputsLists').error('Error fetching inputs lists -->', error.message);
  }

  return {
    inputsLists: {
      bunkeringStations: data?.bunkeringStations,
      bunkeringMethods: data?.bunkeringMethods,
      bunkeringFromTo: data?.bunkeringFromTo,
      bunkeringFluids: data?.bunkeringFluids,
      bunkeringAgents: data?.bunkeringAgents,
    },
    loading,
  };
};
