import { ApolloQueryResult, gql, useLazyQuery } from "@apollo/client";
import { Equipment, EquipmentFilterInput, EquipmentSortInput } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";
import { PaginatedResult } from "../interfaces";

export const GET_LAZY_EQUIPMENT_PAGINATED = gql`
  query GetLazyEquipmentPaginated($skip: Int, $take: Int, $filterInput: EquipmentFilterInput, $order: [EquipmentSortInput!]) {
    lazyEquipmentPaginated: equipment(skip: $skip, take: $take, filterInput: $filterInput, order: $order) {
      items {
        id
        uniqueName
        hours
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

export const useGetLazyEquipmentPaginated = (): PaginatedResult<Equipment, EquipmentFilterInput, EquipmentSortInput> => {
  const [getLazyEquipmentPaginated, { data, loading, error }] = useLazyQuery(GET_LAZY_EQUIPMENT_PAGINATED, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const loadData = (skip: number, take: number, filterInput: EquipmentFilterInput, order: EquipmentSortInput[]) => {
    logger('GetLazyEquipmentPaginated').debug('Query variables', {skip, take, filterInput, order});   

    getLazyEquipmentPaginated({ variables: { skip, take, filterInput, order }})
      .then((result: ApolloQueryResult<any>) => {
        if (result.data && result.data.lazyEquipmentPaginated) {
          const { items, pageInfo } = result.data.lazyEquipmentPaginated;
          logger('GetLazyEquipmentPaginated').info('Equipment fetched successfully', {
            recordsCount: items?.length,
            pageInfo: pageInfo,
          });
        } else {
          logger('GetLazyEquipmentPaginated').warning('No data received in query result');
        }
      }).catch((apolloError) => {
        logger('GetLazyEquipmentPaginated').error('Error re-fetching equipment -->', apolloError.message);
      });
  };
  
  if (error) {
    logger('GetLazyEquipmentPaginated').error('Error fetching equipment -->', error.message);
  }

  return {
    data: data?.lazyEquipmentPaginated?.items || [],
    totalCount: data?.lazyEquipmentPaginated?.totalCount || 0,
    currentCount: data?.lazyEquipmentPaginated?.items?.length || 0,
    pageInfo: data?.lazyEquipmentPaginated?.pageInfo,
    loading,
    loadData,
  };
};