import { ApolloError, gql, useMutation } from '@apollo/client';
import { QueryBunkeringArgs } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_BUNKERINGS_WITH_COUNTS } from './useGetBunkeringsWithCounts';
import { DeleteMutationResponse } from '../interfaces';

export const DELETE_BUNKERING = gql`
  mutation DeleteBunkering($bunkeringId: String!) {
    deleteBunkering(bunkeringId: $bunkeringId) {
      deleteResult {
        deletedIds
        message
        success
      }
      errors {
        code
        message
      }
    }
  }
`;

interface DeleteBunkeringResult {
  removeBunkering: (bunkeringId: string, queryVariables: QueryBunkeringArgs) => Promise<DeleteMutationResponse>;
  removeBunkeringLoading: boolean;
}

export const useDeleteBunkering = (): DeleteBunkeringResult => {
  const [deleteBunkering, { loading }] = useMutation(DELETE_BUNKERING, {
    onError: (error: ApolloError) => {
      logger('DeleteBunkering').error('Error deleting bunkering -->', error.message);
    }
  });

  const removeBunkering = async (bunkeringId: string, refetchVariables: QueryBunkeringArgs): Promise<DeleteMutationResponse> => {
    const response = await deleteBunkering({ 
      variables: { bunkeringId },
      refetchQueries: [
        {
          query: GET_BUNKERINGS_WITH_COUNTS,
          variables: refetchVariables,
        }
      ],
    });

    const responseData = response.data?.deleteBunkering?.deleteResult;
    if (responseData?.success) {
      logger('DeleteBunkering').info('Bunkering deleted successfully', response.data);
      return {
        responseData,
        responseMessage: `Bunkering removed successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to remove bunkering!`,
      };
    }
  };

  return { 
    removeBunkering, 
    removeBunkeringLoading: loading, 
  };
};
