import { isNil } from 'lodash';
import { Bunkering } from 'src/generated/dotnet.graphql';
import { normalizeDateTime } from 'src/helpers';

export const validateForm = (data: any, setSnackbar: any) => {
  const { date, fluid } = data;

  if (isNil(date) || isNil(fluid)) {
    setSnackbar({
      open: true,
      message: 'Please fill required field(s). Check form field(s) marked with red color',
      type: 'error',
    });
    return false;
  }

  return true;
};

export const buildBunkeringByFluidTypeName = (bunkering: Bunkering): string => {
  return `${normalizeDateTime(bunkering.date)} | ${bunkering.station} | ${bunkering.fluid} | ${bunkering.amount} ${bunkering.qty}`;
}
