import { ApolloError, gql, useMutation } from '@apollo/client';
import { RemoveInventoryFromEquipmentInput, QueryEquipmentArgs } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { UpdateEquipmentInventoryCountCache } from '../fragments.graphql';
import { GET_LAZY_INVENTORY_FOR_EQUIPMENT_PAGINATED } from './useGetLazyInventoryForEquipmentPaginated';
import { DeleteMutationResponse } from '../interfaces';

export const REMOVE_INVENTORY_FROM_EQUIPMENT = gql`
  mutation RemoveInventoryFromEquipment($input: RemoveInventoryFromEquipmentInput!) {
    removeInventoryFromEquipment(input: $input) {
      deleteResult {
        deletedIds
        message
        success
      }
      errors {
        code
        message
      }
    }
  }
`;

interface RemoveInventoryFromEquipmentResult {
  removeInventoryFromEquipment: (input: RemoveInventoryFromEquipmentInput, refetchQueryVariables: QueryEquipmentArgs) => Promise<DeleteMutationResponse>;
  removeInventoryFromEquipmentLoading: boolean;
}

export const useRemoveInventoryFromEquipment = (equipmentId: string): RemoveInventoryFromEquipmentResult => {
  const [deleteInventoryFromEquipment, { loading, error }] = useMutation(REMOVE_INVENTORY_FROM_EQUIPMENT, {
    onError: (error: ApolloError) => {
      logger('RemoveInventoryFromEquipment').error(`Error removing inventory from equipment -->`, error.message);
    },  
    update: (cache, { data }) => {
      if (data?.removeInventoryFromEquipment?.deleteResult.success) {      
        const recordCacheId = cache.identify({
          __typename: 'Equipment',
          id: equipmentId,
        });
              
        const existingRecord = cache.readFragment<any>({
          id: recordCacheId,
          fragment: UpdateEquipmentInventoryCountCache,
        });

        if (existingRecord) {
          const updatedCount = { inventoryCount: existingRecord.inventoryCount - data?.removeInventoryFromEquipment?.deleteResult?.deletedIds.length};

          cache.writeFragment({
            id: recordCacheId,
            fragment: UpdateEquipmentInventoryCountCache,
            data: updatedCount,
          });

        } else {
          logger('Cache-RemoveInventoryFromEquipment').warning(`Equipment: ${equipmentId} not found in cache`);
        }
      } else {
        logger('Cache-RemoveInventoryFromEquipment').warning(`Equipment: ${equipmentId} cache update failed --> No response from upsert mutation`);
      }
    }
  });

  const removeInventoryFromEquipment = async (input: RemoveInventoryFromEquipmentInput, refetchQueryVariables: QueryEquipmentArgs): Promise<DeleteMutationResponse> => {
    const response = await deleteInventoryFromEquipment({ 
      variables: { input },
      refetchQueries: [
        {
          query: GET_LAZY_INVENTORY_FOR_EQUIPMENT_PAGINATED,
          variables: refetchQueryVariables,
        },
      ],
    });
    const responseData = response.data?.removeInventoryFromEquipment?.deleteResult;
    const responseDataError = response.data?.removeInventoryFromEquipment?.errors;

    if (responseData.success) {
      logger('RemoveInventoryFromEquipment').info('Inventory removed from equipment successfully', response.data);
      return {
        responseData,
        responseMessage: `Inventory removed from equipment successfully!`,
      };
    } else if (responseDataError) {
      logger('RemoveInventoryFromEquipment').info('Failed to remove inventory from equipment!', response.data);
      return {
        responseDataError: responseDataError[0],
        responseMessage: responseDataError[0].message,
      }
    } else {
      return {
        responseMessage: `Failed to remove inventory from equipment!`,
      };
    }
  };

  return { 
    removeInventoryFromEquipment, 
    removeInventoryFromEquipmentLoading: loading, 
  };
};