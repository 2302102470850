import { FC } from 'react';
import Button from '@mui/material/Button';
import { IconButton, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CustomDialog } from 'src/helpers/modals';
import InventoryGrid from './InventoryGrid';
import { Inventory, InventoryType } from 'src/generated/dotnet.graphql';

interface InjectedProps {
  isVisibile: boolean;
  onCancelInventoryDialog: any;
  onSelect: (inventory: Inventory) => void;
  inventoryType: InventoryType;
}

const InventoryDialog: FC<InjectedProps> = ({
  isVisibile,
  onCancelInventoryDialog,
  onSelect,
  inventoryType,
}) => (
  <CustomDialog scroll="paper" fullWidth maxWidth="md" open={isVisibile}>
    <DialogTitle sx={{ m: 0, p: 4 }} style={{ fontSize: '26px' }}>
      <span className="ml-2">Inventory</span>
      {onCancelInventoryDialog ? (
        <IconButton
          aria-label="close"
          onClick={onCancelInventoryDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
    <DialogContent dividers>
      <span className="ml-2"><b><small>To select spare, click/tap on a row.</small></b></span>
      <InventoryGrid
        inventoryType={inventoryType}
        onSelect={onSelect}
      />
    </DialogContent>
    <DialogActions sx={{ m: 0, p: 3 }}>
      <Button onClick={onCancelInventoryDialog} className="w-32">
        Cancel
      </Button>
    </DialogActions>
  </CustomDialog>
);

export default InventoryDialog;
