import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, DialogTitle, Dialog, DialogActions, DialogContent, Box } from '@mui/material';
import Input from 'src/components/UI/Forms/Input';
import { LoadingButton } from '@mui/lab';

interface InjectedProps {
  visible: boolean;
  onSave: (hoursOffset: number) => void;
  onCancel: () => void;
  loading: boolean;
}

const AddHourOffsetDialog: FC<InjectedProps> = ({
  visible,
  onSave,
  onCancel,
  loading
}) => {
  const { control, handleSubmit, reset } =  useForm<any>({ mode: 'onBlur', shouldFocusError: true });

  useEffect(() => {
    if (visible) {
      reset();
    }
  }, [visible]);

  const handleSave = async (data: any) => {
    if (!data) return;
    onSave(parseInt(data.hourOffset));
  };

  const handleCancel = () => {
    reset();
    onCancel();
  };

  return (
    <>
      <form
        className="relative bg-white flex-grow"
      >
        <Dialog
          scroll="paper"
          fullWidth
          maxWidth="sm"
          open={visible}
          onClose={handleCancel}
        >
          <DialogTitle>
            <span className="font-bold text-2xl">Add Hours Offset</span>
          </DialogTitle>
          <DialogContent dividers sx={{ p: 4 }}>
            <div>
              <p className="text-sm">
                If the actual Equipment hours differ from the Hours meter reading, enter an hours offset so you can enter the Hours meter reading as it appears, and YMS will automatically apply the offset.
              </p><br />
              <p className="text-sm">
                <strong>Positive offset:</strong> Enter a positive offset if the Hours meter displays a greater value than actual hours, for example, if you have to replace the Hours Counter.
              </p><br />
              <p className="text-sm">
                <strong>Negative offset:</strong> Enter a negative offset if the Hours meter displays a smaller value than the actual hours, for example, if you replaced the equipment but kept the original hours meter.
              </p>
              <div className="pt-5">
                <Input
                  name="hourOffset"
                  control={control}
                  rules={{ required: 'Hour Offset is required' }}
                  inputProps={{
                    label: 'Hour Offset',
                    type: 'number',
                    inputProps: {
                      // min: 0,
                      inputMode: 'numeric',
                      pattern: '^-?[0-9]+$',
                      step: 1,
                      style: { textAlign: 'end' },
                      onInput: (e: React.FormEvent<HTMLInputElement>) => {
                        const input = e.currentTarget;
                        input.value = input.value.replace(/[^-?\d]/g, ''); // Allow only numbers and a leading "-"
                      },
                    },
                  }}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
            <Box
              sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
            >
              <LoadingButton onClick={handleSubmit(handleSave)} loading={loading} className="mr-3" variant="contained">
                Save
              </LoadingButton>
              <Button className="mr-2" onClick={handleCancel}>
                Cancel
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </form>
    </>
  );
};

export default AddHourOffsetDialog;
