import '../style.css'
import { useState, FC, useRef, useEffect, useCallback } from 'react';
import { isEmpty, isNil } from 'lodash';
import { Alert, Snackbar, IconButton, useMediaQuery, Button, AlertColor } from '@mui/material';
import { DeleteTwoTone } from '@mui/icons-material';
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import { TypeFilterValue, TypeSingleSortInfo, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import WarningDialog from 'src/components/UI/WarningDialog';
import EquipmentAddSpareDialog from './EquipmentAddSpareDialog';
import AddIcon from '@mui/icons-material/Add';
import { DeleteResult, Equipment, Inventory, InventoryFilterInput, InventorySortInput, RemoveInventoryFromEquipmentInput, SortEnumType, UserError } from 'src/generated/dotnet.graphql';
import { GRID_LIMIT, SEVERITY } from 'src/consts';
import { useGetLazyInventoryForEquipmentPaginated } from 'src/hooks/equipment/useGetLazyInventoryForEquipmentPaginated';
import { useRemoveInventoryFromEquipment } from 'src/hooks/equipment/useRemoveInventoryFromEquipment';
import InventoryFormDialog from 'src/components/PageDrawer/Inventory/InventoryFormDialog';
import SpareLocationDialog from 'src/components/PageDrawer/LogEntry/components/SparesTab/components/SpareLocationDialog';

const filter = [
  {
    name: 'productName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'manufacturer',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'partNumber',
    operator: 'contains',
    type: 'select',
    value: '',
  },
];

const getSelectorByFilterName = async (
  name: string,
  value: any,
): Promise<Record<string, any>> => {
  switch (name) {
    case 'productName':
    case 'manufacturer':
    case 'partNumber': {
      return {
        [name]: value,
      };
    }
    default:
      return {};
  }
};

interface InjectedProps {
  initialValue: Equipment;
  inventoryCount: number;
  setInventoryCount: (value: number) => void;
  recordReadOnly: boolean;
  moduleReadOnly: boolean;
}

const EquipmentSparesTab: FC<InjectedProps> = ({ 
  initialValue, 
  inventoryCount, 
  setInventoryCount, 
  recordReadOnly, 
  moduleReadOnly
}) => {
  const defaultFilterValue = { equipmentId: initialValue.id };
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(GRID_LIMIT);
  const [filterValue, setFilterValue] = useState<InventoryFilterInput>(defaultFilterValue);
  const [sortValue, setSortValue] = useState<InventorySortInput[]>([{ productName: SortEnumType.Asc }]);

  const isTablet = useMediaQuery('(min-width: 700px) and (max-width: 1200px)');
  const isMobile = useMediaQuery('(max-width: 420px)')
  const [selectedInventory, setSelectedInventory] = useState<Inventory>();
  const [addPopupVisible, setAddPopupVisible] = useState<boolean>(false);
  const [editPopupVisible, setEditPopupVisible] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState<string[]>([]);
  const [deleteWarningMessage, setDeleteWarningMessage] = useState<string>('');
  const [isLocationPopupVisible, setLocationPopupVisible] = useState<boolean>(false);
  const prevRelatedItemsRef = useRef<Inventory[]>([]);
  const [excludeIds, setExcludeIds] = useState<string[]>([]);
  const refetchQueryVariables = { skip: skip, take: limit, filterInput: filterValue, order: sortValue };
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();

  const { data, totalCount, loading, loadData } = useGetLazyInventoryForEquipmentPaginated();
  const { removeInventoryFromEquipment, removeInventoryFromEquipmentLoading } = useRemoveInventoryFromEquipment(initialValue.id);
  
  useEffect(() => {
    if (initialValue.id) {
      loadData(skip, limit, filterValue, sortValue);
    }
  }, [skip, limit, filterValue, sortValue]);  
   
  useEffect(() => {
    const onInventoryForEquipmentFetch = () => {
      const excludedIds = data
        .map((inventory) => inventory.id)
        .filter((id) => id !== undefined) as string[];
        
      // Only update if `inventoryForEquipment` has changed
      if (JSON.stringify(data) !== JSON.stringify(prevRelatedItemsRef.current)) {
        setExcludeIds(excludedIds);
        prevRelatedItemsRef.current = data;
      }
    };
    onInventoryForEquipmentFetch();
  }, [data]);

  const dataSource = async () => {
    return {
      data,
      count: totalCount,
    };
  };

  const onSortInfoChange = (value: TypeSortInfo) => {
    const sortInfo = value as TypeSingleSortInfo
    if (isNil(sortInfo)) return;

    let sortPayload: InventorySortInput[];
    const sortDirection = sortInfo.dir === 1 ? SortEnumType.Asc : SortEnumType.Desc;
    const [field, subField] = sortInfo.name.split('.');

    if (subField) {
        // Handle nested objects
        sortPayload = [{
            [field]: {
                [subField]: sortDirection,
            },
        }];
    } else {
        // Handle non-nested objects
        sortPayload = [{
            [sortInfo.name]: sortDirection,
        }];
    }
    setSortValue(sortPayload)
  }

  const onFilterValueChange = async (filterValue: TypeFilterValue) => {
    if (isNil(filterValue)) return;

    const selectors = await Promise.all(
      filterValue.map(async (v) => {
        if (isEmpty(v.value)) return null;
        const selector = await getSelectorByFilterName(v.name, v.value);
        return selector;
      })
    );
    
    // Filter out null values
    const validSelectors = selectors.filter((selector) => selector !== null);

    // Combine the valid selectors into a single `filterInput` object
    const filterPayload: any = validSelectors.reduce((acc, obj) => {
      return { ...acc, ...obj };
    }, {});

    const newFilterValue = {
      ...defaultFilterValue,
      ...filterPayload,
    }

    setFilterValue((prevFilterValue: any) => {
      if (JSON.stringify(prevFilterValue) === JSON.stringify(newFilterValue)) {
        return prevFilterValue;
      }
      return newFilterValue;
    });
  }; 

  const handleAddClick = () => {
    setAddPopupVisible(true);
  };

  const handleAddCancel = () => {
    setAddPopupVisible(false);
  };

  const handleAddSave = (responseMessage: string, responseData?: Inventory[], responseDataError?: UserError) => {
    setAddPopupVisible(false);
    if(responseData) {
      setInventoryCount(inventoryCount + responseData.length)
    }
    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const onDelete = (inventory: Inventory) => {
    setDeleteWarningMessage(
      `Are you sure you want to remove ${inventory.productName} as a Spare part for ${initialValue.uniqueName}?<br />
      This will remove the association between the equipment and inventory, the inventory will remain unchanged.`
    );
    setDeleteSelected([inventory.id]);
    setIsDeleting(true);
  };

  const handleDeleteOk = async () =>{
    const payload: RemoveInventoryFromEquipmentInput = {
      equipmentId: initialValue.id,
      inventoryIds: deleteSelected,
    };
    const { responseData, responseDataError, responseMessage } = await removeInventoryFromEquipment(payload, refetchQueryVariables);
    if(responseData?.success) {
      setInventoryCount(inventoryCount - responseData.deletedIds.length)
      setIsDeleting(false);
      setDeleteSelected([]);
    }
    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const handleDeleteCancel = () =>{
    setIsDeleting(false);
    setDeleteSelected([]);
  }

  const onRowClick = useCallback(({ data }) => {
    setSelectedInventory(data);
    setEditPopupVisible(true);
  }, []);

  const handleEditSave = (responseData: Inventory, responseMessage: string) => {
    setSelectedInventory(undefined);
    setEditPopupVisible(false);
    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const handleEditCancel = () => {
    setSelectedInventory(undefined);
    setEditPopupVisible(false);
  };

  const handleEditDelete = async (responseDataDeleteInventory: DeleteResult, responseMessageDeleteInventory: string) => {
    handleEditCancel();
    const payload: RemoveInventoryFromEquipmentInput = {
      equipmentId: initialValue.id,
      inventoryIds: responseDataDeleteInventory.deletedIds,
    };
    const { responseData, responseDataError, responseMessage } = await removeInventoryFromEquipment(payload, refetchQueryVariables);
    if(responseData?.success) {
      setInventoryCount(inventoryCount - responseData.deletedIds.length)
      setIsDeleting(false);
      setDeleteSelected([]);
    }
    setSnackbar({
      message: `${responseMessageDeleteInventory}`,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  }

  const onLocationClick = (inventory: Inventory) =>{
    setSelectedInventory(inventory);
    setLocationPopupVisible(true);
  }

  const handleLocationPopupCancel = () => {
    setSelectedInventory(undefined);
    setLocationPopupVisible(false);
  }

  const columns = [
    {
      name: 'productName',
      header: 'Product Name',
      flex: 1,
    },
    {
      name: 'manufacturer',
      header: 'Manufacturer',
      flex: 0.5,
      visible: !isMobile,
    },
    {
      name: 'partNumber',
      header: 'Part Number',
      flex: 0.5,
      visible: !(isTablet || isMobile)
    },
    {
      name: 'totalAmount',
      header: 'Total Amount',
      flex: 0.5,
    },
    {
      id: 'actions',
      header: 'Actions',
      flex: 0.4,
      render: ({ data }: any) => (
        <div className = "flex justify-center">
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              onDelete(data);
            }}
            size="small"
            color="error"
            aria-label="Delete item"
            disabled={moduleReadOnly || recordReadOnly}
          >
            <DeleteTwoTone fontSize="inherit" />
          </IconButton>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              onLocationClick(data)}
            }
            size="small"
            color="primary"
            aria-label="Location"
          >
            <LocationOnTwoToneIcon fontSize="inherit" />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        <div className="flex justify-end mb-4">
          <Button 
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddClick} 
            disabled={moduleReadOnly || recordReadOnly}
          >
            Add
          </Button>
        </div>
        <div data-testid="data-grid" className="flex flex-col flex-grow eq-spares">
          <LicensedReactDataGrid
            idProperty="id"
            skip={skip}
            onSkipChange={setSkip}
            limit={limit}
            onLimitChange={setLimit}
            pagination='remote'
            rowHeight={40}
            loading={loading}
            defaultFilterValue={filter}
            onFilterValueChange={onFilterValueChange}
            onSortInfoChange={onSortInfoChange}
            allowUnsort={false}
            columns={columns}
            dataSource={dataSource}
            onRowClick={onRowClick}
          />
        </div>
      </div>

      <EquipmentAddSpareDialog
        initialValue={initialValue}
        visible={addPopupVisible}
        excludeIds={excludeIds}
        onSave={handleAddSave}
        onCancel={handleAddCancel}
        refetchQueryVariables={refetchQueryVariables}
      />

      {!!selectedInventory && (
        <InventoryFormDialog 
          visible={editPopupVisible}
          inventory={selectedInventory}
          onSave={handleEditSave}
          onDelete={handleEditDelete}
          onCancel={handleEditCancel}
          moduleReadOnly={moduleReadOnly}
          isCreate={false}
        />
      )}

      <SpareLocationDialog
        onCancel={handleLocationPopupCancel}
        inventoryId={selectedInventory?.id}
        visible={isLocationPopupVisible}
      />

      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content={deleteWarningMessage}
        loading={removeInventoryFromEquipmentLoading}
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </>
  );
};

export default EquipmentSparesTab;
