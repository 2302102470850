import { gql, useQuery  } from "@apollo/client";
import { InventoryLocation } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_INVENTORY_LOCATIONS = gql`
  query GetInventoryLocations($inventoryId: String!) {
    inventoryLocations(inventoryId: $inventoryId) {
      id
      locationId
      serialNum
      amount
      max
      useBy
      isDefault
      locationPath
      dateEntered
      inventory {
        id
      }
    }
  }
`

interface InventoryLocationsResult {
  inventoryLocations: InventoryLocation[];
  inventoryLocationsLoading: boolean;
}

export const useGetInventoryLocations = (inventoryId: string | undefined): InventoryLocationsResult => {
  const { data, loading, error } = useQuery(GET_INVENTORY_LOCATIONS, {
    variables: { inventoryId },
    skip: !inventoryId,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetInventoryLocation').error('Error fetching locations for spare-inventory -->', error.message);
  }
  
  return {
    inventoryLocations: data?.inventoryLocations || [],
    inventoryLocationsLoading: loading,
  };
};

  