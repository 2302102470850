import { FC } from 'react';
import { Box, Button, Theme, Tooltip, DialogActions, ButtonPropsVariantOverrides } from '@mui/material';
import { SxProps } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { OverridableStringUnion } from '@mui/types';

interface InjectedProps {
  sx?: SxProps<Theme>;
  okType?: 'button' | 'submit' | 'reset';
  okText?: string;
  cancelText?: string;
  onCancel?: () => void;
  onOk?: () => void;
  formId?: string;
  loading?: boolean;
  okVariant?: OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>;
  disabled?: boolean;
}

const DialogActionBar: FC<InjectedProps> = ({
  sx,
  okType = 'submit',
  okText = 'Save',
  okVariant = 'contained',
  onCancel,
  onOk,
  cancelText = 'Close',
  children = null,
  formId,
  loading,
  disabled = false,
}) => {
  return (
    <DialogActions>
      <Box sx={{ justifyContent: "flex-start", flexGrow: 1, display: "flex" }}>
        <Tooltip title={disabled ? 'You do not have permission to save this document' : ''}>
          <LoadingButton
            loading={loading}
            form={formId}
            disabled={disabled}
            type={okType}
            onClick={onOk}
            className="mr-3 w-32"
            variant={okVariant}
          >
            {okText}
          </LoadingButton>
        </Tooltip>
        {onCancel && (
          <Button onClick={onCancel} className="w-32">
            {cancelText}
          </Button>
        )}
      </Box>
      
      <Box sx={{ justifyContent: "flex-end", flexGrow: 1, display: "flex" }}>
        {children}
      </Box>
    </DialogActions>
  );
};

export default DialogActionBar;
