import { FC } from "react";
import Button from "@mui/material/Button";
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LicensedReactDataGrid from "src/components/UI/LicensedReactDataGrid";
import { useGetInventoryLocations } from "src/hooks/inventory/useGetInventoryLocation";

interface InjectedProps {
  visible: boolean;
  onCancel?: () => void;
  inventoryId?: string;
}

const SpareLocationDialog: FC<InjectedProps> = ({ inventoryId, visible, onCancel }) => {

  const { inventoryLocations, inventoryLocationsLoading } = useGetInventoryLocations(inventoryId)

  const columns = [
    {
      name: "amount",
      header: "Amount",
      defaultWidth: 95,
      headerAlign: 'start' as any,
      textAlign: "end" as any,
    },
    {
      name: "locationPath",
      header: "Location",
      flex: 1,
    },
  ];

  return (
    <Dialog scroll="paper" fullWidth maxWidth="md" open={visible}>
      <DialogTitle sx={{ m: 0, p: 4 }} style={{ fontSize: "26px" }}>
        <span className="ml-2">Spare Location(s)</span>
        {onCancel ? (
          <IconButton
            aria-label="close"
            onClick={onCancel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers>
        <div data-testid="data-grid" className="flex flex-col flex-grow m-2">
          <LicensedReactDataGrid
            rowHeight={40}
            loading={inventoryLocationsLoading}
            idProperty="inventoryId"
            columns={columns}
            dataSource={inventoryLocations}
          />
        </div>
      </DialogContent>
      <DialogActions sx={{ m: 0, p: 3, justifyContent: "flex-end" }}>
        <Button onClick={onCancel} className="w-32" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SpareLocationDialog;
