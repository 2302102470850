import { ApolloError, gql, useMutation } from '@apollo/client';
import { QueryDocumentationArgs } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_DOCUMENTATIONS } from './useGetDocumentations';
import { DeleteMutationResponse } from '../interfaces';

export const DELETE_DOCUMENTATIONS = gql`
  mutation DeleteDocumentations($documentationIds: [String!]!) {
    deleteDocumentations(documentationIds: $documentationIds) {
      deleteResult {
        deletedIds
        message
        success
      }
      errors {
        code
        message
      }
    }
  }
`;

interface DeleteDocumentationsResult {
  bulkRemoveDocumentations: (documentationIds: string[], queryVariables: QueryDocumentationArgs) => Promise<DeleteMutationResponse>;
  bulkRemoveDocumentationsLoading: boolean;
}

export const useDeleteDocumentations = (): DeleteDocumentationsResult => {
  const [deleteDocumentations, { loading }] = useMutation(DELETE_DOCUMENTATIONS, {
    onError: (error: ApolloError) => {
      logger('DeleteLogEntries').error('Error deleting log entry/entries -->', error.message);
    }
  });

  const bulkRemoveDocumentations = async (documentationIds: string[], refetchVariables: QueryDocumentationArgs): Promise<DeleteMutationResponse> => {
    const response = await deleteDocumentations({ 
      variables: { documentationIds },
      refetchQueries: [
        {
          query: GET_DOCUMENTATIONS,
          variables: refetchVariables,
        }
      ],
    });
    
    const responseData = response.data?.deleteDocumentations?.deleteResult;
    if (responseData?.success) {
      logger('DeleteDocumentations').info(`${documentationIds.length > 1 ? 'Documentations' : 'Documentation'} deleted successfully`, response.data);
      return {
        responseData,
        responseMessage: `${documentationIds.length > 1 ? 'Documentations' : 'Documentation'} removed successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to remove ${documentationIds.length > 1 ? 'documentations' : 'documentation'}!`,
      };
    }
  };

  return { 
    bulkRemoveDocumentations, 
    bulkRemoveDocumentationsLoading: loading, 
  };
};
