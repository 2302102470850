import { FC, useState } from 'react';
import { IconButton } from '@mui/material';
import { withSnackbar } from 'src/components/UI/SnackbarHOC';
import { InventoryType } from 'src/generated/dotnet.graphql';
import InventoriesToLocationDialog from './component/InventoriesToLocationDialog';
import EditLocationAltOutlinedIcon from '@mui/icons-material/EditLocationAltOutlined';

interface InjectedProps {
  onSubmit: () => void;
  visible: boolean;
  inventoryType: InventoryType;
  inventoryTypeTitle: string;
}

const InventoriesToLocationButton: FC<InjectedProps> = ({
  onSubmit,
  visible,
  inventoryType,
  inventoryTypeTitle
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = async () => {
    setIsDialogOpen(true);
  };

  const handleCancelDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={handleOpenDialog}
        aria-label="Manage Inventory To Location"
        color="info"
        disabled={visible}
      >
        <EditLocationAltOutlinedIcon />
      </IconButton>

      <InventoriesToLocationDialog
        visible={isDialogOpen}
        inventoryType={inventoryType}
        inventoryTypeTitle={inventoryTypeTitle}
        onCancel={handleCancelDialog}
        onSubmit={onSubmit}
        saveLoading={false}
      />
    </>
  );
};

export default withSnackbar(InventoriesToLocationButton);
