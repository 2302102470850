import { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PhotoIcon from '@mui/icons-material/Photo';
import CommentIcon from '@mui/icons-material/Comment';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import FactCheckIcon from '@mui/icons-material/FactCheck'; // based on verifiedReq
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'; //based on smsReport
import EventBusyIcon from '@mui/icons-material/EventBusy';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import Tooltip from '@mui/material/Tooltip';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';

interface GridRowIconProps {
  hasAttachments?: boolean;
  hasPhotos?: boolean;
  hasComments?: boolean;
  hasSpares?: boolean;
  isCritical?: boolean;
  isReport?: boolean;
  requiresVerification?: boolean;
  expiredAtLocation?: boolean;
  lowStock?: boolean;
  hasTasks?: boolean;
}

const GridRowIcon: FC<GridRowIconProps> = ({ 
  hasAttachments, 
  hasPhotos, 
  hasComments, 
  hasSpares, 
  isCritical, 
  isReport,
  requiresVerification,
  expiredAtLocation,
  lowStock,
  hasTasks,
}) => {
  return (
    <div className="flex">
      { hasTasks &&
        <Tooltip title="Has Tasks">
          <InventoryOutlinedIcon fontSize='small' color='info'/>
        </Tooltip>
      }
      { hasAttachments && 
        <Tooltip title="Has Attachments">
          <AttachFileIcon fontSize='small' color='info'/>
        </Tooltip>
      }
      { hasPhotos && 
        <Tooltip title="Has Photos">
          <PhotoIcon fontSize='small' color='info'/>
        </Tooltip>
      }
      { hasComments && 
        <Tooltip title="Has Comments">
          <CommentIcon fontSize='small' color='success'/>
        </Tooltip>
      }
      { hasSpares && 
        <Tooltip title="Spares Used">
          <Inventory2OutlinedIcon fontSize='small' color='primary'/>
        </Tooltip>
      }
      { isCritical && 
        <Tooltip title="Critical">
          <AddIcon fontSize='small' color='error'/>
        </Tooltip>
      } 
      { isReport && 
        <Tooltip title="Is a Form">
          <DocumentScannerIcon fontSize='small' color='info'/>
        </Tooltip>
      } 
      { requiresVerification && 
        <Tooltip title="Requires Verification">
          <FactCheckIcon fontSize='small' color='warning'/>
        </Tooltip>
      } 
      { expiredAtLocation && 
        <Tooltip title="Expired at Location">
          <EventBusyIcon fontSize='small' color='error'/>
        </Tooltip>
      } 
      { lowStock && 
        <Tooltip title="Low Stock">
          <ProductionQuantityLimitsIcon fontSize='small' color='error'/>
        </Tooltip>
      } 
    </div>
  );
};

export default GridRowIcon;
