import '../style.css';
import { FC, useCallback, useState, useEffect } from 'react';
import { isBoolean, isEmpty, isNil } from 'lodash';
import LicensedReactDataGrid from '../../../../UI/LicensedReactDataGrid';
import { useGetLazyInventoryPaginated } from 'src/hooks/inventory/useGetLazyInventoryPaginated';
import { Inventory, InventoryFilterInput, InventorySortInput, InventoryType, SortEnumType } from 'src/generated/dotnet.graphql';
import { TypeFilterValue, TypeSingleSortInfo, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import { GRID_LIMIT } from 'src/consts';

const filter = [
  {
    name: 'productName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'manufacturer',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'modelNumber',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const getSelectorByFilterName = async (
  name: string,
  value: any,
): Promise<Record<string, any>> => {
  switch (name) {
    case 'productName':
    case 'manufacturer':
    case 'modelNumber': {
      return {
        [name]: value,
      };
    }
    default:
      return {};
  }
};

const transformData = (data: any[]): Record<string, Inventory> => {
  return data.reduce((acc, { id, ...rest }) => {
    acc[id] = rest;
    return acc;
  }, {});
}

type InjectedProps = {
  excludeIds: any;
  onSelect: (items: any[]) => void;
};

const EquipmentAddSpareGrid: FC<InjectedProps> = ({
  excludeIds,
  onSelect,
}) => {
  const defaultFilterValue = { 
    inventoryType: InventoryType.GeneralInventory, 
    excludeIds
  };
  const defaultSortValue = [
    { productName: SortEnumType.Asc },
    { manufacturer: SortEnumType.Asc },
    { modelNumber: SortEnumType.Asc },
  ];
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(GRID_LIMIT);
  const [filterValue, setFilterValue] = useState<InventoryFilterInput>(defaultFilterValue);
  const [sortValue, setSortValue] = useState<InventorySortInput[]>(defaultSortValue);

  const { data, totalCount, loading, loadData } = useGetLazyInventoryPaginated();
  
  useEffect(() => {
    loadData(skip, limit, filterValue, sortValue);
  }, [skip, limit, filterValue, sortValue]);  

  const dataSource = async () => {
    return {
      data,
      count: totalCount,
    };
  };

  const onSortInfoChange = (value: TypeSortInfo) => {
    const sortInfo = value as TypeSingleSortInfo
    if (isNil(sortInfo)) return;

    let sortPayload: InventorySortInput[];
    const sortDirection = sortInfo.dir === 1 ? SortEnumType.Asc : SortEnumType.Desc;
    const [field, subField] = sortInfo.name.split('.');

    if (subField) {
        // Handle nested objects
        sortPayload = [{
            [field]: {
                [subField]: sortDirection,
            },
        }];
    } else {
        // Handle non-nested objects
        sortPayload = [{
            [sortInfo.name]: sortDirection,
        }];
    }
    setSortValue(sortPayload)
  }

  const onFilterValueChange = async (filterValue: TypeFilterValue) => {
    if (isNil(filterValue)) return;

    const selectors = await Promise.all(
      filterValue.map(async (v) => {
        if (isEmpty(v.value)) return null;
        const selector = await getSelectorByFilterName(v.name, v.value);
        return selector;
      })
    );
    
    // Filter out null values
    const validSelectors = selectors.filter((selector) => selector !== null);

    // Combine the valid selectors into a single `filterInput` object
    const filterPayload: any = validSelectors.reduce((acc, obj) => {
      return { ...acc, ...obj };
    }, {});

    const newFilterValue = {
      ...defaultFilterValue,
      ...filterPayload,
    }

    setFilterValue((prevFilterValue: any) => {
      if (JSON.stringify(prevFilterValue) === JSON.stringify(newFilterValue)) {
        return prevFilterValue;
      }
      return newFilterValue;
    });
  }; 

  const onSelectionChange = useCallback(({ selected }) => {
    if (isBoolean(selected) && selected) {
      const transformedData = transformData(data);
      onSelect(Object.keys(transformedData));
    } else {
      onSelect(Object.keys(selected));
    }
  }, [data]);

  const columns = [
    {
      name: 'productName',
      header: 'Product Name',
      flex: 1,
    },

    {
      name: 'manufacturer',
      header: 'Manufacturer',
      flex: 1,
    },

    {
      name: 'modelNumber',
      header: 'Model Number',
      flex: 1,
    },
  ];

  return (
    <div data-testid="data-grid" className="flex flex-col flex-grow p-4 inv-space-add-eq-grid">
      <LicensedReactDataGrid
        idProperty="id"
        checkboxColumn
        onSelectionChange={onSelectionChange}
        skip={skip}
        onSkipChange={setSkip}
        limit={limit}
        onLimitChange={setLimit}
        pagination='remote'
        rowHeight={40}
        loading={loading}
        defaultFilterValue={filter}
        onFilterValueChange={onFilterValueChange}
        onSortInfoChange={onSortInfoChange}
        allowUnsort={false}
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  );
};

export default EquipmentAddSpareGrid;
