import { FC, useState } from 'react';
import { Button, DialogTitle, DialogActions, DialogContent, Box, Snackbar, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import EqNewTaskCreateForm from './EqNewTaskCreate';
import { CustomDialog } from 'src/helpers/modals';

interface InjectedProps {
  visible: boolean;
  initialValue: WorkIssuesDocument;
  onSave: () => void;
  onCancel?: () => void;
  title?: string;
  moduleReadOnly: boolean;
}

const EqNewTaskDialog: FC<InjectedProps> = ({
  initialValue,
  visible,
  onCancel,
  onSave,
  title,
  moduleReadOnly,
}) => {
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });
  const [processing, setProcessing] = useState<boolean>(false);

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const handleSave = () => {
    onSave();
    setSnackbar({
      open: true,
      message: 'New task has been created!',
      type: 'success',
    });
  };

  const handleCancel = () => {
    onCancel && onCancel();
    setProcessing(false);
    setSnackbar({
      open: false,
      type: 'success',
      message: '',
    });
  };

  return (
    <>
      <CustomDialog
        scroll="paper"
        fullWidth
        maxWidth="md"
        open={visible}
        onClose={handleCancel}
      >
        <DialogTitle>
          <span className="font-bold text-2xl">{title}</span>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          <EqNewTaskCreateForm
            initialValue={initialValue}
            onSave={handleSave}
            moduleReadOnly={moduleReadOnly}
          />
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          <Box sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}>
            <Button 
              className="mr-2" 
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={processing}
              type="submit"
              form="Equipment-create-new-task-form"
              variant="contained"
            >
              Save
            </LoadingButton>
          </Box>
        </DialogActions>
      </CustomDialog>

      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={onSnackbarClose}
      >
        <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EqNewTaskDialog;
