import { logger } from 'src/helpers/logger';
import { ApolloError, gql, useMutation } from '@apollo/client';
import { QueryDocumentationArgs } from 'src/generated/dotnet.graphql';
import { GET_DOCUMENTATIONS } from './useGetDocumentations';
import { DeleteMutationResponse } from '../interfaces';

export const DELETE_DOCUMENTATION = gql`
  mutation DeleteDocumentation($documentationId: String!) {
    deleteDocumentation(documentationId: $documentationId) {
      deleteResult {
        deletedIds
        message
        success
      }
      errors {
        code
        message
      }
    }
  }
`;

interface DeleteDocumentationResult {
  removeDocumentation: (documentationId: string, variables: QueryDocumentationArgs) => Promise<DeleteMutationResponse>;
  removeDocumentationLoading: boolean;
}

export const useDeleteDocumentation = (): DeleteDocumentationResult => {
  const [deleteDocumentation, { loading }] = useMutation(DELETE_DOCUMENTATION, {
    onError: (error: ApolloError) => {
      logger('DeleteDocumentation').error('Error deleting documentation -->', error.message);
    }
  });

  const removeDocumentation = async (documentationId: string, refetchVariables: QueryDocumentationArgs): Promise<DeleteMutationResponse> => {
    const response = await deleteDocumentation({ 
      variables: { documentationId },
      refetchQueries: [{
        query: GET_DOCUMENTATIONS,
        variables: refetchVariables
      }],
    });
    const responseData = response.data?.deleteDocumentation?.deleteResult;
    if (responseData?.success) {
      logger('DeleteDocumentation').info('Documentation deleted successfully', response.data);
      return {
        responseData,
        responseMessage: `Documentation removed successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to remove documentation!`,
      };
    }
  };

  return { 
    removeDocumentation, 
    removeDocumentationLoading: loading, 
  };
};
