import '../styles.css'
import { FC, useEffect, useState } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import { useGetLazyInventoryAtLocation } from 'src/hooks/inventory/useGetLazyInventoryAtLocation';
import { logger } from 'src/helpers/logger';
import { InventoryLocation, InventoryType, RecordType } from 'src/generated/dotnet.graphql';
import InventoryCard from './InventoryCard';

interface InjectedProps {
  locationSelected: string | undefined;
  onChange: (item: any) => void;
  inventoryType: InventoryType;
  recordType: RecordType;
  moduleReadOnly: boolean;
  refetchTree?: boolean;
  isInventoryDeleted?: boolean;
}

const CardViewGrid: FC<InjectedProps> = ({ 
    locationSelected, 
    onChange, 
    inventoryType,
    recordType, 
    moduleReadOnly,
    refetchTree,
    isInventoryDeleted, 
  }) => {
  const [inventoryAtLocation, setInventoryAtLocation] = useState<InventoryLocation[]>([])
  const [refetch, setRefetch] = useState<boolean>(false);
  const { getLazyInventoryAtLocation, loading } = useGetLazyInventoryAtLocation();
  
  const loadInventory = async (locationId: string) => {
    try {
      const queryResult = await getLazyInventoryAtLocation({ variables: { locationId, filterInput: { inventoryType } } });
      const inventoryLocation = queryResult?.data?.inventoryAtLocation || [];
      
      setInventoryAtLocation(inventoryLocation);
    } catch (error) {
      logger('GetInventoryByLocation').error(`Error loading inventory for location ${locationId}:`, error);
      setInventoryAtLocation([]);
    }
  };

  // Clear the data on inventory type change
  useEffect(() => {
    setInventoryAtLocation([]);
  }, [inventoryType]);
  
  useEffect(() => {
    if (locationSelected) {
      loadInventory(locationSelected);
    }
  }, [locationSelected, refetch, isInventoryDeleted, refetchTree]);

  const onRefetch = () => {
    setRefetch(!refetch);
  }

  // TODO - investigate, this makes the ui rerendering when interact with the cards
  // if (loading) {
  //   return (
  //     <div className="flex flex-grow items-center justify-center">
  //       <CircularProgress />
  //     </div>
  //   );
  // }

  return (
    <div className='card-grid-wrapper custom-scrollbar'>
      {locationSelected ? (
        <div className="col-span-1 p-2 overflow-hidden px-2">
          <Grid
            container 
            spacing={{ xs: 2, md: 3 }} 
            columns={12}
          >
            {inventoryAtLocation?.map((inventoryLocation) => (
              <Grid item 
                xs={12} // Takes full width on extra-small screens
                sm={6}  // Two cards per row on small screens
                md={4}  // Three cards per row on medium screens
                lg={4}  // Four cards per row on large screens
                key={inventoryLocation.id}>
                  <InventoryCard 
                    inventoryLocation={inventoryLocation} 
                    inventoryType={inventoryType}
                    onChange={onChange} 
                    recordType={recordType} 
                    moduleReadOnly={moduleReadOnly}  
                    onRefetch={onRefetch}                
                  />
              </Grid>
            ))}
          </Grid>
        </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
            <span>Please select a location</span>
          </div>
        )
      }
    </div>
  );
};

export default CardViewGrid;
