import './styles.css';
import { Alert, Snackbar, AlertColor } from '@mui/material';
import { useEffect, useState, useRef, useMemo } from 'react';
import PageDrawer from '../../components/PageDrawer';
import PageHeader, { Action } from '../../components/UI/PageHeader';
import LogEntryGrid from './components/LogEntryGrid';
import MasterSearchInput from 'src/components/MasterSearchInput';
import DeleteRecordBtn from 'src/components/DeleteRecordsButton';
import LogEntryDetailForm from '../../components/PageDrawer/LogEntry/LogEntryDetailForm';
import PrintButton from 'src/components/UI/PrintButton';
import { useAuth } from '../../contexts/auth';
import { useAppState } from 'src/contexts/app-state';
import { LogEntry, LogEntryFilterInput, RecordType, SortEnumType } from 'src/generated/dotnet.graphql';
import { useDeleteLogEntries } from 'src/hooks/logEntries/useDeleteLogEntries';
import { saveViewMode, getViewMode, isEmptyObject } from '../../utils/'
import { COMPONENTS, GRID_LIMIT, GridType, SEVERITY } from 'src/consts';
import { AccessType, getAccessType } from 'src/utils/permissions';
import { MasterSearchInputValue } from "src/components/MasterSearchInput";
import CategoryViewGrid from './components/CategoryViewGrid';
import LocationViewGrid from './components/LocationViewGrid';
import useMergedFilters from 'src/hooks/common/useMergedFilters';
import ViewSelector from 'src/components/ViewSelector';

export const defaultFilterValue = {
  isWarranty: undefined,
  restricted: undefined,
  sms: undefined,
  searchText: undefined
};

export const defaultSortValue = { 
  logDate: SortEnumType.Desc 
};

const LogEntryPage = () => {
  // Global state
  const { user } = useAuth();
  const { settingsPersonal } = useAppState();
  // Grid pagination state - keep it on parent component so it can be used for mutations refetching
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(GRID_LIMIT);
  const [masterSearchInput, setMasterSearchInput] = useState<MasterSearchInputValue | null>(null);
  const [filterValue, setFilterValue] = useMergedFilters<LogEntryFilterInput>(masterSearchInput, defaultFilterValue, GridType.DEFAULT);
  const [sortValue, setSortValue] = useState<any>(defaultSortValue);
  const [rowSelected, setRowSelected] = useState<Record<string, LogEntry>>({});
  // Apollo client mutations
  const { removeLogEntries } = useDeleteLogEntries();
  // Component related state
  const gridRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const oldSelectedItem = useRef<LogEntry | null>(null);
  const [viewType, setViewType] = useState<GridType>(GridType.DEFAULT);
  const [selectedLogEntry, setSelectedLogEntry] = useState<LogEntry>();
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [refetchTree, setRefetchTree] = useState<boolean>(false);
  const [savedViewList, setSavedViewList] = useState<any>([]);
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();
  const refetchQueryVariables = { skip: skip, take: limit, filterInput: filterValue, order: sortValue };
  const moduleReadOnly = getAccessType(settingsPersonal?.fldLogsMaint) !== AccessType.FULL_ACCESS;

  const handleDataExport = (type: string) => {
    gridRef?.current.handleExport(type);
  }

  const handleGeneratePDF = () => {
    return gridRef?.current.handleGeneratePDF();
  }

  const initializeViewMode = async () => {
    const mode = getViewMode();
    if (mode) setViewType(mode);
  };

  useEffect(() => {
    initializeViewMode();
  }, []);

  useEffect(() => {
    renderContent();
  }, [masterSearchInput]);

  const handleCreateClick = async () => {
    setIsCreate(true);
    const logEntry: any = {
      logDate: new Date().toISOString(),
      fldPerformedBy: `${user?.fldFirst} ${user?.fldLast}`,
      department: user?.Department,
    };

    setSelectedLogEntry(logEntry);
  };

  const handleSave = async (responseData: LogEntry, responseMessage: string, isCreated: boolean) => {
    setSelectedLogEntry(responseData);
    oldSelectedItem.current = responseData;

    if (isCreated) {
      setIsCreate(false);
    }
    if (viewType !== GridType.DEFAULT) {
      setRefetchTree(!refetchTree);
      renderContent();
    }

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const handleDelete = async (responseData: LogEntry, responseMessage: string) => {
    if (responseData) {
      setSelectedLogEntry(undefined);
    }

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });

    if (viewType !== GridType.DEFAULT) {
      setRefetchTree(!refetchTree);
      renderContent();
    }
  };

  const handleDeleteMultipleRecords = async (validRecords: string[], invalidMessage: string) => {
    if (invalidMessage) {
      setSnackbar({
        message: invalidMessage,
        severity: 'error',
      });
    }

    if (validRecords.length > 0) {
      const { responseData, responseMessage } = await removeLogEntries(validRecords, { skip, take: limit, filterInput: filterValue, order: sortValue });
      setRowSelected({});

      setSnackbar({
        message: responseMessage,
        severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
      });

      if (viewType !== GridType.DEFAULT) {
        setRefetchTree(!refetchTree);
        renderContent();
      }
    }
  };

  const handleCancel = () => {
    setIsCreate(false);
    setSelectedLogEntry(undefined);
    oldSelectedItem.current = null;
  };

  const onSelect = async (logEntry: LogEntry) => {
    setSelectedLogEntry(logEntry);
    oldSelectedItem.current = logEntry;
  };

  const onSelectChange = async (selectedLogEntry: LogEntry) => {
    setSelectedLogEntry(selectedLogEntry);
  };

  const isTableOriginal = useMemo(() => {
    if (viewType === GridType.CATEGORY || viewType === GridType.DEFAULT || viewType === GridType.LOCATION) {
      return true;
    } else {
      return false;
    }
  }, [viewType])

  const renderContent = () => {
    let gridType = viewType;
    if (!isTableOriginal) {
      const savedViewItem = savedViewList.find((item: any) => item.id === gridType);
      if (savedViewItem) {
        gridType = JSON.parse(savedViewItem.layout).mode;
      }
    }
    switch (gridType) {
      case GridType.LOCATION:
        return (
          <div
            style={{ maxHeight: containerRef.current?.clientHeight || 0 }}
            className="flex-grow h-full w-full flex overflow-y-auto"
          >
            <LocationViewGrid 
              masterSearchInput={masterSearchInput} 
              onChange={onSelectChange} 
              refetchTree={refetchTree} 
            />
          </div>
        );
      case GridType.CATEGORY:
        return (
          <div
            style={{ maxHeight: containerRef.current?.clientHeight || 0 }}
            className="flex-grow h-full w-full flex overflow-y-auto"
          >
            <CategoryViewGrid 
              masterSearchInput={masterSearchInput} 
              onChange={onSelectChange} 
              refetchTree={refetchTree} 
            />
          </div>
        );
      default:
        return (
          <div className="flex flex-grow h-full">
            <LogEntryGrid
              darken 
              onSelect={onSelect}
              ref={gridRef} 
              skip={skip}
              setSkip={setSkip}
              limit={limit}
              setLimit={setLimit}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              sortValue={sortValue}
              setSortValue={setSortValue} 
              rowSelected={rowSelected}
              setRowSelected={setRowSelected}
            />
          </div>
        );
    }
  };

  const renderView = () => (
    <ViewSelector
      defaultOptions={[GridType.DEFAULT, GridType.CATEGORY, GridType.LOCATION]}
      contentType={viewType}
      setContentType={setViewType}
      saveViewMode={saveViewMode}
      onSavedViewList={setSavedViewList}
      onDataExport={handleDataExport}
    />
  );

  const onLogEntrySearch = (isReset: boolean, searchValue: MasterSearchInputValue) => {
    if(isReset) {
      setMasterSearchInput(null);
      setFilterValue({
        isWarranty: undefined,
        restricted: undefined,
        sms: undefined,
        searchText: undefined,
      });
      setSkip(0);
    }
    else {
      setMasterSearchInput(searchValue);
      setSkip(0);
    }
  };

  const renderMasterSearch = () => (
    <MasterSearchInput
      recordType={RecordType.LogEntry}
      label="Log search"
      onChange={onLogEntrySearch}
    />
  )

  const actions: Action[] = [
    {
      customComponent: (
        <PrintButton
          generatePDF={handleGeneratePDF}
          enable={!moduleReadOnly}
          title='Print Log Entry'
        />
      ),
    },
    {
      icon: 'add',
      title: 'New entry',
      onClick: handleCreateClick,
      disabled: moduleReadOnly
    },
  ]

  if(viewType === GridType.DEFAULT) {
    actions.unshift(
      {
        customComponent: (
          <DeleteRecordBtn
            visible={moduleReadOnly || isEmptyObject(rowSelected)}
            records={rowSelected}
            onValidate={handleDeleteMultipleRecords}
          />
        ),
      }
    )
  }

  return (
    <div className="flex flex-grow flex-col overflow-auto">
      <PageHeader
        title="Maintenance History"
        actions={actions}
        renderView={renderView}
        renderMasterSearch={renderMasterSearch}
      >
        <div className="flex md:hidden">{renderView()}</div>
      </PageHeader>

      <div ref={containerRef} className="flex flex-grow flex-col w-full">
        {renderContent()}
      </div>

      <PageDrawer 
        onClose={handleCancel} 
        visible={!!selectedLogEntry}
      >
        {!!selectedLogEntry && (
          <LogEntryDetailForm
            initialValue={selectedLogEntry}
            isCreate={isCreate}
            onSave={handleSave}
            onDelete={handleDelete}
            onCancel={handleCancel}
            refetchQueryVariables={refetchQueryVariables}
            moduleReadOnly={moduleReadOnly}
            callerComponent={COMPONENTS.LogEntryPage}
          />
        )}
      </PageDrawer>

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </div>
  );
};

export default LogEntryPage;
