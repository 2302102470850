import { FC } from 'react';
import { Control } from 'react-hook-form';
import Autocomplete from 'src/components/UI/Forms/Autocomplete';
import { useGetEquipmentSuppliers } from 'src/hooks/companies/useGetEquipmentSuppliers';

type InjectedProps = {
  onChange: (field: string, value: any) => void;
  placeholder?: string;
  control: Control;
  name: string;
  label?: string;
  variant?: string;
  size?: string;
};

const EquipmentSuppliersDropdown: FC<InjectedProps> = ({
  placeholder,
  control,
  label,
  name,
  onChange,
  variant,
  size,
}) => {
  const {data, loading} = useGetEquipmentSuppliers();

  return (
    <Autocomplete
      onChange={onChange}
      loading={loading}
      label={label}
      placeholder={placeholder}
      options={data}
      control={control}
      name={name}
      keyExpr=""
      displayExpr="name"
      variant={variant}
      size={size}
      freeSolo={true}
    />
  )
};

export default EquipmentSuppliersDropdown
