import { gql, useQuery  } from "@apollo/client";
import { DateRange } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_FORMS_DATE_RANGE = gql`
  query GetFormsDateRange {
    appSettings {
      recentFormsDateRange
    }
  }
`

interface GetFormsDateRangeResult {
  recentFormsDateRange: DateRange;
  recentFormsDateRangeLoading: boolean;
}

export const useGetFormsDateRange = (): GetFormsDateRangeResult => {
  const { data, loading, error } = useQuery(GET_FORMS_DATE_RANGE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  });

  if (error) {
    logger('GetFormsDateRange').error('Error fetching forms date range -->', error.message);
  }
  
  return {
    recentFormsDateRange: data?.appSettings?.recentFormsDateRange,
    recentFormsDateRangeLoading: loading
  };
};

  