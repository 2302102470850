import { ApolloQueryResult, gql, useLazyQuery } from "@apollo/client";
import { InventoryFilterInput, InventorySortInput } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";
import { InventoryResult } from "../interfaces";

export const GET_LAZY_INVENTORY_FOR_EQUIPMENT_PAGINATED = gql`
  query GetLazyInventoryForEquipmentPaginated($skip: Int, $take: Int, $filterInput: InventoryFilterInput, $order: [InventorySortInput!]) {
    lazyInventoryForEquipment: inventory(skip: $skip, take: $take, filterInput: $filterInput, order: $order) {
      items {
        id
        barcodes {
          barcodeNumber
          isDefault
        }
        productName
        manufacturer
        partNumber
        modelNumber
        categoryId
        department
        consumable
        bonded
        sms
        country
        region
        color
        size
        reOrderLevel
        reOrderAmt
        factor
        qty
        rx
        orderQty
        productDescription
        documentsCount
        photosCount
        commentsCount
        equipmentCount
        ordersCount
        totalAmount
        lowStock
        expiredAtLocation
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export const useGetLazyInventoryForEquipmentPaginated = (): InventoryResult => {
  const [getLazyInventoryForEquipment, { data, loading, error }] = useLazyQuery(GET_LAZY_INVENTORY_FOR_EQUIPMENT_PAGINATED, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const loadData = (skip: number, take: number, filterInput: InventoryFilterInput, order: InventorySortInput[]) => {
    logger('GetLazyInventoryForEquipmentPaginated').debug('Query variables', { skip, take, filterInput, order });

    getLazyInventoryForEquipment({ variables: { skip, take, filterInput, order } })
      .then((result: ApolloQueryResult<any>) => {
        if (result.data && result.data.lazyInventoryForEquipment) {
          const { items, pageInfo } = result.data.lazyInventoryForEquipment;
          logger('GetLazyInventoryForEquipmentPaginated').info('Inventory for equipoment fetched successfully', {
            recordsCount: items?.length,
            pageInfo,
          });
        } else {
          logger('GetLazyInventoryForEquipmentPaginated').warning('No data received in query result');
        }
      })
      .catch((apolloError) => {
        logger('GetLazyInventoryForEquipmentPaginated').error('Error re-fetching inventory for equipment -->', apolloError.message);
      });
  };

  if (error) {
    logger('GetLazyInventoryForEquipmentPaginated').error('Error fetching inventory for equipment -->', error.message);
  }

  return {
    data: data?.lazyInventoryForEquipment?.items || [],
    totalCount: data?.lazyInventoryForEquipment?.totalCount || 0,
    currentCount: data?.lazyInventoryForEquipment?.items?.length || 0,
    pageInfo: data?.lazyInventoryForEquipment?.pageInfo,
    loading,
    loadData,
  };
};
