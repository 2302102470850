import { gql, useQuery } from '@apollo/client';
import { Crew, QueryCrewNamesArgs } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const GET_CREW_NAMES = gql`
  query GetCrewNamesDropdown($order: [CrewSortInput!]) {
    crewNames(order: $order) {
      crewId
      first
      last
    }
  }
`;

export interface CrewType {
  crewID: string;
  name: string;
}

interface CrewResult {
  data: CrewType[];
  loading: boolean;
}

export const useGetCrewNames = ({ order }: QueryCrewNamesArgs): CrewResult => {
  const { data, loading, error } = useQuery(GET_CREW_NAMES, {
    variables: { order },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetCrewNamesDropdown').error('Error fetching crew names list for dropdown -->', error.message);
  }

  const formattedData = data?.crewNames.map((item: Crew) => ({
    crewID: item.crewId,
    name: `${item.first} ${item.last}`,
  })) || [];

  return {
    data: formattedData,
    loading,
  };
};
