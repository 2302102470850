import { gql, useQuery } from '@apollo/client';
import { DocumentRevision, FormRevision } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const GET_FORM_REVISIONS = gql`
  query GetFormRevisions($formId: String!) {
    formRevisions(formId: $formId) {
      id
      dateRevision
      lastAccessedByName
      reportFile
    }
  }
`;

export interface FormRevisionsResult {
  revisions: FormRevision[];
  revisionsLoading: boolean;
  revisionsCount: number;
}

export const useGetFormRevisions = (formId: string): FormRevisionsResult => {
  const { data, loading, error } = useQuery(GET_FORM_REVISIONS, {
    variables: { formId },
    skip: !formId,
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetFormRevisions').error(`Error fetching revisions for form: ${formId} -->`, error.message);
  }

  return {
    revisions: data?.formRevisions || [],
    revisionsLoading: loading,
    revisionsCount: data?.formRevisions.length, 
  };
};
