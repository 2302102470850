import { ApolloError, gql, useMutation } from '@apollo/client';
import { QueryEquipmentArgs } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { DeleteMutationResponse } from '../interfaces';
import { GET_EQUIPMENT_WITH_COUNTS } from './useGetEquipmentWithCounts';

export const BULK_DELETE_EQUIPMENT = gql`
  mutation BulkDeleteEquipment($equipmentIds: [String!]!) {
    bulkDeleteEquipment(equipmentIds: $equipmentIds) {
      deleteResult {
        deletedIds
        message
        success
      }
      errors {
        code
        message
      }
    }
  }
`;

interface BulkDeleteEquipmentResult {
  bulkRemoveEquipment: (equipmentIds: string[], queryVariables: QueryEquipmentArgs) => Promise<DeleteMutationResponse>;
  bulkRemoveEquipmentLoading: boolean;
}

export const useBulkDeleteEquipment = (): BulkDeleteEquipmentResult => {
  const [bulkDeleteEquipment, { loading }] = useMutation(BULK_DELETE_EQUIPMENT, {
    onError: (error: ApolloError) => {
      logger('BulkDeleteEquipment').error('Error deleting equipment -->', error.message);
    }
  });

  const bulkRemoveEquipment = async (equipmentIds: string[], refetchVariables: QueryEquipmentArgs): Promise<DeleteMutationResponse> => {
    const response = await bulkDeleteEquipment({ 
      variables: { equipmentIds },
      refetchQueries: [
        {
          query: GET_EQUIPMENT_WITH_COUNTS,
          variables: refetchVariables,
        }
      ],
    });
    
    const responseData = response.data?.bulkDeleteEquipment?.deleteResult;
    if (responseData?.success) {
      logger('BulkDeleteEquipment').info(`${equipmentIds.length > 1 ? 'Multiple' : ''} Equipment deleted successfully`, response.data);
      return {
        responseData,
        responseMessage: `${equipmentIds.length > 1 ? 'Multiple' : ''} Equipment removed successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to remove ${equipmentIds.length > 1 ? 'Multiple' : ''} Equipment!`,
      };
    }
  };

  return { 
    bulkRemoveEquipment, 
    bulkRemoveEquipmentLoading: loading, 
  };
};
