import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery  } from "@apollo/client";
import { BunkeringCollectionSegment, BunkeringFilterInput, BunkeringSortInput } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_LAZY_BUNKERING = gql`
  query GetLazyBunkering($filterInput: BunkeringFilterInput, $order: [BunkeringSortInput!]) {
    bunkering(filterInput: $filterInput, order: $order) {
      items {
        id,
        date,
        fluid,
        from,
        to,
        station,
        agent,
        start,
        stop,
        amount,
        qty,
        cost,
        curr,
        oilRecord,
        responsible,
        method,
      }
    }
  }
`

interface LazyBunkeringData {
  bunkering: BunkeringCollectionSegment;
}

interface LazyBunkeringResult {
  getLazyBunkering: LazyQueryExecFunction<LazyBunkeringData, OperationVariables>;
}

export const useGetLazyBunkering = (filterInput: BunkeringFilterInput, order: BunkeringSortInput[]): LazyBunkeringResult => {

  const [ getLazyBunkering, { error }] = useLazyQuery(GET_LAZY_BUNKERING, {
    fetchPolicy: "network-only",
    variables: { filterInput, order },
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetLazyBunkering').error('Error fetching bunkering -->', error.message);
  }

  return {
    getLazyBunkering
  };
};