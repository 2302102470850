import '../styles.css';
import { useCallback, useState, MutableRefObject, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { pick, isEmpty, isNil, isBoolean } from 'lodash';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { TypeComputedProps, TypeFilterValue, TypeSingleSortInfo, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import { exportCSV, exportExcel } from '../../../utils';
import { ExportDialogType } from 'src/consts';
import { Equipment, EquipmentFilterInput, EquipmentSortInput, SortEnumType } from 'src/generated/dotnet.graphql';
import { useGetEquipmentWithCounts } from 'src/hooks/equipment/useGetEquipmentWithCounts';
import { useGetLazyEquipment } from 'src/hooks/equipment/useGetLazyEquipment';
import LicensedReactDataGrid from '../../../components/UI/LicensedReactDataGrid';
import GridRowIcon from 'src/components/UI/LicensedReactDataGrid/components/GridRowIcon';
import { defaultFilterValue, defaultSortValue } from '..';

const filter = [
  {
    name: 'uniqueName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'manufacturer',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'modelNumber',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'serialNum',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'department',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const getSelectorByFilterName = async (
  name: string,
  value: any
): Promise<Record<string, any>> => {
  switch (name) {
    case 'uniqueName':
    case 'manufacturer':
    case 'modelNumber':
    case 'serialNum':
    case 'department': {
      return {
        [name]: value,
      };
    }
    default:
      return {};
  }
};

const transformData = (data: any[]): Record<string, Equipment> => {
  return data.reduce((acc, { id, ...rest }) => {
    acc[id] = rest;
    return acc;
  }, {});
};

type InjectedProps = {
  darken: boolean;
  onSelect: (equipment: Equipment) => void;
  skip: number;
  setSkip: (skip: number) => void;
  limit: number;
  setLimit: (limit: number) => void;
  filterValue: any;
  setFilterValue: (filterValue: any) => void;
  sortValue: any;
  setSortValue: (sortValue: any) => void;
  rowSelected: Record<string, Equipment>;
  setRowSelected: (rowSelected: Record<string, Equipment>) => void;
};

interface EquipmentGridRef {
  handleExport: (type: string) => void;
}

const EquipmentGrid = forwardRef<EquipmentGridRef, InjectedProps>(({
  onSelect,
  skip,
  setSkip,
  limit,
  setLimit,
  filterValue,
  setFilterValue,
  sortValue,
  setSortValue,
  rowSelected,
  setRowSelected,
}: InjectedProps, ref) => {
  const [gridRef, setGridRef] = useState<any>(null);
  const isMobile = useMediaQuery('(max-width: 420px)');
  const isTablet = useMediaQuery('(min-width: 700px) and (max-width: 1200px)');
  const [exportDialog, setExportDialog] = useState<ExportDialogType | null>(null);
  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [groupCollapsedStatus, setGroupCollapsedStatus] = useState(false);

  const { getLazyEquipment } = useGetLazyEquipment(filterValue, sortValue);
  const { data, totalCount, loading, loadData } = useGetEquipmentWithCounts(skip, limit, filterValue, sortValue);

  const prevFilterValue = useRef(filterValue);
  const prevSkip = useRef(skip);
  const prevLimit = useRef(limit);
  const prevSortValue = useRef(sortValue);

  useImperativeHandle(ref, () => ({
    handleExport: (type) => handleDataExport(type),
  }));

  useEffect(() => {
    const refreshButton = document.querySelector('.inovua-react-pagination-toolbar__icon--named--REFRESH');
    if (refreshButton) {
      const handleClick = () => {
        // Set default values for reset when clicking refresh button
        setFilterValue(defaultFilterValue);
        setSkip(0);
        setSortValue(defaultSortValue);
        // Reload data
        loadData(skip, limit, filterValue, sortValue);
        if (gridRef.current) {
          gridRef.current.clearAllFilters(); // Ensure all filters are cleared in the grid
        }
      };
      // Ensure the refresh button exists before adding event listener
      if (refreshButton) {
        refreshButton.addEventListener('click', handleClick);
      }
      // Cleanup the event listener on unmount or when the component changes
      return () => {
        if (refreshButton) {
          refreshButton.removeEventListener('click', handleClick);
        }
      };
    }
  }, []);

  useEffect(() => {
    const isFilterChanged = JSON.stringify(filterValue) !== JSON.stringify(prevFilterValue.current);
    const isPaginationChanged = skip !== prevSkip.current || limit !== prevLimit.current;
    const isSortChanged = JSON.stringify(sortValue) !== JSON.stringify(prevSortValue.current);

    if (isFilterChanged || isPaginationChanged || isSortChanged) {
      loadData(skip, limit, filterValue, sortValue);

      // Update refs only after the data is loaded
      prevFilterValue.current = filterValue;
      prevSkip.current = skip;
      prevLimit.current = limit;
      prevSortValue.current = sortValue;
    }
  }, [skip, limit, filterValue, sortValue]);

  const dataSource = async () => {
    return {
      data,
      count: totalCount,
    };
  };

  const onSortInfoChange = (value: TypeSortInfo) => {
    const sortInfo = value as TypeSingleSortInfo;
    if (isNil(sortInfo)) return;

    let sortPayload: EquipmentSortInput[];
    const sortDirection =
      sortInfo.dir === 1 ? SortEnumType.Asc : SortEnumType.Desc;
    const [field, subField] = sortInfo.name.split('.');

    if (subField) {
      // Handle nested objects
      sortPayload = [
        {
          [field]: {
            [subField]: sortDirection,
          },
        },
      ];
    } else {
      // Handle non-nested objects
      sortPayload = [
        {
          [sortInfo.name]: sortDirection,
        },
      ];
    }
    setSortValue(sortPayload);
  };

  const onFilterValueChange = async (filterValue: TypeFilterValue) => {
    if (isNil(filterValue)) return;

    const filterPayload: Record<string, any> = {};

    await Promise.all(
      filterValue.map(async (v: { value: any; name: any; operator: any }) => {
        if (isEmpty(v.value)) return;

        const selector = await getSelectorByFilterName(v.name, v.value);
        if (selector) {
          Object.keys(selector).forEach((key) => {
            filterPayload[key] = selector[key];
          });
        }
      })
    );
    setFilterValue(filterPayload as EquipmentFilterInput);
  };

  const onGroupByChange = (groupBy: string[]) => {
    if (isEmpty(groupBy)) return;

    let sortPayload: EquipmentSortInput[] = [];

    groupBy.forEach((sortField) => {
      const [field, subField] = sortField.split('.');

      if (subField) {
        // Handle nested objects
        sortPayload.push({
          [field]: {
            [subField]: SortEnumType.Asc,
          },
        });
      } else {
        // Handle non-nested objects
        sortPayload.push({
          [field]: SortEnumType.Asc,
        });
      }
    });
    setSortValue(sortPayload);
    gridRef.current.collapseAllGroups();
  };

  const onSelectionChange = useCallback(
    ({ selected }) => {
      if (isBoolean(selected) && selected) {
        const transformedData = transformData(data);
        setRowSelected(transformedData);
      } else {
        setRowSelected(selected);
      }
    },
    [data, rowSelected]
  );

  const onRowClick = useCallback(
    ({ data }) => {
      if (!data?.__group) {
        if (Object.keys(rowSelected || {}).length < 2) {
          onSelect(data);
        }
      }
    },
    [rowSelected]
  );

  const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    setGridRef(ref);

    // Check if ref.current exists and clearAllFilters is defined
    if (ref?.current?.clearAllFilters) {
      ref.current.clearAllFilters();
    }
  };

  const renderGroupTitle = (value: any, { data }: any) => {
    const columns = data.fieldPath.map((col: string) =>
      col === data.name ? col.toUpperCase() : col
    );
    let path = columns && columns.length && columns.join('>');
    let dataValue = data.value;
    return data.value === 'null' ? `No ${path} Assigned` : dataValue;
  };

  const CollapseButton = () => {
    if (groupCollapsedStatus) {
      setGroupCollapsedStatus(false);
      return gridRef.current.collapseAllGroups();
    }
    if (!groupCollapsedStatus) {
      setGroupCollapsedStatus(true);
      return gridRef.current.expandAllGroups();
    }
  };

  const exportData = (type: string, withFilters: boolean) => {
    setExportDialog(null);
    switch (type) {
      case 'CSV':
        return onExportToCSV(withFilters);
      case 'xlsx':
        return onExportToExcel(withFilters);
      default:
    }
  };

  const getRows = async (issue: any) => {
    const rows = issue.map((data: any) => {
      return {
        ...pick(data, [
          'uniqueName',
          'manufacturer',
          'modelNumber',
          'department',
          'serialNum',
          'hours',
        ]),
      };
    });
    return rows;
  };

  const onExportToExcel = async (withFilters: boolean) => {
    const queryResult = await getLazyEquipment({ variables: { where: withFilters ? filterValue : null, order: sortValue } });
    const data = queryResult?.data?.lazyEquipment.items || [];
    const columnsData = gridRef.current.visibleColumns.map((c: any) => ({
      header: c.header, 
      key: c.id
    }));
    const columns = columnsData.filter((item: { header: any }) => {
      return item.header && typeof item.header === 'string';
    });
    const rows = await getRows(data);
    return exportExcel(columns, rows);
  };

  const onExportToCSV = async (withFilters: boolean) => {
    const columns = gridRef.current.visibleColumns;
    const queryResult = await getLazyEquipment({
      variables: {
        where: withFilters ? filterValue : null,
        order: sortValue,
      },
    });
    const data = queryResult?.data?.lazyEquipment.items || [];
    const rows = await getRows(data);
    return exportCSV(columns, rows);
  };

  const handleDataExport = (type: string) => {
    const areFiltersActive = gridRef?.current.computedFilterValue.some((f: { value: any }) => !isEmpty(f.value));
    if (areFiltersActive) {
      setExportDialog({
        visible: true,
        type,
        title: type === 'CSV' ? 'CSV' : 'Excel',
      });

      return;
    }
    exportData(type, false);
  };

  const columns = [
    {
      id: 'icons',
      header: 'Icons',
      visible: !(isTablet || isMobile),
      defaultWidth: 100,
      render: ({ data }: any) => (
        <GridRowIcon
          hasAttachments={data.documentsCount > 0}
          hasPhotos={data.photosCount > 0}
          hasComments={data.commentsCount > 0}
          isCritical={data.sms}
          hasTasks={data.workIssuesCount > 0}
        />
      ),
      onRender: (cellProps: any, { data }: any) => {
        if (data.hasOverDueTasks) {
          cellProps.style.borderLeft = 'red 3px solid';
        }
      },
    },
    {
      name: 'uniqueName',
      header: 'Equipment Name',
      defaultFlex: 1,
    },
    {
      name: 'manufacturer',
      header: 'Manufacturer',
      defaultFlex: 0.75,
      visible: !isMobile,
    },
    {
      name: 'modelNumber',
      header: 'Model Number',
      defaultFlex: 0.75,
      visible: !isMobile,
    },
    {
      name: 'serialNum',
      header: 'Serial Number',
      defaultFlex: 0.75,
      visible: !isMobile,
    },
    {
      name: 'department',
      header: 'Department',
      defaultFlex: 0.5,
      visible: !isMobile,
    },
    {
      name: 'hours',
      header: 'Hours',
      defaultFlex: 0.4,
      visible: !(isTablet || isMobile),
      headerAlign: 'start' as any,
      textAlign: 'end' as any,
      render: ({ data }: any) => (data.countHours ? data.hours : null),
    },
  ];

  return (
    <div data-testid="data-grid" className="flex flex-col flex-grow">
      <div className="flex flex-row items-center justify-end">
        {!isEmpty(gridRef?.current.computedGroupBy) &&
          (groupCollapsedStatus ? (
            <div>
              <Tooltip title="Collapse All">
                <IconButton onClick={CollapseButton}>
                  <UnfoldLessIcon />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <div>
              <Tooltip title="Expand All">
                <IconButton onClick={CollapseButton}>
                  <UnfoldMoreIcon />
                </IconButton>
              </Tooltip>
            </div>
          ))}
      </div>

      <LicensedReactDataGrid
        onReady={onReady}
        loading={loading}
        columns={columns}
        pagination="remote"
        idProperty="id"
        dataSource={dataSource}
        enableSelection
        checkboxColumn
        skip={skip}
        onSkipChange={setSkip}
        limit={limit}
        onLimitChange={setLimit}
        onRowClick={onRowClick}
        selected={rowSelected}
        onSelectionChange={onSelectionChange}
        onSortInfoChange={onSortInfoChange}
        defaultFilterValue={filter}
        onFilterValueChange={onFilterValueChange}
        defaultGroupBy={[]}
        onGroupByChange={onGroupByChange}
        collapsedGroups={collapsedGroups}
        onGroupCollapseChange={setCollapsedGroups}
        renderGroupTitle={renderGroupTitle}
        sourceRoot={true}
        // pageSizes={[10, 50, 100, 500, 1000, totalCount]}
      />

      <Dialog maxWidth="xs" open={exportDialog?.visible || false}>
        <DialogTitle>{`Export data to ${exportDialog?.title}`}</DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            You have filters applied. Would you like to export with current
            filters?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            autoFocus
            onClick={() =>
              exportDialog && exportData(exportDialog?.type, false)
            }
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() =>
              exportDialog && exportData(exportDialog?.type, true)
            }
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    );
  }
);

export default EquipmentGrid;
