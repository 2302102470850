import { FC, useEffect, useState } from 'react';
import { isArray, isNil, isEmpty } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeView } from '@mui/lab';
import MenuItems, { NavConfig } from './navigation';
import {
  TblDocumentation,
  TblSystemsHierarchy,
} from '../../../generated/graphql';
import SidebarMenuItem from './components/MenuItem';
import { useAppState } from 'src/contexts/app-state';
interface InjectedProps {
  onClick: () => void;
}

interface DocumentationWithCategory extends TblDocumentation {
  category?: TblSystemsHierarchy;
}

const SidebarMenu: FC<InjectedProps> = ({ onClick }) => {
  const history = useHistory();
  const location = useLocation();
  const [nodeIds, setNodeIds] = useState([]);
  const [selected, setSelected] = useState<any>(null);
  const { settingsPersonal } = useAppState();
  const [navigationTree, setNavigationTree] = useState<NavConfig[]>(MenuItems);

  const getDynamicFormItems = async () => {

    const nodes: NavConfig[] = [];

    // nodes.push({
    //   label: 'Settings',
    //   node: 'Settings',
    //   children: [
    //     {
    //       path: RoutePath.SETTINGS_YMS_SETTINGS,
    //       node: 'yms-settings',
    //       label: 'YMS Settings',
    //       hide: false,
    //     },
    //     {
    //       path: RoutePath.SETTINGS_LIST_UTILITY,
    //       node: 'list-utility',
    //       label: 'List Utility',
    //       hide: false,
    //     },
    //     {
    //       path: RoutePath.SETTINGS_CATEGORY_UTILITY,
    //       node: 'systems-hierarchy',
    //       label: 'Category Utility',
    //       hide: false,
    //     },
    //     {
    //       path: RoutePath.SETTINGS_LOCATIONS_UTILITY,
    //       node: 'locations-hierarchy',
    //       label: 'Locations Utility',
    //       hide: false,
    //     },
    //     {
    //       path: RoutePath.RUNNING_LOGS_GROUPS_UTILITY,
    //       node: 'running-logs-groups',
    //       label: 'Running Log Utility',
    //       hide: false,
    //     },
    //     {
    //       path: RoutePath.SETTINGS_RECYCLE_BIN,
    //       node: 'recycle-bin',
    //       label: "Recycle Bin"
    //     },
    //     {
    //       path: RoutePath.REPORT_ISSUES,
    //       node: 'report-issues',
    //       label: "Report Issues",
    //       external: true,
    //     }
    //   ]
    // })

    const updatedMenuItem = {
      label: 'main',
      children: [...MenuItems, ...nodes],
    };
    if (settingsPersonal) {
      handleNavMenuTree(updatedMenuItem);
    }
    if (settingsPersonal && settingsPersonal.fldShowCurrentTasks) {
      const taskTab = updatedMenuItem.children.find(
        (item) => item.node === 'Tasks',
      );
      if (taskTab?.children) {
        const currentTaskTab = taskTab.children.find(
          (item) => item.node === 'CurrentTasks',
        );
        if (!currentTaskTab) {
          taskTab.children.push({
            node: 'CurrentTasks',
            label: 'Current Tasks',
            path: '/tasks/current',
          });
        }
      }
    }

    // Make sure we start from scratch otherwise list can be with duplicated items.
    setNavigationTree(updatedMenuItem.children);
    const routeData = findInTree(location.pathname, { path: "root", children: updatedMenuItem.children })
    setNodeIds(routeData.path);
    setSelected(location.pathname)
  };

  const handleStatusModule = (navMenuItem: any) => {
    switch (navMenuItem.node) {
      case 'Documentation Libraries':
        if (!settingsPersonal.fldCDM) {
          return true;
        }
        return false;
      case 'Logs':
        if (!settingsPersonal.fldLogs) {
          return true;
        }
        return false;
      case 'Tasks':
        if (!settingsPersonal.fldWL) {
          return true;
        }
        return false;
      case 'Events & Schedules':
        if (!settingsPersonal.fldScheduling) {
          return true;
        }
        return false;
      default:
        return false;
    }
  };

  const handleStatusTree = (navMenuItem: any) => {
    if (settingsPersonal.fldNoRights) {
      // if (isNil(navMenuItem.path) || navMenuItem.node === 'hor') { // TD-1301 show hours of rest based on settingsPersonal.fldCrew
      if (isNil(navMenuItem.path)) {
        return false;
      }
      return true;
    }

    switch (navMenuItem.node) {
      case 'Equipment':
        if (!settingsPersonal.fldEQ) {
          return true;
        }
        break;
      case 'General Inventory':
        if (!settingsPersonal.fldInv) {
          return true;
        }
        break;
      case 'Beverages':
        if (!settingsPersonal.fldInvBev) {
          return true;
        }
        break;
      case 'Uniform':
        if (!settingsPersonal.fldInvUni) {
          return true;
        }
        break;
      case 'Dry & Cold Stores':
        if (!settingsPersonal.fldInvStores) {
          return true;
        }
        break;
      case 'Medical':
        if (!settingsPersonal.fldInvMed) {
          return true;
        }
        break;
      case 'Purchasing':
        if (!settingsPersonal.fldPO) {
          return true;
        }
        // return false;
        break;
      case 'Certificates & Documents':
      case 'CrewCertificates':
      case 'Certificates':
        if (!settingsPersonal.fldCerts) {
          return true;
        }
        break;
      case 'guests':
        if (!settingsPersonal.fldGuest) {
          return true;
        }
        break;
      case 'profiles':
        if (!settingsPersonal.fldCrew) {
          return true;
        }
        break;
      case 'hor':
        if (!settingsPersonal.fldCrewEmp) {
          return true;
        }
        break;
      case 'yms-settings':
          if (!settingsPersonal.fldTritonSettings) {
            return true;
          }
          break;
      case 'systems-hierarchy':
        if (!settingsPersonal.fldAddCats) {
          return true;
        }
        break;
      case 'locations-hierarchy':
        if (!settingsPersonal.fldAddlocs) {
          return true;
        }
        break;
      case 'running-logs-groups':
        if (settingsPersonal.fldLogsRL != 2) {
          return true;
        }
        break;
      case 'Running Logs':
        if (!settingsPersonal.fldLogsRL) {
          return true;
        }
        break;
      case `Engineer's Day Log`:
        if (!settingsPersonal.fldLogsDay) {
          return true;
        }
        break;  
      case 'SMS Forms':
        if (!settingsPersonal.fldSMS) {
          return true;
        }
        break;
      case 'ISPS Forms':
        if (!settingsPersonal.fldISPS) {
          return true;
        }
        break;
      case 'LibraryCertificates':
        if (!settingsPersonal.fldCerts) {
          return true;
        }
        break;
      case 'Maintenance History':
        if (!settingsPersonal.fldLogsMaint) {
          return true;
        }
        break;
      case 'Bunker / Transfer Logs':
        if (!settingsPersonal.fldLogsXfer) {
          return true;
        }
        break;
      case 'equipment-schedules':
      case 'event-scheduling':
      case 'drill-scheduling':
      case 'inspections':
        if (!settingsPersonal.fldScheduling) {
          return true;
        } 
        break;   
      default:
        break;
    }
    return false;
  };

  const findInTree = (id: string, tree: any) => {
    if (tree.path === id) {
      let path = [tree.node];
      return { result: tree, path };
    } else {
      if (tree.children) {
        for (let child of tree.children) {
          let tmp: any = findInTree(id, child);
          if (!isEmpty(tmp)) {
            tmp.path.unshift(tree.node);
            return tmp;
          }
        }
      }
      return {};
    }
  };

  const handleNavMenuTree = (navMenuItem: any) => {
    if (isNil(navMenuItem)) {
      return;
    }

    if (!isNil(navMenuItem.path)) {
      navMenuItem.hide = handleStatusTree(navMenuItem);
    } else {
      const hideFlag = handleStatusModule(navMenuItem);
      if (hideFlag) {
        navMenuItem.hide = true;
      }
    }

    if (isArray(navMenuItem.children)) {
      navMenuItem.children.forEach((item: any) => handleNavMenuTree(item));
    }
  };


  useEffect(() => {
    if (settingsPersonal) {
      getDynamicFormItems();
    }
  }, [settingsPersonal]);

  const handleNavigationItemClick = (node: NavConfig) => {
    // console.log(node.path);
    if (node?.external && node.label === 'Report Issues') {
      window.open(node.path, '_blank');
      return
    };
    setSelected(node.path);
    onClick();
 
    if (isNil(node.path)) return;

    history.push(node.path);
  };

  const renderTree = (node: NavConfig) => {
    if (node?.hide) {
      return null;
    }

    if (node?.children && node.children.length > 0) {
      const data = JSON.stringify(node.children);
      if (!data.includes('"hide":false') || !data.includes('hide')) {
        return null;
      }
    }
    return (
      <SidebarMenuItem
        style={selected === node.path ? { backgroundColor: '#596E83' } : {}}
        key={node.label}
        nodeId={node.label}
        label={node.label}
        expandable={isNil(node.path)}
        onMenuItemClick={() => handleNavigationItemClick(node)}
        url={node.path}
      >
        {isArray(node.children) ? node.children.map(renderTree) : null}
      </SidebarMenuItem>
    );
  };

  return (
    <TreeView
      aria-label="Main side navigation"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      selected={selected}
      onNodeToggle={(evt: any, nodeids: any) => setNodeIds(nodeids)}
      expanded={nodeIds}
    >
      {navigationTree.map(renderTree)}
    </TreeView>
  );
};

export default SidebarMenu;
