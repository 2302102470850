import { FC, useState } from 'react';
import { Button, DialogTitle, DialogActions, DialogContent, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CustomDialog } from 'src/helpers/modals';
import AddInventoryToLocationGrid from './AddInventoryToLocationGrid';
import { InventoryType } from 'src/generated/dotnet.graphql';

interface InjectedProps {
  excludedIds: string[];
  inventoryType: InventoryType;
  visible: boolean;
  onSave: (inventoryIds: string[]) => void;
  onCancel?: () => void;
}

const AddInventoryToLocationDialog: FC<InjectedProps> = ({
  excludedIds,
  inventoryType,
  visible,
  onCancel,
  onSave,
}) => {
  const [selectedInventoryIds, setSelectedInventoryIds] = useState<string[]>([]);

  const handleSave = async () => {
    if (!selectedInventoryIds) return;
    onSave(selectedInventoryIds);
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleSelect = (inventoryIds: string[]) => {
    setSelectedInventoryIds(inventoryIds);
  };

  return (
    <>
      <CustomDialog
        scroll="paper"
        fullWidth
        maxWidth="md"
        open={visible}
        onClose={handleCancel}
      >
        <DialogTitle>
          <span className="font-bold text-2xl">Add Inventory to Location</span>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          <AddInventoryToLocationGrid
            excludeIds={excludedIds}
            inventoryType={inventoryType}
            onSelect={handleSelect}
          />
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          <Box
            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
          >
            <Button className="mr-2" onClick={handleCancel}>
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              onClick={handleSave}
              variant="contained"
            >
              Save
            </LoadingButton>
          </Box>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export default AddInventoryToLocationDialog;
