import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { isNil, last, split, upperCase } from "lodash";
import PSPDFKitWeb, { Instance } from "pspdfkit";
import { AppBar, Button, Dialog, Toolbar, Slide } from "@mui/material";
import { IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";
import { DOWNLOAD_URL, FileExtensionType, PSPDF_LICENSE } from "../../../../consts";
import FileViewer from "src/components/FileViewer";
import { DocxFileType } from "src/components/FileViewer/FileViewerDocx";
import { DocumentRevision, Form } from "src/generated/dotnet.graphql";
import { ExcelFileType } from "src/components/FileViewer/FileViewerExcel";

interface InjectedProps {
  visible: boolean;
  onRequestClose: () => void;
  onFileSave: (fileData: ArrayBuffer | DocxFileType | ExcelFileType) => void;
  form: Form | null;
  document: ArrayBuffer | null;
}

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

const FormPDFDialog: FC<InjectedProps> = ({
  visible,
  onRequestClose,
  form,
  onFileSave,
  document,
}) => {
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const pdfInstance = useRef<Instance | null>(null);
  const viewerRef = useRef<any>(null);

  const getRemoteDocument = async (): Promise<ArrayBuffer> => {
    const pdfResponse = await fetch(`${DOWNLOAD_URL}/${form!.reportFile}`);
    return pdfResponse.arrayBuffer();
  };

  const loadPdf = async () => {
    
    if (isNil(form?.reportFile) || isNil(containerRef)) return;
    
    const documentBuffer = (isNil(document) || document.byteLength === 0) // TD-2380 fix
    ? await getRemoteDocument()
    : document;

    try {
      pdfInstance.current = await PSPDFKitWeb.load({
        document: documentBuffer,
        licenseKey: PSPDF_LICENSE,
        container: containerRef as HTMLElement,
        // Our PDF Library is hosted under public directory.
        // TODO: Add post install step.
        baseUrl: `${window.location.protocol}//${window.location.host}/`,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const unloadPdf = () => {
    if (!isNil(pdfInstance.current) || !isNil(containerRef)) {
      PSPDFKitWeb.unload(pdfInstance.current || (containerRef as HTMLElement));
    }

    pdfInstance.current = null;
    setContainerRef(null);
  };

  const onRef = (ref: HTMLDivElement | null) => {
    setContainerRef(ref);
  };

  const onClose = () => {
    onRequestClose();
    unloadPdf();
  };

  const handleSaveClick = async () => {
    if (ext === FileExtensionType.PDF) {
      await pdfInstance.current?.save();
      const buffer = await pdfInstance.current?.exportPDF();
      if (isNil(buffer)) return;
      onFileSave(buffer!);
    } else {
      const result = await viewerRef.current?.save();
      if (isNil(result)) return;
      onFileSave(result!);
    }
    onClose();
  };

  useEffect(() => {
    if (!visible || isNil(containerRef)) return;

    loadPdf();

    return () => {
      unloadPdf();
    };
  }, [form?.reportFile, visible, containerRef, document]);

  const ext = useMemo(
    () => upperCase(last(split(form?.reportFile, "."))),
    [form?.reportFile]
  );
  
  return (
    <Dialog
      fullScreen
      open={visible}
      onClose={onClose}
      TransitionComponent={Transition as any}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="div" className="w-full">
            {form?.reportType}
          </Typography>
          <Button onClick={handleSaveClick} autoFocus color="inherit">
            Save
          </Button>
        </Toolbar>
      </AppBar>

      {ext === FileExtensionType.PDF ? (
        <div ref={onRef} className="w-full h-full" />
      ) : (
        form?.reportFile && (
          <FileViewer
            url={`${DOWNLOAD_URL}/${form.reportFile}`}
            ref={viewerRef}
            form={form}
          />
        )
      )}
    </Dialog>
  );
};

export default FormPDFDialog;
