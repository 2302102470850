import { FC } from 'react';
import Button from '@mui/material/Button';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InventoryForEquipmentGrid from './InventoryForEquipmentGrid';
import { Inventory } from 'src/generated/dotnet.graphql';

interface InjectedProps {
  inventoryForEquipment: Inventory[];
  isVisible: boolean;
  onCancelInventoryDialog: any;
  onSelect: (inventory: Inventory) => void;
  handleSearchMore: any;
}

const InventoryForEquipmentDialog: FC<InjectedProps> = ({
  inventoryForEquipment,
  isVisible,
  onCancelInventoryDialog,
  onSelect,
  handleSearchMore,
}) => (
  <Dialog
    open={isVisible}
    onClose={onCancelInventoryDialog}
    fullWidth
    maxWidth="md"
    scroll="paper"
    aria-labelledby="scroll-dialog-title"
    aria-describedby="scroll-dialog-description"
  >
    <DialogTitle sx={{ m: 0, p: 4 }} style={{ fontSize: '26px' }}>
      <span className="ml-2">Select Spare</span>
      {onCancelInventoryDialog ? (
        <IconButton
          aria-label="close"
          onClick={onCancelInventoryDialog}
          sx={{
            position: 'absolute',
            right: 10,
            top: 14,
            color: (theme) => theme.palette.grey[400],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
    <DialogContent dividers>
      <span className="ml-2 mb-2"> To select spare click/tap on row</span>
      <InventoryForEquipmentGrid
        inventoryForEquipment={inventoryForEquipment}
        onSelect={onSelect}
      />
    </DialogContent>
    <DialogActions sx={{ m: 0, p: 3 }}>
      <Button onClick={onCancelInventoryDialog} className="w-32">
        Cancel
      </Button>

      <Button
        onClick={handleSearchMore}
        className="flex justify-end ml-4 mr-3"
        variant="contained"
      >
        Show all inventory
      </Button>
    </DialogActions>
  </Dialog>
);

export default InventoryForEquipmentDialog;
