import { gql, useQuery } from '@apollo/client';
import { InventoryFilterInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { CategoriesResult } from '../interfaces';

export const GET_INVENTORY_COUNTS_BY_CATEGORY = gql`
  query GetInventoryCountsByCategory($filterInput: InventoryFilterInput) {
    inventoryCountsByCategory(filterInput: $filterInput) {
      count
      item {
        id
        category
        depts
        noView
        tags
        parentId
      }
    }
  }
`;

export const useGetInventoryCountsByCategory = (filterInput: InventoryFilterInput): CategoriesResult => {
  const { data, loading, error, refetch } = useQuery(GET_INVENTORY_COUNTS_BY_CATEGORY, {
    variables: { filterInput },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetInventoryCountsByCategory').error('Error fetching categories -->', error.message);
  }

  return {
    recordCountsByCategory: data?.inventoryCountsByCategory || [],
    refetchRecordCountsByCategory: refetch,
    loading,
  };
};
