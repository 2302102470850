import { gql, useQuery } from '@apollo/client';
import { BunkeringFilterInput, GroupCountOfString } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const GET_BUNKERING_COUNTS_BY_FLUID_TYPE = gql`
  query GetBunkeringCountsByFluidType($filterInput: BunkeringFilterInput) {
    bunkeringCountsByFluidType(filterInput: $filterInput) {
      count
      item
    }
  }
`;

export interface BunkeringGroupCountsByFluidTypeResult {
  bunkeringCountsByFluidType: GroupCountOfString[];
  refetchBunkeringCountsByFluidType: any;
  loading: boolean;
}

export const useGetBunkeringCountsByFluidType = (filterInput: BunkeringFilterInput): BunkeringGroupCountsByFluidTypeResult => {
  const { data, loading, error, refetch } = useQuery(GET_BUNKERING_COUNTS_BY_FLUID_TYPE, {
    variables: { filterInput },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetBunkeringCountsByFluidType').error('Error fetching Bunkering Fluid Types -->', error.message);
  }

  return {
    bunkeringCountsByFluidType: data?.bunkeringCountsByFluidType || [],
    refetchBunkeringCountsByFluidType: refetch,
    loading,
  };
};
