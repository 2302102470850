// Styles
import './styles.css';
import { Box, DialogTitle, DialogContent, DialogActions, Button, Dialog, Alert, Snackbar, AlertColor } from '@mui/material';
// React components
import { useEffect, useState, useRef, useMemo } from 'react';
// YMS components
import BunkeringGrid from './component/BunkeringGrid';
import BunkeringDetailForm from '../../components/PageDrawer/Bunkering/BunkeringDetailForm';
import PageDrawer from '../../components/PageDrawer';
import PageHeader from '../../components/UI/PageHeader';
import MasterSearchInput from 'src/components/MasterSearchInput';
import DeleteRecordBtn from 'src/components/DeleteRecordsButton';
// GraphQL
import { useAuth } from '../../contexts/auth';
import { useAppState } from 'src/contexts/app-state';
import { Bunkering, BunkeringFilterInput, DeleteResult, RecordType, SortEnumType } from 'src/generated/dotnet.graphql';
import { useBulkDeleteBunkering } from 'src/hooks/bunkering/useBulkDeleteBunkering';
// Utils
import { saveViewMode, getViewMode, isEmptyObject } from '../../utils/'
import { GRID_LIMIT, GridType, SEVERITY } from 'src/consts';
import { AccessType, getAccessType } from 'src/utils/permissions';
import { MasterSearchInputValue } from "src/components/MasterSearchInput";
import useMergedFilters from 'src/hooks/common/useMergedFilters';
import ViewSelector from 'src/components/ViewSelector';
import BunkeringFluidTypeViewGrid from './component/BunkeringFluidTypeViewGrid';

export const defaultFilterValue = {
  sample: undefined,
  searchText: undefined
};

export const defaultSortValue = { 
  date: SortEnumType.Desc
};

const BunkeringPage = () => {
  // Global state
  const { user } = useAuth();
  const { settingsPersonal } = useAppState();
  // Grid pagination state - keep it on parent component so it can be used for mutations refetching
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(GRID_LIMIT);
  const [masterSearchInput, setMasterSearchInput] = useState<MasterSearchInputValue | null>(null);
  const [filterValue, setFilterValue] = useMergedFilters<BunkeringFilterInput>(masterSearchInput, defaultFilterValue);
  const [sortValue, setSortValue] = useState<any>(defaultSortValue);
  const [rowSelected, setRowSelected] = useState<Record<string, Bunkering>>({});
  // Apollo client mutations
  const { bulkRemoveBunkering } = useBulkDeleteBunkering();
  // Component related state
  const gridRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const oldSelectedItem = useRef<Bunkering | null>(null);
  const [viewType, setViewType] = useState<GridType>(GridType.DEFAULT);
  const [selectedBunkering, setSelectedBunkering] = useState<Bunkering>();
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [refetchTree, setRefetchTree] = useState<boolean>(false);
  const [savedViewList, setSavedViewList] = useState<any>([]);
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();
  const refetchQueryVariables = { skip: skip, take: limit, filterInput: filterValue, order: sortValue };
  const moduleReadOnly = getAccessType(settingsPersonal?.fldLogsXfer) !== AccessType.FULL_ACCESS;

  const init = async () => {
    const mode = getViewMode();
    if (mode) setViewType(mode);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    renderContent();
  }, [masterSearchInput]);

  const handleCreateClick = async () => {
    setIsCreate(true);
    const bunkering: any = {
      fldDate: new Date().toISOString(),
    };

    setSelectedBunkering(bunkering);
  };

  const handleSave = async (responseData: Bunkering, responseMessage: string, isCreated: boolean) => {
    setSelectedBunkering(responseData);
    oldSelectedItem.current = responseData;

    if (isCreated) {
      setIsCreate(false);
    }

    if (viewType !== 'Grid') {
      setRefetchTree(!refetchTree);
      renderContent();
    }

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const handleDelete = async (responseData: DeleteResult, responseMessage: string) => {
    if (responseData.success) {
      setSelectedBunkering(undefined);
    }

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });

    if (viewType !== 'Grid') {
      setRefetchTree(!refetchTree);
      renderContent();
    }
  };

  const handleDeleteMultipleRecords = async (validRecords: string[], invalidMessage: string) => {
    if (invalidMessage) {
      setSnackbar({
        message: invalidMessage,
        severity: 'error',
      });
    }

    if (validRecords.length > 0) {
      const { responseData, responseMessage } = await bulkRemoveBunkering(validRecords, refetchQueryVariables);

      setRowSelected({});

      setSnackbar({
        message: responseMessage,
        severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
      });

      if (viewType !== 'Grid') {
        setRefetchTree(!refetchTree);
        renderContent();
      }
    }
  };

  const handleCancel = () => {
    setIsCreate(false);
    setSelectedBunkering(undefined);
    oldSelectedItem.current = null;
  };

  const onSelect = async (bunkering: Bunkering) => {
    setSelectedBunkering(bunkering);
    oldSelectedItem.current = bunkering;
  };

  const isTableOriginal = useMemo(() => {
    if (viewType === GridType.CATEGORY || viewType === GridType.DEFAULT || viewType === GridType.LOCATION) {
      return true;
    } else {
      return false;
    }
  }, [viewType])

  const renderContent = () => {
    let gridType = viewType;
    if (!isTableOriginal) {
      const savedViewItem = savedViewList.find((item: any) => item.pkey === gridType);
      if (savedViewItem) {
        gridType = JSON.parse(savedViewItem.fldLayout).mode;
      }
    }
    switch (viewType) {
      case GridType.FLUID:
        return (
          <div
            style={{ maxHeight: containerRef.current?.clientHeight || 0 }}
            className="flex-grow h-full w-full flex overflow-y-auto"
          >
            <BunkeringFluidTypeViewGrid
              masterSearchInput={masterSearchInput} 
              onChange={onSelect} 
              refetchTree={refetchTree} 
            />
          </div>
        );
      default:
        return (
          <div className="flex flex-grow h-full">
            <BunkeringGrid
              darken 
              onSelect={onSelect}
              ref={gridRef} 
              skip={skip}
              setSkip={setSkip}
              limit={limit}
              setLimit={setLimit}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              sortValue={sortValue}
              setSortValue={setSortValue} 
              rowSelected={rowSelected}
              setRowSelected={setRowSelected}
            />
          </div>
        );
    }
    
  };

  const handleDataExport = (type: string) => {
    gridRef?.current.handleExport(type);
  }

  const renderView = () => (
    <ViewSelector
      defaultOptions={[GridType.DEFAULT, GridType.FLUID]}
      contentType={viewType}
      setContentType={setViewType}
      saveViewMode={saveViewMode}
      onSavedViewList={setSavedViewList}
      onDataExport={handleDataExport}
    />
  );

  const onBunkeringSearch = (isReset: boolean, searchValue: MasterSearchInputValue) => {
    if(isReset) {
      setMasterSearchInput(null);
    }
    else {
      setMasterSearchInput(searchValue);
    }
  };

  const renderMasterSearch = () => (
    <MasterSearchInput
      recordType={'BUNKERING' as RecordType}
      label="Bunkering search"
      onChange={onBunkeringSearch}
    />
  )

  return (
    <div className="flex flex-grow flex-col overflow-auto">
      <PageHeader
        title="Bunkering Logs"
        actions={!moduleReadOnly ? 
          [
            {
              customComponent: (
                <DeleteRecordBtn
                  visible={isEmptyObject(rowSelected)}
                  records={rowSelected}
                  onValidate={handleDeleteMultipleRecords}
                />
              ),
              disabled: !(settingsPersonal?.fldLogsMaint)
            },
            {
              icon: 'add',
              title: 'New entry',
              onClick: handleCreateClick,
            },
          ] : []
        }
        renderView={renderView}
        renderMasterSearch={renderMasterSearch}
      >
        <div className="flex md:hidden">{renderView()}</div>
      </PageHeader>

      <div ref={containerRef} className="flex flex-grow flex-col w-full">
        {renderContent()}
      </div>

      <PageDrawer onClose={handleCancel} visible={!!selectedBunkering}>
        {!!selectedBunkering && (
          <BunkeringDetailForm
            initialValue={selectedBunkering}
            isCreate={isCreate}
            onSave={handleSave}
            onDelete={handleDelete}
            onCancel={handleCancel}
            refetchQueryVariables={refetchQueryVariables}
            moduleReadOnly={moduleReadOnly}
          />
        )}
      </PageDrawer>

      <Dialog scroll="paper" fullWidth maxWidth="md" open={false}>
        <DialogTitle>
          <span className="font-bold text-2xl">Completion cancelled</span>
        </DialogTitle>
        <DialogContent>
          <p>Dialog has been closed.</p>
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          <Box
            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
          >
            <Button variant="contained">OK</Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </div>
  );
};

export default BunkeringPage;