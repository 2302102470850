import React, { FC } from "react";
import {
  Control,
  RegisterOptions,
  useController,
} from "react-hook-form";
import { OverridableStringUnion } from "@mui/types";
import {
  FormControl,
  FormHelperText,
  TextFieldPropsSizeOverrides,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

interface InjectedProps {
  control: Control;
  name: string;
  rules?: RegisterOptions;
  label?: string;
  size?: OverridableStringUnion<
    "small" | "medium",
    TextFieldPropsSizeOverrides
  >;
  options: any[];
  onChange: (name: string, value: any) => void;
  keyExpr?: string;
  fullWidth?: boolean;
  displayExpr: string;
  freeSolo?: boolean;
}

const MultiSelect: FC<InjectedProps> = ({
  control,
  name,
  onChange,
  rules,
  label,
  keyExpr,
  displayExpr,
  options,
  size = "small",
  fullWidth = true,
  freeSolo = true,
}) => {
  const {
    field: { value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const handleChange = async (event: any, newValue: any) => {
    if (newValue && newValue.inputValue) {
      onChange(name, {
        ...newValue,
        [name]: newValue.inputValue,
      });
    } else {
      onChange(name, newValue);
    }
  };

  return (
    <FormControl
      size={size}
      required={!!rules?.required}
      fullWidth={fullWidth}
      error={!!error}
    >
      <Autocomplete
        multiple
        id={keyExpr}
        value={value}
        options={options.map((option) => option[displayExpr])}
        onChange={handleChange}
        freeSolo={freeSolo}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => {
            return (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            );
          })
        }
        renderInput={(params) => <TextField {...params} label={label} autoComplete='off' size={size} />}
      />
      {error && (
        <FormHelperText error={!!error}>{error?.message}</FormHelperText>
      )}
    </FormControl>
  );
};

export default MultiSelect;
