import { ApolloError, gql, useMutation } from '@apollo/client';
import { Equipment, EquipmentInput, QueryEquipmentArgs, UserError } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { MutationResponse } from '../interfaces';
import { GET_EQUIPMENT_WITH_COUNTS } from './useGetEquipmentWithCounts';

export const UPSERT_EQUIPMENT = gql`
  mutation UpsertEquipment($input: EquipmentInput!) {
    upsertEquipment(input: $input) {
      equipment {
        id
        uniqueName
        manufacturer
        modelNumber
        department
        hours
        hoursOffset
        countHours
        categoryId
        locationId
        reportingTag
        restricted
        sms
        notes
        supplier
        inService
        expireDate
        serialNum
        arrangementNum
        artNumber
        dateEntered
        rating
        parentId
      }
      errors {
        code
        message
      }
    }
  }
`;

interface UpsertEquipmentResult {
  upsertEquipment: (input: EquipmentInput, isCreate: boolean, queryVariables?: QueryEquipmentArgs) => Promise<MutationResponse<Equipment, UserError>>;
  upsertEquipmentLoading: boolean;
  upsertEquipmentError?: ApolloError;
}

export const useUpsertEquipment = (): UpsertEquipmentResult => {
  const [AddOrUpdateEquipment, { loading, error }] = useMutation(UPSERT_EQUIPMENT, {
    onError: (error: ApolloError) => {
      logger('UpsertEquipment').error(`Error upserting equipment -->`, error.message);
    }
  });

  const upsertEquipment = async (input: EquipmentInput, isCreate: boolean, queryVariables?: QueryEquipmentArgs): Promise<MutationResponse<Equipment, UserError>> => {
    const response = await AddOrUpdateEquipment({ 
      variables: { input },
      refetchQueries: [{
        query: GET_EQUIPMENT_WITH_COUNTS,
        variables: queryVariables
      }],
    })

    const responseData = response.data?.upsertEquipment?.equipment;
    if (responseData) {
      logger('UpsertEquipment').info(`Equipment ${isCreate ? 'added' : 'updated'} successfully`, response.data);
      return {
        responseData,
        responseMessage: `Equipment ${isCreate ? 'added' : 'updated'} successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to ${isCreate ? 'add' : 'update'} equipment!`,
      };
    }
  };

  return { 
    upsertEquipment, 
    upsertEquipmentLoading: loading, 
    upsertEquipmentError: error,
  };
};