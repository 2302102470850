import { FC, useCallback, useState } from 'react';
import { useForm  } from 'react-hook-form';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Alert, Snackbar, AlertColor } from '@mui/material';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { SEVERITY, UpdateRecordInputType } from 'src/consts';
import { isObject, last } from 'lodash';
import { BulkModifyInventoryLocationInput, InventoryLocation, InventoryLocationInput, InventoryType, RecordType } from 'src/generated/dotnet.graphql';
import { useAppState } from 'src/contexts/app-state';
import { isEmptyObject, isNotNil } from 'src/utils';
import LocationDropdown from 'src/components/Dropdowns/LocationDropdown';
import { DeleteTwoTone, MoveUpTwoTone } from '@mui/icons-material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import { useGetLazyInventoryAtLocation } from 'src/hooks/inventory/useGetLazyInventoryAtLocation';
import { logger } from 'src/helpers/logger';
import { useBulkDeleteInventoryFromLocation } from 'src/hooks/inventory/useBulkDeleteInventoryLocation';
import WarningDialog from 'src/components/UI/WarningDialog';
import MoveInventoryToLocationDialog from './MoveInventoryToLocationDialog';
import { useBulkMoveInventoryToLocation } from 'src/hooks/inventory/useBulkMoveInventoryToLocation';
import { useBulkAddInventoryToLocation } from 'src/hooks/inventory/useBulkAddInventoryToLocation';
import AddInventoryToLocationDialog from './AddInventoryToLocationDialog';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useUpdateInventoryLocation } from 'src/hooks/inventory/useUpdateInventoryLocation';

interface InjectedProps {
  visible: boolean;
  inventoryType: InventoryType;
  inventoryTypeTitle: string;
  onCancel?: () => void;
  onSubmit: () => void;
  saveLoading: boolean;
}

const InventoriesToLocationDialog: FC<InjectedProps> = ({
  visible,
  inventoryType,
  inventoryTypeTitle,
  onCancel,
  onSubmit,
}) => {
  const { control, reset, formState: { errors } } = useForm<any>({ mode: 'onBlur', shouldFocusError: true });
  const { settingsPersonal } = useAppState();

  const [rowSelected, setRowSelected] = useState<any>({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [isMoveDuplicate, setIsMoveDuplicate] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [inventoryAtLocation, setInventoryAtLocation] = useState<InventoryLocation[]>([])
  const [selectedLocationId, setSelectedLocationId] = useState<string | null>(null);
  const { getLazyInventoryAtLocation, loading } = useGetLazyInventoryAtLocation();
  const { bulkAddInventoryToLocation, bulkAddInventoryToLocationLoading} = useBulkAddInventoryToLocation();
  const { bulkRemoveInventoryFromLocation } = useBulkDeleteInventoryFromLocation();
  const { bulkMoveInventoryToLocation, bulkMoveInventoryToLocationLoading } = useBulkMoveInventoryToLocation();
  const { updateInventoryLocation } = useUpdateInventoryLocation();
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();
  const [moveDuplicateWarningMessage, setMoveDuplicateWarningMessage] = useState<string>('');
  const [deleteWarningMessage, setDeleteWarningMessage] = useState<string>('');

  const loadInventoryAtLocation = async (locationId: string) => {
    try {
      const queryResult = await getLazyInventoryAtLocation({ variables: { locationId, filterInput: {inventoryType} } });
      const inventoryLocation = queryResult?.data?.inventoryAtLocation || [];
      setInventoryAtLocation(inventoryLocation);
    } catch (error) {
      logger('GetInventoryByLocation').error(`Error loading inventory for location ${locationId}:`, error);
      setInventoryAtLocation([]);
    }
  };

  const onCancelClick = () => {
    reset();
    setSelectedLocationId(null);
    setInventoryAtLocation([]);
    setRowSelected({});
    onCancel && onCancel();
  };

  const handleAdd = () => {
    setIsAdding(true);
  };

  const handleAddCancel = () => {
    setIsAdding(false);
  };
  
  const handleMove = () => {
    setIsMoving(true);
  };

  const handleMoveCancel = () => {
    setIsMoving(false);
    setDeleteWarningMessage('Are you sure you wish to remove inventory from location?')
  };

  const handleDelete = () => {
    setIsDeleting(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleting(false);
  };

  const handleMoveDuplicateCancel = () => {
    setIsMoveDuplicate(false);
  };

  const handleAddOk = async (inventoryIds: string[]) => {
    const payload: InventoryLocationInput[] = inventoryIds.map((id: string) => ({
      inventoryId: id,
      locationId: selectedLocationId,
      amount: 0
    }));

    const { responseData, responseMessage } = await bulkAddInventoryToLocation(payload);

    if (responseData) {
      handleAddCancel();
      await loadInventoryAtLocation(selectedLocationId!);  // Reload inventoryAtLocation grid
      onSubmit();
    }

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const handleMoveOk = async (newLocationId: string) => {
    const selectedIds = Object.keys(rowSelected);
    const payload: BulkModifyInventoryLocationInput = {
      ids: selectedIds,
      locationId: newLocationId,
    };

    const { responseData, responseErrors, responseMessage } = await bulkMoveInventoryToLocation(payload);

    if (responseData) {
      setRowSelected({});
      handleMoveCancel();
      await loadInventoryAtLocation(selectedLocationId!);  // Reload inventoryAtLocation grid
      onSubmit();

      setSnackbar({
        message: responseMessage,
        severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
      });
    } else if (responseErrors) {
      setMoveDuplicateWarningMessage(responseMessage);
      setIsMoveDuplicate(true);
      handleMoveCancel();
    } else {
      setSnackbar({
        message: responseMessage,
        severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
      });
    }
  };

  const handleDeleteOk = async () => {
    const inventoryLocationIds = Object.keys(rowSelected);
    if (inventoryLocationIds.length > 0) {
      const { responseData, responseMessage } = await bulkRemoveInventoryFromLocation(inventoryLocationIds);
      setRowSelected({});

      if (responseData?.success && selectedLocationId) {
        await loadInventoryAtLocation(selectedLocationId);  // Reload inventoryAtLocation grid
        onSubmit();
      }

      setSnackbar({
        message: responseMessage,
        severity: responseData?.success ? SEVERITY.SUCCESS : SEVERITY.ERROR,
      });
    }
    setIsDeleting(false);
  };

  const handleAmountUpdate = useCallback(async ({ data, value }) => {
    // Optimistically update the grid
    const newAmount = parseInt(value);
    const updatedInventory = inventoryAtLocation.map((item) => item.id === data.id ? { ...item, amount: newAmount } : item);
    setInventoryAtLocation(updatedInventory);

    const payload: InventoryLocationInput = {
      id: data.id,
      amount: newAmount,
    };

    const { responseData, responseMessage } = await updateInventoryLocation(payload);

    if (responseData) {
      await loadInventoryAtLocation(selectedLocationId!);  // Reload inventoryAtLocation grid
      onSubmit();
    }

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  }, [inventoryAtLocation, selectedLocationId])

  const handleSelectLocation = async (value: string[]) => {
    const selectedLocation = last(value);
    if (isNotNil(selectedLocation)) {
      setSelectedLocationId(selectedLocation!); // keep track of the selected location
      await loadInventoryAtLocation(selectedLocation!);
    }
  };

  const handleSelectionChange = useCallback(({ selected }) => {
    if(isObject(selected) && Object.keys(selected).length === 0) {
      setRowSelected({});
    }
    else {
      setRowSelected(selected);
    }
  }, []);

  const columns = [
    {
      name: 'inventory.productName',
      header: 'Product Name',
      flex: 1,
      render: ({ data }: any) => <span>{data?.inventory?.productName}</span>,
      editable: () => false
    },
    {
      name: 'inventory.manufacturer',
      header: 'Manufacturer',
      flex: 1,
      render: ({ data }: any) => <span>{data?.inventory?.manufacturer}</span>,
      editable: () => false
    },
    {
      name: 'amount',
      header: 'Amount',
      defaultWidth: 95,
      headerAlign: 'start' as any,
      textAlign: 'end' as any,
    },
  ];

  return (
    <div>
      <Dialog
        open={visible}
        onClose={onCancelClick}
        fullWidth
        maxWidth="md"
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>
          <span className="font-bold text-2xl">
            {`Manage ${inventoryTypeTitle} at Location`}
          </span>
          {onCancel ? (
            <IconButton
              aria-label="close"
              onClick={onCancelClick}
              sx={{
                position: 'absolute',
                right: 10,
                top: 14,
                color: (theme) => theme.palette.grey[400],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent dividers>
            <div className="bg-white h-full flex-grow">
              <div style={{ display: "flex", alignItems: "center", fontSize: "14px" }}>
                <InfoOutlinedIcon style={{ marginRight: "8px", fontSize: "18px" }} />
                <span>Select a location to manage. Use the action buttons to add, move or remove selected inventory. Double click the “Amount” column to quickly update the amount of inventory at the selected location.</span>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: '20px' }}>
                <div>
                  <LocationDropdown
                    name="locationId"
                    control={control}
                    onChange={(_event, value: any) => {handleSelectLocation(value)}}
                    label="Choose Location"
                    recordType={RecordType.InventoryGeneral}
                    allDepts={settingsPersonal?.fldAllDepts > 0}
                    dropdownHeight={700}
                  />
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    onClick={handleAdd}
                    size="small"
                    aria-label="Add item"
                    disabled={!selectedLocationId}
                  >
                    <AddRoundedIcon fontSize="medium" color={!selectedLocationId ? "disabled" : "primary"} />
                  </IconButton>
                  <IconButton
                    onClick={handleMove}
                    size="small"
                    aria-label="Move item"
                    disabled={isEmptyObject(rowSelected)}
                  >
                    <MoveUpTwoTone fontSize="medium" color={isEmptyObject(rowSelected) ? "disabled" : "primary"} />
                  </IconButton>
                  <IconButton
                    onClick={handleDelete}
                    color="error"
                    aria-label="Delete task"
                    className="ml-2"
                    size="small"
                    disabled={isEmptyObject(rowSelected)}
                  >
                    <DeleteTwoTone fontSize="medium" color={isEmptyObject(rowSelected) ? "disabled" : "error"} />
                  </IconButton>
                </div>
              </div>

              <div className="flex flex-col flex-grow inv-storage-grid pt-5">
                <LicensedReactDataGrid
                  showColumnMenuTool={false}
                  rowHeight={40}
                  loading={loading}
                  idProperty="id"
                  columns={columns}
                  dataSource={inventoryAtLocation}
                  selected={rowSelected}
                  onSelectionChange={handleSelectionChange}
                  onEditComplete={handleAmountUpdate}
                  checkboxColumn
                  checkboxOnlyRowSelect
                  editable
                  defaultSortInfo={[{ name: 'inventory.productName', dir: 1 }]}
                />
              </div>
            </div>
        </DialogContent>
        <DialogActions sx={{ m: 0, p: 3, justifyContent: 'flex-end' }}>
          <Button 
            onClick={onCancelClick} 
            variant="contained" 
            className="w-32"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <MoveInventoryToLocationDialog
        visible={isMoving}
        control={control}
        onMove={handleMoveOk}
        onCancel={handleMoveCancel}
      />

      <AddInventoryToLocationDialog
        visible={isAdding}
        onSave={handleAddOk}
        onCancel={handleAddCancel} 
        excludedIds={inventoryAtLocation.map(item => item.inventory?.id).filter(Boolean)}
        inventoryType={inventoryType}      
      />

      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content={deleteWarningMessage}
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />

      <WarningDialog
      disabled={true}
        visible={isMoveDuplicate}
        title="Duplicate Warning"
        content={moveDuplicateWarningMessage}
        cancelText='Close'
        color='error'
        onCancel={handleMoveDuplicateCancel}
      />

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>

    </div>
  );
};

export default InventoriesToLocationDialog;
