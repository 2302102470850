import { FC, useEffect, useState } from 'react';
import { isArray, isNil } from 'lodash';
import { TreeView } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, Chip, CircularProgress, SvgIcon } from '@mui/material';
import StyledTreeItem from 'src/components/HierarchyViewStyledTreeItem';
import LogIcon from 'src/assets/icon-log-primary.svg?react';
import EqIcon from 'src/assets/icon-eq-primary.svg?react';
import { useGetLogEntryCountsByCategory } from 'src/hooks/logEntries/useGetLogEntryCountsByCategory';
import { useGetLazyLogEntriesGroupedByEquipment } from 'src/hooks/logEntries/useGetLazyLogEntriesGroupedByEquipment';
import { GroupCountOfCategory, GroupListOfEquipmentAndLogEntry, LogEntry, LogEntryFilterBy, LogEntryFilterInput } from 'src/generated/dotnet.graphql';
import { generateHierarchicalTree, getPlainTextFromHtml, TreeDataItem } from 'src/utils';
import { useGetLazyLogEntry } from 'src/hooks/logEntries/useGetLazyLogEntry';
import { logger } from "src/helpers/logger";
import { treeNodeDetailDisplay } from '../utils';
import { MasterSearchInputValue } from "src/components/MasterSearchInput";
import useMergedFilters from 'src/hooks/common/useMergedFilters';
import { GridType } from 'src/consts';

interface InjectedProps {
  onChange: (logEntry: LogEntry) => void;
  refetchTree?: boolean;
  masterSearchInput?: MasterSearchInputValue | null;
}

const CategoryViewGrid: FC<InjectedProps> = ({
  onChange,
  refetchTree,
  masterSearchInput = null,
}) => {
  const [data, setData] = useState<TreeDataItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selected, setSelected] = useState<any>(null);
  const [expanded, setExpanded] = useState<any[]>([]);
  const [logEntriesMap, setLogEntriesMap] = useState<Map<string, TreeDataItem[]>>(new Map());
  const [expandedNodes, setExpandedNodes] = useState<Set<string>>(new Set());
  const [filterValue] = useMergedFilters<LogEntryFilterInput>(
    masterSearchInput,
    {
      isWarranty: undefined,
      restricted: undefined,
      sms: undefined,
      searchText: ""
    },
    GridType.CATEGORY
  );
  const { recordCountsByCategory, refetchRecordCountsByCategory } = useGetLogEntryCountsByCategory(filterValue);
  const { getLazyLogEntry } = useGetLazyLogEntry();
  const { getLazyLogEntriesGroupedByEquipment } = useGetLazyLogEntriesGroupedByEquipment();

  useEffect(() => {
    if (recordCountsByCategory) {
      setLoading(true);
      const transformedData = transformData(recordCountsByCategory);
      const treeData = generateHierarchicalTree(null, 'id', 'parentId', transformedData);
      // Check if the treeData is different before setting state
      setData((prevData) => {
        if (JSON.stringify(prevData) !== JSON.stringify(treeData)) {
          return treeData || [];
        }
        return prevData;
      });
      setLoading(false)
    }
  }, [recordCountsByCategory]);

  useEffect(() => {
    refetchRecordCountsByCategory();
    // Reload log entries for expanded nodes when `refetchTree` changes
    expanded.forEach(async (nodeId) => {
      await loadLogEntries(nodeId);
    });
  }, [refetchTree, filterValue]);

  const transformData = (data: GroupCountOfCategory[]) => {
    return data.map((record: GroupCountOfCategory) => ({
      id: record.item?.id || "",
      name: record.item?.category !== undefined ? record.item?.category : "No Category Related",
      parentId: record.item?.parentId || null,
      count: record.count,
    }));
  };
  
  const handleToggle = async (event: any, nodeIds: any) => {
    const [nodeId] = nodeIds;
    if (!expandedNodes.has(nodeId)) {
      setExpandedNodes(prev => new Set(prev).add(nodeId));
      await loadLogEntries(nodeId);
    }
    setExpanded(nodeIds);
  };

  const handleExpandClick = () => {
    const dataIds: string[] = [];
    const collectIds = (node: any) => {
      dataIds.push(node.id);
      if (node.children) {
        node.children.forEach((child: any) => collectIds(child));
      }
    };
    data.forEach(item => collectIds(item));
    setExpanded(oldExpanded => (oldExpanded.length === 0 ? dataIds : []));
  };

  const handleChange = async (item: any) => {
    setSelected(item.id);
    const queryResult = await getLazyLogEntry({ variables: { logEntryId: item.id } });
    const logEntry = queryResult.data?.logEntry;
    if (logEntry) {
      onChange(logEntry); 
    } else {
      logger('GetLogEntry').error(`Log entry not found with id: ${item.id}`);
    }
  };  

  const loadLogEntries = async (categoryId: string) => {
    try {
      // Fetch log entries grouped by equipment
      const queryResult = await getLazyLogEntriesGroupedByEquipment({
        variables: {
          filterById: categoryId,
          filterBy: LogEntryFilterBy.Category,
          filterInput: { ...filterValue },
        },
      });
  
      // Extract log entry groups from query result
      const logEntryGroups = queryResult.data?.logEntriesGroupedByEquipment || [];
      
      // Initialize arrays to hold processed data
      const equipmentNodes: any[] = [];
      let noEquipmentNode: any = null; // Track the "Not Equipment Related" node
      
      logEntryGroups.forEach((group: any) => {
        if (!group.item) {
          // Create "Not Equipment Related" node if it doesn't exist
          if (!noEquipmentNode) {
            noEquipmentNode = {
              id: `no-equipment-${categoryId}`,
              name: "Not Equipment Related",
              count: 0,
              children: [],
            };
            equipmentNodes.push(noEquipmentNode);
          }
  
          // Add log entries to the "Not Equipment Related" node
          group.list.forEach((logEntry: any) => {
            if (!noEquipmentNode.children.some((entry: any) => entry.id === logEntry.id)) {
              noEquipmentNode.children.push({
                id: logEntry.id,
                name: getPlainTextFromHtml(logEntry.html),
                logDate: logEntry.logDate,
                count: 0,
                children: [],
              });
            }
          });

          // Update the count for the "Not Equipment Related" node
          noEquipmentNode.count = noEquipmentNode.children.length;
        } else {
          // Process groups with a valid item
          equipmentNodes.push({
            id: group.item.id,
            name: group.item.uniqueName,
            count: group.count, // Use the count from the data
            children: group.list.map((logEntry: any) => ({
              id: logEntry.id,
              name: getPlainTextFromHtml(logEntry.html),
              logDate: logEntry.logDate,
              count: 0,
              children: [],
            })),
          });
        }
      });
  
      // Update the logEntriesMap with the processed data
      setLogEntriesMap(prev => new Map(prev).set(categoryId, equipmentNodes));
    } catch (error) {
      console.error('Error loading log entries:', error);
      // Set an empty array in case of error
      setLogEntriesMap(prev => new Map(prev).set(categoryId, []));
    }
  };

  const renderTree = (data: TreeDataItem) => (
    <StyledTreeItem
      key={data.id}
      nodeId={data.id}
      label={
        <div className="flex flex-row items-center">
          <span className="mr-3 block font-bold">{data.name}</span>
          <Chip label={data.count} />
        </div>
      }
    >
      {isArray(data.children) ? data.children.map(child => renderTree(child)) : null}
      {expandedNodes.has(data.id) && renderEquipmentNodes(data.id)}
    </StyledTreeItem>
  );

  const renderEquipmentNodes = (categoryId: string) => {
    const equipmentNodes = logEntriesMap.get(categoryId) || [];

    if (isNil(equipmentNodes) || equipmentNodes.length === 0) return null;

    return equipmentNodes.map((equipment: any) => (
      <StyledTreeItem
        key={equipment.id}
        nodeId={equipment.id}
        label={
          <div className="flex flex-row items-center">
            {equipment.name !== "Not Equipment Related" 
            ? 
              <SvgIcon className='mr-2' fontSize='small'>
                <EqIcon/>
              </SvgIcon>
            :
              <SvgIcon className='mr-2' fontSize='small'>
                <LogIcon/>
              </SvgIcon>
            }
            <span className="mr-3 block font-bold">{equipment.name}</span>
            <Chip label={equipment.count} />
          </div>
        }
      >
        {renderLogEntries(equipment.children)}
      </StyledTreeItem>
    ));
  };

  const renderLogEntries = (logEntries: TreeDataItem[]) => {
    return logEntries.map((logEntry: any) => (
      <Button
        key={logEntry.id}
        onClick={() => handleChange(logEntry)}
        sx={{ justifyContent: 'flex-start' }}
        style={selected === logEntry.id ? { background: '#0a214291', color: '#fff' } : {}}
        fullWidth
        size="large"
        startIcon={(<SvgIcon><LogIcon/></SvgIcon>)}
        variant="text"
      >
        {treeNodeDetailDisplay(logEntry.logDate, logEntry.name)}
      </Button>
    ));
  };

  if (loading) {
    return (
      <div className="flex flex-grow items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      <Box sx={{ mb: 4 }}>
        <Button
          variant={'contained'}
          className="ml-1 p-2"
          sx={{ height: 38, width: 125 }}
          startIcon={expanded.length === 0 ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
          onClick={handleExpandClick}
        >
          {expanded.length === 0 ? 'Expand' : 'Collapse'}
        </Button>
      </Box>
      <TreeView
        sx={{ width: '100%' }}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        onNodeToggle={handleToggle}
      >
        {data.map(renderTree)}
      </TreeView>
    </div>
  );
};

export default CategoryViewGrid;
