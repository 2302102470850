import { ChangeEvent, FC, useEffect, useState } from 'react';
import { debounce, isEmpty } from 'lodash';
import { Checkbox, Box, Popover, OutlinedInput, MenuItem, ListItemText, FormControl, InputAdornment, IconButton } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone';
import Badge from '@mui/material/Badge';
import { RecordType } from 'src/generated/dotnet.graphql';
import { is } from 'date-fns/locale';

const StyledCheckbox = (props: any) => {
  return (
    <Checkbox
      {...props}
      sx={{
        color: (theme) => theme.palette.primary.main,
        '&.Mui-checked': {
          color: (theme) => theme.palette.primary.main,
        },
        '&.MuiCheckbox-indeterminate': {
          color: (theme) => theme.palette.primary.main,
        },
        '&.Mui-disabled': {
          color: (theme) => theme.palette.action.disabled,
        },
        transform: 'scale(0.9)', // Scale down the checkbox size
        padding: '4px', // Adjust padding to keep the clickable area appropriate
      }}
    />
  );
};

enum SubFilterValue {
  SMS = 'sms',
  RESTRICTED = 'restricted',
  IS_RESTRICTED = 'isRestricted',
  IS_WARRANTY = 'isWarranty',
  IS_REPORT = 'isReport',
  REQUIRES_VERIFICATION = 'requiresVerification',
  IS_IN_STOCK = 'isInStock',
  IS_BELLOW_MINIMUM = 'isBelowMinimum',
  HAS_EXPIRED_AT_LOCATION = 'hasExpiredAtALocation',
  IS_CONSUMABLE = 'isConsumable',
  IS_CRITICAL = 'isCritical',
  IS_EXPIRED = 'isExpired',
  SAMPLE_RECEIVED = 'sample',
  COUNT_HOURS = 'countHours',
  UNDEFINED = ''
}

type SubFilterOption = {
  label: string;
  value: SubFilterValue;
};

const SUB_FILTER_OPTIONS_EQUIPMENT = [
  {
    label: 'Critical Equipment',
    value: SubFilterValue.IS_CRITICAL
  },
  {
    label: 'Monitors Hours',
    value: SubFilterValue.COUNT_HOURS
  },
  {
    label: 'Expired',
    value: SubFilterValue.IS_EXPIRED
  },
  {
    label: 'Restricted to HOD',
    value: SubFilterValue.IS_RESTRICTED
  },
];

const SUB_FILTER_OPTIONS_LOGENTRY = [
  {
    label: 'SMS Required',
    value: SubFilterValue.SMS
  },
  {
    label: 'Restricted to HOD',
    value: SubFilterValue.RESTRICTED
  },
  {
    label: 'Warranty',
    value: SubFilterValue.IS_WARRANTY
  },
];

const SUB_FILTER_OPTIONS_LIBRARIES = [
  {
    label: 'Is a Form',
    value: SubFilterValue.IS_REPORT
  },
  {
    label: 'Requires Verification',
    value: SubFilterValue.REQUIRES_VERIFICATION
  },
];

const SUB_FILTER_OPTIONS_GENERAL_INVENTORY = [
  {
    label: 'In Stock',
    value: SubFilterValue.IS_IN_STOCK
  },
  {
    label: 'Below Minimum',
    value: SubFilterValue.IS_BELLOW_MINIMUM
  },
  {
    label: 'Expired at a Location',
    value: SubFilterValue.HAS_EXPIRED_AT_LOCATION
  },
  {
    label: 'Consumable',
    value: SubFilterValue.IS_CONSUMABLE
  },
  {
    label: 'Critical',
    value: SubFilterValue.IS_CRITICAL
  },
];

const SUB_FILTER_OPTIONS_BEVERAGES_UNIFORMS_MEDICAL = [
  {
    label: 'In Stock',
    value: SubFilterValue.IS_IN_STOCK
  },
  {
    label: 'Below Minimum',
    value: SubFilterValue.IS_BELLOW_MINIMUM
  },
  {
    label: 'Expired at a Location',
    value: SubFilterValue.HAS_EXPIRED_AT_LOCATION
  },
];

const SUB_FILTER_OPTIONS_STORES = [
  {
    label: 'In Stock',
    value: SubFilterValue.IS_IN_STOCK
  },
  {
    label: 'Below Minimum',
    value: SubFilterValue.IS_BELLOW_MINIMUM
  },
];

const SUB_FILTER_OPTIONS_BUNKERING = [
  {
    label: 'Sample Received',
    value: SubFilterValue.SAMPLE_RECEIVED
  },
];

export interface MasterSearchInputValue {
  subFilterValue?: { [key: string]: any; };
  searchText?: string;
}

interface InjectedProps {
  onChange: (isReset: boolean, searchValue: MasterSearchInputValue) => void;
  label: string;
  recordType: RecordType;
}

const MasterSearchInput: FC<InjectedProps> = ({ onChange, label, recordType }) => {
  const [subFilterOptions, setSubFilterOptions] = useState<SubFilterOption[]>([]);
  const [subFilterValue, setSubFilterValue] = useState<any>();
  const [searchText, setSearchText] = useState<any>(undefined);
  const [subFilterAnchorEl, setSubFilterAnchorEl] = useState(null);
  const subFilterId = 'sub-filter-id';
  const isSubFilterOpen = Boolean(subFilterAnchorEl);
  const [checked, setChecked] = useState(
    subFilterOptions.reduce((acc: any, item: any) => {
      acc[item.value] = undefined;
      return acc;
    }, {})
  );

  const handleChange = (key: string | number, ev: any) => {
    setChecked((prevState: { [x: string]: any }) => {
      const currentState = prevState[key];
      let newState;
  
      if (currentState === undefined) {
        newState = { ...prevState, [key]: true };
      } else if (currentState === true) {
        newState = { ...prevState, [key]: false };
      } else {
        newState = { ...prevState, [key]: undefined };
      }
  
      // Update subFilterValue based on the new checked state
      const updatedSubFilterValue = {
        ...subFilterValue,
        [key]: newState[key] === undefined ? undefined : newState[key],
      };
  
      setSubFilterValue(updatedSubFilterValue);
  
      return newState;
    });
  };

  useEffect(() => {
    const handleSearch = debounce(() => {
      onChange(false, { subFilterValue, searchText });
    }, 500);

    handleSearch();

    // Cleanup to cancel any pending debounced calls when dependencies change
    return () => {
      handleSearch.cancel();
    };
  }, [subFilterValue, searchText]);
  
  useEffect(()=>{
    switch (recordType){
      case RecordType.LogEntry: {
        setSubFilterOptions(SUB_FILTER_OPTIONS_LOGENTRY)
        setSubFilterValue({
          sms: undefined,
          isWarranty: undefined,
          restricted: undefined,
        })
        break;
      }
      case RecordType.Equipment: {
        setSubFilterOptions(SUB_FILTER_OPTIONS_EQUIPMENT)
        setSubFilterValue({
          isCritical: undefined,
          hasWork: undefined,
          isExpired: undefined,
          isRestricted: undefined,
        })
        break;
      }
      case RecordType.DocumentationSms:
      case RecordType.DocumentationIsps: {
        setSubFilterOptions(SUB_FILTER_OPTIONS_LIBRARIES);
        setSubFilterValue({
          isReport: undefined,
          requiresVerification: undefined,
        })
        break;
      }
      case RecordType.InventoryGeneral: {
        setSubFilterOptions(SUB_FILTER_OPTIONS_GENERAL_INVENTORY);
        setSubFilterValue({
          isInStock: undefined,
          isBelowMinimum: undefined,
          hasExpiredAtALocation: undefined,
          isConsumable: undefined,
          isCritical: undefined,
        })
        break;
      }
      case RecordType.InventoryBeverages:
      case RecordType.InventoryUniform:
      case RecordType.InventoryMedical: {
        setSubFilterOptions(SUB_FILTER_OPTIONS_BEVERAGES_UNIFORMS_MEDICAL);
        setSubFilterValue({
          isInStock: undefined,
          isBelowMinimum: undefined,
          hasExpiredAtALocation: undefined,
        })
        break;
      }
      case RecordType.InventoryDryAndColdStore: {
        setSubFilterOptions(SUB_FILTER_OPTIONS_STORES);
        setSubFilterValue({
          isInStock: undefined,
          isBelowMinimum: undefined,
        })
        break;
      }
      case RecordType.Bunkering: {
        setSubFilterOptions(SUB_FILTER_OPTIONS_BUNKERING);
        setSubFilterValue({
          sample: undefined,
        })
        break;
      }
      default:
        setSubFilterOptions([]);
        setSubFilterValue({});
        break;  
    }
  
  },[recordType])

  const handleTextInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }

  const handleClear = () => {
    // Reset text input
    setSearchText('');

    // Reset all checkboxes to undefined
    const resetChecked = Object.keys(checked).reduce((acc, key) => {
      acc[key] = undefined;
      return acc;
    }, {} as { [key: string]: undefined });

    setChecked(resetChecked);

    // Reset the subFilterValue state to reflect the cleared checkboxes
    const resetSubFilterValue = Object.keys(subFilterValue).reduce((acc, key) => {
      acc[key] = undefined;
      return acc;
    }, {} as { [key: string]: undefined });

    setSubFilterValue(resetSubFilterValue);
  }

  const handleSubFilterClose = () => {
    setSubFilterAnchorEl(null)
  }

  const handleSubFilterOpen = (event: any) => {
    setSubFilterAnchorEl(event.currentTarget);
  };

  const renderSubFilter = () => {
    return (
      <Popover
        id={subFilterId}
        open={isSubFilterOpen}
        anchorEl={subFilterAnchorEl}
        onClose={handleSubFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ minWidth: '200px' }}>
          {subFilterOptions.map((item: any) => (
            <MenuItem 
              key={item.value} 
              value={item} 
              onClick={() => handleChange(item.value, !subFilterValue[item.value])}
            >
              <StyledCheckbox
                checked={checked[item.value] === true}
                indeterminate={checked[item.value] === undefined}
                onChange={(ev: any) => handleChange(item.value, ev)}
              />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Box>
      </Popover>
    );
  };
  
  return (
    <Box 
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          sm: '1fr',
          md: '1fr'
        },
        gap: '10px',
      }}
    >
      <FormControl fullWidth>
        {/* <InputLabel htmlFor="outlined-basic">{label}</InputLabel> */}
        <OutlinedInput
          sx={{ 
            height: 37,
            width: '100%', // Ensure input takes available width
            maxWidth: '300px', // Set a max width to make it narrower
             }}
          id="outlined-basic"
          type={'text'}
          value={searchText}
          // label={label}
          placeholder={label}
          onChange={handleTextInputChange}
          endAdornment={
            <InputAdornment position="end">
              {!isEmpty(subFilterOptions) && (
                <IconButton
                  aria-label="Filter"
                  onClick={handleSubFilterOpen}
                  onMouseDown={(evt: any) => evt.preventDefault()}
                  edge="end"
                  className="mr-1"
                >
                  <Badge badgeContent={subFilterValue && Object.values(subFilterValue).filter(value => value !== undefined).length} color="error">
                    <FilterAltTwoToneIcon />
                  </Badge>
                </IconButton>
              )}
              <IconButton
                aria-label="Clear Search"
                onClick={handleClear}
                onMouseDown={(evt: any) => evt.preventDefault()}
                edge="end"
              >
                <CancelIcon
                />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {renderSubFilter()}
    </Box >
  )
};

export default MasterSearchInput;