import { ApolloError, gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { Bunkering, BunkeringInput, QueryBunkeringArgs } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_BUNKERINGS_WITH_COUNTS } from './useGetBunkeringsWithCounts';

export const UPSERT_BUNKERING = gql`
  mutation UpsertBunkering($input: BunkeringInput!) {
    upsertBunkering(input: $input) {
      bunkering {
        id
        agent
        amount
        cost
        curr
        date
        fluid
        from
        method
        notes
        oilRecord
        qty
        responsible
        sample
        start
        station
        stop
        to
      }
    }
  }
`;

export interface BunkeringMutationResponse {
  responseData?: Bunkering;
  responseMessage: string;
}

export interface BunkeringsMutationResponse {
  responseData?: Bunkering[];
  responseMessage: string;
}

interface UpsertBunkeringResult {
  upsertBunkering: (input: BunkeringInput, isCreate: boolean, queryVariables: QueryBunkeringArgs) => Promise<BunkeringMutationResponse>;
  upsertBunkeringLoading: boolean;
  upsertBunkeringError?: ApolloError;
}

export const useUpsertBunkering = (): UpsertBunkeringResult => {
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [AddOrUpdateBunkering, { loading, error }] = useMutation(UPSERT_BUNKERING, {
    onError: (error: ApolloError) => {
      logger('UpsertBunkering').error(`Error ${isCreate ? 'adding' : 'updating'} bunkering -->`, error.message);
    }
  });

  const upsertBunkering = async (input: BunkeringInput, isCreate: boolean, refetchVariables: QueryBunkeringArgs): Promise<BunkeringMutationResponse> => {
    setIsCreate(isCreate);

    const response = await AddOrUpdateBunkering({ 
      variables: { input },
      refetchQueries: [{
        query: GET_BUNKERINGS_WITH_COUNTS,
        variables: refetchVariables
      }],
    });

    const responseData = response.data?.upsertBunkering?.bunkering;
    if (responseData) {
      logger('UpsertBunkering').info(`Bunkering ${isCreate ? 'added' : 'updated'} successfully`, response.data);
      return {
        responseData,
        responseMessage: `Bunkering ${isCreate ? 'added' : 'updated'} successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to ${isCreate ? 'add' : 'update'} bunkering!`,
      };
    }
  };

  return { 
    upsertBunkering, 
    upsertBunkeringLoading: loading, 
    upsertBunkeringError: error,
  };
};