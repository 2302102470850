import { Alert } from "@mui/material";
import { split, last, lowerCase } from "lodash";
import { forwardRef } from "react";
import { useParams } from "react-router-dom";
import FileViewerExcel from "./FileViewerExcel";
import FileViewerPdf from "./FileViewerPdf";
import FileViewerTxt from "./FileViewerTxt";
import FileViewerDocx from "./FileViewerDocx";
import { Form, FormRevision } from "src/generated/dotnet.graphql";

interface FileViewerRef {
  save: () => void;
}

interface FileViewerProps {
  url: string;
  form: Form;
  formRevision?: FormRevision;
}

const FileViewer = forwardRef<FileViewerRef, FileViewerProps>(
  ({ url, form, formRevision }, ref) => {
    return (
      <div className="w-full h-full" data-testid="file-viewer">
        <FileTypeSwitch url={url} ref={ref} form={form} formRevision={formRevision} />
      </div>
    );
  }
);

const FileTypeSwitch = forwardRef<FileViewerRef, FileViewerProps>(
  ({ url, form, formRevision }, ref) => {
    const ext = lowerCase(last(split(url, ".")));
    switch (ext) {
      case "pdf":
        return <FileViewerPdf url={url} />;
      case ".xlsm":
      case "xltm":
      case "xltx":
      case "xlt":
      case "xlsx":
      case "xls":
        return <FileViewerExcel url={url} ref={ref} form={form} />;
      case "docx":
        return <FileViewerDocx url={url} ref={ref} form={form} formRevision={formRevision}/>;
      case "txt":
        return <FileViewerTxt url={url} />;
      default:
        return (
          <Alert severity="error">
            Unsupported file type:{" "}
            <a target="_blank" href={url}>
              Download here
            </a>
          </Alert>
        );
    }
  }
);

export default FileViewer;

export const FileViewerPage = () => {
  const { filename } = useParams<{ filename: string }>();
  const form = {} as Form;
  if (!filename) return <></>;
  return <FileViewer url={`/test-files/${filename}`} form={form}/>;
};
