// Styles
import '../../../App.css';
import '../../../theme/styles.css';
import '../styles.css';
// React components
import { FC, useEffect, useRef, useState } from 'react';
import { isNil, size, isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
// Third party components
import { DeleteTwoTone } from '@mui/icons-material';
import { Alert, AlertColor, IconButton, Snackbar } from '@mui/material';
// TDI components
import WarningDialog from 'src/components/UI/WarningDialog';
import { useAppState } from 'src/contexts/app-state';
import RecordEditWarningCard from 'src/components/UI/RecordEditWarningCard';
import { InjectedDrawerProps } from 'src/components/PageDrawer';
import AttachmentTab from 'src/modules/Attachments';
import Comments from 'src/modules/Comments';
// GraphQL
import { useAuth } from 'src/contexts/auth';
import { RecordType, AttachmentType, QueryLogEntriesArgs, Bunkering, BunkeringInput, DeleteResult } from 'src/generated/dotnet.graphql';
import { useUpsertBunkering } from 'src/hooks/bunkering/useUpsertBunkering';
import { useDeleteBunkering } from 'src/hooks/bunkering/useDeleteBunkering';
import { useGetBunkeringCounts } from 'src/hooks/bunkering/getBunkeringCounts';

// utils
import { normalizeDateFormValue } from 'src/helpers';
import { logger } from 'src/helpers/logger';
import { isValidDateFormat, toStaticDate, toStaticTime, utcToTimeZone } from 'src/utils/format-dates';
import { validateForm } from '../../../pages/BunkeringPage/utils';
import Tabs from 'src/components/UI/Tabs';
import StickyAppBar from 'src/components/UI/StickyAppBar';
import BunkeringSummaryForm from './components/BunkeringSummaryForm';
import DatePicker from 'src/components/UI/Forms/DatePicker';
import SelectInput from 'src/components/UI/Forms/SelectInput';
import { useGetInputsLists } from 'src/hooks/bunkering/useGetInputsLists';

interface BunkeringDetailFormProps extends Partial<InjectedDrawerProps> {
  initialValue: Bunkering;
  isCreate?: boolean;
  onSave: (responseData: Bunkering, responseMessage: string, isCreated: boolean) => void;
  onDelete?: (responseData: DeleteResult, responseMessage: string) => void;
  onCancel: () => void;
  refetchQueryVariables: QueryLogEntriesArgs;
  moduleReadOnly: boolean;
  onUndo?: () => void;
  type?: string;
}

const BunkeringDetailForm: FC<BunkeringDetailFormProps> = ({ 
  initialValue,
  onCancel,
  onSave,
  refetchQueryVariables: queryVariables,
  onDelete,
  onUndo,
  moduleReadOnly = false,
  isCreate = false,
  type,
  setFormIsDirty,
}) => {
  const { control, setValue, handleSubmit, getValues, reset, watch, formState } = useForm<any>({ 
    defaultValues: {
      agent: initialValue.agent || null,
      amount: initialValue.amount || null,
      cost: initialValue.cost || null,
      curr: initialValue.curr || null,
      date: normalizeDateFormValue(utcToTimeZone(initialValue.date)),
      fluid: initialValue.fluid || null,
      from: initialValue.from || null,
      method: initialValue.method || null,
      notes: initialValue?.notes || '',
      oilRecord: initialValue?.oilRecord || null,
      qty: initialValue.qty || null,
      responsible: isEmpty(initialValue.responsible) ? [] : initialValue.responsible?.split(', '),
      sample: initialValue.sample || false,
      start: initialValue.start ? normalizeDateFormValue(utcToTimeZone(initialValue.start)) : null,
      stop: initialValue.stop ? normalizeDateFormValue(utcToTimeZone(initialValue.stop)) : null,
      station: initialValue.station || null,
      to: initialValue.to || null,
    },
  });

  const { user } = useAuth();
  const { settingsPersonal } = useAppState();
  const formInitialValues = useRef<any>({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [recordReadOnly, setRecordReadOnly] = useState(false);
  const [documentsCount, setDocumentsCount] = useState<number>();
  const [photosCount, setPhotosCount] = useState<number>();
  const [commentsCount, setCommentsCount] = useState<number>();
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();
  const { upsertBunkering } = useUpsertBunkering();
  const { removeBunkering } = useDeleteBunkering();
  const { getBunkeringCounts } = useGetBunkeringCounts();
  const { inputsLists } = useGetInputsLists();
  
  const setBunkeringCounts = async () => {
    // get logentry counts
    const queryResult = await getBunkeringCounts(initialValue.id);
    const bunkeringCounts = queryResult.data?.bunkeringById;
    if (bunkeringCounts) {
      setDocumentsCount(bunkeringCounts.documentsCount || 0);
      setPhotosCount(bunkeringCounts.photosCount || 0);
      setCommentsCount(bunkeringCounts.commentsCount || 0);
    } else {
      logger('GetBunkeringCounts').error(`Bunkering with id: ${initialValue.id} not found!`);
    }
  }

  const setRecordReadOnlyPermission = async ()=>{
    if(settingsPersonal.fldAllDepts != 2 && user?.Department != initialValue.department){
      setRecordReadOnly(true);
    }
    if(!settingsPersonal.fldDeptHead) {
      setRecordReadOnly(true);
    }
  }

  const setInitialValues = async () => {
    const defaultValues = {
      ...getValues(),
    };

    formInitialValues.current = defaultValues;
    reset(defaultValues);
  };

  useEffect(()=>{
    if(settingsPersonal){
      setRecordReadOnlyPermission();
    }
  },[settingsPersonal])

  useEffect(() => {
    setInitialValues();
    return () => {
      formInitialValues.current = {};
    };
  }, []);

  useEffect(() => {
    // Don't get the counts on a new creation LogEntry as the primary key does not exist yet
    if (!isCreate) {
      setBunkeringCounts();
    }
  }, [isCreate]);

  const onChange = async (name: string, value: any) => {
    let shouldDirty = true;
    setValue(name, value, { shouldDirty: shouldDirty });
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSave = async (data: any) => {

    if (!validateForm(data, setSnackbar)) return;

    const {
      date,
      station,
      agent,
      fluid,
      oilRecord,
      cost,
      curr,
      notes,
      sample,
      responsible,
      amount,
      qty,
      from,
      to,
      start,
      stop,
      method,
    } = data;

    const document: BunkeringInput = {
      date: date ? toStaticDate(new Date(date)) : null,
      station,
      agent,
      fluid,
      oilRecord,
      cost: cost ? parseFloat(cost) : null,
      curr: curr?.curr || null,
      notes,
      sample,
      responsible: responsible?.join(', ') || null,
      amount: amount ? parseFloat(amount) : null,
      qty,
      from,
      to,
      start: start ? toStaticTime(new Date(start)) : null,
      stop: stop ? toStaticTime(new Date(stop)) : null,
      method,
      id: initialValue.id || null, // Set primary key, so we will be able to upsert.
    };

    const { responseData, responseMessage } = await upsertBunkering(document, isCreate, queryVariables);

    if (responseData) {
      onSave(responseData as Bunkering, responseMessage, isCreate);
      reset(getValues());
    }
  };

  const handleDelete = () => {
    setIsDeleting(true)
  };

  const handleDeleteOk = async () =>{
    const { responseData, responseMessage } = await removeBunkering(initialValue.id, queryVariables);

    responseData?.success && onDelete && onDelete(responseData, responseMessage);
    setIsDeleting(false);
  };

  const handleDeleteCancel = () =>{
    setIsDeleting(false);
  }

  const handleCancelUndo = () => {
    if (isCreate) {
      setFormIsDirty && setFormIsDirty(false) // set dirty form to false so you can navigate to other module
      return onCancel();
    }
    reset(formInitialValues.current);
  };

  const handleOk = (isEditing: boolean) => {
    if (isEditing && !validateForm(getValues(), setSnackbar) && !recordReadOnly) return;
    if (isEditing && !recordReadOnly) return; // We will send submit action that will be handled in HandleSave.
    handleCancel();
  };

  const hasValuesBeenChanged = formState.isDirty
    && (size(formState.dirtyFields) > 0 || size(formState.touchedFields) > 0);
  const isEditing = hasValuesBeenChanged || isCreate;

  useEffect(() => {
    setFormIsDirty && setFormIsDirty(hasValuesBeenChanged);
  }, [hasValuesBeenChanged]);

  const formClass = type === 'Dialog'
  ? 'relative bg-white flex-grow'
  : 'relative bg-white pt-14 md:pt-19 flex-grow';

  return (
    <form
      id="logentry-details-form"
      className={`${formClass}`}
      onSubmit={handleSubmit(handleSave)}
    >
      <div className="bg-white h-full flex-grow pt-6">
        <div className="px-6 h-full">
          {(moduleReadOnly || recordReadOnly) && (
            <RecordEditWarningCard recordType={initialValue.__typename} />
          )}
          <div className="mt-6 mb-6 flex flex-col md:flex-row md:space-x-6">
            <DatePicker
              views={['year', 'month', 'day']}
              rules={{
                required: '*Date value is required for this field!',
                validate: (value) =>
                  isValidDateFormat(value) ||
                  'Please enter a valid date format dd-MMM-yyyy',
              }}
              name="date"
              control={control}
              label="Date"
              fullWidth
            />
            <SelectInput
              options={inputsLists.bunkeringStations || []}
              label="Port / Location"
              placeholder="Port / Location"
              control={control}
              name="station"
              freeSolo
            />
          </div>
          <div className="mb-20">
            <Tabs
              tabs={[
                {
                  label: 'Summary',
                  component: (
                    <BunkeringSummaryForm
                      control={control}
                      onChange={onChange}
                      readOnly={recordReadOnly}
                      inputsLists={inputsLists}
                    />
                  ),
                },
                {
                  label: documentsCount === undefined ? 'Attachments' : `Attachments (${documentsCount})`,
                  disabled: isCreate,
                  component: (
                    <AttachmentTab
                      recordId={initialValue.id}
                      recordType={RecordType.Bunkering}
                      recordTypeName={initialValue.__typename!}
                      attachmentType={AttachmentType.Document}
                      setAttachmentsCount={setDocumentsCount}
                      readOnly={moduleReadOnly || recordReadOnly} 
                    />
                  ),
                },
                {
                  label: photosCount === undefined ? 'Photos' : `Photos (${photosCount})`,
                  disabled: isCreate,
                  component: (
                    <AttachmentTab
                      recordId={initialValue.id}
                      recordType={RecordType.Bunkering}
                      recordTypeName={initialValue.__typename!}
                      attachmentType={AttachmentType.Photo}
                      setAttachmentsCount={setPhotosCount}
                      readOnly={moduleReadOnly || recordReadOnly} 
                    />
                  ),
                },
                {
                  label: commentsCount === undefined ? 'Comments' : `Comments (${commentsCount})`,
                  disabled: isCreate,
                  component: (
                    <Comments
                      recordId={initialValue.id}
                      recordType={RecordType.Bunkering}
                      recordTypeName={initialValue.__typename!}
                      setCommentsCount={setCommentsCount}
                      readOnly={recordReadOnly}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>

      {type !== 'Dialog' && (
        <StickyAppBar
          cancelText="Cancel"
          okType={isEditing ? 'submit' : 'button'}
          okText={isEditing ? (isCreate ? 'Create' : 'Save') : 'Close'}
          onOk={() => handleOk(isEditing)}
          disabled={(moduleReadOnly || recordReadOnly) && isEditing}
          onCancel={isEditing ? () => handleCancelUndo() : undefined}
        >
          {!moduleReadOnly && !recordReadOnly && !isNil(initialValue.id) && (
            <IconButton
              onClick={handleDelete}
              color="error"
              aria-label="Delete item"
            >
              <DeleteTwoTone />
            </IconButton>
          )}
        </StickyAppBar>
      )}

      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        color="error"
        okText='Yes'
        content="Are you sure you wish to delete record?"
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </form>
  );
};

export default BunkeringDetailForm;