import { gql, useQuery } from '@apollo/client';
import { EquipmentFilterInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { CategoriesResult } from '../interfaces';

export const GET_EQUIPMENT_COUNTS_BY_CATEGORY = gql`
  query GetEquipmentCountsByCategory($filterInput: EquipmentFilterInput) {
    equipmentCountsByCategory(filterInput: $filterInput) {
      count
      item {
        id
        category
        depts
        noView
        tags
        parentId
      }
    }
  }
`;

export const useGetEquipmentCountsByCategory = (filterInput: EquipmentFilterInput): CategoriesResult => {
  const { data, loading, error, refetch } = useQuery(GET_EQUIPMENT_COUNTS_BY_CATEGORY, {
    variables: { filterInput },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetEquipmentCountsByCategory').error('Error fetching categories -->', error.message);
  }

  return {
    recordCountsByCategory: data?.equipmentCountsByCategory || [],
    refetchRecordCountsByCategory: refetch,
    loading,
  };
};
