// React components
import { FC } from 'react';
import { Control } from 'react-hook-form';
// Third party components
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
// TDI components
import RichTextEditor from 'src/components/UI/Forms/RichTextEditor';
import Checkbox from 'src/components/UI/Forms/Checkbox';
import Input from 'src/components/UI/Forms/Input';
import MultiSelect from 'src/components/UI/Forms/MultiSelect';
// GraphQL
import { SortEnumType } from 'src/generated/dotnet.graphql';
import { useGetCrewNames } from 'src/hooks/crewNames/useGetCrewNames';
import CurrenciesDropdown from 'src/components/Dropdowns/CurrenciesDropdown';
import TimePicker from 'src/components/UI/Forms/TimePicker';
import { InputsListsData } from 'src/hooks/interfaces';
import SelectInput from 'src/components/UI/Forms/SelectInput';

interface BunkeringSummaryProps {
  onChange: (field: string, value: any) => void;
  control: Control;
  inputsLists: Partial<InputsListsData>;
  readOnly?: boolean;
}

const BunkeringSummaryForm: FC<BunkeringSummaryProps> = ({
  onChange,
  control,
  inputsLists,
  readOnly = false,
}) => {
  const { data: assignments } = useGetCrewNames({ order: [{ first: SortEnumType.Asc }, { last: SortEnumType.Asc }] });

  return (
    <div className="mt-3">
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="general-information"
          id="general-information"
        >
          General Information
        </AccordionSummary>
        <AccordionDetails>
          <div className="mt-3 flex flex-col md:flex-row">
            <SelectInput
              options={inputsLists.bunkeringAgents || []}
              label="Agent / Supplier"
              placeholder="Agent / Supplier"
              control={control}
              name="agent"
              freeSolo
            />
          </div>
          <div className="mt-6 flex flex-col md:flex-row">
            <SelectInput
              options={inputsLists.bunkeringFluids || []}
              rules={{
                required: '*Fluid type is required for this field!'
              }}
              label="Fluid Type"
              placeholder="Fluid Type"
              control={control}
              name="fluid"
              freeSolo
            />
            <Input
              control={control}
              name="oilRecord"
              inputProps={{
                label: 'Oil Record Code',
                className: 'ml-6',
                sx: {
                  '& .MuiOutlinedInput-root': {
                    '& .MuiOutlinedInput-input': {
                      textAlign: 'left',
                    },
                  },
                },
              }}
            />
          </div>
          <div className="mt-6 flex flex-col md:flex-row">
            <Input
              control={control}
              name="cost"
              inputProps={{
                type: 'decimal',
                inputProps: { min: 0 },
                label: 'Cost',
                className: 'mr-6',
                sx: {
                  '& .MuiOutlinedInput-root': {
                    '& .MuiOutlinedInput-input': {
                      textAlign: 'right',
                    },
                  },
                },
              }}
            />
            <CurrenciesDropdown
              label="Currency"
              onChange={onChange}
              control={control}
              name="curr"
            />
          </div>
          <div className="mt-6">
            <RichTextEditor
              control={control}
              name="notes"
              onChange={onChange}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion className="mt-3" defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="additional-details"
          id="additional-details"
        >
          Bunker / Transfer Details
        </AccordionSummary>
        <AccordionDetails>
          <div className="mt-3">
            <Checkbox
              control={control}
              name="sample"
              label="Sample Received"
            />
          </div>
          <div className="mt-6">
            <MultiSelect
              control={control}
              name="responsible"
              keyExpr="responsible"
              displayExpr="name"
              options={assignments}
              label="Person In Charge"
              onChange={onChange}
            />
          </div>
          <div className="mt-6 flex flex-col md:flex-row gap-6">
            <Input
              control={control}
              name="amount"
              inputProps={{
                type: 'decimal',
                inputProps: { min: 0 },
                label: 'Amount',
                sx: {
                  '& .MuiOutlinedInput-root': {
                    '& .MuiOutlinedInput-input': {
                      textAlign: 'right',
                    },
                  },
                },
              }}
            />
            <Input
              control={control}
              name="qty"
              inputProps={{
                label: 'Unit Of Measure',
                sx: {
                  '& .MuiOutlinedInput-root': {
                    '& .MuiOutlinedInput-input': {
                      textAlign: 'left',
                    },
                  },
                },
              }}
            />
          </div>
          <div className="mt-6 flex flex-col md:flex-row gap-6">
            <SelectInput
              options={inputsLists.bunkeringFromTo || []}
              label="Transfer From"
              placeholder="Transfer From"
              control={control}
              name="from"
              freeSolo
            />
            <SelectInput
              options={inputsLists.bunkeringFromTo || []}
              label="Transfer To"
              placeholder="Transfer To"
              control={control}
              name="to"
              freeSolo
            />
          </div>
          <div className="mt-6 flex flex-col md:flex-row">
            <TimePicker
              name="start"
              control={control}
              label="Start Time"
              size="small"
              className='mr-6 w-full'
            />
            <TimePicker
              name="stop"
              control={control}
              label="Stop Time"
              size="small"
              className='w-full'
            />
          </div>
          <div className="mt-6">
            <SelectInput
              options={inputsLists.bunkeringMethods || []}
              label="Pump Used"
              placeholder="Pump Used"
              control={control}
              name="method"
              freeSolo
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default BunkeringSummaryForm;