import { FC, useEffect, useState } from 'react';
import { TreeView } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, Chip, CircularProgress } from '@mui/material';
import StyledTreeItem from 'src/components/HierarchyViewStyledTreeItem';
import { generateHierarchicalTree, TreeDataItem } from 'src/utils';
import useMergedFilters from 'src/hooks/common/useMergedFilters';
import { logger } from 'src/helpers/logger';
import { InventoryFilterInput, GroupCountOfCategory, Inventory, InventoryType } from 'src/generated/dotnet.graphql';
import { MasterSearchInputValue } from 'src/components/MasterSearchInput';
import { GridType } from 'src/consts';
import { useGetInventoryCountsByCategory } from 'src/hooks/inventory/useGetInventoryCountsByCategory';
import { useGetLazyInventoryById } from 'src/hooks/inventory/useGetLazyInventoryById';
import { useGetLazyInventoryByCategory } from 'src/hooks/inventory/useGetLazyInventoryByCategory';
import { buildInventoryNodeAlertIcons, buildInventoryByCategoryNodeName } from '../utils';

interface InjectedProps {
  onChange: (inventory: Inventory) => void;
  refetchTree?: boolean;
  masterSearchInput?: MasterSearchInputValue | null;
  inventoryType: InventoryType;
}

const CategoryViewGrid: FC<InjectedProps> = ({
  onChange,
  refetchTree,
  masterSearchInput = null,
  inventoryType,
}) => {
  const [data, setData] = useState<TreeDataItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selected, setSelected] = useState<string | null>(null);
  const [inventoriesMap, setInventoriesMap] = useState<Map<string, TreeDataItem[]>>(new Map());
  const [expandedNodes, setExpandedNodes] = useState<Set<string>>(new Set());
  const defaultFilterValue = { 
    inventoryType,
    searchText: '',
  };
  const [filterValue] = useMergedFilters<InventoryFilterInput>(masterSearchInput, defaultFilterValue, GridType.CATEGORY);
  const { recordCountsByCategory, refetchRecordCountsByCategory } = useGetInventoryCountsByCategory(filterValue);
  const { getLazyInventoryByCategory } = useGetLazyInventoryByCategory();
  const { getLazyInventoryById } = useGetLazyInventoryById();

  useEffect(() => {
    if (recordCountsByCategory) {
      setLoading(true);
      const transformedData = transformData(recordCountsByCategory);
      const treeData = generateHierarchicalTree(null, 'id', 'parentId', transformedData);
      // Check if the treeData is different before setting state
      setData((prevData) => {
        if (JSON.stringify(prevData) !== JSON.stringify(treeData)) {
          return treeData || [];
        }
        return prevData;
      });
      setLoading(false)
    }
  }, [recordCountsByCategory]);

  useEffect(() => {
    refetchRecordCountsByCategory();
    expanded.forEach(async (nodeId) => {
      await loadInventory(nodeId);
    });
  }, [refetchTree, filterValue]);

  const transformData = (data: GroupCountOfCategory[]) => {
    return data.map((record: GroupCountOfCategory) => ({
      id: record.item?.id || "",
      name: record.item?.category !== undefined ? record.item?.category : "No Category Related",
      parentId: record.item?.parentId || null,
      count: record.count,
    }));
  };

  const handleToggle = async (event: any, nodeIds: any) => {
    const [nodeId] = nodeIds;
    if (!expandedNodes.has(nodeId)) {
      setExpandedNodes(prev => new Set(prev).add(nodeId));
      await loadInventory(nodeId);
    }
    setExpanded(nodeIds);
  };

  const handleExpandClick = () => {
    const dataIds: string[] = [];
    const collectIds = (node: any) => {
      dataIds.push(node.id);
      if (node.children) {
        node.children.forEach((child: any) => collectIds(child));
      }
    };
    data.forEach(item => collectIds(item));
    setExpanded(oldExpanded => (oldExpanded.length === 0 ? dataIds : []));
  };

  const handleChange = async (item: TreeDataItem) => {
    setSelected(item.id);
    const queryResult = await getLazyInventoryById({ variables: { inventoryId: item.id } });
    const inventory = queryResult.data?.inventoryById;
    if (inventory) {
      onChange(inventory); 
    } else {
      logger('GetInventoryById').error(`Inventory with id: ${item.id} not found`);
    }
  };  

  const loadInventory = async (categoryId: string) => {
    try {
      const filterInput = { ...filterValue, categoryId };
      const queryResult = await getLazyInventoryByCategory({ variables: { filterInput } });
      const inventory = queryResult.data?.inventory?.items || [];
      
      const inventoryNodes: TreeDataItem[] = inventory.map((entry: Inventory) => ({
        id: entry.id,
        name: buildInventoryByCategoryNodeName(entry, inventoryType),
        alertIcons: buildInventoryNodeAlertIcons(entry, inventoryType),
        value: entry.id,
        count: 0,
        children: [],
      }));
  
      // Update the inventoryMap with the processed data
      setInventoriesMap((prev) => new Map(prev).set(categoryId, inventoryNodes));
    } catch (error) {
      logger('GetInventoryByCategoryOrLocation').error(`Error loading inventory for category ${categoryId}:`, error);
      // Set an empty array in case of error
      setInventoriesMap((prev) => new Map(prev).set(categoryId, []));
    }
  };

  const renderTree = (node: TreeDataItem) => (
    <StyledTreeItem
      key={node.id}
      nodeId={node.id}
      label={
        <div className="flex flex-row items-center">
          <span className="mr-3 block font-bold">{node.name}</span>
          <Chip label={node.count} />
        </div>
      }
    >
      {node.children && node.children.map(renderTree)} {/* Recursive rendering */}
      {(inventoriesMap.get(node.id) || []).map((inventory) => (
        <Button
          key={inventory.id}
          onClick={() => handleChange(inventory)}
          sx={{ justifyContent: 'flex-start' }}
          style={selected === inventory.id ? { background: '#0a214291', color: '#fff' } : {}}
          fullWidth
          size="large"
          startIcon={inventory.alertIcons}
          variant="text"
        >
          {inventory.name}
        </Button>
      ))}
    </StyledTreeItem>
  );
  

  if (loading) {
    return (
      <div className="flex flex-grow items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      <Box sx={{ mb: 4 }}>
        <Button
          variant="contained"
          sx={{ height: 38, width: 125 }}
          startIcon={expanded.length === 0 ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
          onClick={handleExpandClick}
        >
          {expanded.length === 0 ? 'Expand' : 'Collapse'}
        </Button>
      </Box>
      <TreeView
        sx={{ width: '100%' }}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        onNodeToggle={handleToggle}
      >
        {data.map(renderTree)}
      </TreeView>
    </div>
  );
};

export default CategoryViewGrid;
