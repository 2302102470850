import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery  } from "@apollo/client";
import { EquipmentCollectionSegment } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_LAZY_EQUIPMENT_BY_LOCATION = gql`
  query GetLazyEquipmentByLocation($filterInput: EquipmentFilterInput) {
    lazyEquipmentByLocation: equipment(filterInput: $filterInput) {
      items {
        id
        uniqueName
        manufacturer
        modelNumber
        sms
        workIssuesCount
      }
    }
  }
`

interface EquipmentData {
  lazyEquipmentByLocation: EquipmentCollectionSegment;
}

interface LazyEquipmentByLocationResult {
  getLazyEquipmentByLocation: LazyQueryExecFunction<EquipmentData, OperationVariables>;
}

export const useGetLazyEquipmentByLocation = (): LazyEquipmentByLocationResult => {

  const [ getEquipmentByLocation, { error }] = useLazyQuery(GET_LAZY_EQUIPMENT_BY_LOCATION, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetLazyEquipmentByLocation').error('Error fetching equipment by location -->', error.message);
  }

  return {
    getLazyEquipmentByLocation: getEquipmentByLocation
  };
};