import { FC, useState } from 'react';
import { isNil } from 'lodash';
import moment from 'moment';
import { IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LicensedReactDataGrid from '../../../UI/LicensedReactDataGrid';
import FormRevisionPDFDialog from './FormRevisionPDFDialog';
import { utcToTimeZone } from 'src/utils/format-dates';
import { DOWNLOAD_URL } from 'src/consts';
import { downloadFile } from 'src/utils';
import { Form, FormRevision } from 'src/generated/dotnet.graphql';
import { useGetFormRevisions } from 'src/hooks/forms/useGetFormRevisions';

interface InjectedProps {
  form: Form;
}

const FormRevisions: FC<InjectedProps> = ({ form }) => {
  const { revisions, revisionsCount, revisionsLoading } = useGetFormRevisions(form.id);
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedFormRevision, setSelectedFormRevision] = useState<FormRevision>();

  const onDocumentView = (data: FormRevision) => {
    setSelectedFormRevision(data);
    setVisible(true);
  };

  const onDocumentDownload = (data: FormRevision) => {
    const downloadUrl = `${DOWNLOAD_URL}/${data.reportFile}`;
    downloadFile(downloadUrl, data.reportFile);
  };

  const onClose = () => {
    setVisible(false)
  }

  const columns = [
    {
      name: 'lastAccessedByName',
      header: 'Revised By',
      flex: 1,
    },
    {
      name: 'dateRevision',
      header: 'Date',
      headerAlign: 'start' as any,
      textAlign: 'end' as any,
      dateFormat: 'DD-MMM-YYYY HH:mm:ss',
      flex: 1,
      render: ({ value, cellProps: { dateFormat } }: any) => {
        if (!isNil(value)) {
          const UTCLogDate = utcToTimeZone(new Date(value));
          const formatedDate = moment(UTCLogDate).format(dateFormat);
          return formatedDate;
        }
      },
    },
    {
      id: 'icons',
      default: 0.4,
      render: ({ data }: any) => {
        return (
          <div className="flex justify-center">
            <IconButton
              onClick={() => onDocumentView(data)}
              size="small"
              aria-label="View item"
              className="mx-2"
            >
              <VisibilityIcon fontSize="inherit" />
            </IconButton>

            <IconButton
              onClick={() => onDocumentDownload(data)}
              size="small"
              aria-label="Download item"
              className="mx-2"
            >
              <DownloadIcon fontSize="inherit" />
            </IconButton>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <LicensedReactDataGrid
        loading={revisionsLoading}
        rowHeight={40}
        idProperty="id"
        columns={columns}
        dataSource={revisions}
        showColumnMenuTool={false}
        defaultSortInfo={[{ name: 'dateRevision', dir: -1 }]}
      />

      {selectedFormRevision && (
        <FormRevisionPDFDialog 
          visible={visible} 
          onClose={onClose}
          form={form}
          formRevision={selectedFormRevision}
        />
      )}
    </>
  );
};

export default FormRevisions;
