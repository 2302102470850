// React Components
import { FC, useEffect, useState } from 'react';
import { isNil, uniqBy, concat } from 'lodash';
import { Control, UseFormWatch } from 'react-hook-form';

// Style
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

// TDI Components
import RichTextEditor from '../../../UI/Forms/RichTextEditor';
import Autocomplete from '../../../UI/Forms/Autocomplete';
import MultiSelect from 'src/components/UI/Forms/MultiSelect';
import InspectionsScheduleForm from './InspectionsScheduleForm';
import CategoryDropdownOld from '../../../Dropdowns/CategoryDropdown/indexOld';
import Checkbox from 'src/components/UI/Forms/Checkbox';

// Data
import { TblDdListDefaultsDocument } from '../../../../api/queries/tblDDListDefaults/rxdb';
import { getDatabase } from '../../../../rxdb';

// Utils
import { toJSON } from '../../../../utils';
import { useAppState } from 'src/contexts/app-state';

interface InspectionsSummaryProps {
  form: any;
  initialValue: any;
  onChange: (field: string, value: any) => void;
  control: Control;
  watch: UseFormWatch<any>;
  setValue?: any,
  getValues: any;
  disableEdit?: boolean;
}

const InspectionsSummaryForm: FC<InspectionsSummaryProps> = ({
  form,
  onChange,
  initialValue,
  control,
  watch,
  setValue,
  getValues,
  disableEdit = false,
}) => {
  const [workList, setWorkList] = useState<TblDdListDefaultsDocument[]>([]);
  const [departments, setDepartments] = useState<TblDdListDefaultsDocument[]>([]);
  const [assignments, setAssignments] = useState<any[]>([]);
  const [recurTypes, setRecurTypes] = useState<TblDdListDefaultsDocument[]>([]);
  const { settingsPersonal } = useAppState();

  const init = async () => {
    const db = await getDatabase();

    db.tblddlistdefaults.find({ selector: { deletedBy: { $eq: null } } }).$.subscribe((defaults) => {
      const workList = defaults.filter((d) => d.fldListName === 'Work List Names' && d.fldMember !== "");
      const departments = defaults.filter((d) => d.fldListName === 'Department' && d.fldMember !== "");
      const recurTypes = defaults.filter((d) => d.fldListName === 'Recur Type' && d.fldMember !== "");

      recurTypes.sort((a: any, b: any) => a.fldMember > b.fldMember ? 1 : -1);
      
      db.crew.find({
        selector: { 
          fldPosition: { $ne: null },// Select members only with Position set.
          fldIsGuest: {$eq: false},// remove guest from list
          deletedBy: {$eq: null},// Deleted filter
          DateFire: {$eq: null},// released crew filter
       }
      }).$.subscribe((crewItems: any) => {
          const assignmentPositions = uniqBy<any>(crewItems, (a) => a.fldPosition).map((a) => a.fldPosition);
          const assignmentNames = uniqBy<any>(crewItems, (a) => a.fldFirst + ' ' + a.fldLast).map((a) => a.fldFirst + ' ' + a.fldLast);
          const deptNames = uniqBy<any>(departments, (a) => a.fldMember).map((a) => a.fldMember);
          setAssignments(concat(deptNames, assignmentPositions, assignmentNames).map((item: any) => ({ name: `${item}` })));
        });

      setWorkList(toJSON(workList));
      setDepartments(toJSON(departments));
      setRecurTypes(toJSON(recurTypes));
    });
  };

  useEffect(() => {
    if (isNil(initialValue)) return;
    init();
  }, [initialValue]);

  const renderListDefaultBox = (
    items: TblDdListDefaultsDocument[],
    fieldName: string,
    fldListName: string,
    freeSolo = false,
    required = false,
  ) => {
    const sortedData = items?.sort((a: any, b: any) =>
      a.fldMember.localeCompare(b.fldMember)
    );
    return (
      <Autocomplete
        defaultPayload={{
          fldListName,
          fldT4Reqd: true,
          fldIndex: null,
        }}
        displayExpr="fldMember"
        keyExpr="PKey"
        onChange={onChange}
        freeSolo={freeSolo}
        options={sortedData}
        label={fldListName}
        control={control}
        name={fieldName}
        disabled={fldListName === 'Department' ? disableEdit : false}
        collection="tblddlistdefaults" // Use this in order to create new items.
        rules={{ required }}
      />
    );
  };

  let recurTypesOptions: any[] = [];
  const category = watch('fldSRHKey', null);
  const recurTypesOptionNames: string[] = ["Daily", "Weekly", "Monthly", "Yearly", "Event Based"];
  
  recurTypesOptionNames.forEach((value: string) => {
    recurTypesOptions.push({
      fldMember: value,
    });
  });
  
  recurTypesOptions.push({
    divider: true,
    fldMember: "",
  });
  
  recurTypesOptions = recurTypesOptions.concat(...recurTypes);

  const fldHourInterval = watch("fldHourInterval");

  useEffect(() => {
    if(fldHourInterval && isNil(initialValue.fldHoursTrigger)) {
      setValue("fldHoursTrigger", Number(fldHourInterval));
    }
  }, [fldHourInterval]);
  
  return (
    <div className="mt-3">
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="general-information"
          id="general-information"
        >
          General Information
        </AccordionSummary>
        <AccordionDetails>
          {/* Category Hierarchial, collapsable Tree */}
          <div className="mt-3">
            <CategoryDropdownOld
              name="fldSRHKey"
              label="Category"
              defaultValue={initialValue.fldSRHKey}
              control={control}
              onChange={onChange}
              rules={{ required: true }}
              sourceForm="inspections"
              allDepts={settingsPersonal?.fldAllDepts > 0}
            />
          </div>

          <div className="mt-4">
            <RichTextEditor
              control={control}
              name="fldHTML"
              onChange={onChange}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion className="mt-3" defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="Schedule"
          id="Schedule"
        >
          Schedule
        </AccordionSummary>
        <AccordionDetails>
          <InspectionsScheduleForm
            watch={watch}
            control={control}
            initialValue={initialValue}
            form={form}
            onChange={onChange}
            getValues={getValues}
            setValue={setValue}
            disableEdit={disableEdit}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion className="mt-3" defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="work-list-and-regulatory-information"
          id="work-list-and-regulatory-information"
        >
          Work List and Regulatory Information
        </AccordionSummary>
        <AccordionDetails>
          <div className="pt-4">
            <MultiSelect
                control={control}
                name="fldAssignedTo"
                keyExpr="AssignedTo"
                displayExpr="name"
                options={assignments}
                label="Assign to..."
                onChange={onChange}
                freeSolo={false}
              />
          </div>
          <div className="mt-4">
            {renderListDefaultBox(
              departments,
              'Department',
              'Department',
              false,
              true
            )}
          </div>
          <div className="mt-4">
            {renderListDefaultBox(
              workList,
              'fldListType',
              'Work List Names',
              true,
              true
            )}
          </div>
        </AccordionDetails>
        <div className="mt-3">
          <Checkbox
            control={control}
            name="fldSMS"
            label="Critical Schedule (SMS Required)"
          />
      </div>
      </Accordion>
    </div>
  );
};

export default InspectionsSummaryForm;
