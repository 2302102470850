import './style.css';
import { FC, useCallback, useState, useRef, useEffect } from 'react';
import { isEmpty, isNil } from 'lodash';
import { TypeFilterValue, TypeSingleSortInfo, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import { InventoryFilterInput, InventorySortInput, InventoryType, SortEnumType } from 'src/generated/dotnet.graphql';
import { useGetInventoryForLocation } from 'src/hooks/inventory/useGetInventoryForLocation';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';

const filter = [
  {
    name: 'productName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'manufacturer',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'partNumber',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];
  
  const getSelectorByFilterName = async (
    name: string,
    value: any,
  ) => {
  
    switch (name) { 
      case 'productName':
      case 'manufacturer':
      case 'partNumber': {
        return {
          [name]: value,
        };
      }
      default:
        return {};
    }
  };

type InjectedProps = {
  excludeIds: string[];
  inventoryType: InventoryType;
  onSelect: (inventoryIds: string[]) => void;
};

const AddInventoryToLocationGrid: FC<InjectedProps> = ({
  excludeIds,
  inventoryType,
  onSelect,
}) => {
  const defaultFilterValue = { inventoryType, excludeIds };
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(100);
  const [filterValue, setFilterValue] = useState<InventoryFilterInput>(defaultFilterValue);
  const [sortValue, setSortValue] = useState<InventorySortInput[]>([{ productName: SortEnumType.Asc }]);
  const { data, totalCount, loading, loadData } = useGetInventoryForLocation(skip, limit, filterValue, sortValue);

  const prevFilterValue = useRef(filterValue);
  const prevSkip = useRef(skip);
  const prevLimit = useRef(limit);
  const prevSortValue = useRef(sortValue);

  useEffect(() => {
    const isFilterChanged = JSON.stringify(filterValue) !== JSON.stringify(prevFilterValue.current);
    const isPaginationChanged = skip !== prevSkip.current || limit !== prevLimit.current;
    const isSortChanged = JSON.stringify(sortValue) !== JSON.stringify(prevSortValue.current);
  
    if (isFilterChanged || isPaginationChanged || isSortChanged) {
      loadData(skip, limit, filterValue, sortValue);
      prevFilterValue.current = filterValue;
      prevSkip.current = skip;
      prevLimit.current = limit;
      prevSortValue.current = sortValue;
    }
  }, [skip, limit, filterValue, sortValue]);

  const dataSource = async () => {
    return {
      data,
      count: totalCount,
    };
  };

  const onSortInfoChange = (value: TypeSortInfo) => {
    const sortInfo = value as TypeSingleSortInfo
    if (isNil(sortInfo)) return;

    let sortPayload: InventorySortInput[];
    const sortDirection = sortInfo.dir === 1 ? SortEnumType.Asc : SortEnumType.Desc;
    const [field, subField] = sortInfo.name.split('.');

    if (subField) {
        // Handle nested objects
        sortPayload = [{
            [field]: {
                [subField]: sortDirection,
            },
        }];
    } else {
        // Handle non-nested objects
        sortPayload = [{
            [sortInfo.name]: sortDirection,
        }];
    }
    setSortValue(sortPayload)
  }

  const onFilterValueChange = async (filterValue: TypeFilterValue) => {
    if (isNil(filterValue)) return;

    const filterPayload: Record<string, any> = {};

    await Promise.all(
      filterValue.map(async (v: { value: any; name: any; operator: any }) => {
        if (isEmpty(v.value)) return;

        const selector = await getSelectorByFilterName(v.name, v.value);
        if (selector) {
          Object.keys(selector).forEach((key) => {
            filterPayload[key] = selector[key];
          });
        }
      })
    );
    setFilterValue({
      ...defaultFilterValue,
      ...filterPayload,
    });
  };

  const onSelectionChange = useCallback(({ selected: selectedMap }) => {
    const inventoryIds = Object.keys(selectedMap);
    onSelect(inventoryIds);
  }, []);

  const columns = [
    {
      name: 'productName',
      header: 'Product Name',
      flex: 1,
    },

    {
      name: 'manufacturer',
      header: 'Manufacturer',
      flex: 1,
    },

    {
      name: 'partNumber',
      header: 'Part Number',
      flex: 1,
    },
  ];

  return (
    <div data-testid="data-grid" className="flex flex-col flex-grow p-4 inv-add-to-location-grid">
      <LicensedReactDataGrid
        idProperty="id"
        skip={skip}
        onSkipChange={setSkip}
        limit={limit}
        onLimitChange={setLimit}
        pagination='remote'
        rowHeight={40}
        loading={loading}
        defaultFilterValue={filter}
        onFilterValueChange={onFilterValueChange}
        onSortInfoChange={onSortInfoChange}
        allowUnsort={false}
        checkboxColumn
        defaultSelected={[]}
        onSelectionChange={onSelectionChange}
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  );
};

export default AddInventoryToLocationGrid;
