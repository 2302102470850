import './styles.css';
import { useEffect, useState, useRef, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { Alert, Snackbar, AlertColor, FormControl, TextField, InputAdornment, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PageDrawer from '../../components/PageDrawer';
import PageHeader, { Action } from '../../components/UI/PageHeader';
import { GRID_LIMIT, SEVERITY, UpdateRecordInputType, UpdateRecordsOptionsType, GridType, INVENTORY, FormType } from 'src/consts';
import { AccessType, getAccessType } from 'src/utils/permissions';
import { saveViewMode, getViewMode, isEmptyObject } from '../../utils'
import { useAppState } from 'src/contexts/app-state';
import { BulkModifyInventoryInput, DeleteResult, Inventory, InventoryFilterInput, InventorySortInput, InventoryType, RecordType, SortEnumType } from 'src/generated/dotnet.graphql';
import MasterSearchInput, { MasterSearchInputValue } from 'src/components/MasterSearchInput';
import useMergedFilters from 'src/hooks/common/useMergedFilters';
import ViewSelector from 'src/components/ViewSelector';
import DeleteRecordBtn from 'src/components/DeleteRecordsButton';
import UpdateRecordsButton from 'src/components/UpdateRecordsButton';
import InventoryGrid from './components/InventoryGrid';
import InventoryDetailForm from 'src/components/PageDrawer/Inventory/InventoryDetailForm';
import CategoryViewGrid from './components/CategoryViewGrid';
import LocationViewGrid from './components/LocationViewGrid';
import CardViewGrid from './components/CardViewGrid';
import { useForm } from 'react-hook-form';
import CardViewLocationDropdown from './components/CardViewLocationDropdown';
import { isString, last } from 'lodash';
import { useGetLazyInventoryByBarcode } from 'src/hooks/inventory/useGetInventoryByBarcode';
import BarcodeExactMatchInventoryDialog from 'src/components/PageDrawer/Inventory/component/BarCodes/BarcodeExactMatchInventoryDialog';
import BarcodeMultipleMatchesInventoryDialog from 'src/components/PageDrawer/Inventory/component/BarCodes/BarcodeMultipleMatchesInventoryDialog';
import { useBulkDeleteInventory } from 'src/hooks/inventory/useBulkDeleteInventory';
import { useBulkUpdateInventory } from 'src/hooks/inventory/useBulkUpdateInventory';
import InventoriesToLocationButton from 'src/components/InventoriesToLocationButton';

export const GENERAL_INV_RECORDS_UPDATE_OPTIONS: UpdateRecordsOptionsType = {
  CATEGORY: { fieldName: 'categoryId', columnName: 'Category' },
  MANUFACTURER: { fieldName: 'manufacturer', columnName: 'Manufacturer' },
  DEPARTMENT: { fieldName: 'department', columnName: 'Department' },
  CONSUMABLE: { fieldName: 'consumable', columnName: 'Consumable' },
  BONDED: { fieldName: 'bonded', columnName: 'Bonded' },
  SMS: { fieldName: 'sms', columnName: 'Critical' },
  RE_ORDER_LEVEL: { fieldName: 'reOrderLevel', columnName: 'Minimum Holding Level' },
}

export const BEVERAGES_RECORDS_UPDATE_OPTIONS: UpdateRecordsOptionsType = {
  CATEGORY: { fieldName: 'categoryId', columnName: 'Category' },
  MANUFACTURER: { fieldName: 'manufacturer', columnName: 'Maker/Bottler' },
  PART_NUMBER: { fieldName: 'partNumber', columnName: 'Varietal' },
  COUNTRY: { fieldName: 'country', columnName: 'Country' },
  REGION: { fieldName: 'region', columnName: 'Region' },
  MODEL_NUMBER: { fieldName: 'modelNumber', columnName: 'Vintage' },
  SIZE: { fieldName: 'size', columnName: 'Size' },
  BONDED: { fieldName: 'bonded', columnName: 'Bonded' },
  RE_ORDER_LEVEL: { fieldName: 'reOrderLevel', columnName: 'Minimum Holding Level' },
}

export const UNIFORM_RECORDS_UPDATE_OPTIONS: UpdateRecordsOptionsType = {
  CATEGORY: { fieldName: 'categoryId', columnName: 'Category' },
  MANUFACTURER: { fieldName: 'manufacturer', columnName: 'Manufacturer' },
  MODEL_NUMBER: { fieldName: 'modelNumber', columnName: 'Style' },
  COLOR: { fieldName: 'color', columnName: 'Color' },
  SIZE: { fieldName: 'size', columnName: 'Size' },
  RE_ORDER_LEVEL: { fieldName: 'reOrderLevel', columnName: 'Minimum Holding Level' },
}

export const STORES_AND_MEDICAL_RECORDS_UPDATE_OPTIONS: UpdateRecordsOptionsType = {
  CATEGORY: { fieldName: 'categoryId', columnName: 'Category' },
  MANUFACTURER: { fieldName: 'manufacturer', columnName: 'Manufacturer' },
  RE_ORDER_LEVEL: { fieldName: 'reOrderLevel', columnName: 'Minimum Holding Level' },
}

export const defaultSortValue: InventorySortInput[] = [{ productName: SortEnumType.Asc }];

const InventoriesPage = () => {
    // Global state
  const { control } = useForm<any>();
  const { user } = useAuth();
  const { settingsPersonal } = useAppState();
  const history = useHistory();
  // State to track filter values by library type
  const { type } = useParams<{ type: string }>();
  const inventoryInfo = Object.values(INVENTORY).find((v) => v.PATH === type);
  const [filterValuesByInventory, setFilterValuesByInventory] = useState<Record<string, InventoryFilterInput>>({});
  const defaultFilterValue = { 
    inventoryType: inventoryInfo?.TYPE as InventoryType,
    searchText: undefined,
    isInStock: undefined,
    isBelowMinimum: undefined,
    hasExpiredAtALocation: undefined,
    isConsumable: undefined,
    isCritical: undefined,
  };
  // Grid pagination state - keep it on parent component so it can be used for mutations refetching
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(GRID_LIMIT);
  const [barcodeSearchValue, setBarcodeSearchValue] = useState<string>('');
  const [masterSearchInput, setMasterSearchInput] = useState<MasterSearchInputValue | null>(null);
  const [filterValue, setFilterValue] = useMergedFilters<InventoryFilterInput>(masterSearchInput, defaultFilterValue, GridType.DEFAULT);
  const [sortValue, setSortValue] = useState<InventorySortInput[]>(defaultSortValue);
  const [rowSelected, setRowSelected] = useState<Record<string, Inventory>>({});
  // Apollo client mutations
  const { bulkRemoveInventory } = useBulkDeleteInventory();
  const { bulkUpdateInventory, bulkUpdateInventoryLoading } = useBulkUpdateInventory();
  const { getLazyInventoryByBarcode } = useGetLazyInventoryByBarcode();
  // Component related state
  const gridRef = useRef<any>(null);
  const isMobile = useMediaQuery('(max-width: 420px)');
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [viewType, setViewType] = useState<GridType>(GridType.DEFAULT);  
  const [savedViewList, setSavedViewList] = useState<any>([]);
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const oldSelectedItem = useRef<Inventory | null>(null);
  const [selectedInventory, setSelectedInventory] = useState<Inventory>();
  const [locationSelected, setLocationSelected] = useState<string>();
  const [isInventoryDeleted, setIsInventoryDeleted] = useState<boolean>(false); 
  const [refetchTree, setRefetchTree] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();
  const [recordType, setRecordType] = useState<RecordType>(RecordType.Undefined);
  const [pageTitle, setPageTitle] = useState<string>(INVENTORY.UNDEFINED.TITLE);
  const refetchQueryVariables = { skip: skip, take: limit, filterInput: filterValue, order: sortValue };
  const [barcodeExactMatch, setBarcodeExactMatch] = useState<Inventory | null>(null);
  const [barcodeMultipleMatches, setBarcodeMultipleMatches] = useState<Inventory[] | null>(null);

  const initializeViewMode = async () => {
    const mode = await getViewMode();
    if (mode) setViewType(mode);
  };
  
  useEffect(() => {
    initializeViewMode();
  }, []);

  const initializeInventoryType = () => {
    switch (type) {
      case 'generalInventory':
        setRecordType(RecordType.InventoryGeneral);
        setPageTitle(INVENTORY.GENERAL.TITLE);
        break;
      case 'beverages':
        setRecordType(RecordType.InventoryBeverages);
        setPageTitle(INVENTORY.BEVERAGES.TITLE);
        break;
      case 'stores':
        setRecordType(RecordType.InventoryDryAndColdStore);
        setPageTitle(INVENTORY.DRY_STORES.TITLE);
        break;
      case 'uniform':
        setRecordType(RecordType.InventoryUniform);
        setPageTitle(INVENTORY.UNIFORM.TITLE);
        break;
      case 'medical':
        setRecordType(RecordType.InventoryMedical);
        setPageTitle(INVENTORY.MEDICAL.TITLE);
        break;
      default:
        setRecordType(RecordType.Undefined);
        setPageTitle(INVENTORY.UNDEFINED.TITLE);
        break;
    }
  };

  useEffect(() => {
    initializeInventoryType();
    setLocationSelected(undefined);
    setSelectedInventory(undefined);
    setRowSelected({}) // Clear grid selection on inventory type change
  }, [type]);
  
  const moduleReadOnly = useMemo(() => {
    switch (type) {
      case 'generalInventory':
        return getAccessType(settingsPersonal?.fldInv) !== AccessType.FULL_ACCESS;
      case 'beverages':
        return getAccessType(settingsPersonal?.fldInvBev) !== AccessType.FULL_ACCESS;
      case 'stores':
        return getAccessType(settingsPersonal?.fldInvStores) !== AccessType.FULL_ACCESS;
      case 'uniform':
        return getAccessType(settingsPersonal?.fldInvUni) !== AccessType.FULL_ACCESS;
      case 'medical':
        return getAccessType(settingsPersonal?.fldInvMed) !== AccessType.FULL_ACCESS;
      default:
        return false;
    }
  }, [type])

  // Store and retrieve filter values by inventory type
  const storeFilterValue = (inventoryType: string, filterValue: InventoryFilterInput) => {
    setFilterValuesByInventory((prev) => ({
      ...prev,
      [inventoryType]: filterValue,
    }));
  };

  const restoreFilterValue = (inventoryType: string): InventoryFilterInput | undefined => {
    return filterValuesByInventory[inventoryType];
  };

  useEffect(() => {
    const previousFilterValue = restoreFilterValue(inventoryInfo?.TYPE as string);
    // If there's a previously stored filter value, use that, otherwise use the default
    setFilterValue(previousFilterValue ?? defaultFilterValue);
  }, [inventoryInfo?.TYPE]);

  // When the filter value changes, store it by inventory type
  useEffect(() => {
    if (filterValue) {
      storeFilterValue(inventoryInfo?.TYPE as string, filterValue);
    }
  }, [filterValue, inventoryInfo?.TYPE]);

  useEffect(() => {
    // Reset filter value when the grid view changes
    setFilterValue(defaultFilterValue);
  }, [viewType]);
  
  const handleCreateClick = async () => {
    setIsCreate(true);
    const document: any = {
      categoryId: null,
      department: user?.Department,
      inventoryType: inventoryInfo?.TYPE as InventoryType
    };
    setSelectedInventory(document);
  };
  
  const handleSave = async (responseData: Inventory, responseMessage: string, isCreated: boolean) => {
    if (isCreated) {
      setSelectedInventory(responseData);
      oldSelectedItem.current = responseData;
      setIsCreate(false);
    }

    if (viewType !== GridType.DEFAULT) {
      setRefetchTree(!refetchTree);
      renderContent();
    }

    handleBarcodeExactMatchCancel();

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const handleDelete = async (responseData: DeleteResult, responseMessage: string) => {
    if (responseData.success) {
      setSelectedInventory(undefined);
    }

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });

    if (viewType !== GridType.DEFAULT) {
      setIsInventoryDeleted(!isInventoryDeleted);
      setRefetchTree(!refetchTree);
      renderContent();
    }
  };

  const handleDeleteMultipleRecords = async (validRecords: string[], invalidMessage: string) => {
    if (invalidMessage) {
      setSnackbar({
        message: invalidMessage,
        severity: 'error',
      });
    }

    if (validRecords.length > 0) {
      const { responseData, responseMessage } = await bulkRemoveInventory(validRecords, refetchQueryVariables);
      setRowSelected({});

      setSnackbar({
        message: responseMessage,
        severity: responseData?.success ? SEVERITY.SUCCESS : SEVERITY.ERROR,
      });

      if (viewType !== GridType.DEFAULT) {
        setRefetchTree(!refetchTree);
        renderContent();
      }
    }
  };

  const handleUpdateMultipleRecords = async (payload: UpdateRecordInputType) => {
    const selectedRecordsIds: string[] = Object.keys(rowSelected || []);
    if (selectedRecordsIds.length > 0) {

      const input: BulkModifyInventoryInput = {
        ids: selectedRecordsIds,
        [payload.fieldName]: payload.fieldName === 'reOrderLevel' ? parseInt(payload.value) : payload.value,
      }

      const { responseData, responseMessage } = await bulkUpdateInventory(input, payload.columnName, refetchQueryVariables);
      setRowSelected({});

      setSnackbar({
        message: responseMessage,
        severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
      });

      if (viewType !== GridType.DEFAULT) {
        setRefetchTree(!refetchTree);
        renderContent();
      }
    }
  };

  const handleManageInventoryToLocation = async () => {
    if (viewType !== GridType.DEFAULT) {
      setRefetchTree(!refetchTree);
      renderContent();
    }
  };

  const handleCancel = () => {
    setIsCreate(false);
    setSelectedInventory(undefined);
    oldSelectedItem.current = null;
  };

  const handleBarcodeMultipleMatchesCancel = () => {
    setBarcodeMultipleMatches(null);
    setBarcodeSearchValue('');
  };

  const handleBarcodeExactMatchCancel = () => {
    setBarcodeExactMatch(null);
    setBarcodeSearchValue('');
  };

  const onSelect = (document: Inventory) => {
    setSelectedInventory(document);
    oldSelectedItem.current = document;
  };

  const onSelectChange = async (selectedDocument: Inventory) => {
    setSelectedInventory(selectedDocument);
  };

  const refreshGridView = () => {
    setRefetchTree(!refetchTree)
  }

  const isTableOriginal = useMemo(() => {
    if (viewType === GridType.CATEGORY || viewType === GridType.LOCATION || viewType === GridType.DEFAULT) {
      return true;
    } else if(viewType === GridType.CARDS) {
      setLocationSelected(undefined); // Reseting location when change views so the Cards view will reset after leave the page
      return true;
    } else {
      return false;
    }
  }, [viewType])

  const renderContent = () => {
    let gridType = viewType;
    if (!isTableOriginal) {
      const savedViewItem = savedViewList.find((item: any) => item.id === gridType);
      if (savedViewItem) {
        gridType = JSON.parse(savedViewItem.layout).mode;
      }
    }
    switch (viewType) {
      case GridType.CATEGORY:
        return (
          <div
            style={{ maxHeight: containerRef.current?.clientHeight || 0 }}
            className="flex-grow h-full w-full flex overflow-y-auto"
          >
            <CategoryViewGrid
              masterSearchInput={masterSearchInput} 
              onChange={onSelectChange} 
              refetchTree={refetchTree} 
              inventoryType={inventoryInfo?.TYPE as InventoryType} 
            />
          </div>
        );
      case GridType.LOCATION:
        return (
          <div
            style={{ maxHeight: containerRef.current?.clientHeight || 0 }}
            className="flex-grow h-full w-full flex overflow-y-auto"
          >
            <LocationViewGrid
              masterSearchInput={masterSearchInput} 
              onChange={onSelectChange} 
              refetchTree={refetchTree} 
              inventoryType={inventoryInfo?.TYPE as InventoryType} 
            />
          </div>
        );
      case GridType.CARDS:
        return (
            <CardViewGrid 
              locationSelected={locationSelected}
              onChange={onSelectChange}
              refetchTree={refetchTree}
              inventoryType={inventoryInfo?.TYPE as InventoryType}
              recordType={recordType}
              moduleReadOnly={moduleReadOnly}
              isInventoryDeleted={isInventoryDeleted}
            />
        )
      default:
        return (
          <div className="flex flex-grow h-full">
            <InventoryGrid
              darken 
              refetchTree={refetchTree} 
              onSelect={onSelect}
              ref={gridRef} 
              skip={skip}
              setSkip={setSkip}
              limit={limit}
              setLimit={setLimit}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              sortValue={sortValue}
              setSortValue={setSortValue} 
              rowSelected={rowSelected}
              setRowSelected={setRowSelected}
              inventoryType={inventoryInfo?.TYPE as InventoryType}
            />
          </div>
        );
    }
  };

  const handleSelectLocation = (value: string[]) => {
    setLocationSelected(last(value))
  }

  const handleDataExport = (type: string) => {
    gridRef?.current.handleExport(type)
  }

  const renderView = () => (
    <ViewSelector
      defaultOptions={[GridType.DEFAULT, GridType.CATEGORY, GridType.LOCATION, GridType.CARDS]}
      contentType={viewType}
      setContentType={setViewType}
      saveViewMode={saveViewMode}
      onSavedViewList={setSavedViewList}
      onDataExport={handleDataExport}
    />
  );

  const onInventorySearch = (isReset: boolean, searchValue: MasterSearchInputValue) => {
    if(isReset) {
      setMasterSearchInput(null);
      setFilterValue(defaultFilterValue);
      setSkip(0);
    }
    else {
      setMasterSearchInput(searchValue);
      setSkip(0);
    }
  };

  const renderMasterSearch = () => (
    <MasterSearchInput
      key={recordType}
      recordType={recordType}
      label={`${pageTitle} search`}
      onChange={onInventorySearch}
    />
  )

  const onBarcodeSearchChange = (data: any) => {
    setBarcodeSearchValue(isString(data) ? data : data.target.value);
  };

  const onBarcodeSearchKeyDown = async (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      const queryResult = await getLazyInventoryByBarcode({ variables: { barcode: barcodeSearchValue} });
      const inventoryByBarcode = queryResult.data?.inventoryByBarcode;
      if (inventoryByBarcode) {
        if (inventoryByBarcode.length === 1) {
          // Exact match: only one item found
          setBarcodeMultipleMatches([]); // Clear multiple matches
          setBarcodeExactMatch(inventoryByBarcode[0]);
        } else if (inventoryByBarcode.length > 1) {
          // Multiple matches: more than one item found
          setBarcodeExactMatch(null); // Clear exact match
          setBarcodeMultipleMatches(inventoryByBarcode);
        } else {
          // No matches: reset both states
          setSnackbar({
            message: "No barcode matches found!",
            severity: SEVERITY.WARNING,
          });
          setBarcodeExactMatch(null);
          setBarcodeMultipleMatches([]);
        }
      }
    }
  };

  const renderSearch = () => (
    <FormControl>
      <TextField
        sx={{ 
          height: 37,
          width: '100%', // Ensure input takes available width
          maxWidth: '300px', // Set a max width to make it narrower
            }}
        id="outlined-basic"        
        type="text"
        variant="outlined"
        placeholder={isMobile ? "Bar Code..." : "Search Bar Code..."}
        value={barcodeSearchValue}
        size="small"
        autoComplete='off'
        onChange={onBarcodeSearchChange}
        onKeyDown={onBarcodeSearchKeyDown}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>      
  );

  const renderCardViewLocationDropdown = () => (
    <CardViewLocationDropdown
      control={control}
      name="locationId"
      onChange={(_event, value: any) => {handleSelectLocation(value)}}
      inventoryType={inventoryInfo?.TYPE as InventoryType}
      dropdownHeight={700}
    />
  );

  if (!inventoryInfo) {
    history.push('/');
    return null;
  }

  const getFilteredUpdateOptions = (inventoryType: string | null): UpdateRecordsOptionsType => {
    switch (inventoryType) {
      case InventoryType.GeneralInventory:
        return GENERAL_INV_RECORDS_UPDATE_OPTIONS;
      case InventoryType.Beverages:
        return BEVERAGES_RECORDS_UPDATE_OPTIONS;
      case InventoryType.Uniform:
        return UNIFORM_RECORDS_UPDATE_OPTIONS;
      case InventoryType.DryAndColdStore:
      case InventoryType.Medical:
        return STORES_AND_MEDICAL_RECORDS_UPDATE_OPTIONS;
      default:
        return {};
    }
  };
  
  const actions: Action[] = [
    {
      customComponent: (
        <InventoriesToLocationButton
          onSubmit={handleManageInventoryToLocation}
          visible={moduleReadOnly}
          inventoryType={inventoryInfo?.TYPE as InventoryType}
          inventoryTypeTitle={pageTitle}
        />
      ),
    },
    {
      icon: 'add',
      title: 'New entry',
      onClick: handleCreateClick,
      disabled: moduleReadOnly
    }
  ]

  if(viewType === GridType.DEFAULT) {
    actions.unshift(
      {
        customComponent: (
          <UpdateRecordsButton
            onSubmit={handleUpdateMultipleRecords}
            visible={moduleReadOnly || isEmptyObject(rowSelected)}
            recordType={recordType}
            recordsNumber={rowSelected && Object.keys(rowSelected).length}
            options={getFilteredUpdateOptions(inventoryInfo.TYPE)}
            saveLoading={bulkUpdateInventoryLoading}
          />
        ),
      },
      {
        customComponent: (
          <DeleteRecordBtn
            visible={moduleReadOnly || isEmptyObject(rowSelected)}
            records={rowSelected}
            onValidate={handleDeleteMultipleRecords}
          />
        ),
      }
    )
  }

  return (
    <div className="flex flex-grow flex-col overflow-auto">
      <PageHeader
        title={pageTitle}
        actions={actions}
        renderView={renderView}
        renderSearch={viewType !== GridType.CARDS && renderSearch}
        renderMasterSearch={viewType !== GridType.CARDS && renderMasterSearch}
        renderLocationDropdown={viewType === GridType.CARDS && renderCardViewLocationDropdown}
      >
        <div className="flex md:hidden">{renderView()}</div>
      </PageHeader>

      <div ref={containerRef} className="flex flex-grow flex-col w-full pt-4">
        {renderContent()}
      </div>

      <PageDrawer 
        onClose={handleCancel} 
        visible={!!selectedInventory} 
      >
        {!!selectedInventory && (
          <InventoryDetailForm
            viewType={viewType}
            initialValue={selectedInventory}
            recordType={recordType}
            isCreate={isCreate}
            onSave={handleSave}
            refreshGridView={refreshGridView}
            onDelete={handleDelete}
            onCancel={handleCancel}
            refetchQueryVariables={refetchQueryVariables}
            inventoryType={inventoryInfo?.TYPE as InventoryType}
            moduleReadOnly={moduleReadOnly}
          />
        )}
      </PageDrawer>

      {barcodeMultipleMatches && barcodeMultipleMatches.length > 0 && (
        <BarcodeMultipleMatchesInventoryDialog 
          onCancel={handleBarcodeMultipleMatchesCancel}
          visible={!!barcodeMultipleMatches}
          barcodeMatches={barcodeMultipleMatches}
          onSelect={setBarcodeExactMatch}
        />
      )}

      {barcodeExactMatch && (
        <BarcodeExactMatchInventoryDialog
          visible={!!barcodeExactMatch}
          inventory={barcodeExactMatch}
          onSave={handleSave}
          onDelete={handleDelete}
          onCancel={handleBarcodeExactMatchCancel}
          type={FormType.DIALOG}
          moduleReadOnly={moduleReadOnly}
        />
      )}

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </div>
  );
};

export default InventoriesPage;
