import { useState, useEffect, useMemo } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, useMediaQuery, useTheme, Select, FormLabel, Radio, RadioGroup, FormControlLabel, TextField, Divider } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useAppState } from 'src/contexts/app-state';
import { useAuth } from 'src/contexts/auth';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useGetGridSavedViews } from 'src/hooks/gridSavedViews/useGetGridSavedViews';
import { useUpsertGridSavedView } from 'src/hooks/gridSavedViews/useUpsertGridSavedView';
import { useDeleteGridSavedView } from 'src/hooks/gridSavedViews/useDeleteGridSavedView';
import { GridSavedViewInput, GridSavedView } from 'src/generated/dotnet.graphql';

export enum GridType {
  LOCATION = 'Location',
  CATEGORY = 'Category',
  EQUIPMENT = 'Equipment',
  DEFAULT = 'Grid',
  CARDS = 'Cards',
  SAVE_VIEW = 'Save View',
  EXPORT_EXCEL = 'Export Excel',
  EXPORT_CSV = 'Export CSV',
  ISSUEDTO = 'Issued To',
}

const METHOD_CREATE = 'METHOD_CREATE';
const METHOD_UPDATE = 'METHOD_UPDATE';
const DEFAULT_EXPORT_OPTIONS = [GridType.EXPORT_EXCEL, GridType.EXPORT_CSV];

const ViewSelector = (props: any) => {
  const { defaultOptions, exportOptions = DEFAULT_EXPORT_OPTIONS, contentType, setContentType, saveViewMode, onSavedViewList } = props;
  const { control, formState: { errors }, setError, handleSubmit, getValues, reset } = useForm();
  const { user } = useAuth();
  const history = useHistory();
  const theme = useTheme();
  const { tableState, hierarchyState, setTableState, setHierarchyState } = useAppState();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [isOpenSave, setOpenSave] = useState(false);
  const [method, setMethod] = useState(METHOD_CREATE);
  const { gridSavedViews } = useGetGridSavedViews(history.location.pathname);
  const { upsertGridSavedView } = useUpsertGridSavedView();
  const { removeGridSavedView } = useDeleteGridSavedView();

  useEffect(() => {
    if (gridSavedViews && onSavedViewList) {
      onSavedViewList(gridSavedViews);
    }
  }, [gridSavedViews]);

  const handleRemoveLayout = async (evt: any, item: any) => {
    evt.stopPropagation();
    await removeGridSavedView(item.id, {
      moduleName: history.location.pathname,
    });

  // Check if the currently selected view is the one being deleted
    if (contentType === item.id) {
      // Reset contentType to the default or another view
      const defaultView = GridType.DEFAULT;  // Change this to any default you prefer
      setContentType(defaultView);
      saveViewMode(defaultView);

      // Reset the table and hierarchy state if needed
      setTableState({
        ...tableState,
        [history.location.pathname]: null,
      });
      setHierarchyState({
        ...hierarchyState,
        [history.location.pathname]: null,
      });
  }
  };

  const saveGridState = () => {
    if (method === METHOD_CREATE) {
      const name = getValues('name');
      const isExist = gridSavedViews.find((item: GridSavedView) => item.viewName === name);
      if (isExist) {
        setError('name', { type: 'exiting' }, { shouldFocus: true });
        return;
      }
      handleSaveLayout(name, true);
    } else {
      handleSaveLayout('', false);
    }
  };

  const handleSaveLayout = async (name: string, isCreated: boolean) => {
    try {
      const savedViewItem = gridSavedViews.find((item: GridSavedView) => item.id === contentType);
      let id = '', viewName = '', layout: any = {};

      if (isCreated) {
        viewName = name;
        if (savedViewItem && savedViewItem.layout) {
          const savedViewItemLayout = JSON.parse(savedViewItem.layout).mode;
          layout = {
            mode: savedViewItemLayout,
            detail:
              savedViewItemLayout === GridType.DEFAULT
                ? tableState[history.location.pathname]
                : {
                    selectedKey:
                      hierarchyState[history.location.pathname].selectedKey,
                    expanded:
                      hierarchyState[history.location.pathname].expanded,
                  },
          };
        } else {
          layout = {
            mode: contentType,
            detail:
              contentType === GridType.DEFAULT
                ? tableState[history.location.pathname]
                : {
                    selectedKey:
                      hierarchyState[history.location.pathname].selectedKey,
                    expanded:
                      hierarchyState[history.location.pathname].expanded,
                  },
          };
        }
      } else {
        if (savedViewItem && savedViewItem.viewName && savedViewItem.layout) {
          viewName = savedViewItem.viewName;
          id = savedViewItem.id;
          const savedViewItemLayout = JSON.parse(savedViewItem.layout).mode;
          layout = {
            mode: savedViewItemLayout,
            detail:
              savedViewItemLayout === GridType.DEFAULT
                ? tableState[history.location.pathname]
                : {
                    selectedKey:
                      hierarchyState[history.location.pathname].selectedKey,
                    expanded:
                      hierarchyState[history.location.pathname].expanded,
                  },
          };
        }
      }

      const payload: GridSavedViewInput = {
        id: id || null,
        crewId: user?.fldCrewID,
        layout: JSON.stringify(layout),
        module: history.location.pathname,
        viewName: viewName,
      };

      await upsertGridSavedView(payload, isCreated, history.location.pathname);
      
      setOpenSave(false);
      reset();
      setMethod(METHOD_CREATE);
    } catch (error) {
      console.log('save layout error --', error);
    }
  };

  const handleChangeMethod = (event: any) => {
    setMethod(event.target.value);
  };

  const handleCancel = () => {
    setOpenSave(false);
    setMethod(METHOD_CREATE);
    reset();
  };

  const handleExport = (type: any) => {
    props.onDataExport && props.onDataExport(type);
  };

  const isTableOriginal = useMemo(() => {
    if (
      contentType === GridType.CATEGORY ||
      contentType === GridType.DEFAULT ||
      contentType === GridType.LOCATION ||
      contentType === GridType.CARDS ||
      contentType === GridType.EQUIPMENT
    ) {
      return true;
    } else {
      return false;
    }
  }, [contentType]);

  const isGridView = useMemo(() => {
    if (
      contentType !== GridType.CATEGORY &&
      contentType !== GridType.LOCATION &&
      contentType !== GridType.EQUIPMENT &&
      contentType !== GridType.EXPORT_CSV &&
      contentType !== GridType.EXPORT_EXCEL &&
      contentType !== GridType.ISSUEDTO
    ) {
      return true
    } else {
      return false
    }
  }, [contentType]);
  

  const handleViewChange = (currentValue: string) => {
    switch (currentValue) {
      case GridType.SAVE_VIEW:
        setOpenSave(true);
        break;
      case GridType.EXPORT_EXCEL:
        handleExport('xlsx');
        break;
      case GridType.EXPORT_CSV:
        handleExport('CSV');
        break;
      case GridType.DEFAULT:
        setTableState({
          ...tableState,
          [history.location.pathname]: null
        });
        setContentType(currentValue as GridType);
        saveViewMode(currentValue as GridType);
        break;
      case GridType.CATEGORY:
      case GridType.LOCATION:
        setContentType(currentValue as GridType);
        saveViewMode(currentValue as GridType);
        setHierarchyState({
          ...hierarchyState,
          [history.location.pathname]: null
        });
        break;
      case GridType.CARDS:
        setContentType(currentValue as GridType);
        saveViewMode(currentValue as GridType);
        break;
      case GridType.EQUIPMENT:
        setContentType(currentValue as GridType);
        saveViewMode(currentValue as GridType);
        break;
      case GridType.ISSUEDTO:
        setContentType(currentValue as GridType);
        saveViewMode(currentValue as GridType);
        break;
      default:
        const savedViewItem = gridSavedViews.find((item: any) => item.id === currentValue);
        setContentType(currentValue as GridType);
        saveViewMode(currentValue as GridType);
        const layout = savedViewItem?.layout || '{}';
        const originalMode = JSON.parse(layout).mode;
        if (originalMode === GridType.DEFAULT) {
          const currentPath = history.location.pathname;
          setTableState({
            ...tableState,
            [currentPath]: JSON.parse(layout).detail
          });
        } else {
          const currentPath = history.location.pathname;
          setHierarchyState({
            ...hierarchyState,
            [currentPath]: {
              selectedKey: '',
              expanded: JSON.parse(layout).detail?.expanded
            }
          });
        }
        break;
    }
  };

  const formError = useMemo(() => {
    if (errors && errors.name?.type === 'required') {
      return {
        error: true,
        errorMsg: 'View name is required'
      };
    }
    if (errors && errors.name?.type === 'exiting') {
      return {
        error: true,
        errorMsg: 'This View Name is exist!'
      };
    }
    return {
      error: false,
      errorMsg: ''
    };
  }, [errors, errors.name]);

  return (
    <>
      <FormControl fullWidth={!isLargeScreen}>
        <InputLabel>View by</InputLabel>
        <Select
          value={contentType}
          label="View by"
          sx={{ height: 37 }}
          onChange={(event) => handleViewChange(event.target.value)}
          renderValue={(value) => {
            const label = (gridSavedViews && gridSavedViews.find((item: any) => item.id === value)?.viewName) || value;
            return <div>{label}</div>;
          }}
        >
          {defaultOptions.map((viewOption: any) => (
            <MenuItem 
              key={viewOption} 
              value={viewOption}
            >
              {viewOption}
            </MenuItem>
          ))}

          {exportOptions.length > 0 && (
            <Divider 
              light
            />)
          }
          {exportOptions.map((exportOption: any) => (
            <MenuItem 
              key={exportOption} 
              value={exportOption}
            >
              {exportOption}
            </MenuItem>
          ))}

          {(gridSavedViews || []).length > 0 && <Divider light/>}
          {gridSavedViews && gridSavedViews.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              <div className="flex justify-between w-full">
                {item.fldViewName}{' '}
                <div
                  className="ml-2 text-red-400"
                  onClick={(evt) => handleRemoveLayout(evt, item)}
                >
                  <DeleteOutlineIcon />
                </div>
              </div>
            </MenuItem>
          ))}

          {isGridView && (
            <Divider 
              light
            />
          )}
          {isGridView && (
            <MenuItem
              key={GridType.SAVE_VIEW}
              value={GridType.SAVE_VIEW}
              onClick={() => handleViewChange(GridType.SAVE_VIEW)}
            >
              {GridType.SAVE_VIEW}
            </MenuItem>
          )}
        </Select>
      </FormControl>

      <Dialog maxWidth="md" open={isOpenSave}>
        <form onSubmit={handleSubmit(saveGridState)}>
          <DialogTitle>Save Current View</DialogTitle>
          <DialogContent dividers>
            <Box sx={{ flexGrow: 1, minWidth: '400px' }}>
              {!isTableOriginal && (
                <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Save
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={method}
                    onChange={handleChangeMethod}
                  >
                    <FormControlLabel
                      value={METHOD_CREATE}
                      control={<Radio />}
                      label="Create a new layout"
                    />
                    <FormControlLabel
                      value={METHOD_UPDATE}
                      control={<Radio />}
                      label="Update existing layout"
                    />
                  </RadioGroup>
                </FormControl>
              )}
              {method === METHOD_CREATE && (
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      id="outlined-basic"
                      label="View Name"
                      variant="outlined"
                      error={formError.error}
                      helperText={formError.errorMsg}
                    />
                  )}
                  rules={{ required: true }}
                />
              )}
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ViewSelector;