import { FC, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Alert, AlertColor, Snackbar } from '@mui/material';
import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import { isNil } from 'lodash';
import SparesGrid from './components/SparesGrid';
import { RecordType, SparesUsedInput, SparesUsed } from 'src/generated/dotnet.graphql';
import SpareInfoDialog, { ISparePopupForm } from './components/SpareInfoDialag';
import SpareLocationDialog from './components/SpareLocationDialog';
import AddIcon from '@mui/icons-material/Add';
import { useUpsertSpare } from 'src/hooks/sparesUsed/useUpsertSpare';
import { useDeleteSpare } from 'src/hooks/sparesUsed/useDeleteSpare';
import { useGetSparesUsed } from 'src/hooks/sparesUsed/useGetSparesUsed';
import { SEVERITY } from 'src/consts';

interface Props {
  issue?: WorkIssuesDocument;
  onDelete?: (spare: SparesUsed) => Promise<void>;
  onCreate?: (spare: SparesUsedInput, isCreate: boolean) => Promise<void>;
  recordId: string;
  recordType: RecordType;
  recordTypeName: string;
  equipmentId?: string;
  isReport?: boolean;
  setSparesCount: (value: number) => void;
  readOnly?: boolean;
}

const SparesTab: FC<Props> = ({
  issue,
  onDelete,
  onCreate,
  recordId,
  recordType,
  recordTypeName,
  equipmentId,
  isReport = false,
  readOnly = false,
  setSparesCount,
}) => {
  const { upsertSpare } = useUpsertSpare(recordId, recordType, recordTypeName);
  const { deleteSpare } = useDeleteSpare(recordId, recordType, recordTypeName);
  const { sparesUsed, sparesUsedLoading, sparesUsedCount } = useGetSparesUsed(recordId);

  const [isFormVisible, setFormVisible] = useState<boolean>(false);
  const [editableItem, setEditableItem] = useState<SparesUsed>();
  const [spareForLocation, setSpareForLocation] = useState<SparesUsed>();
  const [spares, setSpares] = useState<SparesUsed[]>([]);
  const [loading, setLoading] = useState(false);
  const [isLocationPopupVisible, setLocationPopupVisible] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();

  useEffect(() => {
    if (recordId && !sparesUsedLoading) {
      setSpares(sparesUsed);
      setSparesCount(sparesUsedCount);
      setLoading(sparesUsedLoading);
    }
  }, [recordId, sparesUsedLoading, sparesUsedCount]);

  const handleAddClick = () => {
    setEditableItem(undefined);
    setFormVisible(true);
  };

  const handleEdit = async (spare: SparesUsed) => {
    setEditableItem(spare);
    setFormVisible(true);
  };

  const handleLocation = (spare: SparesUsed)=>{
    setSpareForLocation(spare);
    setLocationPopupVisible(true);
  }

  const handleDelete = async (spareKey: string) => {
    const { responseData, responseMessage } = await deleteSpare(spareKey);

    if (onDelete) {
      console.log('to be implemented when gets to be called from LogEntryWithEqDetailsForm or LogEntryWithWorkIssueDetailsForm components')
      // await spare.remove();
      // await onDelete(spare);
    }

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const onClickSave = async (payload: ISparePopupForm) => {
    const isCreate = isNil(payload.id);

    const sparePayload: SparesUsedInput = {
      id: payload.id,
      logEntryId: recordId,
      inventoryId: payload.inventoryId,
      amount: parseInt(payload.amount),
    }

    const { responseData, responseMessage } = await upsertSpare(sparePayload, isCreate);

    if (onCreate) {       
      console.log('to be implemented when gets to be called from LogEntryWithEqDetailsForm or LogEntryWithWorkIssueDetailsForm components')
      // await onCreate(payload, isNil(payload.pkey));
    }

    setFormVisible(false);

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const onFormCancel = () => {
    setEditableItem(undefined);
    setFormVisible(false);
    setLocationPopupVisible(false);
  };

  return (
    <>
      <div>
        { !isReport && (
          <div className="flex justify-end mb-4">
            <Button 
              variant="contained" 
              startIcon={<AddIcon />}
              onClick={handleAddClick} 
              disabled={readOnly}
            >
              Add
            </Button>
          </div>
        )}
        <SparesGrid
          onEdit={handleEdit}
          onDelete={handleDelete}
          onLocation={handleLocation}
          issue= {issue}
          loading={loading}
          spares={spares}
          readOnly={readOnly}
        />
      </div>

      <SpareInfoDialog
        spareUsed={editableItem}
        onCancel={onFormCancel}
        onSubmit={onClickSave}
        visible={isFormVisible}
        equipmentId={equipmentId}
        issue={issue}
      />

      <SpareLocationDialog
        onCancel={onFormCancel}
        inventoryId={spareForLocation?.inventoryId}
        visible={isLocationPopupVisible}
      />

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </>
  );
};

export default SparesTab;
