import { FC, useState } from 'react';
import { Button, DialogTitle, DialogActions, DialogContent, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CustomDialog } from 'src/helpers/modals';
import EquipmentAddSpareGrid from './EquipmentAddSpareGrid';
import { AddInventoryToEquipmentInput, Equipment, Inventory, QueryEquipmentArgs, UserError } from 'src/generated/dotnet.graphql';
import { useAddInventoryToEquipment } from 'src/hooks/equipment/useAddInventoryToEquipment';

interface InjectedProps {
  visible: boolean;
  initialValue: Equipment;
  excludeIds: string[];
  refetchQueryVariables: QueryEquipmentArgs;
  onSave: (responseMessage: string, responseData?: Inventory[], responseDataError?: UserError) => void;
  onCancel: () => void;
}

const EquipmentAddSpareDialog: FC<InjectedProps> = ({
  visible,
  initialValue,
  excludeIds,
  onSave,
  onCancel,
  refetchQueryVariables
}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { addInventoryToEquipment, addInventoryToEquipmentLoading } = useAddInventoryToEquipment(initialValue.id);

  const handleSave = async () => {
    const payload: AddInventoryToEquipmentInput = {
      equipmentId: initialValue.id,
      inventoryIds: selectedItems
    };
    const { responseData, responseDataError, responseMessage } = await addInventoryToEquipment(payload, refetchQueryVariables);
    onSave(responseMessage, responseData, responseDataError);
  };

  const handleSelect = (items: any) => {
    setSelectedItems(items);
  };

  return (
    <>
      <CustomDialog
        scroll="paper"
        fullWidth
        maxWidth="md"
        open={visible}
        onClose={onCancel}
      >
        <DialogTitle>
          <span className="font-bold text-2xl">{`Add spares for ${initialValue.uniqueName}`}</span>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          <EquipmentAddSpareGrid
            excludeIds={excludeIds}
            onSelect={handleSelect}
          />
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          <Box
            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
          >
            <Button className="mr-2" onClick={onCancel}>
              Cancel
            </Button>
            <LoadingButton
              loading={addInventoryToEquipmentLoading}
              type="submit"
              onClick={handleSave}
              variant="contained"
            >
              Save
            </LoadingButton>
          </Box>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export default EquipmentAddSpareDialog;
