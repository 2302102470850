import '../../../App.css';
import '../../../theme/styles.css';
import '../styles.css';
import { v4 as uuid } from 'uuid';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { isNil, size, last, isArray, isEmpty } from 'lodash';
import { DeleteTwoTone } from '@mui/icons-material';
import { Icon, Alert, IconButton, Snackbar, AlertColor } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import CloseIcon from '@mui/icons-material/Close';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import { useForm } from 'react-hook-form';
import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import Tabs from '../../UI/Tabs';
import Input from '../../UI/Forms/Input';
import StickyAppBar from '../../UI/StickyAppBar';
import WarningDialog from 'src/components/UI/WarningDialog';
import { useAuth } from 'src/contexts/auth';
import { useAppState } from 'src/contexts/app-state';
import LogIcon from '../../../assets/icon-log-primary.svg';
import RestoreIcon from '@mui/icons-material/Restore';
import UpdateDisabledIcon from '@mui/icons-material/UpdateDisabled';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
import { getDatabase } from '../../../rxdb';
import { CHAR_LIMIT, COMPONENTS, equipmentDeleteWarningMessage, FormType, inheritanceOffsetConflictWarningMessage, offsetInheritanceConflictWarningMessage, removeHourOffsetWarningMessage, removeInheritHoursWarningMessage, SEVERITY } from '../../../consts';
import EquipmentSparesTab from './component/SparesTab';
import EquipmentSummaryForm from './component/EquipmentSummaryForm';
import EqOutStandingTasks from './component/TasksTab/EqOutStandingTasks';
import { validateForm } from './utils';
import EqNewTaskDialog from './component/TasksTab/EqNewTaskDialog';
import HistoryTab from 'src/components/HistoryTab';
import RecordEditWarningCard from 'src/components/UI/RecordEditWarningCard';
import { handleCharLimitWarning, removeNonAlphanumericChars } from 'src/utils';
import Schedules from "../../../modules/Schedules";
import ScheduleTabButton from "../../../modules/Schedules/components/ScheduleTabButton";
import { normalizeDateFormValue, isValidDate } from 'src/helpers';
import { InjectedDrawerProps } from 'src/components/PageDrawer';
import LogEntryFormDialog from '../LogEntry/LogEntryFormDialog';
import { AttachmentType, DeleteResult, Equipment, EquipmentInput, LogEntry, QueryEquipmentArgs, RecordType, UpdateEquipmentHoursInheritanceInput, UpdateEquipmentHoursOffsetInput } from 'src/generated/dotnet.graphql';
import AttachmentTab from 'src/modules/Attachments';
import Comments from 'src/modules/Comments';
import { toStaticDate } from 'src/utils/format-dates';
import InheritEquipmentHoursDialog from './component/InheritEquipmentHoursDialog';
import { useUpsertEquipment } from 'src/hooks/equipment/useUpsertEquipment';
import AddHourOffsetDialog from './component/AddHourOffsetDialog';
import { useUpdateEquipmentHoursInheritance } from 'src/hooks/equipment/useUpdateEquipmentHoursInheritance';
import { useUpdateEquipmentHoursOffset } from 'src/hooks/equipment/useUpdateEquipmentHoursOffset';
import { useDeleteEquipment } from 'src/hooks/equipment/useDeleteEquipment';
import { useUpdateCache } from 'src/hooks/useUpdateCacheCount';
import { UpdateEquipmentLogEntryCountCache } from 'src/hooks/fragments.graphql';
import EquipmentManufacturersDropdown from 'src/components/Dropdowns/EquipmentManufacturersDropdown';

const useStyles = makeStyles((theme) => ({
  staticTooltipLabel: {
    whiteSpace: "nowrap",
    maxWidth: 900,
  },
}));

interface EquipmentDetailFormProps extends Partial<InjectedDrawerProps> {
  initialValue: Equipment;
  isCreate?: boolean;
  onSave: (responseData: Equipment, responseMessage: string, isCreate: boolean) => void;
  onDelete: (responseData: DeleteResult, responseMessage: string) => void;
  onCancel: () => void;
  moduleReadOnly?: boolean;
  refetchQueryVariables?: QueryEquipmentArgs;
  callerComponent?: string;
  type?: FormType;
}

interface EquipmentDetailFormRef {
  onSaveClick: () => void;
  onDeleteClick: () => void;
}

const EquipmentDetailForm = forwardRef<EquipmentDetailFormRef, EquipmentDetailFormProps>(({
  initialValue,
  isCreate = false,
  onSave,
  onDelete,
  onCancel,
  refetchQueryVariables,
  moduleReadOnly=false,
  setFormIsDirty,
  type
}: EquipmentDetailFormProps, ref) => {
  const classes = useStyles();
  const { 
    control, 
    setValue, 
    handleSubmit, 
    getValues, 
    reset, 
    watch, 
    formState
   } = useForm<any>({
    // For uncontrolled components keep empty string or undefined. Null wouldn't work.
    defaultValues: {
      uniqueName: initialValue.uniqueName || '',
      manufacturer: initialValue.manufacturer,
      modelNumber: initialValue.modelNumber,
      hours: initialValue.hours || 0,
      countHours: initialValue.countHours|| false,
      categoryId: initialValue.categoryId,
      locationId: initialValue.locationId,
      department: initialValue?.department || null,
      reportingTag: initialValue?.reportingTag || null,
      // reportingTag: isEmpty(initialValue.reportingTag) ? [] : initialValue.reportingTag?.split(', '),
      restricted: initialValue.restricted || null,
      sms: initialValue.sms || false,
      notes: initialValue.notes || '',
      supplier: initialValue.supplier,
      inService: normalizeDateFormValue(initialValue.inService),
      expireDate: normalizeDateFormValue(initialValue.expireDate),
      serialNum: initialValue.serialNum,
      arrangementNum: initialValue.arrangementNum,
      rating: initialValue.rating,
      artNumber: initialValue.artNumber,
    },
  });

  const { settingsPersonal } = useAppState();
  const { user } = useAuth();
  const { EQUIPMENT } = CHAR_LIMIT;
  const [eqitem, setEqItem] = useState<Equipment>(initialValue);
  const formInitialValues = useRef<any>({});
  const [workIssueDialogVisible, setWorkIssueDialogVisible] = useState<boolean>(false);
  const [selectedWorkIssue, setSelectedWorkIssue] = useState<WorkIssuesDocument>();
  const [open, setOpen] = React.useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();
  
  const [selectedLogEntry, setSelectedLogEntry] = useState<LogEntry>();
  const [isLogEntryCreate, setIsLogEntryCreate] = useState<boolean>(false);
  
  const [recordReadOnly, setRecordReadOnly] = useState(false);
  const [documentsCount, setDocumentsCount] = useState<number>(initialValue.documentsCount || 0);
  const [photosCount, setPhotosCount] = useState<number>(initialValue.photosCount || 0);
  const [commentsCount, setCommentsCount] = useState<number>(initialValue.commentsCount || 0);
  const [logEntryCount, setLogEntryCount] = useState<number>(initialValue.logEntryCount || 0);
  const [inventoryCount, setInventoryCount] = useState<number>(initialValue.inventoryCount || 0);
  const [tasksCount, setTasksCount] = useState<number>(initialValue.workIssuesCount || 0);
  const [showInheritHoursDialog, setShowInheritHoursDialog] = useState<boolean>(false);
  const [showAddHourOffsetDialog, setShowAddHourOffsetDialog] = useState<boolean>(false);
  const [showRemoveHourOffsetWarning, setShowRemoveHourOffsetWarning] = useState<boolean>(false);
  const [showRemoveInheritHoursWarning, setShowRemoveInheritHoursWarning] = useState<boolean>(false);
  const [showOffsetInheritanceConflictWarning, setShowOffsetInheritanceConflictWarning] = useState<boolean>(false);
  const [offsetInheritanceConflictMessage, setOffsetInheritanceConflictMessage] = useState<string>('');
  const [equipmentToInherit, setEquipmentToInherit] = useState<Equipment>();
  const [showAddInheritanceWarning, setShowAddInheritanceWarning] = useState<boolean>(false);
  const [refetchLogEntries, setRefetchLogEntries] = useState<boolean>(false);

  const { upsertEquipment, upsertEquipmentLoading } = useUpsertEquipment();
  const { updateEquipmentHoursInheritance, updateEquipmentHoursInheritanceLoading } = useUpdateEquipmentHoursInheritance();
  const { updateEquipmentHoursOffset, updateEquipmentHoursOffsetLoading } = useUpdateEquipmentHoursOffset();
  const { removeEquipment, removeEquipmentLoading } = useDeleteEquipment();
  const updateCacheCount = useUpdateCache();

  useImperativeHandle(ref, () => ({
    onSaveClick: () => {
      handleSubmit(handleSave)();
    },
    onDeleteClick: () => {
      handleDelete();
    }
  }));

  const setInitialValues = async () => {
    const defaultValues = {
      ...getValues(),
    };

    formInitialValues.current = defaultValues;
    reset(defaultValues);
  };

  const setRecordReadOnlyPermission = async ()=>{
    if(settingsPersonal.fldAllDepts != 2 && user?.Department != initialValue.department){
      setRecordReadOnly(true)
    }
    if(!settingsPersonal.fldDeptHead && initialValue.restricted) {
      setRecordReadOnly(true)
    }
  }

  useEffect(() => {
    setInitialValues();
    return () => {
      formInitialValues.current = {};
    };
  }, []);

  useEffect(()=>{
    if(settingsPersonal){
      setRecordReadOnlyPermission()
    }
  },[settingsPersonal])

  const handleCreateLogEntry = async () => {
    setIsLogEntryCreate(true);
    const logEntry: any = {
      logDate: new Date().toISOString(),
      performedBy: `${user?.fldFirst} ${user?.fldLast}`,
      equipment: initialValue,
      department: initialValue?.department,
      categoryId: initialValue.categoryId,
      locationId: initialValue.locationId,
      equipmentId: initialValue?.id,
    };
    setSelectedLogEntry(logEntry);
  };

  const handleSaveLogEntry = (responseData: LogEntry, responseMessage: string, isCreated: boolean) => {
    if (isLogEntryCreate) {
      handleUpdateEquipmentLogEntryCacheCount(false);
      setIsLogEntryCreate(false);
    } 
    setSelectedLogEntry(undefined);
    setRefetchLogEntries(!refetchLogEntries);
    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  // TODO - refactor this function once the task module is refactored
  const handleCreateWorkIssue = async () => {
    const db = await getDatabase();
    const newIssue: WorkIssuesDocument = db.workissues.newDocument({
      JobNumber: uuid(),
      fldSRHKey: initialValue.categoryId,
      fldLocHierarchy: initialValue.locationId,
      Department: initialValue?.department,
      EqKey: initialValue?.id,
    });
    setSelectedWorkIssue(newIssue);
    setWorkIssueDialogVisible(true);
  };

  const handleSaveWorkIssue = async () => {
    setSelectedWorkIssue(undefined);
    setWorkIssueDialogVisible(false);
  };

  const onInheritHoursClick = () => {
    if (!isNil(initialValue.hoursOffset)) {
      setOffsetInheritanceConflictMessage(inheritanceOffsetConflictWarningMessage);
      setShowOffsetInheritanceConflictWarning(true);
    } else {
      setShowInheritHoursDialog(true);
    }
  };
    
  const onSelectInheritance = async (equipment: Equipment) => {
    setEquipmentToInherit(equipment);
    setShowAddInheritanceWarning(true);
    setShowInheritHoursDialog(false);
  }

  const handleAddInheritance = async () => {
    if (!equipmentToInherit) return;
    const payload: UpdateEquipmentHoursInheritanceInput = {
      equipmentId: initialValue.id,
      parentEquipmentId: equipmentToInherit.id,
    };
    
    const { responseData, responseMessage } = await updateEquipmentHoursInheritance(payload);
    
    if (responseData) {
      onSave(responseData, responseMessage, isCreate);
      reset(getValues());
    }

    setShowAddInheritanceWarning(false);
  }

  const onRemoveInheritHoursClick = () => {
    setShowRemoveInheritHoursWarning(true);
  }

  const handleRemoveInheritHours = async () => {
    const payload: UpdateEquipmentHoursInheritanceInput = {
      equipmentId: initialValue.id,
      parentEquipmentId: null,
    };
    
    const { responseData, responseMessage } = await updateEquipmentHoursInheritance(payload);
    
    if (responseData) {
      onSave(responseData, responseMessage, isCreate);
      reset(getValues());
    }
    setShowRemoveInheritHoursWarning(false);
  }

  const onAddHourOffsetClick = () => {
    if (!isNil(initialValue.parentId)) {
      setOffsetInheritanceConflictMessage(offsetInheritanceConflictWarningMessage);
      setShowOffsetInheritanceConflictWarning(true);
    } else {
      setShowAddHourOffsetDialog(true);
    }
  }

  const handleAddHourOffset = async (hoursOffset: number) => {
    const payload: UpdateEquipmentHoursOffsetInput = {
      equipmentId: initialValue.id,
      hoursOffset,
    };
    
    const { responseData, responseMessage } = await updateEquipmentHoursOffset(payload);
    
    if (responseData) {
      onSave(responseData, responseMessage, isCreate);
      reset(getValues());
    }
    setShowAddHourOffsetDialog(false);
  }

  const onRemoveHourOffsetClick = () => {
    setShowRemoveHourOffsetWarning(true);
  }

  const handleRemoveHourOffset = async () => {
    const payload: UpdateEquipmentHoursOffsetInput = {
      equipmentId: initialValue.id,
      hoursOffset: null,
    };
    
    const { responseData, responseMessage } = await updateEquipmentHoursOffset(payload);
    
    if (responseData) {
      onSave(responseData, responseMessage, isCreate);
      reset(getValues());
    }
    setShowRemoveHourOffsetWarning(false);
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onChange = async (name: string, value: any) => {
    let shouldDirty = true;

    if (name === 'categoryId') {
      const updatedValue = (isArray(value) ? last(value) : value) || null;
      if (initialValue.categoryId === updatedValue) {
        shouldDirty = false;
      }
    }

    if (name === 'locationId') {
      const updatedValue = (isArray(value) ? last(value) : value) || null;
      if (initialValue.locationId === updatedValue) {
        shouldDirty = false;
      }
    }
    setValue(name, value, { shouldDirty: shouldDirty });
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSave = async (data: any) => {
    if (!validateForm(data, setSnackbar)) return;
    const {
      uniqueName,
      manufacturer,
      modelNumber,
      hours,
      countHours,
      parent,
      categoryId,
      locationId,
      department,
      reportingTag,
      restricted,
      sms,
      notes,
      supplier,
      inService,
      expireDate,
      serialNum,
      arrangementNum,
      rating,
      artNumber,
    } = data;

    const payload: EquipmentInput = {
      uniqueName,
      manufacturer: typeof manufacturer === 'object' ? manufacturer?.name : manufacturer || null,
      modelNumber,
      hours: parseInt(hours, 10) || null,
      countHours,
      parent: parent || null,
      categoryId: (isArray(categoryId) ? last(categoryId) : categoryId) || null,
      locationId: (isArray(locationId) ? last(locationId) : locationId) || null,
      department: department ? typeof department === 'object' ? department.member : department : null,
      reportingTag: reportingTag ? typeof reportingTag === 'object' ? reportingTag.member : reportingTag : null,
      // reportingTag: reportingTag?.join(', ') || null,
      restricted,
      sms,
      notes,
      supplier: supplier ? typeof supplier === 'object' ? supplier.name : supplier : null,
      inService: isValidDate(inService) ? toStaticDate(inService) : null,
      expireDate: isValidDate(expireDate) ? toStaticDate(expireDate) : null,
      serialNum,
      arrangementNum,
      rating,
      artNumber,
      searchMaker: isNil(manufacturer) ? null : typeof manufacturer === 'object' ? removeNonAlphanumericChars(manufacturer?.name) : removeNonAlphanumericChars(manufacturer) || null,
      searchMod: isNil(modelNumber)? null: removeNonAlphanumericChars(modelNumber),
      id: initialValue.id || null,
    } as any;

    const { responseData, responseMessage } = await upsertEquipment(payload, isCreate, refetchQueryVariables);

    if (responseData) {
      onSave(responseData, responseMessage, isCreate);
      reset(getValues());
    }
  };

  const handleDelete = () => {
    setIsDeleting(true)
  };

  const handleDeleteOk = async () => {
    const { responseData, responseMessage } = await removeEquipment(initialValue.id, refetchQueryVariables);
    responseData?.success && onDelete(responseData, responseMessage);
    setIsDeleting(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleting(false);
  }

  const handleCancelUndo = () => {
    if (isCreate) {
      return onCancel();
    }
    reset(formInitialValues.current);
  };

  const handleOk = (isEditing: boolean) => {
    if (isEditing && !validateForm(getValues(), setSnackbar) && !recordReadOnly) return;
    if (isEditing && !recordReadOnly) return; // We will send submit action that will be handled in HandleSave.

    handleCancel();
  };

  const handleCancelDialog = () => {
    setSelectedWorkIssue(undefined);
    setWorkIssueDialogVisible(false);
    setSelectedLogEntry(undefined);
    handleClose();
  };

  const handleUpdateEquipmentLogEntryCacheCount = (isDelete: boolean) => {
    if (!initialValue) return;

    // update state
    setLogEntryCount(logEntryCount + (isDelete ? -1 : 1));

    // update cache
    updateCacheCount<Equipment>({
      typename: initialValue.__typename!!,
      id: initialValue.id,
      fragment: UpdateEquipmentLogEntryCountCache,
      updateCount: (existingData) =>
        existingData
          ? { logEntryCount: existingData.logEntryCount + (isDelete ? -1 : 1) }
          : null,
    });
  };
  
  const onOutStandingTaskDelete = async (key: string) => {
    const db = await getDatabase();
    const payload = {
      ...eqitem,
      EqKey: initialValue.id || uuid(),
      updatedAt: new Date().toISOString(),
    } as any;
    try {
      // const res = await db.collections.equipment.upsert(payload);
      // onSave(res, false, 'tasks');
      // getDocumentCount();
    } catch (e: any) {
      // setSnackbar({
      //   open: true,
      //   type: 'error',
      //   message: e.message,
      // });
    }
  };

  const onOutStandingTaskCreate = async () => {
    const db = await getDatabase();
    const payload = {
      ...eqitem,
      EqKey: initialValue.id || uuid(),
      updatedAt: new Date().toISOString(),
    } as any;
    try {
      // const res = await db.collections.equipment.upsert(payload);
      // onSave(res, false, 'tasks');
    } catch (e: any) {
      // setSnackbar({
      //   open: true,
      //   type: 'error',
      //   message: e.message,
      // });
    }
  };

   // TODO - remove this function once the task module is refactored - in CompleteTaskButton component refetch Equipment data on the complete task mutation.
  const onTaskComplete = async () => {
    try {
      // Trigger the upsert mutation with just the ID to force a refetch
      const payload = { id: initialValue.id };
      const { responseData } = await upsertEquipment(payload, false, refetchQueryVariables);
  
      if (responseData) {
        console.log("Refetched via dumb mutation:", responseData);
        setValue("hours", responseData.hours); // Update hours field
      }
    } catch (error) {
      console.error("Error refetching via dumb mutation:", error);
    }
  };
  

  if (isNil(eqitem)) return null;

  const hasValuesBeenChanged = formState.isDirty
    && (size(formState.dirtyFields) > 0 || size(formState.touchedFields) > 0);

  const isEditing = hasValuesBeenChanged || isCreate;
    
  useEffect(() => {
    setFormIsDirty && setFormIsDirty(hasValuesBeenChanged);
  }, [hasValuesBeenChanged]);
  
  const actions = [
    {
      icon: (
        <InventoryOutlinedIcon style={{color:"#002041"}}/>
      ),
      name: "Add task",
      onclick: handleCreateWorkIssue,
    },
    {
      icon: (
        <Icon>
          <img src={LogIcon} alt="" />
        </Icon>
      ),
      name: "Add Log Entry",
      onclick: handleCreateLogEntry,
    },
    ...(initialValue.countHours ? [
      initialValue.parentId === null
        ? {
            icon: <RestoreIcon sx={{ color: "#002041" }} />,
            name: "Inherit Hours",
            onclick: onInheritHoursClick,
          }
        : {
            icon: <UpdateDisabledIcon sx={{ color: "#002041" }} />,
            name: "Remove Hour Inheritance",
            onclick: onRemoveInheritHoursClick,
          },
      initialValue.hoursOffset === null
        ? {
            icon: <HourglassBottomIcon sx={{ color: "#002041" }} />,
            name: "Add Hour Offset",
            onclick: onAddHourOffsetClick,
          }
        : {
            icon: <HourglassDisabledIcon sx={{ color: "#002041" }} />,
            name: "Remove Hour Offset",
            onclick: onRemoveHourOffsetClick,
          },
        ]
    : []),
  ];
  
  const formClass = type === 'Dialog'
    ? 'relative bg-white flex-grow'
    : 'relative bg-white pt-14 md:pt-19 flex-grow';

  return (
    <>
      <form
        id="EquipmentDetailForm"
        className={`${formClass}`}
        onSubmit={handleSubmit(handleSave)}
      >
        <div className="bg-white h-full flex-grow pt-6">
          <div className="px-6 h-full flex flex-col">
            {(moduleReadOnly || recordReadOnly) && (
              <RecordEditWarningCard />
            )}
            <div className="mui-textfield-header mb-2">
              <Input
                inputProps={{
                  size: 'medium',
                  label: 'Equipment Name',
                  variant: 'standard',
                }}
                rules={{ required: true, maxLength: EQUIPMENT.UniqueName }}
                warning={(value) => handleCharLimitWarning(value, EQUIPMENT.UniqueName)}
                control={control}
                name="uniqueName"
              />
            </div>

            <div className="mt-3">
              <EquipmentManufacturersDropdown
                control={control}
                label="Manufacturer"
                name="manufacturer"
                onChange={onChange}
                variant="standard"
                size="small"
              />
            </div>

            <div className="mt-3">
              <Input
                inputProps={{
                  size: 'small',
                  label: 'Model Number',
                  variant: 'standard',
                }}
                rules={{ maxLength: EQUIPMENT.ModelNumber }}
                warning={(value) => handleCharLimitWarning(value, EQUIPMENT.ModelNumber)}
                defaultValue={initialValue.modelNumber}
                control={control}
                name="modelNumber"
              />
            </div>

            <div className="mt-6 mb-20">
              <Tabs
                tabs={[
                  {
                    label: 'Summary',
                    component: (
                      <EquipmentSummaryForm
                        initialValue={initialValue}
                        onChange={onChange}
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        readOnly={moduleReadOnly || recordReadOnly}
                      />
                    ),
                  },
                  {
                    // label: `Tasks (${tasksCount})`, // TODO - add counts after Task module is refactored
                    label: `Tasks`,
                    disabled: isCreate,
                    component: (
                      <EqOutStandingTasks
                        initialValue={initialValue}
                        onDelete={onOutStandingTaskDelete}
                        onSave={onOutStandingTaskCreate}
                        count={tasksCount}
                        onTaskComplete={onTaskComplete}
                        disableEdit = {moduleReadOnly || recordReadOnly}
                      />
                    ),
                  },
                  {
                    label: `Spares (${inventoryCount})`,
                    disabled: isCreate,
                    component: (
                      <EquipmentSparesTab 
                        initialValue={initialValue}
                        inventoryCount={inventoryCount}
                        setInventoryCount={setInventoryCount}
                        recordReadOnly={recordReadOnly}
                        moduleReadOnly={moduleReadOnly}
                      />
                    ),
                  },
                  {
                    label: `History (${logEntryCount})`,
                    disabled: isCreate,
                    component: (
                      <HistoryTab 
                        keyName={'equipmentId'}
                        keyValue={initialValue.id} 
                        countHours={initialValue.countHours as boolean}
                        recordReadOnly={recordReadOnly} 
                        updateLogEntryCacheCount={handleUpdateEquipmentLogEntryCacheCount}
                        refetchLogEntries={refetchLogEntries}
                      />
                    ),
                  },
                  {
                    label: (
                      <ScheduleTabButton 
                        selector={{ EqKey: initialValue.id , deletedAt:{$eq:null} }} 
                        disabled={isCreate}
                      />
                    ),
                    component: (
                      <Schedules 
                        EqKey={initialValue.id} 
                        selector={{ EqKey: initialValue.id, deletedAt:{$eq:null} }} 
                        recordReadOnly={moduleReadOnly || recordReadOnly} 
                      />
                    )
                  },
                  {
                    label: documentsCount === undefined ? 'Attachments' : `Attachments (${documentsCount})`,
                    disabled: isCreate,
                    component: (
                      <AttachmentTab
                        recordId={initialValue.id}
                        recordType={RecordType.Equipment}
                        recordTypeName={initialValue.__typename!}
                        attachmentType={AttachmentType.Document}
                        categoryId={initialValue.categoryId}
                        setAttachmentsCount={setDocumentsCount}
                        readOnly={moduleReadOnly || recordReadOnly} 
                      />
                    ),
                  },
                  {
                    label: `Photos (${photosCount})`,
                    disabled: isCreate,
                    component: (
                      <AttachmentTab
                        recordId={initialValue.id}
                        recordType={RecordType.Equipment}
                        recordTypeName={initialValue.__typename!}
                        attachmentType={AttachmentType.Photo}
                        categoryId={initialValue.categoryId}
                        setAttachmentsCount={setPhotosCount}
                        readOnly={moduleReadOnly || recordReadOnly} 
                      />
                    ),
                  },
                  {
                    label: `Comments (${commentsCount})`,
                    disabled: isCreate,
                    component: (
                      <Comments
                        recordId={initialValue.id}
                        recordType={RecordType.Equipment}
                        recordTypeName={initialValue.__typename!}
                        setCommentsCount={setCommentsCount}
                        readOnly={moduleReadOnly || recordReadOnly}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>

        {type !== 'Dialog' && (
          <StickyAppBar
            cancelText="Cancel"
            okType={isEditing ? 'submit' : 'button'}
            okText={isEditing ? (isCreate ? 'Create' : 'Save') : 'Close'}
            onOk={() => handleOk(isEditing)}
            disabled={(moduleReadOnly || recordReadOnly) && isEditing}
            onCancel={isEditing ? () => handleCancelUndo() : undefined}
            loading={upsertEquipmentLoading}
          >
            {!isCreate && !recordReadOnly && !moduleReadOnly && !isNil(initialValue.id) && (
              <Box sx={{ position: 'relative', height: 70 }}>
                <Backdrop open={open} />
                <SpeedDial
                  ariaLabel="SpeedDial tooltip example"
                  sx={{ position: 'absolute', bottom: 12, right: 12 }}
                  FabProps={{ size: 'small' }}
                  icon={(
                    <SpeedDialIcon
                      sx={{ fontSize: 'small' }}
                      icon={(<SpeedDialIcon />)}
                      openIcon={<CloseIcon />}
                    />
                  )}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  open={open}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      tooltipOpen
                      classes={classes}
                      onClick={action.onclick}
                    />
                  ))}
                </SpeedDial>
              </Box>
            )}
            {!isCreate && !(moduleReadOnly || recordReadOnly) && !isNil(initialValue.id) && (
              <IconButton
                onClick={handleDelete}
                color="error"
                aria-label="Delete item"
              >
                <DeleteTwoTone />
              </IconButton>
            )}
          </StickyAppBar>
        )}

        <WarningDialog
          visible={isDeleting}
          title="Delete Warning"
          content={equipmentDeleteWarningMessage}
          okText='Yes'
          color='error'
          loading={removeEquipmentLoading}
          onOk={handleDeleteOk}
          onCancel={handleDeleteCancel}
        />

        <Snackbar
          open={!!snackbar}
          autoHideDuration={2000}
          onClose={() => setSnackbar(undefined)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
        </Snackbar>
      </form>

      <InheritEquipmentHoursDialog
        visible={showInheritHoursDialog}
        initialValue={initialValue}
        onSelect={onSelectInheritance}
        onClose={() => setShowInheritHoursDialog(false)}
      />

      <WarningDialog
        visible={showRemoveInheritHoursWarning}
        loading={updateEquipmentHoursInheritanceLoading}
        title="Remove Inherit Hours Warning"
        content={removeInheritHoursWarningMessage}
        okText='Yes'
        color='error'
        onOk={handleRemoveInheritHours}
        onCancel={() => setShowRemoveInheritHoursWarning(false)}
      />

      <AddHourOffsetDialog
        onCancel={() => setShowAddHourOffsetDialog(false)}
        onSave={handleAddHourOffset}
        visible={showAddHourOffsetDialog}
        loading={upsertEquipmentLoading}
      />

      <WarningDialog
        visible={showRemoveHourOffsetWarning}
        loading={updateEquipmentHoursOffsetLoading}
        title="Remove Hour Offset Warning"
        content={removeHourOffsetWarningMessage}
        okText='Yes'
        color='error'
        onOk={handleRemoveHourOffset}
        onCancel={() => setShowRemoveHourOffsetWarning(false)}
      />

      <WarningDialog
        visible={showOffsetInheritanceConflictWarning}
        title="Offset and Inheritance Conflict Warning"
        content={offsetInheritanceConflictMessage}
        cancelText='Close'
        color='error'
        onCancel={() => setShowOffsetInheritanceConflictWarning(false)}
      />

      <WarningDialog
        visible={showAddInheritanceWarning}
        loading={updateEquipmentHoursInheritanceLoading}
        title="Add Hours Inheritance Warning"
        content={`Are you sure you want ${initialValue.uniqueName} to inherit its hours from ${equipmentToInherit?.uniqueName}?`}
        okText='Yes'
        color='error'
        onOk={handleAddInheritance}
        onCancel={() => setShowAddInheritanceWarning(false)}
      />

      {!!selectedWorkIssue && (
        <EqNewTaskDialog
          visible={workIssueDialogVisible}
          initialValue={selectedWorkIssue}
          onSave={handleSaveWorkIssue}
          onCancel={handleCancelDialog}
          moduleReadOnly={moduleReadOnly}
          title="Create New Task"
        />
      )}

      <LogEntryFormDialog 
        selectedItem={selectedLogEntry} 
        isCreate={isLogEntryCreate}
        onSave={handleSaveLogEntry} 
        onCancel={handleCancelDialog} 
        moduleReadOnly={moduleReadOnly}
        refetchQueryVariables={null}
        callerComponent={COMPONENTS.EquipmentDetailForm}   
      />
    </>
  );
});

export default EquipmentDetailForm;
