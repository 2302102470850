import React, { useState } from 'react';
import { Card, CardActionArea, CardMedia, Box, CardContent, Typography, CardActions, IconButton, AlertColor, useMediaQuery, Snackbar, Alert } from '@mui/material';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import Edit from '@mui/icons-material/Edit';
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { AttachmentInput, AttachmentType, Inventory, InventoryLocation, InventoryType, RecordType, RemoveInventoryFromLocationInput } from 'src/generated/dotnet.graphql';
import { DOWNLOAD_URL, SEVERITY } from 'src/consts';
import { defaultUploadedFile, IFile } from 'src/modules/Attachments';
import { useUpsertAttachment } from 'src/hooks/attachments/useUpsertAttachment';
import { useUpdateInventoryLocationAmount } from 'src/hooks/inventory/useUpdateInventoryLocationAmount';
import { useDeleteInventoryLocation } from 'src/hooks/inventory/useDeleteInventoryLocation';
import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined';
import { isNil } from 'lodash';
import ImagePreviewPopup from 'src/modules/Attachments/component/ImagePreviewPopup';
import AttachmentPopup from 'src/modules/Attachments/component/AttachmentPopup';
import InventoryStorageDialog from 'src/components/PageDrawer/Inventory/component/StorageLocation/components/InventoryStorageDialog';
import WarningDialog from 'src/components/UI/WarningDialog';
import { getInventoryLabels } from '../utils';

interface InventoryCardProps {
  inventoryLocation: InventoryLocation;
  inventoryType: InventoryType;
  onChange: (item: any) => void;
  recordType: RecordType;
  moduleReadOnly: boolean;
  onRefetch: () => void;
}

const InventoryCard: React.FC<InventoryCardProps> = ({
  inventoryLocation,
  inventoryType,
  onChange,
  recordType,
  moduleReadOnly,
  onRefetch
}) => {
  const isDesktopDevice = useMediaQuery('(min-width:1280px)');
  const [selectedInventory, setSelectedInventory] = useState<Inventory>();
  const [selectedStorageLocation, setSelectedStorageLocation] = useState<InventoryLocation>();
  const [uploadedFile, setUploadedFile] = useState<IFile>(defaultUploadedFile);
  const [isAttachemntPopupVisibleIsVisible, setAttachemntPopupVisibleIsVisible] = useState<boolean>(false);
  const [isEditStoragePopupVisible, setEditStoragePopupVisible] = useState<boolean>(false);
  const [showPhotoPreview, setShowPhotoPreview] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLowInventory, setIsLowInventory] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState<InventoryLocation>();
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();
  const labels = getInventoryLabels(inventoryType);

  const { removeInventoryLocation } = useDeleteInventoryLocation();
  const { updateInventoryLocationAmount, updateInventoryLocationAmountLoading } = useUpdateInventoryLocationAmount();
  const { upsertAttachment, upsertAttachmentLoading } = useUpsertAttachment(
    selectedInventory?.id || '', 
    recordType, 
    selectedInventory?.__typename || '', 
    AttachmentType.Photo
  );

  const handlePhotoPreview = (fileName: any) => {
    setShowPhotoPreview(true);
    setImageSrc(`${DOWNLOAD_URL}/${fileName}`);
  };

  const handlePhotoPreviewClose = (e: any) => {
    e.stopPropagation();
    setShowPhotoPreview(false);
  };

  const handleAttachemntPopupCancel = () => {
    setAttachemntPopupVisibleIsVisible(false);
    setUploadedFile(defaultUploadedFile);
  };

  const onAddPhotoClick = (item: InventoryLocation) => {
    setSelectedInventory(item.inventory)
    setUploadedFile(defaultUploadedFile);
    setSelectedStorageLocation(item);
    setAttachemntPopupVisibleIsVisible(true);
  };

  const handleAddPhoto = async (data: AttachmentInput) => {
    if (!selectedInventory) return;
    const payload: AttachmentInput = {
      id: data?.id,
      documentationId: data?.documentationId,
      docTitle: data?.docTitle,
      altPath: data?.altPath,
      description: data?.description,
      fileName: data?.fileName || uploadedFile.key,
      libType: data?.libType || null,
      notes: data?.notes,
      page: data?.page,
      revision: data?.revision,
      revisionDate: data?.revisionDate,
      showInCentral: data?.showInCentral,
      categoryId: selectedInventory?.categoryId || null,
      recordId: selectedInventory.id,
      recordType,
    }

    const isCreate = isNil(payload.documentationId)
    const { responseData, responseMessage} = await upsertAttachment(payload, isCreate);

    setAttachemntPopupVisibleIsVisible(false);
    setUploadedFile(defaultUploadedFile);
    onRefetch();

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const onEditStorageLocationClick = (item: InventoryLocation): void => {
    setSelectedStorageLocation(item);
    setSelectedInventory(item.inventory);
    setEditStoragePopupVisible(true);
  }

  const handleStorageLocationPopupCancel = () => {
    setSelectedStorageLocation(undefined);
    setEditStoragePopupVisible(false);
  };

  const handleEditStorageSave = () => {
    setEditStoragePopupVisible(false);
    setSelectedStorageLocation(undefined);
    onRefetch();
  };

  const onDeleteClick = (inventoryLocation: InventoryLocation) => {
    setDeleteSelected(inventoryLocation);
    setIsDeleting(true);
  };

  const handleOkDelete = async () => {
    if (!deleteSelected?.locationId) return; // remove this line after dete mutation accepts locationId=null
    const payload: RemoveInventoryFromLocationInput = {
      inventoryId: deleteSelected.inventory.id,
      locationId: deleteSelected.locationId
      // locationId: item.locationId ?? null
    }

    const { responseData, responseMessage } = await removeInventoryLocation(payload);
  
    if (responseData?.success) {
      onRefetch();
      handleCancelDelete();
    }
  
    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const handleCancelDelete = () => {
    setIsDeleting(false);
    setDeleteSelected(undefined);
  };

  const onUpdateAmount = async (inventoryLocation: InventoryLocation, increase: boolean): Promise<void> => {
    const { responseData, responseMessage } = await updateInventoryLocationAmount(inventoryLocation, increase);
    if(responseData) onRefetch();
    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };
    
  const onLowInventoryClick = (): void => {
    setIsLowInventory(true);
  }

  const handleChange = (item: InventoryLocation) => {
    setSelectedInventory(item.inventory) // keep track of selected inv in case of delete
    onChange(item.inventory)
  }

  console.group('inventoryType', inventoryType)

  const CommonGridUI = (title: string, value: any) => (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
      <div style={{ textAlign: 'left', marginRight: '10px' }}>
        <Typography component="div" sx={{ fontSize: 12 }}>
          {title}
        </Typography>
      </div>
      <div style={{ textAlign: 'left' }}>
        <Typography component="div" sx={{ fontSize: 12, fontWeight: 700 }}>
          {value}
        </Typography>
      </div>
    </div>
  );

  return (
    <>
      <Card sx={{ display: 'flex' }}>
        {inventoryLocation.inventory.photo ? (
          <CardActionArea
            onClick={() => handlePhotoPreview(inventoryLocation.inventory.photo)}
            sx={{ width: 151, minWidth: 151 }}
          >
            {!hasError ? ( 
              <CardMedia
                component="img"
                sx={{
                  width: 164, // width in pixels or percentage
                  height: 164, // height in pixels or percentage
                  objectFit: "cover", // Ensures the image is cropped to fill the container
                  objectPosition: "center", // Ensures the center of the image is visible
                  borderRadius: "8px", // rounded corners
                }}
                image={`${DOWNLOAD_URL}/${inventoryLocation.inventory.photo}`}
                onError={() => setHasError(true)}
                alt="Item Image"
              />
            ) : (
              <CardMedia
                sx={{

                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ImageNotSupportedOutlinedIcon fontSize='large'/>
              </CardMedia>
            )}
          </CardActionArea>
        ) : (
          <CardActionArea 
            onClick={() => onAddPhotoClick(inventoryLocation)} 
            sx={{ width: 151, minWidth: 151 }}
          >
            <CardMedia
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <AddAPhotoOutlinedIcon fontSize="large" />
            </CardMedia>
          </CardActionArea>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Box sx={{ maxWidth: isDesktopDevice ? 340 : 190 }}>
            <CardActionArea onClick={() => handleChange(inventoryLocation)}>
              <CardContent>
                <Typography component="div" textAlign="left" fontSize={14} fontWeight={700} noWrap>
                  {inventoryLocation.inventory.productName}
                </Typography>
                {CommonGridUI(`${labels.manufacturerLabel}:`, inventoryLocation.inventory.manufacturer)}
                {(inventoryType === InventoryType.GeneralInventory || inventoryType === InventoryType.Beverages) && CommonGridUI(`${labels.partNumberLabel}:`, inventoryLocation.inventory.partNumber)}
                {CommonGridUI(`${labels.modelNumberLabel}:`, inventoryLocation.inventory.modelNumber)}
                {(inventoryType === InventoryType.Beverages || inventoryType === InventoryType.Uniform) && CommonGridUI(`Size:`, inventoryLocation.inventory.size)}
              </CardContent>
            </CardActionArea>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pl: 1, pb: 1 }}>
            <CardActions>
              <IconButton
                onClick={() => onUpdateAmount(inventoryLocation, true)}
                size="medium"
                aria-label="Increase item amount"
                disabled={moduleReadOnly || updateInventoryLocationAmountLoading}
              >
                <AddRoundedIcon fontSize="inherit" color={moduleReadOnly || updateInventoryLocationAmountLoading ? 'disabled' : 'primary'} />
              </IconButton>
              <div style={{ fontWeight: 700 }}>{inventoryLocation.amount || 0}</div>
              <IconButton
                onClick={() => onUpdateAmount(inventoryLocation, false)}
                size="medium"
                aria-label="Decrease item amount"
                disabled={moduleReadOnly || updateInventoryLocationAmountLoading || inventoryLocation.amount <= 0}
              >
                <RemoveRoundedIcon fontSize="inherit" color={moduleReadOnly || updateInventoryLocationAmountLoading || inventoryLocation.amount <= 0 ? 'disabled' : 'error'} />
              </IconButton>
              {(inventoryLocation.amount ?? 0) < (inventoryLocation.inventory?.reOrderLevel ?? 0) && (
                <IconButton
                  onClick={() => onLowInventoryClick()}
                  size="medium"
                  aria-label="Low Inventory"
                  disabled={moduleReadOnly}
                >
                  <ProductionQuantityLimitsIcon color="error" />
                </IconButton>
              )}
            </CardActions>
            <CardActions>
              <IconButton
                onClick={() => onEditStorageLocationClick(inventoryLocation)}
                size="small"
                aria-label="Edit item"
                disabled={moduleReadOnly}
              >
                <Edit fontSize="inherit" color={moduleReadOnly ? 'disabled' : 'primary'} />
              </IconButton>
              <IconButton
                color="error"
                aria-label="Delete task"
                className="ml-2"
                size="small"
                onClick={() => onDeleteClick(inventoryLocation)}
                disabled={moduleReadOnly}
              >
                <DeleteTwoTone fontSize="inherit" color={moduleReadOnly ? 'disabled' : 'error'} />
              </IconButton>
            </CardActions>
          </Box>
        </Box>
      </Card>

      <ImagePreviewPopup
        popupVisible={showPhotoPreview}
        onClose={handlePhotoPreviewClose}
        imageSrc={imageSrc}
      />

      <AttachmentPopup
        visible={isAttachemntPopupVisibleIsVisible}
        attachmentType={AttachmentType.Photo}
        onCancel={handleAttachemntPopupCancel}
        onSubmit={handleAddPhoto}
        setUploadedFile={setUploadedFile}
        uploadedFile={uploadedFile}
        saveLoading={upsertAttachmentLoading}
        disableCheckbox={false}
      />

      <InventoryStorageDialog
        visible={isEditStoragePopupVisible}
        inventory={selectedInventory}
        storageLocation={selectedStorageLocation}
        onSave={handleEditStorageSave}
        onCancel={handleStorageLocationPopupCancel}
        isCreate={false}
        recordType={recordType}
      />

      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText="Yes"
        color="error"
        onOk={handleOkDelete}
        onCancel={handleCancelDelete}
      />

      <WarningDialog 
        visible={isLowInventory} 
        title={'Low Inventory Warning'} 
        content={'Inventory is lower than minimum reorder level!'} 
        cancelText='Close'
        onCancel={() => setIsLowInventory(false)}      
      />
      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </>
  );
};

export default InventoryCard;