import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery  } from "@apollo/client";
import { EquipmentCollectionSegment, EquipmentFilterInput, EquipmentSortInput } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_LAZY_EQUIPMENT = gql`
  query GetLazyEquipment($filterInput: EquipmentFilterInput, $order: [EquipmentSortInput!]) {
    lazyEquipment: equipment(filterInput: $filterInput, order: $order) {
      items {
        id
        uniqueName
        manufacturer
        modelNumber
        department
        serialNum
        hours
      }
    }
  }
`

interface EquipmentData {
  lazyEquipment: EquipmentCollectionSegment;
}

interface LazyEquipmentResult {
  getLazyEquipment: LazyQueryExecFunction<EquipmentData, OperationVariables>;
}

export const useGetLazyEquipment = (filterInput: EquipmentFilterInput, order: EquipmentSortInput[]): LazyEquipmentResult => {

  const [ getEquipment, { error }] = useLazyQuery(GET_LAZY_EQUIPMENT, {
    fetchPolicy: "network-only",
    variables: { filterInput, order },
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetLazyEquipment').error('Error fetching equipment -->', error.message);
  }

  return {
    getLazyEquipment: getEquipment
  };
};