import { RxCollection, RxJsonSchema, RxDocument } from 'rxdb';
import { TblSettingsPersonal } from 'src/generated/graphql';

export type SettingsPersonalDocType = TblSettingsPersonal;

export type SettingsPersonalDocMethods = {};

export type SettingsPersonalDocument = RxDocument<
  SettingsPersonalDocType,
  SettingsPersonalDocMethods
>;

export type SettingsPersonalCollectionMethods = {};

export type SettingsPersonalCollection = RxCollection<
  SettingsPersonalDocType,
  SettingsPersonalDocMethods,
  SettingsPersonalCollectionMethods
>;

export const schema: RxJsonSchema<SettingsPersonalDocType> = {
  title: 'SettingsPersonal ',
  description: 'Settings Personal',
  version: 15,
  keyCompression: false,
  type: 'object',
  properties: {
    autoId: {
      type: ['number'],
    },
    PKey: {
      type: 'string',
      primary: true,
    },
    fldCrewID: {
      type: ['string', 'null'],
      ref: 'crew',
    },
    fldEQ: {
      type: ['number', 'null'],
    },
    fldPO: {
      type: ['number', 'null'],
    },
    fldInv: {
      type: ['number', 'null'],
    },
    fldInvBev: {
      type: ['number', 'null'],
    },
    fldInvStores: {
      type: ['number', 'null'],
    },
    fldInvUni: {
      type: ['number', 'null'],
    },
    fldInvMed: {
      type: ['number', 'null'],
    },
    fldNoRights: {
      type: ['boolean', 'null'],
    },
    fldDeptHead: {
      type: ['boolean', 'null']
    },
    fldSMS: {
      type: ['number', 'null'],
    },
    fldISPS: {
      type: ['number', 'null'],
    },
    fldShowCurrentTasks: {
      type: ['boolean', 'null'],
    },
    fldSMSAdmin: {
      type: ['boolean', 'null'],
    },
    fldISPSAdmin: {
      type: ['boolean', 'null'],
    },
    fldCerts: {
      type: ['number', 'null'],
    },
    fldInvNewItemsConsume: {
      type: ['boolean', 'null'],
    },
    fldCrewEmp: {
      type: ['number', 'null'],
    },
    fldCrew: {
      type: ['number', 'null'],
    },
    deletedAt: {
      type: ['string', 'null'],
    },
    deletedBy: {
      type: ['string', 'null']
    },
    isRecoverable: {
      type: ['boolean', 'null']
    },
    updatedAt: {
      type: 'string',
    },
    fldAllDepts: {
      type: ['number', 'null']
    },
    fldWorkOffline: {
      type: ["number", "null"]
    },
    fldAddlocs: {
      type: ["number", "null"]
    },
    fldAddCats: {
      type: ["number", "null"]
    },
    fldLogsRL: {
      type: ["number", "null"]
    },
    fldLogsDay: {
      type: ["number", "null"]
    },
    fldCDM: {
      type: ["number", "null"]
    },
    fldCrewView: {
      type: ["number", "null"]
    },
    fldGuest: {
      type: ["number", "null"]
    },
    fldWL: {
      type: ["number", "null"]
    },
    fldLogs: {
      type: ["number", "null"]
    },
    fldLogsMaint: {
      type: ["number", "null"]
    },
    fldLogsXfer: {
      type: ["number", "null"]
    },
    fldTritonOpts: {
      type: ["number", "null"]
    },
    fldTritonSettings:{
      type:['number','null']
    },
    fldScheduling:{
      type:['number','null']
    }
  },
  indexes: [
    'PKey',
  ],
  required: [
    'fldCrewID',
  ],
  additionalProperties: false,
};

export default schema;
