import '../styles.css';
import { useState, FC, useRef, useCallback, MutableRefObject } from 'react';
import { TypeComputedProps } from '@inovua/reactdatagrid-enterprise/types';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import { Inventory } from 'src/generated/dotnet.graphql';

interface Props {
  inventoryForEquipment: Inventory[];
  onSelect: (inventory: Inventory) => void;
}

const filter = [
  {
    name: 'productName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'manufacturer',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'partNumber',
    operator: 'contains',
    type: 'select',
    value: '',
  },
];

const InventoryForEquipmentGrid: FC<Props> = ({ inventoryForEquipment, onSelect }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const highlightedRowId = useRef<string>('-1');
  const [gridRef, setGridRef] = useState<any>(null);

  const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    setGridRef(ref);
  };

  const onLoadingChange = (status: boolean) => {
    // If loading completed - check if there any items that needs to be highlighted.
    if (!status && highlightedRowId.current !== '-1') {
      gridRef?.current?.scrollToId(highlightedRowId.current);
      gridRef?.current?.setSelectedById(highlightedRowId.current, true);
      highlightedRowId.current = '-1';
    }
    setLoading(status);
  };

  const onRowClick = useCallback(async ({ data }) => {
    onSelect(data);
  }, []);

  const columns = [
    {
      name: 'productName',
      header: 'Spares',
      flex: 1,
    },
    {
      name: 'manufacturer',
      header: 'Manufacturer',
      flex: 1,
    },
    {
      name: 'partNumber',
      header: 'Part Number',
      flex: 1,
    },
  ];

  return (
    <>
      <div data-testid="data-grid" className="flex flex-col flex-grow spares-inventory-grid">
        <LicensedReactDataGrid
          onRowClick={onRowClick}
          onLoadingChange={onLoadingChange}
          defaultLimit={100}
          livePagination
          onReady={onReady}
          rowHeight={40}
          loading={loading}
          defaultFilterValue={filter}
          idProperty="id"
          columns={columns}
          dataSource={inventoryForEquipment}
        />
      </div>
    </>
  );
};

export default InventoryForEquipmentGrid;
