import '../styles.css'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { isNil, size } from "lodash";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { Alert, AlertColor, IconButton, Snackbar } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DeleteTwoTone } from "@mui/icons-material";
import StickyAppBar from "../../UI/StickyAppBar";
import Input from "../../UI/Forms/Input";
import Tabs from "../../UI/Tabs";
import WarningDialog from 'src/components/UI/WarningDialog';
import FormsSummaryForm from "./components/FormsSummaryForm";
import { handleCharLimitWarning } from "src/utils";
import { normalizeDateFormValue } from 'src/helpers';
import AttachmentTab from 'src/modules/Attachments';
import Comments from 'src/modules/Comments';
import { AttachmentType, DeleteResult, Form, FormInput, QueryFormsArgs, RecordType, SortEnumType } from 'src/generated/dotnet.graphql';
import { InjectedDrawerProps } from 'src/components/PageDrawer';
import { CrewType, useGetCrewNames } from 'src/hooks/crewNames/useGetCrewNames';
import { useUpsertForm } from 'src/hooks/forms/useUpsertForm';
import { useDeleteForm } from 'src/hooks/forms/useDeleteForm';
import { useGetLazyFormCounts } from 'src/hooks/forms/useGetLazyFormCounts';
import { validateForm } from './utils';
import { toStaticDate } from 'src/utils/format-dates';
import { logger } from 'src/helpers/logger';
import { CHAR_LIMIT, COMPONENTS, FormType } from "src/consts";
import { useGetLazyFormById } from 'src/hooks/forms/useGetLazyFormById';

interface InjectedProps extends Partial<InjectedDrawerProps> {
  form: Form;
  onCancel: () => void;
  onSave: (responseData: Form, responseMessage: string, isCreated: boolean) => void;
  onDelete: (responseData: DeleteResult, responseMessage: string) => void;
  onViewForm: (form: Form) => void;
  refetchQueryVariables?: QueryFormsArgs;
  moduleReadOnly: boolean;
  isFormCreate?: boolean;
  callerComponent?: string;
  type?: FormType;
}

interface FormsDetailFormRef {
  onSaveClick: () => void;
  onViewFormClick: () => void;
  onDeleteClick: () => void;
}

const FormsDetailForm = forwardRef<FormsDetailFormRef, InjectedProps>(({
  form,
  onCancel,
  onSave,
  onViewForm,
  onDelete,
  moduleReadOnly = false,
  refetchQueryVariables,
  isFormCreate = false,
  setFormIsDirty,
  callerComponent=COMPONENTS.FormsDetailForm,
  type
}: InjectedProps, ref) => {
  
  const { control, setValue, handleSubmit, getValues, watch, formState, reset } = useForm<any>({
    defaultValues: {
      completedBy: form.completedBy || "",
      completedDate: normalizeDateFormValue(form.completedDate),
      createdBy: form.createdBy || "",
      dateCreated: normalizeDateFormValue(form.dateCreated),
      remark: form.remark || "",
      reportNumber: form.reportNumber || `${format(new Date(), "yyyyMM")}-${+new Date()}`,
      reportType: form.reportType || "",
      verifiedBy: form.verifiedBy || "",
      verifiedDate: normalizeDateFormValue(form.verifiedDate),
    }
  });

  const formInitialValues = useRef<any>({});
  const { data: crewData } = useGetCrewNames({ order: [{ first: SortEnumType.Asc }, { last: SortEnumType.Asc }] });
  const { getLazyFormCounts } = useGetLazyFormCounts();
  const { upsertForm, upsertFormLoading, upsertFormError } = useUpsertForm();
  const { removeForm } = useDeleteForm();
  const { getLazyFormById, getLazyFormByIdLoading } = useGetLazyFormById();

  const { TBLANYREPORT } = CHAR_LIMIT;
  const [isDeletingWarningPopup, setIsDeletingWarningPopup] = useState(false);
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();

  const [documentsCount, setDocumentsCount] = useState<number>();
  const [photosCount, setPhotosCount] = useState<number>();
  const [commentsCount, setCommentsCount] = useState<number>();

  useImperativeHandle(ref, () => ({
    onSaveClick: () => {
      handleSubmit(handleSave)();
    },
    onViewFormClick: () => {
      handleViewFormClick();
    },
    onDeleteClick: () => {
      handleDelete();
    },
  }));
    
  const setFormCounts = async () => {
    // get form counts
    const queryResult = await getLazyFormCounts({ variables: { formId: form.id } });
    const formCounts = queryResult.data?.formById;
    if (formCounts) {
      setDocumentsCount(formCounts.documentsCount || 0);             
      setPhotosCount(formCounts.photosCount || 0);             
      setCommentsCount(formCounts.commentsCount || 0);             
    } else {
      logger('GetFormCounts').error(`Form not found with id: ${form.id}`);
    }
  }

  const hasValuesBeenChanged = formState.isDirty && (size(formState.dirtyFields) > 0 || size(formState.touchedFields) > 0);
  const isEditing = hasValuesBeenChanged || isFormCreate;

  useEffect(() => {
    // Don't get the counts on a new creation Form as the primary key does not exist yet
    if (!isFormCreate) {
      setFormCounts();
    }
  }, [isFormCreate]);

  const setInitialValues = async () => {
    const defaultValues = {
      ...getValues(),
    };

    formInitialValues.current = defaultValues;
    reset(defaultValues);
  };

  useEffect(() => {
    setFormIsDirty && setFormIsDirty(hasValuesBeenChanged);
  }, [hasValuesBeenChanged]);

  useEffect(() => {
    setInitialValues();
    return () => {
      formInitialValues.current = {};
    };
  }, []);

  const onChange = async (name: string, value: any) => {
    setValue(name, value, { shouldDirty: true });
  };

  const handleSave = async (data: Partial<Form>) => {
    const {
      reportNumber,
      createdBy,
      dateCreated,
      completedBy,
      completedDate,
      verifiedBy,
      verifiedDate,
      remark,
    } = data;

    const createdByCrew = crewData?.find((crew: CrewType) => crew.crewID === createdBy);

    const payload: FormInput = {
      id: form.id || null,
      createdByName: createdByCrew?.name,
      reportType: form.reportType,
      reportFile: form.reportFile,
      reportNumber,
      createdBy,
      dateCreated: toStaticDate(new Date(dateCreated)),
      completedBy,
      completedDate: completedDate ? toStaticDate(new Date(completedDate)) : null,
      verifiedBy,
      verifiedDate: verifiedDate ? toStaticDate(new Date(verifiedDate)) : null,
      remark,
    };

    const { responseData, responseMessage, responseDataError } = await upsertForm(payload, isFormCreate, refetchQueryVariables, callerComponent);

    if (responseData) {
      onSave(responseData as Form, responseMessage, isFormCreate);  
      reset(getValues());
    } else {
      console.log('responseDataError', responseDataError)
    }
  };

  const handleDelete = () => {
    setIsDeletingWarningPopup(true);
  };

  const handleDeleteOk = async () =>{
    const { responseData, responseMessage } = await removeForm(form.id, refetchQueryVariables, callerComponent);
    responseData?.success && onDelete(responseData, responseMessage);
    setIsDeletingWarningPopup(false);
  };

  const handleViewFormClick = async () => {
    // Hack to allways get the latest uploaded file;
    const queryResult = await getLazyFormById({ variables: { formId: form.id } });
    const formLatestVersion = queryResult.data?.formById;

    if (formLatestVersion) {
      const newForm = {
        ...form, // Keep all existing fields
        reportFile: formLatestVersion.reportFile, // Overwrite reportFile
      };
      onViewForm(newForm); // Use updated form for PDF preview
    } else {
      onViewForm(form); // Fallback to the existing form if no update is available
    }
  };

  const handleDeleteCancel = () =>{
    setIsDeletingWarningPopup(false);
  }

  const handleOk = (isEditing: boolean) => {
    if (isEditing && !validateForm(getValues(), setSnackbar)) return;
    if (isEditing) return;
    onCancel();
  };

  const handleCancel = () => {
    if (isFormCreate) {
      setFormIsDirty && setFormIsDirty(false) // set dirty form to false so you can navigate to other module
      return onCancel();
    }
    reset(formInitialValues.current);
  };

  const formClass = type === FormType.DIALOG
    ? 'relative bg-white flex-grow px-6 pt-4' 
    : 'relative bg-white pt-16 md:pt-19 mt-3 flex-grow px-6';
  
  return (
    <form className={`${formClass}`} onSubmit={handleSubmit(handleSave)}>
      <div className="documentTitle mb-5">
        {form.reportType}
      </div>

      <div className="mb-5">
        <Input
          inputProps={{
            size: "small",
            label: "Form Number",
            variant: "outlined",
          }}
          rules={{ required: 'Form Number is required!' }}
          warning={(value) => handleCharLimitWarning(value, TBLANYREPORT.fldReportNumber)}
          control={control}
          name="reportNumber"
        />
      </div>

      <Tabs
        className="h-auto pb-12"
        tabs={[
          {
            label: "Summary",
            component: (
              <FormsSummaryForm
                form={form}
                crewData={crewData}
                onChange={onChange}
                control={control}
              />
            ),
          },
          {
            label: documentsCount === undefined ? 'Attachments' : `Attachments (${documentsCount})`,
            disabled: isFormCreate,
            component: (
              <AttachmentTab
                recordId={form.id}
                recordType={RecordType.Forms}
                recordTypeName={form.__typename!}
                attachmentType={AttachmentType.Document}
                setAttachmentsCount={setDocumentsCount}
                readOnly={moduleReadOnly} 
              />
            ),
          },
          {
            label: photosCount === undefined ? 'Photos' : `Photos (${photosCount})`,
            disabled: isFormCreate,
            component: (
              <AttachmentTab
                recordId={form.id}
                recordType={RecordType.Forms}
                recordTypeName={form.__typename!}
                attachmentType={AttachmentType.Photo}
                setAttachmentsCount={setPhotosCount}
                readOnly={moduleReadOnly} 
              />
            ),
          },
          {
            label: commentsCount === undefined ? 'Comments' : `Comments (${commentsCount})`,
            disabled: isFormCreate,
            component: (
              <Comments
                recordId={form.id}
                recordType={RecordType.Forms}
                recordTypeName={form.__typename!}
                setCommentsCount={setCommentsCount}
                readOnly={moduleReadOnly}
              />
            ),
          },
        ]}
      />

      {type !== FormType.DIALOG && (
        <StickyAppBar
          cancelText="Cancel"
          okType={isEditing ? 'submit' : 'button'}
          okText={isEditing ? (isFormCreate ? 'Create' : 'Save') : 'Close'}
          onOk={() => handleOk(isEditing)}
          disabled={(moduleReadOnly) && isEditing}
          onCancel={isEditing ? () => handleCancel() : undefined}
        >
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center">
              <LoadingButton
                disabled={isFormCreate}
                variant="contained"
                loading={getLazyFormByIdLoading}
                onClick={handleViewFormClick}
              >
                View Form
              </LoadingButton>
            </div>

            {!isFormCreate && !moduleReadOnly && !isNil(form.id) && (
              <IconButton
                onClick={handleDelete}
                color="error"
                aria-label="Delete Report"
              >
                <DeleteTwoTone />
              </IconButton>
            )}
          </div>
        </StickyAppBar>
      )}

      <WarningDialog
        visible={isDeletingWarningPopup}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </form>
  );
});

export default FormsDetailForm;
