import { FC, useRef, useState } from "react";
import { DialogContent, DialogTitle, IconButton } from "@mui/material";
import { DeleteTwoTone } from "@mui/icons-material";
import { CustomDialog } from "src/helpers/modals";
import { FormType } from "src/consts";
import { DeleteResult, Form, Inventory, InventoryType, QueryFormsArgs, RecordType } from "src/generated/dotnet.graphql";
import DialogActionBar from "src/components/UI/DialogActionBar/indesx";
import InventoryDetailForm from "./InventoryDetailForm";

interface InjectedProps {
  visible: boolean;
  inventory: Inventory;
  onSave: (responseData: Inventory, responseMessage: string, isCreated: boolean) => void;
  onDelete?: (responseData: DeleteResult, responseMessage: string) => void;
  onCancel: () => void;
  moduleReadOnly: boolean;
  isCreate?: boolean;
}

const InventoryFormDialog: FC<InjectedProps> = ({
  visible,
  inventory,
  onSave,
  onDelete,
  onCancel,
  moduleReadOnly = false,
  isCreate = false,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const inventoryDetailFormRef = useRef<any>(null);

  const onSaveClick = () => {
    if (inventoryDetailFormRef.current) {
      inventoryDetailFormRef.current.onSaveClick();
    }
  };

  const onDeleteClick = () => {
    if (inventoryDetailFormRef.current) {
      inventoryDetailFormRef.current.onDeleteClick();
    }
  };

  const handleSaveResponse = (responseData: Inventory, responseMessage: string, isCreated: boolean) => {
    onSave(responseData, responseMessage, isCreated);
  }

  return (
    <CustomDialog scroll="paper" fullWidth maxWidth="md" open={visible}>
      <DialogTitle>
        <span className="font-bold text-2xl">{`${isCreate ? 'Add' : 'Edit'} Inventory`}</span>
      </DialogTitle>

      <DialogContent dividers sx={{ p: 0 }}>
        {!!inventory && (
          <InventoryDetailForm
            initialValue={inventory}
            isCreate={isCreate}
            onCancel={onCancel}
            onSave={handleSaveResponse}
            onDelete={onDelete}
            moduleReadOnly={moduleReadOnly}
            ref={inventoryDetailFormRef}
            recordType={RecordType.InventoryGeneral} 
            inventoryType={inventory.inventoryType ?? InventoryType.GeneralInventory}    
            setFormIsDirty={setIsEditing}      
            type={FormType.DIALOG}
          />
        )}
      </DialogContent>
      <DialogActionBar
        cancelText="Cancel"
        okType={isEditing ? 'submit' : 'button'}
        okText={isEditing ? (isCreate ? 'Create' : 'Save') : 'Close'}
        onOk={isEditing ? onSaveClick : onCancel}
        disabled={(moduleReadOnly) && isEditing}
        onCancel={isEditing ? () => onCancel() : undefined}
      >
        <div className="flex flex-row items-center justify-between">
          {!isCreate && (
            <IconButton
              onClick={onDeleteClick}
              color="error"
              aria-label="Delete Inventory"
              disabled={moduleReadOnly}
            >
              <DeleteTwoTone />
            </IconButton>
          )}
        </div>
      </DialogActionBar>
    </CustomDialog>
  );
};

export default InventoryFormDialog;
