import { ApolloQueryResult, gql, useLazyQuery, useQuery } from '@apollo/client';
import { Equipment, EquipmentFilterInput, EquipmentSortInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { PaginatedResult } from '../interfaces';

export const GET_LAZY_EQUIPMENT_FOR_INHERITANCE = gql`
  query GetLazyEquipmentForInheritance($skip: Int, $take: Int, $filterInput: EquipmentFilterInput, $order: [EquipmentSortInput!]) {
    lazyEquipmentForInheritance: equipment(skip: $skip, take: $take, filterInput: $filterInput, order: $order) {
      items {
        __typename
        id
        uniqueName
        hours
        hoursOffset
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export const useGetLazyEquipmentForInheritance = (): PaginatedResult<Equipment, EquipmentFilterInput, EquipmentSortInput> => {
  const [getLazyEquipment, { data, loading, error }] = useLazyQuery(GET_LAZY_EQUIPMENT_FOR_INHERITANCE, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const loadData = (skip: number, take: number, filterInput: EquipmentFilterInput, order: EquipmentSortInput[]) => {
    logger('GetLazyEquipmentForInheritance').debug('Query variables', { skip: skip, take: take, filterInput: filterInput, order: order });

    getLazyEquipment({ variables: { skip, take, filterInput, order } })
      .then((result: ApolloQueryResult<any>) => {
        if (result.data && result.data.lazyEquipmentForInheritance) {
          const { items, pageInfo } = result.data.lazyEquipmentForInheritance;
          logger('GetLazyEquipmentForInheritance').info('Equipment for inheritance fetched successfully', { recordsCount: items?.length, pageInfo: pageInfo });
        } else {
          logger('GetLazyEquipmentForInheritance').warning('No data received in query result');
        }
      })
      .catch((apolloError) => {
        logger('GetLazyEquipmentForInheritance').error('Error re-fetching equipment for inheritance -->', apolloError.message);
      });
  };

  if (error) {
    logger('GetLazyEquipmentForInheritance').error('Error fetching equipment for inheritance -->', error.message);
  }

  return {
    data: data?.lazyEquipmentForInheritance?.items || [],
    totalCount: data?.lazyEquipmentForInheritance?.totalCount || 0,
    currentCount: data?.lazyEquipmentForInheritance?.items?.length || 0,
    pageInfo: data?.lazyEquipmentForInheritance?.pageInfo,
    loading,
    loadData,
  };
};
