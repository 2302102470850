import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Alert, AlertColor } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Input from 'src/components/UI/Forms/Input';
import { validateEquipmentReadingMeterForm } from 'src/components/PageDrawer/Equipment/utils';
import { Equipment, UpdateEquipmentHoursInput } from 'src/generated/dotnet.graphql';
import { useUpdateEquipmentHours } from 'src/hooks/equipment/useUpdateEquipmentHours';
import { stopPropagate } from 'src/utils';

interface Props {
  visible: boolean;
  initialValue: Equipment;
  onSave: (responseData: Equipment, responseMessage: string) => void;
  onCancel: () => void;
}

const EquipmentReadingMeterDialog: FC<Props> = ({
  initialValue,
  visible,
  onSave,
  onCancel,
}) => {
  const { control, handleSubmit, watch, reset } = useForm<any>();
  const { updateEquipmentHours, updateEquipmentHoursLoading } = useUpdateEquipmentHours();
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();
  const readingValue = watch('hours');

  const handleCancel = () => {
    onCancel();
    reset();
  };

  const handleSave = async (data: any) => {
    if (!validateEquipmentReadingMeterForm(data, setSnackbar)) return;

    const payload: UpdateEquipmentHoursInput = {
      equipmentId: initialValue.id,
      hours: parseInt(data.hours, 10),
    };
    
    const { responseData, responseMessage } = await updateEquipmentHours(payload);
    
    if (responseData) {
      onSave(responseData, responseMessage);
    }
    reset();
  };

  return (
    <>
      <Dialog fullWidth open={visible}>
        <DialogTitle>
          {`${initialValue?.uniqueName} Hours`} 
        </DialogTitle>
        <form id="Equipment-meter-reading-edit-form" onSubmit={stopPropagate(handleSubmit(handleSave))}>
          <DialogContent dividers sx={{ p: 5, minHeight: "180px" }}>
            <div>
              <div style={{ display: "flex", alignItems: "center", fontSize: "14px" }}>
                <span>{`Current Equipment Hours: ${initialValue.hours}`}</span>
              </div>
              {initialValue.hoursOffset && (
                <div style={{ display: "flex", alignItems: "center", fontSize: "14px",marginTop: "8px" }}>
                  <span>{`Hours Offset: ${initialValue.hoursOffset}`}</span>
                </div>
              )}
              <div className='pt-5'>
                <Input
                  name="hours"
                  inputProps={{
                    label: 'Meter Reading',
                    type: 'number',
                    inputProps: { 
                      min: 0, 
                      style: { 
                        textAlign: 'end', 
                        marginRight:'5px' 
                      },
                    },
                  }}
                  control={control}
                  rules={{
                    min: 0,
                    required: true,
                  }}
                />
              </div>
              {initialValue.hoursOffset && readingValue && (
                <div style={{ display: "flex", alignItems: "center", fontSize: "14px",marginTop: "8px" }}>
                  <span>{`New Equipment Hours: ${Number(initialValue.hoursOffset) + Number(readingValue)}`}</span>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'flex-end' }}>
            <Button autoFocus onClick={handleCancel}>
              Cancel
            </Button>
            <LoadingButton
              loading={updateEquipmentHoursLoading}
              type="submit"
              form="Equipment-meter-reading-edit-form"
              variant="contained"
            >
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </>
  );
};

export default EquipmentReadingMeterDialog;