import { ApolloError, gql, useMutation } from '@apollo/client';
import { Equipment, EquipmentNotFoundError, UpdateEquipmentHoursInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { MutationResponse } from '../interfaces';

export const UPDATE_EQUIPMENT_HOURS = gql`
  mutation UpdateEquipmentHours($input: UpdateEquipmentHoursInput!) {
    updateEquipmentHours(input: $input) {
      equipment {
        id
        uniqueName
        hours
        hoursOffset
      }
      errors {
        ... on EquipmentNotFoundError {
          code
          message
        }
      }
    }
  }
`;

interface UpdateEquipmentHoursResult {
  updateEquipmentHours: (input: UpdateEquipmentHoursInput) => Promise<MutationResponse<Equipment, EquipmentNotFoundError>>;
  updateEquipmentHoursLoading: boolean;
  updateEquipmentHoursError?: ApolloError;
}

export const useUpdateEquipmentHours = (): UpdateEquipmentHoursResult => {
  const [modifyEquipmentHours, { loading, error }] = useMutation(UPDATE_EQUIPMENT_HOURS, {
    onError: (error: ApolloError) => {
      logger('UpdateEquipmentHours').error(`Error updating equipment hours -->`, error.message);
    }
  });

  const updateEquipmentHours = async (input: UpdateEquipmentHoursInput): Promise<MutationResponse<Equipment, EquipmentNotFoundError>> => {
    const response = await modifyEquipmentHours({ 
      variables: { input },
    });

    const responseData = response.data?.updateEquipmentHours?.equipment;
    if (responseData) {
      logger('UpdateEquipmentHours').info(`Equipment hours updated successfully`, response.data);
      return {
        responseData,
        responseMessage: `Equipment hours updated successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to update equipment hours!`,
      };
    }
  };

  return { 
    updateEquipmentHours, 
    updateEquipmentHoursLoading: loading, 
    updateEquipmentHoursError: error,
  };
};