import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery  } from "@apollo/client";
import { logger } from "src/helpers/logger";
import { FormDataResponse } from "./useGetLazyFormById";

export const GET_FORM_COUNTS = gql`
  query GetFormCounts($formId: String!) {
    formById(formId: $formId) {
      __typename
      id
      photosCount
      documentsCount
      commentsCount
    }
  }
`

interface FormResult {
  getLazyFormCounts: LazyQueryExecFunction<FormDataResponse, OperationVariables>;
}

export const useGetLazyFormCounts = (): FormResult => {

  const [ getFormById, { error }] = useLazyQuery(GET_FORM_COUNTS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetFormCounts').error('Error fetching form -->', error.message);
  }

  return {
    getLazyFormCounts: getFormById,
  };
};
  