//React Components
import { useEffect, useState } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

// TDI Components
import routes, { RoutePath } from '../../routes';
import MuiSideNavBarLayout from '../MuiSideNavBarLayout';

// Utils
import { useAuth } from '../../contexts/auth';
import { useAppState } from 'src/contexts/app-state';

const SentryRoute = Sentry.withSentryRouting(Route);

const AuthorizedLayout = () => {
  const { token } = useAuth();
  const { settingsPersonal } = useAppState();
  const location = useLocation();
  // TODO: Revisit me. Maybe it should be stored somehwere.
  const [navigationRoutes, setNavigationRoutes] = useState(routes);

  // Service worker update check when browsing to new location

  // const serviceWorkerUpdate = async () => {
  //   if ('serviceWorker' in navigator) {
  //     const { serviceWorker } = navigator;

  //     try {
  //       console.log("Service worker update check")
  //       const registrations = await serviceWorker.getRegistrations();
  //       registrations.map(r => r.update());
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   serviceWorkerUpdate();
  // }, [location]);

  const handleResetRoutes = () => {
    const updatedRoutes = routes.filter((item) => {
      if (settingsPersonal.fldNoRights) {
        if (item.path !== RoutePath.HOURS_OF_REST) {
          return false;
        }
      }
      switch (item.path) {
        case RoutePath.EQUIPMENT:
          if (!settingsPersonal.fldEQ) {
            return false;
          }
          break;
        case RoutePath.PURCHASING:
          if (!settingsPersonal.fldPO) {
            return false;
          }
          break;
        case RoutePath.INVENTORIES_HOME:
          if (!settingsPersonal.fldInv) {
            return false;
          }
          break;
        // case RoutePath.INVENTORIES_BEVERAGES:
        //   if (!settingsPersonal.fldInvBev) {
        //     return false;
        //   }
        //   break;
        // case RoutePath.INVENTORIES_UNIFORMS:
        //   if (!settingsPersonal.fldInvUni) {
        //     return false;
        //   }
        //   break;
        // case RoutePath.INVENTORIES_STORES:
        //   if (!settingsPersonal.fldInvStores) {
        //     return false;
        //   }
        //   break;
        // case RoutePath.INVENTORIES_MEDICAL:
        //   if (!settingsPersonal.fldInvMed) {
        //     return false;
        //   }
        //   break;
        case `${RoutePath.SMS_FORMS}`:
        // case `${RoutePath.SMS_REPORTS}`:
        // case `${RoutePath.SMS_REPORTS}/:id`:
          if (!settingsPersonal.fldSMS) {
            return false;
          }
          break;
        case `${RoutePath.ISPS_FORMS}`:
        // case `${RoutePath.ISPS_REPORTS}`:
        // case `${RoutePath.ISPS_REPORTS}/:id`:
          if (!settingsPersonal.fldISPS) {
            return false;
          }
          break;
        case `${RoutePath.CERTIFICATES}/:type`:
          if (!settingsPersonal.fldCerts) {
            return false;
          }
          break;

        default:
          return true;
      }
      return true;
    });

    setNavigationRoutes(updatedRoutes);
  };

  useEffect(() => {
    if (settingsPersonal) {
      handleResetRoutes();
    }
  }, [settingsPersonal]);

  return (
    <MuiSideNavBarLayout>
      <Switch>
        {/* why are we storing routes in state and manually mapping them?!?! */}
        {navigationRoutes.map(({ path, component, exact }) => (
          <SentryRoute
            exact={exact}
            key={path}
            path={path}
            component={component}
          />
        ))}
        <Redirect to="/home" />
        {!token && (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        )}
      </Switch>
    </MuiSideNavBarLayout>
  );
};

export default AuthorizedLayout;
