import { FC, useState } from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertColor, Box, FormGroup, IconButton, Snackbar, Tooltip } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import Checkbox from 'src/components/UI/Forms/Checkbox';
import DatePicker from 'src/components/UI/Forms/DatePicker';
import Input from 'src/components/UI/Forms/Input';
import RichTextEditor from '../../../UI/Forms/RichTextEditor';
import ListDefaultAutocomplete from 'src/components/UI/Forms/ListDefaultAutocomplete';
import { handleCharLimitWarning } from '../../../../utils';
import { useAppState } from 'src/contexts/app-state';
import { CHAR_LIMIT, SEVERITY } from 'src/consts';
import { isValidDateFormat } from 'src/utils/format-dates';
import { Equipment, RecordType } from 'src/generated/dotnet.graphql';
import CategoryDropdown from 'src/components/Dropdowns/CategoryDropdown';
import LocationDropdown from 'src/components/Dropdowns/LocationDropdown';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { isNil, isNull } from 'lodash';
import EquipmentReadingMeterDialog from 'src/pages/EquipmentPage/component/EquipmentReadingMeterDialog';
import EquipmentSuppliersDropdown from 'src/components/Dropdowns/EquipmentSuppliersDropdown';
import MultiSelect from 'src/components/UI/Forms/MultiSelect';

interface InjectedProps {
  initialValue: Equipment;
  onChange: (field: string, value: any) => void;
  control: Control;
  watch: UseFormWatch<any>;
  setValue?: UseFormSetValue<any>;
  readOnly? : boolean;
}

const EquipmentSummaryForm: FC<InjectedProps> = ({
  initialValue,
  onChange,
  control,
  watch,
  setValue,
  readOnly=false,
}) => {
  const { settingsPersonal } = useAppState();
  const isMonitorHoursChecked = watch("countHours");
  const [showMeterReadingDialog, setShowMeterReadingDialog] = useState<boolean>(false);
  const { EQUIPMENT } = CHAR_LIMIT;
  const [selectedEquipment, setSelectedEquipment] = useState<Equipment | undefined>(initialValue);
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();
  
  const handleEquipmentHoursSave = (responseData: Equipment, responseMessage: string) => {
    setValue && setValue('hours', responseData.hours)
    setShowMeterReadingDialog(false);
    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  return (
    <div className="mt-3">
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="general-information"
          id="general-information"
        >
          General Information
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full flex flex-col">

            <FormGroup row className="mt-4" style={{ display: 'flex', alignItems: 'center' }}>
              <div className="w-40">
                <Checkbox
                  control={control}
                  name="countHours"
                  label="Monitor Hours"
                />
              </div>
            </FormGroup>

            {isMonitorHoursChecked && (
              <div className="flex items-center mt-1">
                <div className="w-40">
                  <Input
                    name="hours"
                    inputProps={{
                      label: 'Hours',
                      type: 'number',
                      inputProps: { min: 0, style: { textAlign: 'end' } },
                    }}
                    control={control}
                    rules={{
                      min: 0,
                    }}
                    // disabled={!isNil(initialValue.parentId) || !isNil(initialValue.hoursOffset)}
                    disabled={true}
                  />
                </div>
              
                <div className="flex items-center mt-1"> 
                  <span className="flex items-center mt-0 ml-4">
                    {!readOnly && !isNil(initialValue.id) && isNil(initialValue?.parentId) &&
                      <Tooltip title="Update Hours">
                        <IconButton
                          onClick={() => setShowMeterReadingDialog(true)}
                          color="inherit"
                          aria-label="Update Hours"
                        >
                          <MoreTimeIcon />
                        </IconButton>
                      </Tooltip>
                    } 
                  </span>
                  {initialValue?.parentId && isNil(initialValue?.hoursOffset) && (
                    <span style={{ fontSize: '14px', color: '#888' }}>{`Hours inherited from `}<strong>{initialValue?.parentUniqueName}.</strong></span>
                  )}
                  {initialValue?.hoursOffset && isNil(initialValue?.parentId) && (
                    <span style={{ fontSize: '14px', color: '#888' }}>{`${initialValue.uniqueName} Hours are offset from the Meter Reading by ${initialValue?.hoursOffset} Hours.`}</span>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="mt-3">
            <CategoryDropdown
              name="categoryId"
              label="Category"
              control={control}
              onChange={onChange}
              recordType={RecordType.Equipment}
              rules={{ required: true }}
              allDepts={settingsPersonal?.fldAllDepts > 0}
              actionable
            />
          </div>

          <div className="mt-3">
            <LocationDropdown
              name="locationId"
              label="Location"
              control={control}
              onChange={onChange}
              recordType={RecordType.Equipment}
              allDepts={settingsPersonal?.fldAllDepts > 0}
              actionable
            />
          </div>

          <div className="mt-3">
            <ListDefaultAutocomplete
              disabled={readOnly}
              onChange={onChange}
              control={control}
              name="department"
              label="Department"
              listName="Department"
            />
          </div>

          <div className="mt-3">
            {/* TODO - Switch to multiselect after db migration to suport a longer string
            <MultiSelect
              control={control}
              name="reportingTag"
              keyExpr="id"
              displayExpr="member"
              options={reportingTagList}
              label="Reporting Tag"
              onChange={onChange}
            /> */}
            <ListDefaultAutocomplete
              disabled={readOnly}
              onChange={onChange}
              control={control}
              name="reportingTag"
              label="Reporting Tag"
              listName="Reporting Tag"
              freeSolo={true}
            />
          </div>

          <div className="mt-3">
            <FormGroup row>
              <Checkbox
                control={control}
                name="restricted"
                label="Restricted to HoD"
              />
              <Checkbox
                control={control}
                name="sms"
                label="Critical Equipment"
              />
            </FormGroup>
          </div>

          <div className="my-4">
            <RichTextEditor 
              control={control} 
              name="notes" 
              onChange={onChange} 
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion className="mt-3">
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="planning-status"
          id="planning-status"
        >
          Additional Details
        </AccordionSummary>
        <AccordionDetails>
          <div className="pt-4">
            <EquipmentSuppliersDropdown
              control={control}
              label="Supplier"
              name="supplier"
              onChange={onChange}
            />
          </div>

          <div className="w-full flex flex-col mt-4">
            <FormGroup row>
              <DatePicker
                views={['year', 'month', 'day']}
                size="small"
                name="inService"
                control={control}
                label="Date In Service"
                rules={{
                  validate: (value) => isValidDateFormat(value) || 'Enter a valid date (dd-MMM-yyyy)',
                }}
              />
              <Box className="h-px w-2 self-center mx-2s bg-transparent" />
              <DatePicker
                views={['year', 'month', 'day']}
                size="small"
                name="expireDate"
                control={control}
                label="Expiry Date"
                rules={{
                  validate: (value) => isValidDateFormat(value) || 'Enter a valid date (dd-MMM-yyyy)',
                }}
              />
            </FormGroup>
          </div>

          <div className="pt-4">
            <Input
              name="serialNum"
              rules={{ maxLength: EQUIPMENT.SerialNum }}
              warning={(value) => handleCharLimitWarning(value, EQUIPMENT.SerialNum)}
              control={control}
              inputProps={{ label: 'Serial Number' }}
            />
          </div>

          <div className="pt-4">
            <Input
              name="arrangementNum"
              rules={{ maxLength: EQUIPMENT.ArrangementNum }}
              warning={(value) => handleCharLimitWarning(value, EQUIPMENT.ArrangementNum)}
              control={control}
              inputProps={{ label: 'Arrangement Number' }}
            />
          </div>

          <div className="pt-4">
            <Input
              name="rating"
              rules={{ maxLength: EQUIPMENT.Rating }}
              warning={(value) => handleCharLimitWarning(value, EQUIPMENT.Rating)}
              control={control}
              inputProps={{ label: 'Rating' }}
            />
          </div>

          <div className="pt-4">
            <Input
              name="artNumber"
              rules={{ maxLength: EQUIPMENT.ArtNum }}
              warning={(value) => handleCharLimitWarning(value, EQUIPMENT.ArtNum)}
              control={control}
              inputProps={{ label: 'Build Reference/Article Number' }}
            />
          </div>

          <div className="mt-4" />
        </AccordionDetails>
      </Accordion>

      {!!selectedEquipment && (
        <EquipmentReadingMeterDialog
          visible={showMeterReadingDialog}
          initialValue={initialValue}
          onSave={handleEquipmentHoursSave}
          onCancel={() => setShowMeterReadingDialog(false)}
        />
      )}

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </div>
  );
};

export default EquipmentSummaryForm;
