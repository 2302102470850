import { isEmpty, isNil } from 'lodash';

export const validateForm = (data: any, setSnackbar: any) => {
  const { reportNumber, createdBy, dateCreated } = data;

  // Ensure dateCreated is valid
  const isValidDate = !isNil(dateCreated) && new Date(dateCreated).toString() !== 'Invalid Date';

  if (
    isNil(reportNumber) || isEmpty(reportNumber) ||
    isNil(createdBy) || isEmpty(createdBy) ||
    !isValidDate // Instead of isEmpty(dateCreated)
  ) {
    setSnackbar({
      open: true,
      message: 'Please fill required field(s). Check form field(s) marked with red color',
      type: 'error',
    });
    return false;
  }
  return true;
};
