import { ApolloQueryResult, gql, useQuery } from '@apollo/client';
import { CollectionSegmentInfo, Bunkering, BunkeringFilterInput, BunkeringSortInput, QueryBunkeringArgs } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const GET_BUNKERINGS_WITH_COUNTS = gql`
  query GetBunkeringsWithCounts(
    $skip: Int
    $take: Int
    $filterInput: BunkeringFilterInput!
    $order: [BunkeringSortInput!]
  ) {
    bunkering(skip: $skip, take: $take, filterInput: $filterInput, order: $order) {
      items {
        __typename
        agent
        amount
        cost
        curr
        date
        deletedAt
        deletedBy
        department
        flowRate
        fluid
        from
        id
        isRecoverable
        latDegStart
        latDegStop
        latHemStart
        latHemStop
        latMinStart
        latMinStop
        latSecStart
        latSecStop
        longDegStart
        longDegStop
        longHemStart
        longHemStop
        longMinStart
        longMinStop
        longSecStart
        longSecStop
        method
        month
        notes
        oilRecord
        qty
        responsible
        sample
        start
        station
        stop
        to
        updatedAt
        year
        commentsCount
        documentsCount
        photosCount
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export interface BunkeringsResult {
  data: Bunkering[];
  currentCount: number;
  totalCount: number;
  pageInfo: CollectionSegmentInfo;
  loading: boolean;
  loadData: (skip: number, take: number, filterInput: BunkeringFilterInput, order: BunkeringSortInput[]) => void;
}

export const useGetBunkeringsWithCounts = ({ skip, take, filterInput, order }: QueryBunkeringArgs): BunkeringsResult => {
  const { data, loading, error, refetch } = useQuery(GET_BUNKERINGS_WITH_COUNTS, {
    variables: { skip, take, filterInput, order },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const loadData = ( skip: number, take: number, filterInput: BunkeringFilterInput, order: BunkeringSortInput[]) => {
    logger('GetBunkeringsWithCounts').debug('Query variables', { skip: skip, take: take, filterInput: filterInput, order: order });
    refetch({ skip: skip, take: take, filterInput: filterInput, order: order })
      .then((result: ApolloQueryResult<any>) => {
        if (result.data && result.data.bunkering) {
          const { items, pageInfo } = result.data.bunkering;
          logger('GetBunkeringsWithCounts').info('Bunkerings fetched successfully', { recordsCount: items?.length, pageInfo: pageInfo });
        } else {
          logger('GetBunkeringsWithCounts').warning('No data received in query result');
        }
      })
      .catch((apolloError) => {
        logger('GetBunkeringsWithCounts').error('Error re-fetching bunkerings -->', apolloError.message);
      });
  };

  if (error) {
    logger('GetBunkeringsWithCounts').error('Error fetching bunkerings -->', error.message);
  }

  return {
    data: data?.bunkering?.items || [],
    totalCount: data?.bunkering?.totalCount || 0,
    currentCount: data?.bunkering?.items?.length || 0,
    pageInfo: data?.bunkering?.pageInfo,
    loading,
    loadData,
  };
};
