import React, { FC, ReactNode, useMemo } from 'react';
import {
  Autocomplete as MaterialAutocomplete,
  FormHelperText,
  FormControl,
  TextField,
} from '@mui/material';
import { Control, RegisterOptions, useController } from 'react-hook-form';

type InjectedProps = {
  control: Control;
  name: string;
  rules?: RegisterOptions;
  placeholder?: string;
  label?: string;
  freeSolo?: boolean;
  options: any[];
  loading?: boolean;
  displayExpr: string;
  valueExpr: string;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any
  ) => ReactNode;
  disabled?: boolean;
  variant?: any;
  size?: any;
};

const Autocomplete: FC<InjectedProps> = ({
  name,
  control,
  rules,
  placeholder,
  freeSolo,
  label,
  loading,
  options,
  displayExpr,
  valueExpr,
  renderOption,
  disabled,
  variant,
  size = 'small',
}) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const handleChange = async (event: any, newValue: any) => {
    if (newValue === null) {
      onChange(null); // Reset value to null when input is cleared
      return;
    }
    onChange(newValue[valueExpr]);
  };

  const getOptionLabel = (option: any) => {
    // e.g value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    if (option.inputValue) {
      return option.inputValue;
    }
    return option[displayExpr];
  };

  const renderItem = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
  ) => {
    if (renderOption) return renderOption(props, option);

    return (
      <li {...props} key={props.id}>
        {option[displayExpr]}
      </li>
    );
  };

  return (
    <FormControl error={!!error} fullWidth>
      <MaterialAutocomplete
        disabled={disabled}
        value={options.find((item) => String(item[valueExpr]) === String(value)) || null}
        clearOnBlur
        handleHomeEndKeys
        freeSolo={freeSolo}
        loading={loading}
        options={options}
        getOptionLabel={getOptionLabel}
        onChange={handleChange}
        renderOption={renderItem}
        size={size}
        isOptionEqualToValue={(option, value) => String(option[valueExpr]) === String(value[valueExpr])}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            autoComplete='off'
            variant={variant}
            placeholder={placeholder}
            error={!!error}
            helperText={error?.message}
          />
        )}
      />
      {error && (
        <FormHelperText error={!!error}>{error?.message}</FormHelperText>
      )}
    </FormControl>
  );
};

export default Autocomplete;
