import { gql } from '@apollo/client';

// Documents
export const UpdateLogEntryDocumentsCountCache = gql`
  fragment UpdateLogEntryDocumentsCountCache on LogEntry {
    documentsCount
  }
`;
export const UpdateInventoryDocumentsCountCache = gql`
  fragment UpdateInventoryDocumentsCountCache on Inventory {
    documentsCount
  }
`;
export const UpdateBunkeringDocumentsCountCache = gql`
  fragment UpdateBunkeringDocumentsCountCache on Bunkering {
    documentsCount
  }
`;
export const UpdateFormDocumentsCountCache = gql`
  fragment UpdateFormDocumentsCountCache on Form {
    documentsCount
  }
`;
export const UpdateEquipmentDocumentsCountCache = gql`
  fragment UpdateEquipmentDocumentsCountCache on Equipment {
    documentsCount
  }
`;

// Photos
export const UpdateLogEntryPhotosCountCache = gql`
  fragment UpdateLogEntryPhotosCountCache on LogEntry {
    photosCount
  }
`;
export const UpdateInventoryPhotosCountCache = gql`
  fragment UpdateInventoryPhotosCountCache on Inventory {
    photosCount
  }
`;
export const UpdateBunkeringPhotosCountCache = gql`
  fragment UpdateBunkeringPhotosCountCache on Bunkering {
    photosCount
  }
`;
export const UpdateFormPhotosCountCache = gql`
  fragment UpdateFormPhotosCountCache on Form {
    photosCount
  }
`;
export const UpdateEquipmentPhotosCountCache = gql`
  fragment UpdateEquipmentPhotosCountCache on Equipment {
    photosCount
  }
`;

// Comments
export const UpdateLogEntryCommentsCountCache = gql`
  fragment UpdateLogEntryCommentsCountCache on LogEntry {
    commentsCount
  }
`;
export const UpdateDocumentationCommentsCountCache = gql`
  fragment UpdateDocumentationCommentsCountCache on Documentation {
    commentsCount
  }
`;
export const UpdateInventoryCommentsCountCache = gql`
  fragment UpdateInventoryCommentsCountCache on Inventory {
    commentsCount
  }
`;
export const UpdateBunkeringCommentsCountCache = gql`
  fragment UpdateBunkeringCommentsCountCache on Bunkering {
    commentsCount
  }
`;
export const UpdateFormCommentsCountCache = gql`
  fragment UpdateFormCommentsCountCache on Form {
    commentsCount
  }
`;
export const UpdateEquipmentCommentsCountCache = gql`
  fragment UpdateEquipmentCommentsCountCache on Equipment {
    commentsCount
  }
`;

// Orders
export const UpdateInventoryOrdersCountCache = gql`
  fragment UpdateInventoryOrdersCountCache on Inventory {
    ordersCount
  }
`;

// Equipment
export const UpdateEquipmentInventoryCountCache = gql`
  fragment UpdateEquipmentInventoryCountCache on Equipment {
    inventoryCount
  }
`;
export const UpdateEquipmentLogEntryCountCache = gql`
  fragment UpdateEquipmentLogEntryCountCache on Equipment {
    logEntryCount
  }
`;

// Spares used
export const UpdateLogEntrySparesUsedCountCache = gql`
  fragment UpdateLogEntrySparesUsedCountCache on LogEntry {
    sparesUsedCount
  }
`;
export const UpdateInventorySpareForEquipmentCache = gql`
  fragment UpdateInventorySpareForEquipmentCache on Inventory {
    equipmentCount
  }
`;