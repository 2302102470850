import { ApolloError, gql, useMutation } from '@apollo/client';
import { AddInventoryToEquipmentInput, Inventory, QueryEquipmentArgs, UserError } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { UpdateEquipmentInventoryCountCache } from '../fragments.graphql';
import { GET_LAZY_INVENTORY_FOR_EQUIPMENT_PAGINATED } from './useGetLazyInventoryForEquipmentPaginated';

export const ADD_INVENTORY_TO_EQUIPMENT = gql`
  mutation AddInventoryToEquipment($input: AddInventoryToEquipmentInput!) {
    addInventoryToEquipment(input: $input) {
      inventory {
        id
        productName
        manufacturer
        partNumber
        totalAmount
      }
      errors {
        code
        message
      }
    }
  }
`;

export interface AddInventoryToEquipmentMutationResponse {
  responseData?: Inventory[];
  responseDataError?: UserError;
  responseMessage: string;
}

interface AddInventoryToEquipmentResult {
  addInventoryToEquipment: (input: AddInventoryToEquipmentInput, refetchQueryVariables: QueryEquipmentArgs) => Promise<AddInventoryToEquipmentMutationResponse>;
  addInventoryToEquipmentLoading: boolean;
}

export const useAddInventoryToEquipment = (equipmentId: string): AddInventoryToEquipmentResult => {
  const [createInventoryToEquipment, { loading, error }] = useMutation(ADD_INVENTORY_TO_EQUIPMENT, {
    onError: (error: ApolloError) => {
      logger('AddInventoryToEquipment').error(`Error adding inventory to equipment -->`, error.message);
    },  
    update: (cache, { data }) => {
      if (data?.addInventoryToEquipment?.inventory) {      
        const recordCacheId = cache.identify({
          __typename: 'Equipment',
          id: equipmentId,
        });
              
        const existingRecord = cache.readFragment<any>({
          id: recordCacheId,
          fragment: UpdateEquipmentInventoryCountCache,
        });

        if (existingRecord) {
          const updatedCount = { inventoryCount: existingRecord.inventoryCount + data.addInventoryToEquipment.inventory.length};

          cache.writeFragment({
            id: recordCacheId,
            fragment: UpdateEquipmentInventoryCountCache,
            data: updatedCount,
          });

        } else {
          logger('Cache-AddInventoryToEquipment').warning(`Equipment: ${equipmentId} not found in cache`);
        }
      } else {
        logger('Cache-AddInventoryToEquipment').warning(`Equipment: ${equipmentId} cache update failed --> No response from upsert mutation`);
      }
    }
  });

  const addInventoryToEquipment = async (input: AddInventoryToEquipmentInput, refetchQueryVariables: QueryEquipmentArgs): Promise<AddInventoryToEquipmentMutationResponse> => {
    const response = await createInventoryToEquipment({ 
      variables: { input },
      refetchQueries: [
        {
          query: GET_LAZY_INVENTORY_FOR_EQUIPMENT_PAGINATED,
          variables: refetchQueryVariables,
        },
      ],
    });
    const responseData = response.data?.addInventoryToEquipment?.inventory;
    const responseDataError = response.data?.addInventoryToEquipment?.errors;

    if (responseData) {
      logger('AddInventoryToEquipment').info('Inventory added to equipment successfully', response.data);
      return {
        responseData,
        responseMessage: `Inventory added to equipment successfully!`,
      };
    } else if (responseDataError) {
      logger('AddInventoryToEquipment').info('Failed to add inventory to equipment!', response.data);
      return {
        responseDataError: responseDataError[0],
        responseMessage: responseDataError[0].message,
      }
    } else {
      return {
        responseMessage: `Failed to add inventory to equipment!`,
      };
    }
  };

  return { 
    addInventoryToEquipment, 
    addInventoryToEquipmentLoading: loading, 
  };
};