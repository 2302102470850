import { ApolloError, gql, useMutation } from '@apollo/client';
import { QueryBunkeringArgs } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_BUNKERINGS_WITH_COUNTS } from './useGetBunkeringsWithCounts';
import { DeleteMutationResponse } from '../interfaces';

export const BULK_DELETE_BUNKERINGS = gql`
  mutation BulkDeleteBunkering($bunkeringIds: [String!]!) {
    bulkDeleteBunkering(bunkeringIds: $bunkeringIds) {
      deleteResult {
        deletedIds
        message
        success
      }
      errors {
        code
        message
      }
    }
  }
`;

interface BulkDeleteBunkeringResult {
  bulkRemoveBunkering: (bunkeringIds: string[], queryVariables: QueryBunkeringArgs) => Promise<DeleteMutationResponse>;
  bulkRemoveBunkeringLoading: boolean;
}

export const useBulkDeleteBunkering = (): BulkDeleteBunkeringResult => {
  const [bulkDeleteBunkering, { loading }] = useMutation(BULK_DELETE_BUNKERINGS, {
    onError: (error: ApolloError) => {
      logger('BulkDeleteBunkering').error('Error deleting bunkering/bunkerings -->', error.message);
    }
  });

  const bulkRemoveBunkering = async (bunkeringIds: string[], refetchVariables: QueryBunkeringArgs): Promise<DeleteMutationResponse> => {
    const response = await bulkDeleteBunkering({ 
      variables: { bunkeringIds },
      refetchQueries: [
        {
          query: GET_BUNKERINGS_WITH_COUNTS,
          variables: refetchVariables,
        }
      ],
    });
    
    const responseData = response.data?.bulkDeleteBunkering?.deleteResult;
    if (responseData?.success) {
      logger('BulkDeleteBunkering').info(`${bunkeringIds.length > 1 ? 'Multiple bunkering' : 'Bunkering'} deleted successfully`, response.data);
      return {
        responseData,
        responseMessage: `${bunkeringIds.length > 1 ? 'Multiple bunkering' : 'Bunkering'} removed successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to remove log ${bunkeringIds.length > 1 ? 'multiple bunkering' : 'bunkering'}!`,
      };
    }
  };

  return { 
    bulkRemoveBunkering, 
    bulkRemoveBunkeringLoading: loading, 
  };
};
