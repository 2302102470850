import { gql, OperationVariables, QueryResult, useLazyQuery  } from "@apollo/client";
import { logger } from "src/helpers/logger";
import { BunkeringData } from "./useGetLazyBunkeringById";

export const GET_BUNKERING_COUNTS = gql`
  query GetBunkeringCounts($bunkeringId: String!) {
    bunkeringById(bunkeringId: $bunkeringId) {
      id
      photosCount
      documentsCount
      commentsCount
    }
  }
`

interface BunkeringCountsResult {
  getBunkeringCounts: (bunkeringId: string) => Promise<QueryResult<BunkeringData, OperationVariables>>;
}

export const useGetBunkeringCounts = (): BunkeringCountsResult => {

  const [ getBunkering, { error }] = useLazyQuery(GET_BUNKERING_COUNTS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });
  
  if (error) {
    logger('GetBunkeringCounts').error('Error fetching bunkering -->', error.message);
  }

  return {
    getBunkeringCounts: async (bunkeringId: string) => await getBunkering({ variables: { bunkeringId } }),
  };
};
