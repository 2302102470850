import { ApolloError, gql, useMutation } from '@apollo/client';
import { Equipment, EquipmentNotFoundError, UpdateEquipmentHoursInheritanceInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { MutationResponse } from '../interfaces';

export const UPDATE_EQUIPMENT_HOURS_INHERITANCE = gql`
  mutation UpdateEquipmentHoursInheritance($input: UpdateEquipmentHoursInheritanceInput!) {
    updateEquipmentHoursInheritance(input: $input) {
      equipment {
        id
        uniqueName
        manufacturer
        modelNumber
        department
        hours
        hoursOffset
        countHours
        categoryId
        locationId
        reportingTag
        restricted
        sms
        notes
        supplier
        inService
        expireDate
        serialNum
        arrangementNum
        artNumber
        dateEntered
        rating
        parentId
        parentUniqueName
      }
      errors {
        ... on EquipmentNotFoundError {
          code
          message
        }
      }
    }
  }
`;

interface UpdateEquipmentHoursInheritanceResult {
  updateEquipmentHoursInheritance: (input: UpdateEquipmentHoursInheritanceInput) => Promise<MutationResponse<Equipment, EquipmentNotFoundError>>;
  updateEquipmentHoursInheritanceLoading: boolean;
  updateEquipmentHoursInheritanceError?: ApolloError;
}

export const useUpdateEquipmentHoursInheritance = (): UpdateEquipmentHoursInheritanceResult => {
  const [modifyEquipmentHoursInheritance, { loading, error }] = useMutation(UPDATE_EQUIPMENT_HOURS_INHERITANCE, {
    onError: (error: ApolloError) => {
      logger('UpdateEquipmentHoursInheritance').error(`Error inheriting hours -->`, error.message);
    }
  });

  const addInheritanceMessage = `Equipment hours have been inherited successfully!`
  const addInheritanceFailMessage = `Failed to inherit equipment hours!`
  const removeInheritanceMessage = `Equipment hours inheritance has been removed successfully!`
  const removeInheritanceFailMessage = `Failed to remove equipment hours inheritance!`

  const updateEquipmentHoursInheritance = async (input: UpdateEquipmentHoursInheritanceInput): Promise<MutationResponse<Equipment, EquipmentNotFoundError>> => {
    const response = await modifyEquipmentHoursInheritance({ 
      variables: { input },
    });

    const responseData = response.data?.updateEquipmentHoursInheritance?.equipment;
    let responseMessage = '';

    if (input.parentEquipmentId === null) {
      if (responseData) {
        responseMessage = removeInheritanceMessage;
      } else {
        responseMessage = removeInheritanceFailMessage;
      }
    } else {
      if (responseData) {
        responseMessage = addInheritanceMessage;
      } else {
        responseMessage = addInheritanceFailMessage;
      }
    }

    if (responseData) {
      logger('UpdateEquipmentHoursInheritance').info(`Equipment hours have been inherited successfully`, response.data);
      return {
        responseData,
        responseMessage,
      };
    } else {
      return {
        responseMessage,
      };
    }
  };

  return { 
    updateEquipmentHoursInheritance, 
    updateEquipmentHoursInheritanceLoading: loading, 
    updateEquipmentHoursInheritanceError: error,
  };
};