import './styles.css';
import { useEffect, useState, useRef, useMemo } from 'react';
import { useAuth } from 'src/contexts/auth';
import { Alert, Snackbar, AlertColor } from '@mui/material';
import PageDrawer from '../../components/PageDrawer';
import PageHeader, { Action } from '../../components/UI/PageHeader';
import EquipmentDetailForm from '../../components/PageDrawer/Equipment/EquipmentDetailForm';
import EquipmentGrid from './component/EquipmentGrid';
import { useAppState } from 'src/contexts/app-state';
import { saveViewMode, getViewMode, extractValueBetweenTags, isEmptyObject } from '../../utils/'
import EquipmentHoursDialog from './component/EquipmentHoursDialog';
import { equipmentDeleteWarningMessage, GRID_LIMIT, GridType, SEVERITY, UpdateRecordInputType, UpdateRecordsOptionsType } from 'src/consts';
import { AccessType, getAccessType } from 'src/utils/permissions';
import MasterSearchInput, { MasterSearchInputValue } from 'src/components/MasterSearchInput';
import { BulkModifyEquipmentInput, DeleteResult, Equipment, EquipmentFilterInput, RecordType, SortEnumType } from 'src/generated/dotnet.graphql';
import useMergedFilters from 'src/hooks/common/useMergedFilters';
import ViewSelector from 'src/components/ViewSelector';
import DeleteRecordBtn from 'src/components/DeleteRecordsButton';
import UpdateRecordsButton from 'src/components/UpdateRecordsButton';
import { useBulkDeleteEquipment } from 'src/hooks/equipment/useBulkDeleteEquipment';
import CategoryViewGrid from './component/CategoryViewGrid';
import LocationViewGrid from './component/LocationViewGrid';
import { useBulkUpdateEquipment } from 'src/hooks/equipment/useBulkUpdateEquipment';
import { isArray } from 'lodash';

export const RECORDS_UPDATE_OPTIONS: UpdateRecordsOptionsType = {
  CATEGORY: { fieldName: 'categoryId', columnName: 'Category' },
  LOCATION: { fieldName: 'locationId', columnName: 'Location' },
  MANUFACTURER: { fieldName: 'manufacturer', columnName: 'Manufacturer' },
  MODEL_NUMBER: { fieldName: 'modelNumber', columnName: 'Model Number' },
  DEPARTMENT: { fieldName: 'department', columnName: 'Department' },
  RESTRICTED_TO_HOD: { fieldName: 'restricted', columnName: 'Restricted to HOD' },
  SMS: { fieldName: 'sms', columnName: 'Critical Equipment' },
  SUPPLIER: { fieldName: 'supplier', columnName: 'Supplier' },
  MONITOR_HOURS: { fieldName: 'countHours', columnName: 'Monitor Hours' },
  REPORTING_TAG: { fieldName: 'reportingTag', columnName: 'Reporting Tag' },
}

export const defaultFilterValue = { 
  isCritical: undefined,
  isExpired: undefined,
  isRestricted: undefined,
  hasWork: undefined,
  searchText: undefined
};

export const defaultSortValue = { 
  uniqueName: SortEnumType.Asc 
};

const EquipmentPage = () => {
  // Global state
  const { user } = useAuth();
  const { settingsPersonal } = useAppState();
  // Grid pagination state - keep it on parent component so it can be used for mutations refetching
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(GRID_LIMIT);
  const [masterSearchInput, setMasterSearchInput] = useState<MasterSearchInputValue | null>(null);
  const [filterValue, setFilterValue] = useMergedFilters<EquipmentFilterInput>(masterSearchInput, defaultFilterValue, GridType.DEFAULT);
  const [sortValue, setSortValue] = useState<any>(defaultSortValue);
  const [rowSelected, setRowSelected] = useState<Record<string, Equipment>>({});
  // Component related state
  const gridRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const oldSelectedItem = useRef<Equipment | null>(null);
  const [viewType, setViewType] = useState<GridType>(GridType.DEFAULT);
  const [selectedEquipment, setSelectedEquipment] = useState<Equipment>();
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [refetchTree, setRefetchTree] = useState<boolean>(false);
  const [savedViewList, setSavedViewList] = useState<any>([]);
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();
  const refetchQueryVariables = { skip: skip, take: limit, filterInput: filterValue, order: sortValue };
  const moduleReadOnly = getAccessType(settingsPersonal?.fldEQ) !== AccessType.FULL_ACCESS;
  const [showHoursDialog, setShowHoursDialog] = useState<boolean>(false);

  const { bulkRemoveEquipment, bulkRemoveEquipmentLoading } = useBulkDeleteEquipment();
  const { bulkUpdateEquipment, bulkUpdateEquipmentLoading } = useBulkUpdateEquipment();

  const handleDataExport = (type: string) => {
    gridRef?.current.handleExport(type);
  }

  const initializeViewMode = async () => {
    const mode = getViewMode();
    if (mode) setViewType(mode);
  };

  useEffect(() => {
    initializeViewMode();
  }, []);

  useEffect(() => {
    renderContent();
  }, [masterSearchInput]);

  useEffect(() => {
    // Reset filter value when the grid view changes
    setFilterValue(defaultFilterValue);
  }, [viewType]);

  const handleCreateClick = async () => {
    setIsCreate(true);
    const equipment: any ={
      department: user?.Department,
    };
    setSelectedEquipment(equipment);
  };

  const handleSave = async (responseData: Equipment, responseMessage: string, isCreated: boolean) => {
    setSelectedEquipment(responseData);
    oldSelectedItem.current = responseData;
    if (isCreated) {
      setIsCreate(false);
    }
    if (viewType !== GridType.DEFAULT) {
      setRefetchTree(!refetchTree);
      renderContent();
    }
    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const handleDelete = async (responseData: DeleteResult, responseMessage: string) => {
    if (responseData.success) {
      setSelectedEquipment(undefined);
    }

    if (viewType !== GridType.DEFAULT) {
      setRefetchTree(!refetchTree);
      renderContent();
    }

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const handleDeleteMultipleRecords = async (validRecords: string[], invalidMessage: string) => {
    if (invalidMessage) {
      setSnackbar({
        message: invalidMessage,
        severity: 'error',
      });
    }

    if (validRecords.length > 0) {
      const { responseData, responseMessage } = await bulkRemoveEquipment(validRecords, { skip, take: limit, filterInput: filterValue, order: sortValue });
      setRowSelected({});

      setSnackbar({
        message: responseMessage,
        severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
      });

      if (viewType !== GridType.DEFAULT) {
        setRefetchTree(!refetchTree);
        renderContent();
      }
    }
  };

  const handleUpdateMultipleRecords = async (payload: UpdateRecordInputType) => {

    const selectedRecordsIds: string[] = Object.keys(rowSelected || []);

    if (selectedRecordsIds.length > 0) {

      // TODO - This is supporting multiple tags dropdown after db migration
      // Convert the list of strings to a comma-separated string if the fieldName is 'reportingTag'
      // let value = payload.value;
      // if (payload.fieldName === 'reportingTag' && isArray(value)) {
      //   value = value.join(',');
      // }

      const input: BulkModifyEquipmentInput = {
        ids: selectedRecordsIds,
        [payload.fieldName]: payload.value
      }
      const { responseData, responseMessage } = await bulkUpdateEquipment(input, payload.columnName, refetchQueryVariables);

      if (!bulkUpdateEquipmentLoading) setRowSelected({});

      setSnackbar({
        message: responseMessage,
        severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
      });

      if (viewType !== GridType.DEFAULT) {
        setRefetchTree(!refetchTree);
        renderContent();
      }
    }
  };
  

  const handleCancel = () => {
    setIsCreate(false);
    setSelectedEquipment(undefined);
    oldSelectedItem.current = null;
  };

  const onSelect = (equipment: Equipment) => {
    setSelectedEquipment(equipment);
    oldSelectedItem.current = equipment;
  };

  const onSelectChange = async (selectedEquipment: Equipment) => {
    setSelectedEquipment(selectedEquipment);
    oldSelectedItem.current = selectedEquipment;
  };

  const isTableOriginal = useMemo(() => {
    if (viewType === GridType.CATEGORY || viewType === GridType.DEFAULT || viewType === GridType.LOCATION) {
      return true;
    } else {
      return false;
    }
  }, [viewType])

  const renderContent = () => {
    let gridType = viewType;
    if (!isTableOriginal) {
      const savedViewItem = savedViewList.find((item: any) => item.id === gridType);
      if (savedViewItem) {
        gridType = JSON.parse(savedViewItem.layout).mode;
      }
    }
    switch (gridType) {
      case GridType.LOCATION:
        return (
          <div
            style={{ maxHeight: containerRef.current?.clientHeight || 0 }}
            className="flex-grow h-full w-full flex overflow-y-auto"
          >
            <LocationViewGrid 
              masterSearchInput={masterSearchInput} 
              onChange={onSelectChange} 
              refetchTree={refetchTree} 
            />
          </div>
        );
      case GridType.CATEGORY:
        return (
          <div
            style={{ maxHeight: containerRef.current?.clientHeight || 0 }}
            className="flex-grow h-full w-full flex overflow-y-auto"
          >
            <CategoryViewGrid 
              masterSearchInput={masterSearchInput} 
              onChange={onSelectChange} 
              refetchTree={refetchTree} 
            />
          </div>
        );
      default:
        return (
          <div className="flex flex-grow h-full">
            <EquipmentGrid 
              darken 
              onSelect={onSelect}
              ref={gridRef} 
              skip={skip}
              setSkip={setSkip}
              limit={limit}
              setLimit={setLimit}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              sortValue={sortValue}
              setSortValue={setSortValue} 
              rowSelected={rowSelected}
              setRowSelected={setRowSelected}
            />
          </div>
        );
    }
  };
  
  const renderView = () => (
    <ViewSelector
      defaultOptions={[GridType.DEFAULT, GridType.CATEGORY, GridType.LOCATION]}
      contentType={viewType}
      setContentType={setViewType}
      saveViewMode={saveViewMode}
      onSavedViewList={setSavedViewList}
      onDataExport={handleDataExport}
    />
  );

  const onEquipmentSearch = (isReset: boolean, searchValue: MasterSearchInputValue) => {
    if(isReset) {
      setMasterSearchInput(null);
      setFilterValue({
        isCritical: undefined,
        isExpired: undefined,
        isRestricted: undefined,
        hasWork: undefined,
        searchText: undefined,
      });
      setSkip(0);
    }
    else {
      setMasterSearchInput(searchValue);
      setSkip(0);
    }
  };

  const renderMasterSearch = () => (
    <MasterSearchInput
      recordType={RecordType.Equipment}
      label="Equipment search"
      onChange={onEquipmentSearch}
    />
  );

  const onEnterHoursClick = () => {
    setShowHoursDialog(true);
  };

  const onHoursModalCancel = () => {
    setShowHoursDialog(false);
  };

  const actions = [
    {
      icon: 'clock',
      title: 'Enter Hours',
      onClick: onEnterHoursClick,
      color: 'inherit',
      iconbutton: true,
      disabled: moduleReadOnly,
    },
    viewType === GridType.DEFAULT
      ?
        {
          customComponent: (
            <UpdateRecordsButton
              onSubmit={handleUpdateMultipleRecords}
              visible={moduleReadOnly || isEmptyObject(rowSelected)}
              recordType={RecordType.Equipment}
              recordsNumber={rowSelected ? Object.keys(rowSelected).length : 0}
              options={RECORDS_UPDATE_OPTIONS}
              saveLoading={bulkUpdateEquipmentLoading}
            />
          ),
        } 
      : null,
    viewType === GridType.DEFAULT
      ? 
        {
          customComponent: (
            <DeleteRecordBtn
              visible={moduleReadOnly || isEmptyObject(rowSelected)}
              records={rowSelected}
              onValidate={handleDeleteMultipleRecords}
              warningMessage={equipmentDeleteWarningMessage}
              loading={bulkRemoveEquipmentLoading}
            />
          ),
        }
      : null,
    {
      icon: 'add',
      title: 'New EQ',
      onClick: handleCreateClick,
    },
  ].filter(Boolean) as Action[]; // Remove null values and assert type

  return (
    <div className="flex flex-grow flex-col overflow-auto">
      <PageHeader
        title="Equipment Manager"
        actions={actions}
        renderView={renderView}
        renderMasterSearch={renderMasterSearch}
      >
        <div className="flex md:hidden">{renderView()}</div>
      </PageHeader>

      <div ref={containerRef} className="flex flex-grow flex-col w-full">
        {renderContent()}
      </div>

      <PageDrawer onClose={handleCancel} visible={!!selectedEquipment}>
        {!!selectedEquipment && (
          <EquipmentDetailForm
            initialValue={selectedEquipment}
            isCreate={isCreate}
            onSave={handleSave}
            onDelete={handleDelete}
            onCancel={handleCancel}
            refetchQueryVariables={refetchQueryVariables}
            moduleReadOnly={moduleReadOnly}
          />
        )}
      </PageDrawer>

      <EquipmentHoursDialog 
        visible={showHoursDialog} 
        onCancel={onHoursModalCancel} 
      />

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </div>
  );
};

export default EquipmentPage;
