import { useEffect, useState } from 'react';
import { MasterSearchInputValue } from "src/components/MasterSearchInput";
import { GridType } from 'src/consts';

const useMergedFilters = <T extends Record<string, any>>(
  masterSearchInput: MasterSearchInputValue | null,
  initialFilterValue: T,
  gridView: GridType = GridType.DEFAULT
) => {
  const [filterValue, setFilterValue] = useState<T>(initialFilterValue);
  const inUseFilterValue = gridView === GridType.DEFAULT ? filterValue : initialFilterValue

  const mergeMasterSearchFilters = (masterSearchInput: MasterSearchInputValue | null, filterValue: T): T => {
    if (masterSearchInput === null) return filterValue;

    const newFilters = { ...filterValue };
    (newFilters as any).searchText = masterSearchInput.searchText;

    if (masterSearchInput.subFilterValue) {
      Object.keys(masterSearchInput.subFilterValue).forEach((key) => {
        const value = masterSearchInput.subFilterValue?.[key];
        (newFilters as any)[key] = value;
      });
    }

    return newFilters;
  };

  // Effect to update filterValue whenever masterSearchInput changes
  useEffect(() => {
    const combinedFilters = mergeMasterSearchFilters(masterSearchInput, inUseFilterValue);
    if (JSON.stringify(combinedFilters) !== JSON.stringify(filterValue)) {
      setFilterValue(combinedFilters);
    }
  }, [masterSearchInput, inUseFilterValue]);

  return [filterValue, setFilterValue] as const;
};

export default useMergedFilters;
